import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import classnames from 'classnames';
import { scroller } from 'react-scroll';
import getValidation from '../Utils/Validation';
import { customSelectStyle, customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';

export default function AgentPoll(props) {
  const { userInfo, history } = props;
  const polisFlag = `${process.env.REACT_APP_FRONT_DOMAIN}/widget-images/polis-flag.png`;
  const customStyles = customSelectStyle();
  const experienceList = [
    { label: 'До года', value: '1' },
    { label: 'От года до 3 лет', value: '1,3' },
    { label: 'Cвыше 3 лет', value: '3' },
  ];
  const yesNoList = [
    { label: 'Да', value: 'yes' },
    { label: 'Нет', value: 'no' },
  ];
  const newProductsList = [
    { label: 'Каско', value: 'casco' },
    { label: 'Ипотека', value: 'mortgage' },
    { label: 'Имущество физических лиц (дома/квартиры)', value: 'property' },
    { label: 'Страхование выезжающих за рубеж', value: 'leaving' },
    { label: 'Страхование от несчастных случаев', value: 'accident' },
    { label: 'Другое', value: 'another' },
  ];
  const [pollComplete, setPollComplete] = useState(false);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [formData, setFormData] = useState({
    experience: {
      value: experienceList[0],
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    agentNet: {
      value: yesNoList[1],
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    leadBuying: {
      value: yesNoList[1],
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    site: {
      value: yesNoList[1],
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    widget: {
      value: yesNoList[1],
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    training: {
      value: yesNoList[1],
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    newProduct: {
      value: [],
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    anotherNewProduct: {
      value: '',
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    servicesWorkWith: {
      value: '',
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    companiesWorkWith: {
      value: '',
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
    crmWorkWith: {
      value: '',
      errorMessage: '',
      errorVisible: false,
      validationType: 'plain',
    },
  });

  const [loading, setLoading] = useState(false);

  const makeFormDataFlatJson = () => {
    const flatJson = {};
    Object.entries(formData).forEach(([key, value]) => {
      flatJson[key] = value.value;
    });

    return flatJson;
  };

  const formValid = () => {
    const validateFormData = formData;
    let validationSuccess = true;
    let errorBlockName = false;
    Object.keys(formData).forEach((field) => {
      if (field === 'experience'
        || field === 'agentNet'
        || field === 'leadBuying'
        || field === 'site'
        || field === 'widget'
        || field === 'training'
        || field === 'newProduct') {
        return false;
      }
      if (field === 'anotherNewProduct' && validateFormData.newProduct.value.value !== 'another') {
        return false;
      }
      let value;
      if (formData[field].validationType === 'select') {
        value = formData[field].value ? formData[field].value.value : null;
      } else {
        value = formData[field].value;
      }
      formData[field].errorMessage = getValidation(value, formData[field].validationType);
      if (formData[field].errorMessage) {
        validationSuccess = false;
        formData[field].errorVisible = true;
        if (!errorBlockName) {
          errorBlockName = field;
        }
      }
    });
    if (errorBlockName) {
      scroller.scrollTo(errorBlockName, {
        duration: 1200,
        delay: 100,
        smooth: true,
        offset: -100,
      });
    }
    setFormData({ ...validateFormData });
    return validationSuccess;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formValid()) {
      setLoading(true);
      const data = makeFormDataFlatJson();

      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent-poll/${userInfo.id}`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formData: data,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            setPollComplete(true);
          } else {
            showModalInfo(response.error);
          }
        })
        .catch((error) => {
          error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
          showModalInfo(error.customMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const goToProfile = (e) => {
    e.preventDefault();
    history.push('/profile');
  };

  const loadPoll = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent-poll/${userInfo.id}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((poll) => {
        if (poll.form_data) {
          const data = JSON.parse(poll.form_data);

          const experienceValue = experienceList.filter((option) => option.value === data.experience.value) ? data.experience : experienceList[0];
          const newProductValue = newProductsList.filter((option) => option.value === data.newProduct.value) ? data.newProduct : newProductsList[0];

          const loadedFormData = {
            experience: {
              value: experienceValue,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            agentNet: {
              value: data.agentNet,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            leadBuying: {
              value: data.leadBuying,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            site: {
              value: data.site,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            widget: {
              value: data.widget,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            training: {
              value: data.training,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            newProduct: {
              value: newProductValue,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            anotherNewProduct: {
              value: data.anotherNewProduct,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            servicesWorkWith: {
              value: data.servicesWorkWith,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            companiesWorkWith: {
              value: data.companiesWorkWith,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
            crmWorkWith: {
              value: data.crmWorkWith,
              errorMessage: '',
              errorVisible: false,
              validationType: 'plain',
            },
          };
          setFormData({ ...loadedFormData });
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      });
  };

  const changeInput = (e) => {
    const { target } = e;
    const field = target.name;

    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        errorMessage: '',
        errorVisible: false,
        value: target.value,
      },
    }));
  };

  const handleChangeSelect = (e, field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        value: e,
        errorVisible: false,
        errorMessage: '',
      },
    }));
  };

  useEffect(() => {
    if (formData.site.value === 'yes') {
      setFormData((prev) => ({
        ...prev,
        widget: yesNoList[1],
      }));
    }
  }, [formData.site.value]);

  useEffect(() => {
    const { anotherNewProduct } = formData;
    const another = formData.newProduct.value.findIndex((product) => product.value === 'another');
    if (another === -1) {
      anotherNewProduct.value = '';
      anotherNewProduct.errorMessage = '';
      anotherNewProduct.errorVisible = false;
    }
    setFormData((prev) => ({
      ...prev,
      anotherNewProduct,
    }));
  }, [formData.newProduct.value]);

  useEffect(() => {
    loadPoll();
  }, [userInfo]);

  return (
    <>
      <div className="position-relative h-100">
        {!pollComplete ? (
          <>
            <h1 className="page_title d-inline-block mr-3">Давайте познакомимся 😊 Расскажите немного о себе!</h1>
            {userInfo.poll_complete ? (
              <div className="form-group row">
                <div className="col-lg-6">
                  <FormNotificationColorLine type="ok">
                    Вы уже заполнили опрос ранее. Если вы хотите поменять сохраненные ответы, обратитесь, пожалуйста, к своему куратору.
                  </FormNotificationColorLine>
                </div>
              </div>
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="card mb-4">
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3 col">
                      <label>Как давно работаете в сфере страхования?</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="experience"
                        noOptionsMessage={() => 'Не найдено'}
                        placeholder="Опыт работы"
                        value={formData.experience.value}
                        onChange={(e) => { handleChangeSelect(e, 'experience'); }}
                        className="form-control-custom"
                        options={experienceList}
                        isDisabled={userInfo.poll_complete}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label>Есть ли у вас агентская сеть?</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="agentNet"
                        noOptionsMessage={() => 'Не найдено'}
                        placeholder="Агентская сеть"
                        value={formData.agentNet.value}
                        onChange={(e) => { handleChangeSelect(e, 'agentNet'); }}
                        className="form-control-custom"
                        options={yesNoList}
                        isDisabled={userInfo.poll_complete}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label>Интересна ли вам покупка лидов (заявки на страхование)?</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="leadBuying"
                        noOptionsMessage={() => 'Не найдено'}
                        placeholder="Покупка лидов"
                        value={formData.leadBuying.value}
                        onChange={(e) => { handleChangeSelect(e, 'leadBuying'); }}
                        className="form-control-custom"
                        options={yesNoList}
                        isDisabled={userInfo.poll_complete}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label>Есть ли у вас сайт?</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="site"
                        noOptionsMessage={() => 'Не найдено'}
                        placeholder="Есть ли сайт"
                        value={formData.site.value}
                        onChange={(e) => { handleChangeSelect(e, 'site'); }}
                        className="form-control-custom"
                        options={yesNoList}
                        isDisabled={userInfo.poll_complete}
                      />
                    </div>
                    {formData.site.value.value === 'yes' ? (
                      <>
                        <div className="col-lg-6 col-xl-3">
                          <label>Стоит ли виджет(модуль) е-осаго?</label>
                          <Select
                            classNamePrefix="react-select"
                            styles={customStyles}
                            name="widget"
                            noOptionsMessage={() => 'Не найдено'}
                            placeholder="Стоит ли виджет"
                            value={formData.widget.value}
                            onChange={(e) => { handleChangeSelect(e, 'widget'); }}
                            className="form-control-custom"
                            options={yesNoList}
                            isDisabled={userInfo.poll_complete}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label>Какие продукты вы хотите добавить в сервис онлайн-страхования?</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="newProduct"
                        isMulti
                        noOptionsMessage={() => 'Не найдено'}
                        placeholder="Новые продукты"
                        value={formData.newProduct.value}
                        onChange={(e) => { handleChangeSelect(e, 'newProduct'); }}
                        className="form-control-custom"
                        options={newProductsList}
                        isDisabled={userInfo.poll_complete}
                      />
                    </div>
                    {formData.newProduct.value.findIndex((product) => product.value === 'another') !== -1 ? (
                      <>
                        <div className="col-lg-6 col-xl-3 d-flex flex-column justify-content-md-between">
                          <label htmlFor="servicesWorkWith">Введите свой вариант</label>
                          <input
                            value={formData.anotherNewProduct.value}
                            onChange={changeInput}
                            name="anotherNewProduct"
                            type="text"
                            className={classnames('form-control', { error: formData.anotherNewProduct.errorVisible })}
                            id="anotherNewProduct"
                            disabled={userInfo.poll_complete}
                          />
                          {formData.anotherNewProduct.errorVisible && (
                            <div className="validation-error">
                              {formData.anotherNewProduct.errorMessage}
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label htmlFor="servicesWorkWith">С какими агентскими сервисами вы уже работаете?</label>
                      <input
                        value={formData.servicesWorkWith.value}
                        onChange={changeInput}
                        name="servicesWorkWith"
                        type="text"
                        className={classnames('form-control', { error: formData.servicesWorkWith.errorVisible })}
                        id="servicesWorkWith"
                        disabled={userInfo.poll_complete}
                      />
                      {formData.servicesWorkWith.errorVisible && (
                        <div className="validation-error">
                          {formData.servicesWorkWith.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label htmlFor="servicesWorkWith">С какими страховыми компанями вы уже работаете?</label>
                      <input
                        value={formData.companiesWorkWith.value}
                        onChange={changeInput}
                        name="companiesWorkWith"
                        type="text"
                        className={classnames('form-control', { error: formData.companiesWorkWith.errorVisible })}
                        id="companiesWorkWith"
                        disabled={userInfo.poll_complete}
                      />
                      {formData.companiesWorkWith.errorVisible && (
                        <div className="validation-error">
                          {formData.companiesWorkWith.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label htmlFor="servicesWorkWith">Какой CRM системой вы пользуетесь?</label>
                      <input
                        value={formData.crmWorkWith.value}
                        onChange={changeInput}
                        name="crmWorkWith"
                        type="text"
                        className={classnames('form-control', { error: formData.crmWorkWith.errorVisible })}
                        id="crmWorkWith"
                        disabled={userInfo.poll_complete}
                      />
                      {formData.crmWorkWith.errorVisible && (
                        <div className="validation-error">
                          {formData.crmWorkWith.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 col-xl-3">
                      <label>Интересно ли вам обучение в школе агентов Polis.online?</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="training"
                        noOptionsMessage={() => 'Не найдено'}
                        placeholder="Обучение в школе агентов"
                        value={formData.training.value}
                        onChange={(e) => { handleChangeSelect(e, 'training'); }}
                        className="form-control-custom"
                        options={yesNoList}
                        isDisabled={userInfo.poll_complete}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <button disabled={loading || userInfo.poll_complete} className="btn btn-success mr-3" type="submit">Отправить</button>
                  {loading && <ReactLoading className="loading-circle d-inline-block" type="spin" height={38} width={38} />}
                </div>
              </div>
            </form>
          </>
        ) : (
          <div className="agent-poll-success">
            {userInfo.domain !== 'agents' ? null : <div className="agent-poll-success__img"><img src={polisFlag} alt="Флаг" /></div>}
            <div className="agent-poll-success__info">
              <h1>Спасибо за прохождение опроса!</h1>
              <h4>Присоединяйтесь к нашему <a rel="noreferrer" className="dotted_link" target="_blank" href="https://t.me/polis_online">Telegram-каналу</a> - там все новости в оперативном режиме.</h4>
              <p>Осталось заполнить профиль для получения вознаграждения.</p>
              <button className="btn btn-success" type="button" onClick={goToProfile}>Далее</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
