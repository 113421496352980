import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid';
import validate from 'validate.js/validate';

const constraints = {
  password: {
    presence: {
      message: 'Заполните поле',
    },
    format: {
      pattern: '(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$',
      message: 'pattern!!!',
    },
  },
  passwordSubmit: {
    equality: 'password',
  },
};

const validator = (field, value) => {
  const object = {};
  object[field] = value;
  const constraint = constraints[field];
  const result = validate(object, { [field]: constraint });
  if (result) {
    return result[field][0];
  }
  return null;
};

class MyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalWindow: false,
      password: '',
      passwordError: '',
      passwordSubmitError: '',
      visiblePassword: false,
      visiblePasswordSubmit: false,
      type: 'password',
      token: '',
    };
  }


  componentDidMount() {
    const token = document.location.href.substr(document.location.href.lastIndexOf('/') + 1);
    this.setState({ token });
  }

  onCloseModalWindow = () => {
    const { history } = this.props;
    history.replace('/login');
  };

  onVisibleChange = (visible) => {
    this.setState({
      [visible]: [visible],
    });
  };

  handleChangePassword = (e) => {
    const passwordError = validator('password', e.target.value);
    this.setState({
      password: e.target.value,
      passwordError,
    });
  };

  handleChangePasswordSubmit = (e) => {
    const { password } = this.state;
    const passwordSubmitError = validate({
      passwordSubmit: e.target.value, password,
    }, constraints);
    this.setState({
      passwordSubmitError,
    });
  };

  passwordShowHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { type } = this.state;
    this.setState({
      type: type === 'input' ? 'password' : 'input',
    });
  };

  handleFormResetSubmit = (e) => {
    e.preventDefault();
    const {
      passwordError, passwordSubmitError, token, password,
    } = this.state;
    if (passwordError == null) {
      this.setState({ visiblePassword: false });
      if (passwordSubmitError == null) {
        this.setState({ visiblePasswordSubmit: false });
        fetch(`${process.env.REACT_APP_API_DOMAIN}/password/find/${token}`, {
        })
          .then((response) => response.json())
          .then(() => {
            fetch(`${process.env.REACT_APP_API_DOMAIN}/password/reset`, {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                theme: window.location.host.split('.')[0],
                token,
                password,
                password_confirmation: password,
              }),
            })
              .then((response) => response.json())
              .then(() => {
                this.setState({ openModalWindow: true });
              });
          });
      } else {
        this.setState({
          visiblePasswordSubmit: true,
        });
      }
    } else {
      this.setState({
        visiblePassword: true,
      });
    }
  };

  render() {
    const {
      openModalWindow,
      visiblePassword,
      visiblePasswordSubmit,
      type,
    } = this.state;
    const { theme } = this.props;
    const toolTipStyle = {
      'max-width': '320px',
    };
    return (
      <>
        <div className="login-page text-center">
          <form className="form-signin" onSubmit={this.handleFormResetSubmit}>
            <img className="mb-4" src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logo}`} alt="" />
            <h1 className="h3 mb-3 font-weight-normal">Введите новый пароль</h1>
            <label htmlFor="inputPassword" className="sr-only">Пароль</label>
            <Tooltip
              visible={visiblePassword}
              animation="zoom"
              onVisibleChange={() => this.onVisibleChange('visiblePassword')}
              overlay={<span>Пароль должен содержать минимум 8 символов, заглавную букву, строчную букву и цифру</span>}
              trigger=""
              overlayStyle={toolTipStyle}
            >
              <input data-for="inputPassword" data-tip="inputPassword" id="inputPassword" name="password" className="form-control mt-2" placeholder="Придумайте пароль" type={type} onChange={this.handleChangePassword} />
            </Tooltip>
            <FontAwesomeIcon className="password-show" onClick={this.passwordShowHide} icon={type === 'input' ? faEyeSlash : faEye} />
            <Tooltip
              visible={visiblePasswordSubmit}
              animation="zoom"
              onVisibleChange={() => this.onVisibleChange('visiblePasswordSubmit')}
              overlay={<span>Пароль должен совпадать</span>}
              trigger=""
              overlayStyle={toolTipStyle}
            >
              <input data-for="inputPasswordSubmit" data-tip="inputPasswordSubmit" id="inputPasswordSubmit" name="passwordSubmit" className="form-control" placeholder="Потдвердите пароль" type={type} onChange={this.handleChangePasswordSubmit} />
            </Tooltip>
            <button className="btn btn-lg btn-success btn-block" type="submit">Продолжить</button>
          </form>
        </div>
        <Modal classNames={{ overlay: 'modal-window' }} open={openModalWindow} onClose={this.onCloseModalWindow} center>
          <h4>Пароль успешно восстановлен.</h4>
        </Modal>
      </>
    );
  }
}

export default MyComponent;
