import React from 'react';
import {
  CalculatorIcon, ChatBubbleLeftRightIcon, CalendarIcon, BanknotesIcon,
} from '@heroicons/react/24/outline';
import IframeResizer from 'iframe-resizer-react';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

export default function KaskoRequest(props) {
  const { userId, history } = props;
  const domain = window.location.host.split('.')[0];
  return (
    <>
      {true ? (
        <>
          <h1 className="page_title">Страхование КАСКО</h1>
          <h2>Расчет и страхование полиса у 22 страховых компаний</h2>
          <div className="dropdown_block mb-2">
            <a href={`${process.env.REACT_APP_BACKEND_DOMAIN}/storage/public/admin_documents/regulations.pdf`} rel="noopener noreferrer" target="_blank">
              <div className="dropdown_block__target no-dropdown">
                Регламент
                <i className="icon icon_pdf" />
              </div>
            </a>
          </div>
          <div className="partner-scheme mb-5">
            <div className="partner-header mb-2">Схема работы</div>
            <div className="partner-scheme-inner">
              <div className="partner-scheme-item">
                <div>
                  <CalculatorIcon />
                </div>
                <div className="partner-scheme-text">Заполните данные для предрасчёта, выберите вариант</div>
              </div>
              <div className="partner-scheme-item">
                <div>
                  <ChatBubbleLeftRightIcon />
                </div>
                <div className="partner-scheme-text">Ожидайте звонка от менеджера колл-центра для уточнения всех условий</div>
              </div>
              <div className="partner-scheme-item">
                <div>
                  <CalendarIcon />
                </div>
                <div className="partner-scheme-text">Согласуем полис со страховой и пришлём ссылку на оплату</div>
              </div>
              <div className="partner-scheme-item">
                <div>
                  <BanknotesIcon />
                </div>
                <div className="partner-scheme-text">Вышлем полис и начислим комиссию на ваш баланс</div>
              </div>
            </div>
          </div>
          <div className="d-flex partner-screen">
            <IframeResizer
              src={`https://kasko.insapp.ru?apiKey=d3d10c6c4d2741e0b480f78ead3291f1&cpaclientuid=${userId}`}
              id="iFrameResizer0"
              style={{ flex: '1 1 0%' }}
            />
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <FormNotificationColorLine offsetBottom="1rem">
                <ul className="mb-0">
                  <li>Оформление полиса происходит в несколько этапов;</li>
                  <li>Заполняете данные, производите предварительный расчет и выбираете понравившийся вариант;</li>
                  <li>Менеджер колл-центра связывается с Вами и уточняет все детали, запрашивает документы по списку;</li>
                  <li>При необходимости осмотра ТС менеджер согласует с вами дату и время;</li>
                  <li>Оплата полиса производится по ссылке в страховую компанию;</li>
                  <li>Комиссионное вознаграждение можно посмотреть <a rel="noreferrer" className="dotted_link" target="_blank" href="https://docs.google.com/spreadsheets/d/13NqFXdOSaz32n0HkF72AQqwq_4zG6xDsw0jC8GrmG6k/edit">по ссылке</a>;</li>
                  <li>В течение 3-х рабочих дней полис появится у Вас в личном кабинете в разделе «Полисы»;</li>
                  <li>Вознаграждение автоматически начисляется на баланс;</li>
                  <li>Вывод средств происходит в стандартном режиме в зависимости от вашего юр. статуса.</li>
                </ul>
              </FormNotificationColorLine>
            </div>
          </div>
        </>
      ) : (
        <div className="loading-banner">
          <h2>Временно не работает, используйте сервис <a className="dotted_link" href="" onClick={(e) => { e.preventDefault(); history.push('/insurance_request'); }}>«Заявки на Страхование»</a></h2>
        </div>
      )}
    </>
  );
}
