import React, { useState, useRef, useEffect } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import BonusesInfo from './BonusesInfo';
import BonusesHistoryTable from './BonusesHistoryTable';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import TabNavList from '../Layout/Tabs/TabNavList';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';

export default function Bonuses(props) {
  const {
    userInfo, updateUser, history, location, setTour,
  } = props;

  const tableRef = useRef();
  const urlParams = queryString.parse(location.search);
  const [activeTab, setActiveTab] = useState(urlParams.activeTab ? urlParams.activeTab : '1');

  const openModalTableCustomized = () => {
    tableRef.current.openModalTableCustomized(true);
  };

  const downloadReport = () => {
    tableRef.current.downloadReport();
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    history.push({ search: `?activeTab=${tab}` });
  };

  useEffect(() => {
    if (userInfo.curator_obj.full_time_employee === 0) {
      setActiveTab('2');
    }
  }, [userInfo.curator_obj.full_time_employee]);

  useEffect(() => {
    document.title = 'Бонусы';
    setTour('bonus');
  }, []);

  return (
    <div className="position-relative">
      <h1 className="page-wrapper__title d-inline-block mr-3 first-step">Ваши бонусы</h1>
      {(activeTab === '3' || activeTab === '2') ? (
        <>
          <div className="page_header__control mb-2 mb-md-0 mr-2">
            <Button colorType="grayBordered" type="border" width="auto" onClick={downloadReport}>
              <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
              Скачать в Excel
            </Button>
          </div>
          <div className="page_header__control mb-2 mb-md-0">
            <Button colorType="grayBordered" type="border" width="auto" onClick={openModalTableCustomized}>
              <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
              Настроить таблицу
            </Button>
          </div>
        </>
      ) : null}
      <TabNavList>
        {(userInfo.curator_obj.full_time_employee === 1) ? (
          <>
            <TabNavItem
              active={activeTab === '1'}
              onClick={() => { toggle('1'); }}
              text="Бонусная система"
            />
            <TabNavItem
              active={activeTab === '3'}
              onClick={() => { toggle('3'); }}
              text="История"
            />
          </>
        ) : null}
      </TabNavList>
      <TabContent activeTab={activeTab}>
        {(userInfo.curator_obj.full_time_employee === 1) ? (
          <TabPane tabId="1">
            {activeTab === '1' ? (
              <BonusesInfo userInfo={userInfo} updateUser={updateUser} history={history} toggle={toggle} />
            ) : null}
          </TabPane>
        ) : null}
        {(userInfo.curator_obj.full_time_employee === 1) ? (
          <TabPane tabId="3">
            {activeTab === '3' ? (
              <BonusesHistoryTable userInfo={userInfo} ref={tableRef} history={history} tab={activeTab} />
            ) : null}
          </TabPane>
        ) : null}
      </TabContent>
    </div>
  );
}
