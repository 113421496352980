import React, {
  useContext, useMemo, useRef, useState,
} from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCheckCircle,
} from '@fortawesome/fontawesome-free-regular';
import DatePicker from 'react-datepicker';
import ReactLoading from 'react-loading';
import { CSVReader } from 'react-papaparse';
import { subYears } from 'date-fns';
import getValidation from '../../Utils/Validation';
import {
  customFetch,
  customSelectStyle, handleOnDrop, handleOnError, handleOnRemoveFile,
  switchLanguage,
  toLocalDate,
  validateFields,
} from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import getMask from '../../Utils/Masks';

export default function OSAGOProlongationForm(props) {
  const { history, employee } = props;
  const customStyles = useMemo(() => customSelectStyle(), []);
  const insuranceOptions = useMemo(() => [
    { label: 'Ренессанс страхование', value: 'renessans' },
    { label: 'Росгосстрах', value: 'rgs' },
    { label: 'Альфа страхование', value: 'alpha' },
  ], []);
  const [formData, setFormData] = useState({
    insurance: {
      value: insuranceOptions[0],
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    prevPolicyNumber: {
      value: null,
      errorMessage: '',
      validationType: 'policyFull',
      validationRequired: true,
    },
    startDate: {
      value: null,
      errorMessage: '',
      validationType: 'date',
      validationRequired: true,
    },
    birthDate: {
      value: null,
      errorMessage: '',
      validationType: 'date',
      validationRequired: true,
    },
    passportNumberSerie: {
      value: '',
      errorMessage: false,
      validationType: 'passportNumberSerie',
      validationRequired: true,
    },
    firstName: {
      value: '',
      errorMessage: false,
      validationType: 'name',
      validationRequired: true,
    },
    lastName: {
      value: '',
      errorMessage: false,
      validationType: 'name',
      validationRequired: true,
    },
    middleName: {
      value: '',
      errorMessage: false,
      validationType: 'name',
      validationRequired: true,
    },
    phone: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'phone',
    },
    email: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'email',
    },
  });
  const [loadingProlongation, setLoadingProlongation] = useState(false);
  const [existPolicy, setExistPolicy] = useState({});
  const [fileData, setFileData] = useState([]);
  const [fileError, setFileError] = useState('');

  const { showModalInfo } = useContext(ModalMessagesContext);
  const fileRef = useRef(null);

  const handleChangeSelect = (event, field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        value: event,
        errorMessage: '',
      },
    }));
  };

  const handleChangeInput = (e, translate = false, upperCase = false, pureValue = false, firstCapital = false) => {
    const { target } = e;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (pureValue) {
      value = value.replace(/[^a-zа-я0-9]+/ig, '');
    }
    if (translate) {
      value = switchLanguage(value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    if (firstCapital) {
      value = value.toLowerCase();
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    setFormData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value,
        errorMessage: '',
      },
    }));
  };

  const handleChangeDate = (date, field) => {
    let { errorMessage } = formData[field];
    if (date) {
      errorMessage = '';
    }
    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        value: date,
        errorMessage,
      },
    }));
  };

  const handleValidate = (e) => {
    const { target } = e;
    let { value } = formData[target.id];
    if (value && typeof value === 'string') {
      value = value.trim();
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    const errorMessage = getValidation(value, formData[target.id].validationType);

    setFormData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value,
        errorMessage,
      },
    }));
  };

  const validateForm = () => {
    let currentFormData;

    switch (formData.insurance.value.value) {
      case 'alpha':
        currentFormData = {
          insurance: formData.insurance, birthDate: formData.birthDate, phone: formData.phone, email: formData.email,
        };
        break;
      case 'renessans':
        currentFormData = {
          insurance: formData.insurance, startDate: formData.startDate, prevPolicyNumber: formData.prevPolicyNumber,
        };
        break;
      case 'rgs':
        currentFormData = {
          insurance: formData.insurance, birthDate: formData.birthDate, firstName: formData.firstName, lastName: formData.lastName, middleName: formData.middleName, passportNumberSerie: formData.passportNumberSerie, prevPolicyNumber: formData.prevPolicyNumber, phone: formData.phone, email: formData.email, startDate: formData.startDate,
        };
        break;
      default:
        currentFormData = { ...formData };
    }

    const validationResult = validateFields(currentFormData);
    const newFormData = { ...formData };
    Object.keys(validationResult.result).forEach((field) => {
      newFormData[field] = validationResult.result[field];
    });
    setFormData(newFormData);
    return validationResult.validationSuccess;
  };

  const sendProlongationToClients = () => {
    if (loadingProlongation) {
      showModalInfo('Дождитесь окончания предыдущего расчета');
    } else {
      setLoadingProlongation(true);
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/osago/send-prolongation-info/${formData.insurance.value.value}`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          leads: fileData,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error, 'error');
          } else {
            showModalInfo('Задача успешно создана');
          }
        })
        .catch((error) => {
          error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
          showModalInfo(error.customMessage, 'error');
        })
        .finally(() => {
          setLoadingProlongation(false);
        });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formIsValid = validateForm();
    if (formIsValid) {
      setLoadingProlongation(true);
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/osago/get-prolongation-info/${formData.insurance.value.value}`, {
        method: 'post',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          policyNumber: formData.prevPolicyNumber.value,
          startDate: toLocalDate(formData.startDate.value || new Date()),
          birthday: toLocalDate(formData.birthDate.value || new Date()),
          phone: formData.phone.value,
          email: formData.email.value,
          firstName: formData.firstName.value,
          lastName: formData.lastName.value,
          middleName: formData.middleName.value,
          passportNumberSerie: formData.passportNumberSerie.value,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error, 'error');
          }
          if (response.status === 'policy exist') {
            setExistPolicy(response.policy);
          }
          if (response.status === 'prolongation') {
            history.push(`/osago/${response.policyId}?activeTab=1&getChosenOffer=true`);
          }
        })
        .catch(() => {
          showModalInfo('Произошла ошибка', 'error');
        })
        .finally(() => {
          setLoadingProlongation(false);
        });
    }
  };

  return (
    <>
      <form onSubmit={submitForm} id="osago-prolongation-form" className="modal-form">
        <div className="card mb-4">
          <div className="card-header fill_section__title">
            <span className="title_num">1</span>
            <span>Выберите страховую компанию</span>
          </div>
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                name="insurance"
                noOptionsMessage={() => 'Не найдено'}
                inputId="insurance"
                isSearchable={false}
                value={formData.insurance.value.value ? formData.insurance.value : null}
                onChange={(e) => handleChangeSelect(e, 'insurance')}
                options={insuranceOptions}
              />
            </div>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header fill_section__title">
            <span className="title_num">2</span>
            <span>Введите данные</span>
          </div>
          <div className="form-group row">
            <div className="col-xl-4">
              <label htmlFor="prevPolicyNumber">Номер предыдущего полиса</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                onChange={handleChangeInput}
                id="prevPolicyNumber"
                placeholder=""
                name="prevPolicyNumber"
                className={classnames('form-control', { error: formData.prevPolicyNumber.errorMessage })}
                value={formData.prevPolicyNumber.value}
              />
              {formData.prevPolicyNumber.errorMessage && (
                <div className="validation-error">
                  {formData.prevPolicyNumber.errorMessage}
                </div>
              )}
            </div>

            {formData.insurance.value.value === 'renessans' ? (
              <div className="col-xl-4">
                <label htmlFor="startDate">Дата начала нового полиса</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  onBlur={handleValidate}
                  className={classnames('form-control', { error: formData.startDate.errorMessage })}
                  selected={formData.startDate.value}
                  onChange={(date) => handleChangeDate(date, 'startDate')}
                  name="startDate"
                  id="startDate"
                  locale={ru}
                  minDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formData.startDate.errorMessage && (
                  <div className="validation-error">
                    {formData.startDate.errorMessage}
                  </div>
                )}
              </div>
            ) : null}

            {formData.insurance.value.value === 'rgs' ? (
              <>
                <div className="col-lg-4">
                  <label htmlFor="startDate">Дата начала нового полиса</label>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false },
                    }}
                    onBlur={handleValidate}
                    className={classnames('form-control', { error: formData.startDate.errorMessage })}
                    selected={formData.startDate.value}
                    onChange={(date) => handleChangeDate(date, 'startDate')}
                    name="startDate"
                    id="startDate"
                    locale={ru}
                    minDate={new Date()}
                    showYearDropdown
                    showMonthDropdown
                    dateFormat="dd.MM.yyyy"
                    placeholderText="ДД.ММ.ГГГГ"
                    customInput={
                      <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                  />
                  <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                  {formData.startDate.errorMessage && (
                    <div className="validation-error">
                      {formData.startDate.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-4">
                  <label htmlFor="birthDate">Дата рождения страхователя</label>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false },
                    }}
                    onBlur={handleValidate}
                    className={classnames('form-control', { error: formData.birthDate.errorMessage })}
                    selected={formData.birthDate.value}
                    onChange={(date) => handleChangeDate(date, 'birthDate')}
                    name="birthDate"
                    id="birthDate"
                    locale={ru}
                    minDate={subYears(new Date(), 100)}
                    showYearDropdown
                    showMonthDropdown
                    dateFormat="dd.MM.yyyy"
                    placeholderText="ДД.ММ.ГГГГ"
                    customInput={
                      <InputMask mask="99.99.9999" inputMode="tel" />
                          }
                  />
                  <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                  {formData.birthDate.errorMessage && (
                    <div className="validation-error">
                      {formData.birthDate.errorMessage}
                    </div>
                  )}
                </div>
              </>
            ) : null }
          </div>
          {formData.insurance.value.value === 'rgs' ? (
            <>
              <div className="form-group row">
                <div className="col-lg-4">
                  <label htmlFor="lastName">Фамилия</label>
                  <input
                    onBlur={handleValidate}
                    onChange={handleChangeInput}
                    name="lastName"
                    id="lastName"
                    placeholder="Иванов"
                    className={classnames('form-control', { error: formData.lastName.errorMessage })}
                    value={formData.lastName.value}
                  />
                  {formData.lastName.errorMessage && (
                  <div className="validation-error">
                    {formData.lastName.errorMessage}
                  </div>
                  )}
                </div>
                <div className="col-lg-4">
                  <label htmlFor="firstName">Имя</label>
                  <input
                    onBlur={handleValidate}
                    onChange={handleChangeInput}
                    name="firstName"
                    id="firstName"
                    placeholder="Иван"
                    className={classnames('form-control', { error: formData.firstName.errorMessage })}
                    value={formData.firstName.value}
                  />
                  {formData.firstName.errorMessage && (
                  <div className="validation-error">
                    {formData.firstName.errorMessage}
                  </div>
                  )}
                </div>
                <div className="col-lg-4">
                  <label htmlFor="middleName">Отчество</label>
                  <input
                    onBlur={handleValidate}
                    onChange={handleChangeInput}
                    name="middleName"
                    id="middleName"
                    placeholder="Иванович"
                    className={classnames('form-control', { error: formData.middleName.errorMessage })}
                    value={formData.middleName.value}
                  />
                  {formData.middleName.errorMessage && (
                  <div className="validation-error">
                    {formData.middleName.errorMessage}
                  </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <label htmlFor="passportNumberSerie">Серия и номер паспорта</label>
                  <input
                    onBlur={handleValidate}
                    onChange={handleChangeInput}
                    name="passportNumberSerie"
                    id="passportNumberSerie"
                    placeholder="1111 111111"
                    className={classnames('form-control', { error: formData.passportNumberSerie.errorMessage })}
                    value={formData.passportNumberSerie.value}
                  />
                  {formData.passportNumberSerie.errorMessage && (
                  <div className="validation-error">
                    {formData.passportNumberSerie.errorMessage}
                  </div>
                  )}
                </div>
                <div className="col-lg-4">
                  <label htmlFor="email">Email</label>
                  <input
                    onBlur={handleValidate}
                    onChange={handleChangeInput}
                    name="email"
                    id="email"
                    placeholder="your@email.ru"
                    className={classnames('form-control', { error: formData.email.errorMessage })}
                    value={formData.email.value}
                  />
                  {formData.email.errorMessage && (
                  <div className="validation-error">
                    {formData.email.errorMessage}
                  </div>
                  )}
                </div>
                <div className="col-lg-4">
                  <label htmlFor="phone">Телефон</label>
                  <InputMask
                    maskChar=""
                    onBlur={handleValidate}
                    onChange={handleChangeInput}
                    name="phone"
                    id="phone"
                    placeholder="+7 (999) 999-99-99"
                    className={classnames('form-control', { error: formData.phone.errorMessage })}
                    mask={getMask('phone').mask}
                    formatChars={getMask('phone').formatChars}
                    value={formData.phone.value}
                  />
                  {formData.phone.errorMessage && (
                  <div className="validation-error">
                    {formData.phone.errorMessage}
                  </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
          {formData.insurance.value.value === 'alpha' ? (
            <div className="form-group row">
              <div className="col-lg-4">
                <label htmlFor="birthDate">Дата рождения страхователя</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  onBlur={handleValidate}
                  className={classnames('form-control', { error: formData.birthDate.errorMessage })}
                  selected={formData.birthDate.value}
                  onChange={(date) => handleChangeDate(date, 'birthDate')}
                  name="birthDate"
                  id="birthDate"
                  locale={ru}
                  minDate={subYears(new Date(), 100)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formData.birthDate.errorMessage && (
                  <div className="validation-error">
                    {formData.birthDate.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="email">Email</label>
                <input
                  onBlur={handleValidate}
                  onChange={handleChangeInput}
                  name="email"
                  id="email"
                  placeholder="your@email.ru"
                  className={classnames('form-control', { error: formData.email.errorMessage })}
                  value={formData.email.value}
                />
                {formData.email.errorMessage && (
                <div className="validation-error">
                  {formData.email.errorMessage}
                </div>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="phone">Телефон</label>
                <InputMask
                  maskChar=""
                  onBlur={handleValidate}
                  onChange={handleChangeInput}
                  name="phone"
                  id="phone"
                  placeholder="+7 (999) 999-99-99"
                  className={classnames('form-control', { error: formData.phone.errorMessage })}
                  mask={getMask('phone').mask}
                  formatChars={getMask('phone').formatChars}
                  value={formData.phone.value}
                />
                {formData.phone.errorMessage && (
                <div className="validation-error">
                  {formData.phone.errorMessage}
                </div>
                )}
              </div>
            </div>
          ) : null}

          {(employee && formData.insurance.value.value === 'renessans') ? (
            <div className="form-group row">
              <div className="col-lg-4">
                <CSVReader
                  ref={fileRef}
                  onDrop={(fileParsedData) => handleOnDrop(fileParsedData, fileRef, setFileError, setFileData)}
                  onError={() => handleOnError(setFileError, showModalInfo)}
                  noDrag
                  noProgressBar
                  onRemoveFile={() => handleOnRemoveFile(setFileError, setFileData)}
                  accept="text/csv, .csv, application/vnd.ms-excel"
                >
                  {({ file }) => (
                    <>
                      <label className="d-block" htmlFor="passport_page_two">Файл со списком полисов</label>
                      <div className="jq-file styler">
                        <div className="jq-file__name">{file ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /> {file.name}</> : null}</div>
                        {file
                          ? (<div onClick={(e) => fileRef.current.removeFile(e)} className="jq-file__remove" />)
                          : (<div className="jq-file__browse" />
                          )}
                        <input type="text" onClick={(e) => fileRef.current.open(e)} id="passport_page_two" name="passport_page_two" className="custom-input-file" />
                      </div>
                      {fileError && (
                        <div className="validation-error">
                          {fileError}
                        </div>
                      )}
                    </>
                  )}
                </CSVReader>
              </div>
            </div>
          ) : null}
        </div>
        <button disabled={loadingProlongation} type="submit" className="btn btn-success mr-3 mb-3">Оформить пролонгацию</button>
        {(employee && formData.insurance.value.value === 'renessans') ? <button onClick={sendProlongationToClients} disabled={!fileData.length || loadingProlongation} type="button" className="btn btn-success mr-3 mb-3">Отправить ссылку на пролонгацию по смс</button> : null}
        {loadingProlongation ? (
          <div className="form-group row">
            <div className="col-12">
              <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={40} width={40} />
            </div>
          </div>
        ) : null}
      </form>
      {existPolicy.id ? (
        <div>
          <strong>
            По данному номеру уже оформлен полис&nbsp;
            <a className="dotted_link" href={`/osago/${existPolicy.id}`}>№{existPolicy.id}</a>
            {existPolicy.order_date ? (<span>&nbsp;от {existPolicy.order_date}</span>) : null}
          </strong>
        </div>
      ) : null}
    </>
  );
}
