import React, {
  useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext,
} from 'react';
import MultiRef from 'react-multi-ref';
import { reactLocalStorage } from 'reactjs-localstorage';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import matchSorter from 'match-sorter';
import ReactExport from 'react-data-export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import {
  customFetch,
  setTableSorting,
  handleSubmitTableCustomized,
  getCustomizedColumns,
  handleTableCustomizedSetNone,
  handleTableCustomizedSetAll,
  onContextMenuClick,
  onTableCellClick,
  setTableSettings7,
  onFilteredChangeCustom,
  getDefaultSorting7,
} from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import Table from '../../Layout/Table';

function CheckPropertyHistoryTable(props, ref) {
  const {
    userInfo,
    type,
    history,
    tab,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);
  const simulateClick = new MultiRef();

  const tableRef = useRef(null);
  const customizeTableRef = useRef(null);
  const excelFile = useRef(null);

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ExcelFile;

  const [filtered, setFiltered] = useState([]);
  const [propertyHistoryList, setPropertyHistoryList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [excelColumns, setExcelColumns] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [goDownloadFile, setGoDownloadFile] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [activeFilterTab, setActiveFilterTab] = useState('1');
  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);

  const downloadReport = () => {
    const { ExcelColumn } = ReactExport.ExcelFile;
    const { filteredData } = tableRef.current.getTableData();
    const dataToDownload = [];
    for (let index = 0; index < filteredData.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (columns[colIndex].show !== false) {
          if (filteredData[index].values[columns[colIndex].accessor]) {
            recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].accessor];
          } else {
            recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].id];
          }
        }
      }
      dataToDownload.push(recordToDownload);
    }
    const excelcolumns = columns.filter((column) => column.show !== false && column.id !== 'select_cell').map((column) => <ExcelColumn label={column.Header} value={column.Header} />);
    setExcelColumns([...excelcolumns]);
    setDataSet([...dataToDownload]);
    setGoDownloadFile(true);
  };

  const openModalTableCustomized = () => {
    setFlagModalTableCustomized(true);
  };

  useImperativeHandle(ref, () => ({
    customizeTable: () => {
      openModalTableCustomized();
    },
    downloadReport: () => {
      downloadReport();
    },
  }));

  useEffect(() => {
    if (goDownloadFile) {
      excelFile.current.handleDownload();
      setGoDownloadFile(false);
    }
  }, [goDownloadFile]);

  const deleteRequest = (id) => {
    setLoading(true);
    setPropertyHistoryList([]);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/property_request/${id}`, {
      headers: {
        Authorization: token,
      },
      method: 'delete',
    })
      .then((response) => response.json())
      .then((requestData) => {
        setPropertyHistoryList(requestData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateTableColumns = () => {
    const newColumns = [{
      Header: '№',
      accessor: 'id',
      width: reactLocalStorage.getObject('propertyHistoryListSettings').id,
      show: reactLocalStorage.getObject('propertyHistoryListSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Агент',
      id: 'agentName',
      width: reactLocalStorage.getObject('propertyHistoryListSettings').agentName,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agentName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      accessor: (d) => (d.agent ? `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name}` : ''),
      show: reactLocalStorage.getObject('propertyHistoryListSettings').show_agentName !== false && type === 'all',
      showCustomized: type === 'all',
    }, {
      Header: 'Агент 1 уровня',
      width: reactLocalStorage.getObject('propertyHistoryListSettings').agentFirstLevelName,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['agentFirstLevelName'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      accessor: 'agentFirstLevelName',
      show: reactLocalStorage.getObject('propertyHistoryListSettings').show_agentFirstLevelName !== false && type === 'all',
      showCustomized: type === 'all',
    }, {
      Header: 'Кадастровый номер',
      id: 'kadNumber',
      width: reactLocalStorage.getObject('propertyHistoryListSettings').kadNumber,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['kadNumber'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      accessor: (d) => d.form_data.kadNumber,
      show: reactLocalStorage.getObject('propertyHistoryListSettings').show_kadNumber !== false,
      showCustomized: true,
    }, {
      Header: 'Адрес',
      id: 'address',
      width: reactLocalStorage.getObject('propertyHistoryListSettings').address,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['address'], threshold: matchSorter.rankings.WORD_STARTS_WITH }),
      filterAll: true,
      accessor: (d) => (d.form_data.address.value ? d.form_data.address.value : ''),
      show: reactLocalStorage.getObject('propertyHistoryListSettings').show_address !== false,
      showCustomized: true,
    }, {
      Header: 'Статус',
      width: reactLocalStorage.getObject('kbmReturnTableSettings').status,
      Cell: ({ row }) => {
        if (row.original.status === 'complete') return 'Завершено';
        if (row.original.status === 'processing') return 'В работе';
        if (row.original.status === 'reserved') return 'Ожидает оплаты';
        if (row.original.status === 'error') return 'Ошибка';
        return 'Черновик';
      },
      accessor: (d) => {
        if (d.status === 'complete') {
          return 'Завершено';
        } if (d.status === 'processing') {
          return 'В работе';
        } if (d.status === 'reserved') {
          return 'Ожидает оплаты';
        } if (d.status === 'error') {
          return 'Ошибка';
        }
        return 'Черновик';
      },
      Filter: 'selectColumnFilter',
      filter: 'strictEqualSelectFilterFn',
      show: reactLocalStorage.getObject('propertyHistoryListSettings').show_status !== false && type !== 'draft',
      showCustomized: type !== 'draft',
    }, {
      Header: '',
      width: 40,
      id: 'removeRequest',
      filterable: false,
      Cell: ({ row }) => (
        <div title="Удалить черновик"><FontAwesomeIcon onClick={() => deleteRequest(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
      show: type === 'draft',
    }, {
      Header: '',
      width: 40,
      id: 'downloadRequest',
      filterable: false,
      Cell: ({ row }) => row.original.link && (
        <a title="Скачать форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${row.original.link}`}><FontAwesomeIcon icon={faFilePdf} className="fa-fw fa-lg" /></a>
      ),
      show: type !== 'draft',
    }];
    setColumns(newColumns);
  };

  const loadPropertyHistory = () => {
    setLoading(true);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/property_request?type=${type}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPropertyHistoryList(data);
        setTimeout(() => { updateTableColumns(); }, 1000);
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!flagModalTableCustomized && tableRef) {
      updateTableColumns();
    }
  }, [flagModalTableCustomized]);

  useEffect(() => {
    setLoading(true);
    setPropertyHistoryList([]);

    let newFiltered = [];
    if (filtered.length) {
      newFiltered = filtered;
    } else {
      const urlParams = queryString.parse(window.location.search);
      Object.keys(urlParams).forEach((filter) => {
        if (filter !== 'filter_date') {
          newFiltered.push({ id: filter, value: urlParams[filter] });
        }
      });
    }
    setFiltered(newFiltered);
    loadPropertyHistory();
  }, [userInfo]);

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTableCustomized}
        onClose={() => { setFlagModalTableCustomized(false); }}
        center
      >
        <h4>Настройка отображения таблицы</h4>
        <p onClick={() => handleTableCustomizedSetAll(simulateClick)} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
        <p onClick={() => handleTableCustomizedSetNone(simulateClick)} className="text-muted select-button d-inline-block">Отменить все</p>
        <form ref={customizeTableRef} onSubmit={(e) => handleSubmitTableCustomized(e, 'propertyHistoryListSettings', setFlagModalTableCustomized, updateTableColumns)} id="tableCustomized">
          <ul className="two-column-list">
            {getCustomizedColumns(columns, simulateClick)}
          </ul>
          <button type="submit" className="btn btn-success">Сохранить</button>
        </form>
      </Modal>
      <div className="d-none">
        <ExcelFile ref={excelFile} element={<button type="button">Download Data</button>}>
          <ExcelSheet data={dataSet} name="OsagoPoliciesInfo">
            {excelColumns}
          </ExcelSheet>
        </ExcelFile>
      </div>
      <Table
        ref={tableRef}
        defaultSorted={getDefaultSorting7('propertyHistoryListSettings')}
        onContextMenuClick={(e, row) => onContextMenuClick(e, 'check_property', row.original.id)}
        onTableCellClick={(e, row, cell) => onTableCellClick(e, row, cell, 'check_property', history)}
        defaultFiltered={filtered}
        data={propertyHistoryList}
        columns={columns}
        loading={loading}
        noDataText="Нет истории запросов"
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'propertyHistoryListSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings7(newResized, 'propertyHistoryListSettings');
        }}
        onFilteredChange={(filtered) => setFiltered(onFilteredChangeCustom(filtered, null, tab))}
      />
    </>
  );
}

export default forwardRef(CheckPropertyHistoryTable);
