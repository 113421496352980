import React from 'react';
import styled from 'styled-components';
import SidebarItem from './SidebarItem';
import SocialLinks from './SocialLinks';
import InterfaceTourSidebar from '../../InterfaceTours/InterfaceTourSidebar';

const Wrapper = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0 0 48px;
  width: 100%;
  padding-bottom: 30px;
`;

const BottomBlocksContainer = styled.div`
  margin: auto 13px 16px;
`;

export default function SidebarList(props) {
  const {
    sidebarActive, updateUser, getTourList, setTour, theme, userInfo, toggleSidebar, sidebarIsFull,
  } = props;

  return (
    <Wrapper>
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="sector"
        text="Главная"
        to="/dashboard"
        customClassName="tour_2_dt_step_4"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="documentPlus"
        text="Оформить"
        to="/products"
        customClassName="test_tour_dt_step_1 tour_2_dt_step_5"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="drafts"
        text="Черновики"
        to="/drafts"
        customClassName="tour_2_dt_step_6"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="policies"
        text="Полисы"
        to="/policies"
        customClassName="tour_2_dt_step_7"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="leads"
        text="Лиды"
        to="/requests-list"
        customClassName="tour_2_dt_step_11"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      {userInfo.hide_commission === 0 && (
        <SidebarItem
          sidebarActive={sidebarActive}
          sidebarIsFull={sidebarIsFull}
          icon="statistic"
          text="Статистика"
          to="/reports"
          customClassName="tour_2_dt_step_9"
          theme={theme}
          toggleSidebar={toggleSidebar}
        />
      )}
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="userGroup"
        text="Агенты"
        to="/agents"
        customClassName="tour_2_dt_step_8"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="widgets"
        text="Виджеты"
        to="/widgets"
        customClassName="tour_2_dt_step_12"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      <SidebarItem
        sidebarActive={sidebarActive}
        sidebarIsFull={sidebarIsFull}
        icon="referal"
        text="Реф. программа"
        to="/referral"
        customClassName="tour_2_dt_step_10"
        theme={theme}
        toggleSidebar={toggleSidebar}
      />
      {(userInfo.role === 'superadmin' || userInfo.role === 'admin') && (
        <>
          <SidebarItem
            sidebarActive={sidebarActive}
            sidebarIsFull={sidebarIsFull}
            icon="briefcase"
            text="Администрирование"
            to="/admin"
            theme={theme}
            toggleSidebar={toggleSidebar}
          />
          <SidebarItem
            sidebarActive={sidebarActive}
            sidebarIsFull={sidebarIsFull}
            icon="question"
            text="FAQ-база знаний"
            to="/faq_knowledge"
            theme={theme}
            toggleSidebar={toggleSidebar}
          />
        </>
      )}
      {sidebarActive || sidebarIsFull ? (
        <BottomBlocksContainer>
          {userInfo.show_interface_tours && userInfo.interface_tours_percent < 100 ? (
            <InterfaceTourSidebar updateUser={updateUser} userInfo={userInfo} getTourList={getTourList} setTour={setTour} theme={theme} />
          ) : null}
          {theme.link_to_telegram ? <SocialLinks link={theme.link_to_telegram} /> : null}
        </BottomBlocksContainer>
      ) : null}
    </Wrapper>
  );
}
