import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/fontawesome-free-solid';
import { customFetch } from '../../Utils/Helpers';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

export default class OsagoIntegrationAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redColor: 'rgb(255, 0, 0)',
      greenColor: 'rgb(0, 197, 60)',
      integrationAnswers: [],
      formId: '',
      calculactionNotExist: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      const formId = match.params.id;
      this.setState({ formId }, () => this.loadIntegrationAnswers());
    } else {
      this.setState({ calculactionNotExist: 'Расчет не производился' });
    }
  }

  copyForm = (e) => {
    e.preventDefault();
    const { copyDraft } = this.props;
    copyDraft();
  };

  loadIntegrationAnswers = () => {
    const { formId } = this.state;
    const { setIntegrationAnswersLoading } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setIntegrationAnswersLoading(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${formId}/integrationAnswers`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          this.setState({
            integrationAnswers: response.filteredAnswers,
          });
        } else {
          this.setState({
            calculactionNotExist: response.error,
          });
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setIntegrationAnswersLoading(false);
      });
  };

  render() {
    const {
      integrationAnswers, calculactionNotExist, greenColor, redColor,
    } = this.state;
    const { hideCommission, integrationAnswersLoading } = this.props;
    return (
      <>
        {!integrationAnswersLoading ? (
          <>
            {!calculactionNotExist ? (
              <>
                <h2>Предварительные данные, которые были получены в предыдущем расчете:</h2>
                {integrationAnswers.map((item) => (
                  <React.Fragment key={item.id}>
                    <div className="company_item">
                      <div className="company_item__image_block">
                        <div className="company_item__image">
                          <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${item.logo}`} alt="" width="142" height="62" />
                          <div className="company_item__star mt-2">
                            <FontAwesomeIcon icon={faStar} className="fa-fw" />
                            <FontAwesomeIcon icon={faStar} className="fa-fw" />
                            <FontAwesomeIcon icon={faStar} className="fa-fw" />
                            <FontAwesomeIcon icon={faStar} className="fa-fw" />
                            <FontAwesomeIcon icon={faStar} className="fa-fw" />
                          </div>
                        </div>
                        <div className="search_results__company-name">
                          {item.companyName}
                        </div>
                      </div>
                      <div className="company_item__content">
                        <h2><span>ОСАГО{item.integration ? ` - ${item.integration}` : null}</span></h2>
                        <div className="company_item__content--body">
                          {item.price ? (
                            <>
                              <h3>Страховая премия: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.price)}</h3>
                              {hideCommission === 0 && (
                                <SimpleNotification>Предварительный размер вознаграждения: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((item.commission * item.price) / 100)} = {item.commission}%.</SimpleNotification>
                              )}
                            </>
                          ) : (<div className="error_block">Отказ</div>)}
                          {item.coefficients ? (
                            <div className="table-responsive mt-2">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>КБМ</th>
                                    <th>КМ</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{item.coefficients.kbm}</td>
                                    <td>{item.coefficients.km}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : null}
                          {item.integrations ? (
                            <div className="table-responsive mt-2">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Интеграция</th>
                                    <th>Статус интеграции</th>
                                    <th>Сумма СП / Ошибка</th>
                                    <th>Вх. вознаграждение (заявленное)</th>
                                    <th>Вх. вознаграждение (факт)</th>
                                    <th>Анализ вознаграждения</th>
                                    <th>ID расчета</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(item.integrations).map(([key, value]) => (value.status ? (
                                    <tr key={key}>
                                      <td>{key}</td>
                                      <td>Работает</td>
                                      <td>{value.errorMessage ? value.errorMessage : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value.premium)}</td>
                                      <td>{value.polisOnlineCommission !== false ? `${value.polisOnlineCommission}%` : '-'}</td>
                                      <td>{value.innerCommission !== false ? `${value.innerCommission}%` : '-'}</td>
                                      <td>
                                        {value.polisOnlineCommission < value.innerCommission
                                        && value.polisOnlineCommission !== false
                                        && value.innerCommission !== false
                                        && <span style={{ color: greenColor }}>Выше заявленной</span>}
                                        {value.polisOnlineCommission > value.innerCommission
                                        && value.polisOnlineCommission !== false
                                        && value.innerCommission !== false
                                        && <span style={{ color: redColor }}>Ниже заявленной</span>}
                                        {value.polisOnlineCommission === value.innerCommission
                                        && value.polisOnlineCommission !== false
                                        && value.innerCommission !== false
                                        && <span>Соответствует заявленной</span>}
                                      </td>
                                      <td>{value.integrationRequestId}</td>
                                    </tr>
                                  ) : (
                                    <tr key={key}>
                                      <td>{key}</td>
                                      <td>Нет СК</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                    </tr>
                                  )))}
                                </tbody>
                              </table>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {item.upSales ? item.upSales.map((crossOffer) => (
                      <div key={`${item.id}-${crossOffer.code}`} className="company_item">
                        <div className="company_item__image_block">
                          <div className="company_item__image">
                            <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${item.logo}`} alt="" width="142" height="62" />
                            <div className="company_item__star mt-2">
                              <FontAwesomeIcon icon={faStar} className="fa-fw" />
                              <FontAwesomeIcon icon={faStar} className="fa-fw" />
                              <FontAwesomeIcon icon={faStar} className="fa-fw" />
                              <FontAwesomeIcon icon={faStar} className="fa-fw" />
                              <FontAwesomeIcon icon={faStar} className="fa-fw" />
                            </div>
                          </div>
                        </div>
                        <div className="company_item__content">
                          <h2><span>ОСАГО + &laquo;{crossOffer.name}&raquo;{item.integration ? ` - ${item.integration}` : null}</span></h2>
                          <div className="company_item__content--body">
                            {item.price ? (
                              <>
                                <h3 className="mb-0">Страховая премия: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(parseFloat(item.price) + parseFloat(crossOffer.price))}</h3>
                                <div className="cross-additional-info mb-2">
                                  ({new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.price)} + {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(crossOffer.price)})
                                </div>
                                {hideCommission === 0 && (
                                  <SimpleNotification offsetBottom="1rem">Предварительный размер вознаграждения: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((item.commission * item.price) / 100)} + {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((crossOffer.commission * crossOffer.price) / 100)} ({new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(((item.commission * item.price) / 100) + ((crossOffer.commission * crossOffer.price) / 100))}) = {item.commission}% + {crossOffer.commission}%.</SimpleNotification>
                                )}
                                <SimpleNotification>Полис ОСАГО + &laquo;{crossOffer.name}&raquo;</SimpleNotification>
                              </>
                            ) : (<div className="error_block">Отказ</div>)}
                            {item.coefficients ? (
                              <div className="table-responsive mt-2">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>КБМ</th>
                                      <th>КМ</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{item.coefficients.kbm}</td>
                                      <td>{item.coefficients.km}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                            {item.integrations ? (
                              <div className="table-responsive mt-2">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Интеграция</th>
                                      <th>Статус интеграции</th>
                                      <th>Сумма СП / Ошибка</th>
                                      <th>Вх. вознаграждение (заявленное)</th>
                                      <th>Вх. вознаграждение (факт)</th>
                                      <th>Анализ вознаграждения</th>
                                      <th>ID расчета</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.entries(item.integrations).map(([key, value]) => (value.status ? (
                                      <tr key={key}>
                                        <td>{key}</td>
                                        <td>Работает</td>
                                        <td>{value.errorMessage ? value.errorMessage : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(parseFloat(value.premium) + parseFloat(crossOffer.price))}</td>
                                        <td>{value.polisOnlineCommission !== false ? `${value.polisOnlineCommission}% + ${crossOffer.commission}%` : '-'}</td>
                                        <td>{value.innerCommission !== false ? `${value.innerCommission}% + ${crossOffer.commission}%` : '-'}</td>
                                        <td>-</td>
                                        <td>{value.integrationRequestId}</td>
                                      </tr>
                                    ) : (
                                      <tr key={key}>
                                        <td>{key}</td>
                                        <td>Нет СК</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                      </tr>
                                    )))}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )) : null}
                  </React.Fragment>
                ))}
              </>
            ) : <h2>{calculactionNotExist}</h2>}
          </>
        ) : null}
      </>
    );
  }
}
