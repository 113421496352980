import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import InnerCommission from './InnerCommission';
import OuterCommission from './OuterCommission';
import PriorityOfIntegration from './PriorityOfIntegration';
import AdminDocuments from './AdminDocuments';
import AdminNews from './AdminNews';
import AdminWidgetList from './AdminWidgetList';
import Themes from './Themes';
import PaymentRealizations from './PaymentRealizations/PaymentRealizations';
import Aliases from './Aliases';
import InsuranceProgramFactory from '../InsuranceProgramFactory/InsuranceProgramFactory';
import InterfaceToursAdmin from '../InterfaceTours/InterfaceToursAdmin';
import DemoDataAdmin from '../DemoData/DemoDataAdmin';
import AddPolicy from './AddPolicy';
import BlackList from './BlackList';
import Reports from './Reports';
import Monitoring from './Monitoring';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import CustomContracts from '../Agent/CustomContracts/CustomContracts';
import AdminFaqKnowledgeTable from './AdminFaqKnowledgeTable';
import AddPullPolicies from './AddPullPolicies';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.tableRef = React.createRef();
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    document.title = 'Панель администрирования';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  downloadTableData = (e) => {
    e.preventDefault();
    this.tableRef.current.downloadTableData();
  };

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    window.history.replaceState(null, null, `?activeTab=${tab}`);
  }

  render() {
    const { activeTab } = this.state;
    const { history, location, userRole } = this.props;
    return (
      <div className="position-relative">
        <h1 className="page-wrapper__title d-inline-block mr-3">Панель администрирования</h1>
        {activeTab === '10' && (
          <div className="page_header__control mb-2 mb-md-0 mr-2">
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
              <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
              Настроить таблицу
            </Button>
          </div>
        )}
        {activeTab === '14' && (
          <div className="page_header__control mb-2 mb-md-0">
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadTableData}>
              <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
              Скачать в Excel
            </Button>
          </div>
        )}
        <TabNavList>
          <TabNavItem
            active={activeTab === '1'}
            onClick={() => { this.toggle('1'); }}
            text="Отчеты"
          />
          {userRole === 'superadmin' && (
            <>
              <TabNavItem
                active={activeTab === '2'}
                onClick={() => { this.toggle('2'); }}
                text="Входящее вознаграждение"
              />
              <TabNavItem
                active={activeTab === '3'}
                onClick={() => { this.toggle('3'); }}
                text="Исходящее вознаграждение"
              />
              <TabNavItem
                active={activeTab === '4'}
                onClick={() => { this.toggle('4'); }}
                text="Настройка приоритетов"
              />
              <TabNavItem
                active={activeTab === '15'}
                onClick={() => { this.toggle('15'); }}
                text="Выплаты КВ"
              />
            </>
          )}
          <TabNavItem
            active={activeTab === '6'}
            onClick={() => { this.toggle('6'); }}
            text="Документы"
          />
          <TabNavItem
            active={activeTab === '7'}
            onClick={() => { this.toggle('7'); }}
            text="Новости"
          />
          <TabNavItem
            active={activeTab === '8'}
            onClick={() => { this.toggle('8'); }}
            text="Рекламные материалы"
          />
          <TabNavItem
            active={activeTab === '9'}
            onClick={() => { this.toggle('9'); }}
            text="Кастомизации"
          />
          <TabNavItem
            active={activeTab === '10'}
            onClick={() => { this.toggle('10'); }}
            text="Фабрика программ страхования"
          />
          <TabNavItem
            active={activeTab === '11'}
            onClick={() => { this.toggle('11'); }}
            text="Алиасы"
          />
          <TabNavItem
            active={activeTab === '13'}
            onClick={() => { this.toggle('13'); }}
            text="Демо-туры"
          />
          <TabNavItem
            active={activeTab === '12'}
            onClick={() => { this.toggle('12'); }}
            text="Демо-данные"
          />
          <TabNavItem
            active={activeTab === '14'}
            onClick={() => { this.toggle('14'); }}
            text="Черный список"
          />
          <TabNavItem
            active={activeTab === '16'}
            onClick={() => { this.toggle('16'); }}
            text="Добавить полис"
          />
          {userRole === 'superadmin' && (
            <TabNavItem
              active={activeTab === '20'}
              onClick={() => { this.toggle('20'); }}
              text="Загрузка реестра"
            />
          )}
          <TabNavItem
            active={activeTab === '17'}
            onClick={() => { this.toggle('17'); }}
            text="Мониторинг"
          />
          <TabNavItem
            active={activeTab === '18'}
            onClick={() => { this.toggle('18'); }}
            text="Доступы"
          />
          <TabNavItem
            active={activeTab === '19'}
            onClick={() => { this.toggle('19'); }}
            text="FAQ-База знаний"
          />
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            { activeTab === '1' ? <Reports tab="1" location={location} /> : null }
          </TabPane>
          {userRole === 'superadmin' && (
            <>
              <TabPane tabId="2">
                { activeTab === '2' ? <InnerCommission tab="2" location={location} /> : null }
              </TabPane>
              <TabPane tabId="3">
                { activeTab === '3' ? <OuterCommission tab="3" location={location} /> : null }
              </TabPane>
              <TabPane tabId="4">
                { activeTab === '4' ? <PriorityOfIntegration tab="4" location={location} /> : null }
              </TabPane>
              <TabPane tabId="15">
                { activeTab === '15' ? <PaymentRealizations tab="15" location={location} history={history} /> : null }
              </TabPane>
            </>
          )}
          {(userRole === 'superadmin' || userRole === 'admin') && (
            <TabPane tabId="19">
              { activeTab === '19' ? <AdminFaqKnowledgeTable tab="19" location={location} history={history} /> : null }
            </TabPane>
          )}
          <TabPane tabId="6">
            { activeTab === '6' ? <AdminDocuments tab="6" location={location} /> : null }
          </TabPane>
          <TabPane tabId="7">
            { activeTab === '7' ? <AdminNews tab="7" location={location} history={history} /> : null }
          </TabPane>
          <TabPane tabId="8">
            { activeTab === '8' ? <AdminWidgetList tab="8" location={location} history={history} /> : null }
          </TabPane>
          <TabPane tabId="9">
            { activeTab === '9' ? <Themes tab="9" location={location} history={history} /> : null }
          </TabPane>
          <TabPane tabId="10">
            { activeTab === '10' ? <InsuranceProgramFactory tab="10" location={location} history={history} ref={activeTab === '10' ? this.child : undefined} /> : null }
          </TabPane>
          <TabPane tabId="11">
            { activeTab === '11' ? <Aliases tab="11" location={location} history={history} /> : null }
          </TabPane>
          <TabPane tabId="13">
            { activeTab === '13' ? <InterfaceToursAdmin tab="13" location={location} history={history} /> : null }
          </TabPane>
          <TabPane tabId="12">
            { activeTab === '12' ? <DemoDataAdmin tab="12" location={location} history={history} /> : null }
          </TabPane>
          <TabPane tabId="14">
            { activeTab === '14' ? <BlackList tab="14" location={location} history={history} ref={activeTab === '14' ? this.tableRef : undefined} /> : null }
          </TabPane>
          <TabPane tabId="16">
            { activeTab === '16' ? <AddPolicy tab="16" location={location} history={history} ref={activeTab === '16' ? this.tableRef : undefined} /> : null }
          </TabPane>
          {(userRole === 'superadmin' || userRole === 'admin') && (
            <TabPane tabId="20">
              { activeTab === '20' ? <AddPullPolicies tab="20" location={location} history={history} /> : null }
            </TabPane>
          )}
          <TabPane tabId="17">
            { activeTab === '17' ? <Monitoring tab="17" location={location} history={history} ref={activeTab === '17' ? this.tableRef : undefined} /> : null }
          </TabPane>
          <TabPane tabId="18">
            { activeTab === '18' ? <CustomContracts agent={{ id: 1, license_contract_signed: true, full_time_employee: true }} updateAgent={this.updateAgent} ref={activeTab === '18' ? this.child : undefined} history={history} tab={activeTab} /> : null }
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

Admin.contextType = ModalMessagesContext;

export default Admin;
