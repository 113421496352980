import React, { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import CustomIcon from '../CustomIcon';
import UserProfilePopup from './UserProfilePopup';

const Wrapper = styled.div`
  position: relative;
  margin: 0 8px 0 0;
  visibility: visible;
  opacity: 1;
  transition: visibility .15s ease, opacity .15s ease;

  @media (max-width: 767px) {
    //margin-left: 48px;
  }
`;

const Icon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  align-items: flex-end;
  background-color: #f6f6f6;
  border: 1px solid #fff;
  color: #e9e9e9;
  transition: color .15s ease, background-color .15s ease;
  display: flex;
  justify-content: center;

  ${(props) => {
    if (!props.activeFlag) {
      return '';
    }
    return `
      background-color: ${props.theme ? props.theme.subColor : '#f0f000'} !important;;
      color: #3d3d3b;
    `;
  }}

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Profile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${Icon} {
      background-color: #cfcfcf;
      color: #3d3d3b;
    }
  }
`;

const PopupButton = styled.div`
  margin: 0 0 0 12px;
  padding: 0;
  color: ${(props) => (props.theme ? props.theme.mainColor : '#01e250')};
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  transition: opacity .15s ease;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Info = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
      'photo name'
      'photo position';
  -ms-grid-columns: min-content 16px 1fr;
  grid-template-columns: min-content 1fr;
  text-align: left;
  column-gap: 16px;
  visibility: visible;
  opacity: 1;
  transition: visibility .15s ease, opacity .15s ease;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Image = styled.div`
  position: relative;
  grid-row: span 2;
  grid-area: photo;
  width: 44px;
  height: 44px;
  align-items: center;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    display: ${(props) => (props.activeFlag ? 'none' : 'flex')};
  }
`;

const Name = styled.div`
  font-weight: 600;
  color: #3d3d3b;
  // Ждем функционала геймофикации
  // align-self: start;
  align-self: center;
  grid-row: span 2;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Status = styled.div`
  font-weight: 600;
  color: #a3a3a3;
  letter-spacing: .05em;
  grid-area: position;
  font-size: 10px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    display: none;
  }
`;

const IconBorder = styled.div`
  svg{
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    overflow-clip-margin: content-box;
    overflow: hidden;
  }

  circle:first-child{
    fill: transparent;
    stroke: #e9e9e9;
    stroke-width: 4;
  }

  circle:last-child{
    fill: transparent;
    stroke-width: 4;
    // Ждем функционала геймофикации
    // stroke: ${(props) => (props.theme ? props.theme.mainColor : '#01e250')};
    stroke: transparent;
    stroke-dasharray: 95;
    stroke-dashoffset: 95;
    stroke-linecap: round;
  }
`;

const Count = styled.div`
  position: absolute;
  top: 0;
  right: -7px;
  z-index: 1;
  width: 21px;
  height: 18px;
  border: 1px solid #fff;
  border-radius: 23px;
  background-color: #01e250;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  display: none;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    display: flex;
  }
`;

const CloseButton = styled.div`
  display: none;
  top: 2px;
  left: 2px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e9e9e9;
  border-radius: 50%;

  @media (max-width: 767px) {
    display: ${(props) => (props.activeFlag ? 'flex' : 'none')};
  }
`;

export default function NavbarUserProfile(props) {
  const {
    userInfo, theme, handleLogout, notificationsList, notificationsTippy, notificationsTippyActiveFlag, faq,
  } = props;

  const [activeFlag, setActiveFlag] = useState(false);
  const tippyRef = useRef(null);

  useEffect(() => () => {
    if (!activeFlag) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [activeFlag]);

  const closeTippy = () => {
    tippyRef.current.click();
  };

  return (
    <Tippy
      placement="bottom-end"
      interactive
      trigger="click"
      className="page-popup page-popup--agents-list page-popup_auto-width"
      arrow={false}
      offset={[0, 12]}
      ref={tippyRef}
      content={(
        <UserProfilePopup
          faq={faq}
          theme={theme}
          userInfo={userInfo}
          handleLogout={handleLogout}
          closeTippy={closeTippy}
          notificationsListLength={notificationsList.length}
          notificationsTippy={notificationsTippy}
        />
      )}
      hideOnClick
      onShow={() => { if (notificationsTippyActiveFlag) { notificationsTippy.current.click(); } setActiveFlag(true); }}
      onHide={() => setActiveFlag(false)}
    >
      <Wrapper className="tour_2_dt_step_2_open">
        <Profile>
          <Info>
            <Image activeFlag={activeFlag}>
              <Icon theme={theme} activeFlag={activeFlag}>
                <CustomIcon icon="userFilled" height="26px" width="21px" />
              </Icon>
              <IconBorder theme={theme}>
                <svg viewBox="0 0 44 44" width="44" height="44">
                  <circle cx="22" cy="22" r="20" />
                  <circle cx="22" cy="22" r="20" />
                </svg>
              </IconBorder>
              {notificationsList.length ? (
                <Count>{notificationsList.length}</Count>
              ) : null}
            </Image>
            <CloseButton activeFlag={activeFlag}>
              <CustomIcon icon="cross" />
            </CloseButton>
            <Name>{userInfo.last_name ? userInfo.last_name : null}{userInfo.first_name ? ` ${userInfo.first_name.charAt(0)}.` : null}{userInfo.middle_name ? ` ${userInfo.middle_name.charAt(0)}.` : null}</Name>
            {/* Ждем функционала геймофикации <Status>юниор</Status> */}
          </Info>
          <PopupButton theme={theme}>
            <CustomIcon icon="chevronDown" height="24px" width="14px" />
          </PopupButton>
        </Profile>
      </Wrapper>
    </Tippy>
  );
}
