import React, {
  useEffect, useState, useContext, useMemo, forwardRef, useImperativeHandle, useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
} from '@fortawesome/fontawesome-free-solid';
import {
  parseISO,
  format,
  compareAsc,
  isLeapYear,
  addDays, differenceInDays,
} from 'date-fns';
import {
  animateScroll as scroll, scroller,
} from 'react-scroll';
import queryString from 'query-string';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import {
  customFetch, getDeepObjectValue, setDeepObjectValidationFlag, setDeepObjectValue, validateFields, abortFetching, getCustomFieldOptions, setNewPartnerForDigitalBroker,
} from '../../Utils/Helpers';
import getValidation from '../../Utils/Validation';
import IflCheckInfo from './IflCheckInfo';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import IflShortForm from './IflShortForm';
import IflForm from './IflForm';
import IflChosenOffer from './IflChosenOffer';
import ModalCommercialOfferForm from './ModalCommercialOfferForm';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

function IflFormContainer(props, ref) {
  const {
    userFullTimeEmployee,
    userRole,
    policyStatus,
    policy,
    history,
    copyDraft,
    hideCommission,
    match,
    location,
    remoteEmployee,
    userDigitalBroker,
    domain,
  } = props;

  const genderOptions = [
    { value: 'M', label: 'Мужской' },
    { value: 'F', label: 'Женский' },
  ];

  const [goingSaveDraft, setGoingSaveDraft] = useState(0);
  const [gonnaCalc, setGonnaCalc] = useState(false);
  const [curE, setCurE] = useState(null);

  const urlParams = useMemo(() => queryString.parse(location.search), []);

  const insuredAddressRegistrationRef = useRef();

  const { showModalInfo } = useContext(ModalMessagesContext);

  const commercialOfferRef = useRef(null);
  const regionRef = useRef();

  const [customField, setCustomField] = useState(null);
  const [customFieldOptions, setCustomFieldOptions] = useState([]);
  const [customFieldName, setCustomFieldName] = useState('');
  const [linkToDeal, setLinkToDeal] = useState(null);
  const [leadSource, setLeadSource] = useState('');
  const [managerAttracted, setManagerAttracted] = useState('');
  const [leadSourcesOptions, setLeadSourcesOptions] = useState([]);
  const [managerAttractedOptions, setManagerAttractedOptions] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [errorBlockName, setErrorBlockName] = useState('');
  const [loadInfoFlags, setLoadingInfoFlags] = useState({
    companies: true,
    integrationsClassifiers: false,
  });
  const [loadingIflInfo, setLoadingIflInfo] = useState(true);
  const [draftInfo, setDraftInfo] = useState({
    hash: 0,
    id: 0,
    type: 'ifl',
  });
  const [showNSCheckInfo, setShowNSCheckInfo] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [blurClass, setBlurClass] = useState('');
  const [newAgent, setNewAgent] = useState({ value: false, label: false });

  const [formData, setFormData] = useState({
    hasGasFlag: {
      value: false,
    },
    fireDangerFlag: {
      value: false,
    },
    repairFlag: {
      value: false,
    },
    lastName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    firstName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    middleName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    passport: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportNumberSerie',
    },
    birthday: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    passportDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    insuredAddress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    insuredAddressRegistration: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    gender: {
      value: genderOptions[0],
      errorMessage: '',
      validationRequired: false,
      validationType: 'select',
    },
    addressRegistrationFlag: {
      value: false,
    },
    contactPhone: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'phone',
    },
    contactEmail: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'email',
    },
    risks: {
      value: [],
    },
    startDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    endDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    passportDivision: {
      value: '',
      errorMessage: '',
      validationRequired: false,
      validationType: 'plain',
    },
    passportUnitNumber: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportUnitNumber',
    },
    floor: {
      value: '',
      errorMessage: '',
      validationRequired: false,
      validationType: 'plain',
    },
    personalDataFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    contractInfoFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    insuranceRulesFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    birthPlace: {
      value: '',
      errorMessage: '',
      validationRequired: false,
      validationType: 'plain',
    },
    inn: {
      value: '',
      errorMessage: '',
      validationRequired: false,
      validationType: 'plain',
    },
    region: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    promo: {
      value: '',
      errorMessage: '',
      validationRequired: false,
      validationType: 'plain',
    },
    period: {
      value: {
        value: 1,
        label: 'На год',
      },
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    periodOptions: [
      { value: 1, label: 'На год' },
      { value: 2, label: 'На период' },
    ],
    insuranceLimit: {
      value: 0,
      errorMessage: '',
      validationType: 'insuranceLimit',
      validationRequired: true,
    },
    homeProperty: {
      value: 100000,
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: false,
    },
    civilResponsibility: {
      value: 100000,
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: false,
    },
    decoration: {
      value: 100000,
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: false,
    },
    flatSum: {
      value: 1000000,
      errorMessage: '',
      validationType: 'insuranceLimit',
      validationRequired: false,
    },
    programm: {
      value: {
        value: 'A', label: 'A',
      },
      errorMessage: '',
      validationType: 'select',
      validationRequired: false,
    },
    flatMaterial: {
      value: {
        value: 'Кирпичный', label: 'Кирпичный',
      },
      errorMessage: '',
      validationType: 'select',
      validationRequired: false,
    },
    area: {
      value: '',
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: false,
    },
    roomsCount: {
      value: '',
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: false,
    },
    material: {
      value: '',
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: false,
    },
    flatFloor: {
      value: 1,
      errorMessage: '',
      validationType: 'decimal',
      validationRequired: false,
    },
    buildYear: {
      value: '',
      errorMessage: '',
      validationType: 'yearOfConstruction',
      validationRequired: true,
    },
    pType: {
      value: {
        value: 'flat',
        label: 'Страхование квартиры',
      },
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    pProgram: {
      value: {},
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    insType: {
      value: {
        value: 'KLASSIC',
        label: 'классический',
      },
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    coverz: {
      value: [],
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    compensationType: {
      value: {},
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    attrs: {
      value: [],
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    insuranceTerritoryCode: {
      value: {
        value: 'Москва',
        label: 'Москва',
      },
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
  });

  const [companies, setCompanies] = useState({});
  const [chosenOffer, setChosenOffer] = useState({});
  const [scrollTo, setScrollTo] = useState('');
  const [goCalcFlag, setGoCalcFlag] = useState(false);
  const [phoneCheckRequestFlag, setPhoneCheckRequestFlag] = useState(false);
  const [showForm, setShowForm] = useState('short');
  const [loadingInit, setLoadingInit] = useState(false);
  const [selectedSort, setSelectedSort] = useState('commissionCurrency');
  const [policySortOrderUp, setPolicySortOrderUp] = useState(false);
  const [policyIntegrationsData, setPolicyIntegrationsData] = useState({
    ifl: [],
  });
  const [allPolices, setAllPolices] = useState({
    ifl: [],
  });
  const [showCommission, setShowCommission] = useState(true);
  const [commercialOfferCompanies, setCommercialOfferCompanies] = useState([]);
  const [showCommercialOfferModalForm, setShowCommercialOfferModalForm] = useState('');
  const [loadingCommercialOffer, setLoadingCommercialOffer] = useState(false);

  useEffect(() => {
    if (showForm !== 'short') {
      setShowForm(draftInfo.type);
    }
  }, [draftInfo.type]);

  const prepValueToValidate = (value, fieldName) => {
    if (value && typeof value === 'string') {
      value = value.trim();
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    if (typeof value === 'string'
            && fieldName === 'passport'
    ) {
      value = value.replace(/ +/g, '');
    }
    return value;
  };

  const postProcessingDate = (value, fieldName, errorMessage) => {
    let resetErrorMessage = errorMessage;
    if (fieldName === 'startDate' && formData.startDate.value && formData.endDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть больше даты окончания';
    } else if (fieldName === 'endDate' && formData.endDate.value && formData.startDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть меньше даты начала';
    }
    return resetErrorMessage;
  };

  const postProcessingAddress = (name, value) => {
    let registrationAddressFlag = formData.addressRegistrationFlag.value;
    if (name === 'insuredAddress') {
      registrationAddressFlag = false;
    }
    if (name === 'insuredAddressRegistration') {
      registrationAddressFlag = value === formData.insuredAddress.value.value;
    }

    setFormData((prev) => ({
      ...prev,
      addressRegistrationFlag: {
        ...prev.addressRegistrationFlag,
        value: registrationAddressFlag,
      },
    }));
  };

  const handleValidate = (e, args = {}) => {
    let fieldName = '';
    if (e) {
      const { target } = e;
      fieldName = target.id;
    } else {
      fieldName = args.fieldName;
    }
    const path = args.path ? `${args.path}.${fieldName}` : fieldName;
    let value = getDeepObjectValue(formData, path);
    let errorMessage = value.error;
    value = value.data;
    if (errorMessage) {
      errorMessage = value.error;
    } else {
      value = prepValueToValidate(value, fieldName);

      errorMessage = getValidation(value, formData[fieldName].validationType);

      if (fieldName === 'multiSelect' && (formData[fieldName].value === null || !formData[fieldName].value.length)) {
        errorMessage = 'Выберите хотя бы один вариант';
      }

      if (fieldName === 'startDate' || fieldName === 'endDate') {
        errorMessage = postProcessingDate(value, fieldName, errorMessage);
      }

      if (fieldName === 'insuredAddress' || fieldName === 'insuredAddressRegistration') {
        postProcessingAddress();
      }

      if (fieldName === 'gender' || fieldName === 'birthday') {
        setBlurClass('blur');
      }
    }
    setDeepObjectValue(formData, setFormData, path, value, errorMessage);
  };

  const setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  const setCursorToEnd = (e) => {
    e.target.onfocus = setAddressEnd;
  };

  const toggleFlag = (e) => {
    const { target } = e;
    setFormData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value: !prev[target.id].value,
        errorMessage: '',
      },
    }));
  };

  const getCompaniesList = (type) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getCompanies(lsToken, type).then((response) => {
      setCompanies(response);
      setLoadingInfoFlags((prev) => ({
        ...prev,
        companies: false,
      }));
    });
  };

  const getPrivacyPolicy = () => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/privacy_policy`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((response) => {
        setPrivacyPolicy(response.path);
      });
  };

  const makeFormDataFlatJson = () => {
    const flatJson = {};
    Object.keys(formData).forEach((key) => {
      flatJson[key] = formData[key].value;
    });

    return flatJson;
  };

  const validateForm = () => {
    const formFields = { ...formData };
    if (showForm === 'short') {
      delete formFields.birthday;
      delete formFields.region;
    }
    // ^^^^
    delete formFields.birthPlace;
    delete formFields.insuranceLimit;

    delete formFields.homeProperty;
    delete formFields.civilResponsibility;
    delete formFields.decoration;
    delete formFields.flatFloor;
    delete formFields.area;
    delete formFields.roomsCount;
    delete formFields.programm;
    delete formFields.flatMaterial;

    delete formFields.gender;
    delete formFields.floor;
    delete formFields.inn;

    const validationResult = validateFields(formFields);
    let newErrorBlockName = '';
    newErrorBlockName = Object.keys(validationResult.result).find(((key) => validationResult.result[key].errorMessage));
    const newFormData = { ...formData };
    Object.keys(validationResult.result).forEach((field) => {
      newFormData[field] = validationResult.result[field];
    });

    if (!newFormData.startDate.value) {
      newFormData.startDate.errorMessage = 'Заполните поле';
      newFormData.startDate.errorVisible = true;
    }

    if (chosenOffer && Object.prototype.hasOwnProperty.call(chosenOffer, 'companyCode') && chosenOffer.companyCode === 'renessans') {
      if (!newFormData.pType.value) {
        newFormData.pType.errorMessage = 'Обязательно';
        newFormData.pType.errorVisible = true;
        newErrorBlockName = 'pType';
      }
      if (!newFormData.pProgram.value) {
        newFormData.pProgram.errorMessage = 'Обязательно';
        newFormData.pProgram.errorVisible = true;
        newErrorBlockName = 'pProgram';
      }
      if (!newFormData.insType.value) {
        newFormData.insType.errorMessage = 'Обязательно';
        newFormData.insType.errorVisible = true;
        newErrorBlockName = 'insType';
      }
      if (!newFormData.compensationType.value) {
        newFormData.compensationType.errorMessage = 'Обязательно';
        newFormData.compensationType.errorVisible = true;
        newErrorBlockName = 'compensationType';
      }
    }
    setFormData(newFormData);
    if (newErrorBlockName) {
      setErrorBlockName(newErrorBlockName);
      setScrollTo('errorBlock');
    }
    return validationResult.validationSuccess;
  };

  const saveDraft = (e, goCalc = false, skipValidation = false) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    setGonnaCalc(goCalc);
    const formIsValid = skipValidation || validateForm();
    if (formIsValid) {
      setGoingSaveDraft(1);
      setCurE(e);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (goingSaveDraft) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      const data = makeFormDataFlatJson();
      data.passportDate = data.passportDate ? `${format(data.passportDate, 'yyyy-MM-dd')} 00:00:00` : '';
      data.birthday = data.birthday ? `${format(data.birthday, 'yyyy-MM-dd')} 00:00:00` : '';
      data.startDate = data.startDate ? `${format(data.startDate, 'yyyy-MM-dd')} 00:00:00` : '';
      data.endDate = data.endDate ? `${format(data.endDate, 'yyyy-MM-dd')} 00:00:00` : '';

      data.promo = formData.promo.value;
      try {
        if (draftInfo.id) {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${draftInfo.id}`, {
            method: 'put',
            headers: {
              Authorization: lsToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              type: 'ifl',
              formData: data,
              service: true,
              product: draftInfo.type,
              leadSource,
              linkToDeal,
              managerAttracted,
              customField: customField ? customField.value : null,
              newAgent: newAgent.value,
            }),
          }, controller).then((response) => response.json())
            .then(() => {
              setGoingSaveDraft(0);
              if (gonnaCalc) {
                if (showForm === 'short') {
                  setGoCalcFlag(true);
                } else {
                  setShowNSCheckInfo(true);
                  setScrollTo('check_info');
                }
              } else {
                if (showCommercialOfferModalForm) {
                  if (showCommercialOfferModalForm === 'send') {
                    commercialOfferRef.current.open();
                    setShowCommercialOfferModalForm('');
                  } else {
                    setLoadingCommercialOffer(true);
                    commercialOfferRef.current.getPdf(curE);
                  }
                }
                showModalInfo('Черновик сохранен');
              }
              setGoingSaveDraft(0);
            })
            .catch(() => {
              setGoingSaveDraft(0);
              showModalInfo('Ошибка', 'error');
            });
        } else {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
            method: 'post',
            headers: {
              Authorization: lsToken,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              type: 'ifl',
              formData: data,
              service: true,
              product: draftInfo.type,
              leadSource,
              linkToDeal,
              managerAttracted,
              customField: customField ? customField.value : null,
              newAgent: newAgent.value,
            }),
          }, controller).then((response) => response.json())
            .then((newDraft) => {
              setDraftInfo((prev) => ({
                ...prev,
                id: newDraft.id,
                hash: newDraft.hash,
              }));
              setGoingSaveDraft(0);
              if (gonnaCalc) {
                setGoCalcFlag(true);
              } else {
                showModalInfo('Черновик сохранен');
              }
            })
            .catch(() => {
              setGoingSaveDraft(0);
              showModalInfo('Ошибка', 'error');
            });
        }
      } catch (er) {
        setGoingSaveDraft(0);
        showModalInfo('Ошибка', 'error');
      }
    }

    return function cleanup() {
      abortFetching(controller);
    };
  }, [goingSaveDraft]);

  const loadPolicyData = () => {
    const draftData = policy.form_data;
    let newStartDate = null;
    let newEndDate = null;
    let diffDays = 0;
    if (policy.status !== 'complete' && policy.status !== 'processing') {
      if (draftData.startDate && differenceInDays(parseISO(draftData.startDate), new Date()) >= 7) {
        newStartDate = parseISO(draftData.startDate.substring(0, 10));
        diffDays = 0;
      } else {
        newStartDate = new Date(new Date().setHours(0, 0, 0, 0));
        newStartDate.setDate(newStartDate.getDate() + 1);
        newStartDate = new Date(newStartDate);
        if (draftData.startDate) {
          const oldStartDate = parseISO(draftData.startDate.substring(0, 10));
          const diffTime = Math.abs(newStartDate - oldStartDate);
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
      }
    } else if (draftData.startDate) {
      newStartDate = parseISO(draftData.startDate.substring(0, 10));
    }
    if ((draftData.endDate && compareAsc(parseISO(draftData.startDate), new Date()) === 1) || policy.status === 'complete' || policy.status === 'processing') {
      newEndDate = parseISO(draftData.endDate.substring(0, 10));
    } else if (newStartDate) {
      if (draftData.endDate) {
        newEndDate = new Date(parseISO(draftData.endDate));
        newEndDate = newEndDate.setDate(newEndDate.getDate() + diffDays);
        if (isLeapYear(new Date(newEndDate)) && !isLeapYear(new Date(draftData.endDate))) {
          newEndDate = addDays(newEndDate, 1);
        }
        newEndDate = new Date(newEndDate);
      } else {
        newEndDate = new Date();
        newEndDate = newEndDate.setFullYear(newStartDate.getFullYear() + 1);
        newEndDate = new Date(newEndDate);
      }
    }

    const birthday = draftData.birthday ? parseISO(draftData.birthday.substring(0, 10)) : '';
    const passportDate = draftData.passportDate ? parseISO(draftData.passportDate.substring(0, 10)) : '';

    const genderValue = draftData.gender && genderOptions.filter((option) => option.value === draftData.gender.value).length ? draftData.gender : genderOptions[0];

    const errorMessageStartDate = newStartDate && newEndDate && newEndDate < newStartDate ? 'Не может быть больше даты окончания' : '';
    const errorMessageEndDate = newEndDate && newStartDate && newEndDate < newStartDate ? 'Не может быть меньше даты начала' : '';
    setDraftInfo({
      type: policy.type_insurance,
      id: policy.id,
      hash: policy.hash,
    });
    setLeadSource(policy.lead_source ? policy.lead_source : '');
    setManagerAttracted(policy.manager_attracted ? policy.manager_attracted : '');
    setCustomField(policy.custom_field ? { label: policy.custom_field.name, value: policy.custom_field.id } : null);
    setLinkToDeal(policy.link_to_deal ? policy.link_to_deal : '');
    const data = {
      lastName: {
        ...formData.lastName,
        value: draftData.lastName,
      },
      firstName: {
        ...formData.firstName,
        value: draftData.firstName,
      },
      middleName: {
        ...formData.middleName,
        value: draftData.middleName,
      },
      passport: {
        ...formData.passport,
        value: draftData.passport ? draftData.passport : '',
      },
      birthday: {
        ...formData.birthday,
        value: birthday,
      },
      passportDate: {
        ...formData.passportDate,
        value: passportDate,
      },
      insuredAddress: {
        ...formData.insuredAddress,
        value: draftData.insuredAddress ? draftData.insuredAddress : '',
      },
      insuredAddressRegistration: {
        ...formData.insuredAddressRegistration,
        value: draftData.insuredAddressRegistration ? draftData.insuredAddressRegistration : '',
      },
      gender: {
        ...formData.gender,
        value: genderValue,
      },
      addressRegistrationFlag: {
        ...formData.addressRegistrationFlag,
        value: draftData.addressRegistrationFlag,
      },
      hasGasFlag: {
        ...formData.hasGasFlag,
        value: draftData.hasGasFlag,
      },
      fireDangerFlag: {
        ...formData.fireDangerFlag,
        value: draftData.fireDangerFlag,
      },
      repairFlag: {
        ...formData.repairFlag,
        value: draftData.repairFlag,
      },
      contactPhone: {
        ...formData.contactPhone,
        value: draftData.contactPhone ? draftData.contactPhone : '',
      },
      contactEmail: {
        ...formData.contactEmail,
        value: draftData.contactEmail ? draftData.contactEmail : '',
      },
      risks: {
        value: draftData.risks ? draftData.risks : [],
      },
      startDate: {
        ...formData.startDate,
        value: newStartDate,
        errorMessage: formData.startDate.errorMessage ? formData.startDate.errorMessage : errorMessageStartDate,
      },
      endDate: {
        ...formData.endDate,
        value: newEndDate,
        errorMessage: formData.endDate.errorMessage ? formData.endDate.errorMessage : errorMessageEndDate,
      },
      passportDivision: {
        ...formData.passportDivision,
        value: draftData.passportDivision ? draftData.passportDivision : '',
      },
      passportUnitNumber: {
        ...formData.passportUnitNumber,
        value: draftData.passportUnitNumber ? draftData.passportUnitNumber : '',
      },
      floor: {
        ...formData.floor,
        value: draftData.floor,
      },
      personalDataFlag: {
        ...formData.personalDataFlag,
        value: draftData.personalDataFlag || false,
      },
      contractInfoFlag: {
        ...formData.contractInfoFlag,
        value: draftData.contractInfoFlag || false,
      },
      insuranceRulesFlag: {
        ...formData.insuranceRulesFlag,
        value: draftData.insuranceRulesFlag || false,
      },
      birthPlace: {
        ...formData.birthPlace,
        value: draftData.birthPlace || '',
      },
      inn: {
        ...formData.inn,
        value: draftData.inn || '',
      },
      region: {
        ...formData.region,
        value: {
          value: draftData.region.value ? draftData.region.value.value : '',
          data: draftData.region.data ? draftData.region.data : {},
        },
      },
      promo: {
        ...formData.promo,
      },
      period: {
        ...formData.period,
      },
      periodOptions: [
        ...formData.periodOptions,
      ],
      insuranceLimit: {
        ...formData.insuranceLimit,
        value: parseFloat(draftData.insuranceLimit) ? parseFloat(draftData.insuranceLimit) : 0,
      },
      flatSum: {
        ...formData.flatSum,
        value: parseFloat(draftData.flatSum) ? parseFloat(draftData.flatSum) : 100000,
      },
      homeProperty: {
        ...formData.homeProperty,
        value: parseFloat(draftData.homeProperty) ? parseFloat(draftData.homeProperty) : 100000,
      },
      civilResponsibility: {
        ...formData.civilResponsibility,
        value: parseFloat(draftData.civilResponsibility) ? parseFloat(draftData.civilResponsibility) : 100000,
      },
      decoration: {
        ...formData.decoration,
        value: parseFloat(draftData.decoration) ? parseFloat(draftData.decoration) : 100000,
      },
      programm: {
        ...formData.programm,
      },
      flatFloor: {
        ...formData.flatFloor,
        value: parseFloat(draftData.flatFloor) ? parseFloat(draftData.flatFloor) : '',
      },
      flatMaterial: {
        ...formData.flatMaterial,
        value: draftData.flatMaterial ? draftData.flatMaterial : { value: 'Кирпичный', label: 'Кирпичный' },
      },
      area: {
        ...formData.area,
        value: parseFloat(draftData.area) ? parseFloat(draftData.area) : '',
      },
      roomsCount: {
        ...formData.roomsCount,
        value: parseFloat(draftData.roomsCount) ? parseFloat(draftData.roomsCount) : '',
      },
      buildYear: {
        ...formData.buildYear,
        value: draftData.buildYear ? draftData.buildYear : '',
      },
      pType: {
        ...formData.pType,
        value: (Object.prototype.hasOwnProperty.call(draftData, 'pType') && draftData.pType) ? draftData.pType : {
          value: 'flat',
          label: 'Страхование квартиры',
        },
      },
      pProgram: {
        ...formData.pProgram,
        value: (Object.prototype.hasOwnProperty.call(draftData, 'pProgram') && draftData.pProgram) ? draftData.pProgram : {},
      },
      insType: {
        ...formData.insType,
        value: (Object.prototype.hasOwnProperty.call(draftData, 'insType') && draftData.insType) ? draftData.insType : {
          value: 'KLASSIC',
          label: 'классический',
        },
      },
      coverz: {
        ...formData.coverz,
        value: (Object.prototype.hasOwnProperty.call(draftData, 'coverz') && draftData.coverz) ? draftData.coverz : [],
      },
      compensationType: {
        ...formData.compensationType,
        value: (Object.prototype.hasOwnProperty.call(draftData, 'compensationType') && draftData.compensationType) ? draftData.compensationType : {},
      },
      insuranceTerritoryCode: {
        ...formData.insuranceTerritoryCode,
        value: (Object.prototype.hasOwnProperty.call(draftData, 'insuranceTerritoryCode') && draftData.insuranceTerritoryCode) ? draftData.insuranceTerritoryCode : {
          value: 'Москва',
          label: 'Москва',
        },
      },
      attrs: {
        ...formData.attrs,
        value: (Object.prototype.hasOwnProperty.call(draftData, 'attrs') && draftData.attrs) ? draftData.attrs : [],
      },
    };

    data.period.value = draftData.period.value;
    if (draftData.promo) data.promo.value = draftData.promo.value;
    data.region.data = draftData.region.data;
    data.region.value.value = draftData.region.value;
    data.insuranceLimit.value = parseInt(draftData.insuranceLimit, 10) ? parseInt(draftData.insuranceLimit, 10) : 0;

    if (policy.company_code && policy.company_code === 'euroins') {
      if (parseInt(data.insuranceLimit.value, 10) === 100000 || parseInt(data.insuranceLimit.value, 10) === 50000) {
        data.insuranceLimit.value = { value: data.insuranceLimit.value.value, label: data.insuranceLimit.value.value };
      } else {
        data.insuranceLimit.value = { value: 100000, label: 100000 };
      }
    }

    if (policy.company_code && policy.company_code === 'pari') {
      if (parseInt(data.insuranceLimit.value, 10) === 100000 || parseInt(data.insuranceLimit.value, 10) === 50000 || parseInt(data.insuranceLimit.value, 10) === 30000) {
        data.insuranceLimit.value = { value: data.insuranceLimit.value.value, label: data.insuranceLimit.value.value };
      } else {
        data.insuranceLimit.value = { value: 100000, label: 100000 };
      }
    }

    setNewPartnerForDigitalBroker(policy, setNewAgent);

    setFormData(data);
    if (policy.type_integration && policy.company) {
      const obja = {
        companyCode: policy.company_code,
        companyName: policy.company,
        integration: policy.type_integration,
        price: policy.cost,
      };
      setChosenOffer(obja);
      setShowForm('ifl');
    }
    setDisableField(policy.status !== 'pending');
    setLoadingIflInfo(false);
  };

  const copyForm = () => {
    copyDraft();
    setLoadingInit(false);
    setShowForm('short');
    setShowNSCheckInfo(false);
    setChosenOffer({});
    setPolicyIntegrationsData({
      ifl: [],
    });
    setAllPolices({
      ifl: [],
    });
    setDisableField(false);
  };

  const makeRequestForSupport = () => {
    let insuranceId = '';

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/ifl/${policy.id}/get-insurance-id`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        }
        if (response.insuranceId) {
          insuranceId = `: ${response.insuranceId}`;
        }
        const requestText = `
Добрый день!

Посмотрите пож-та расчет${insuranceId}
Страхователь: ${formData.lastName.value} ${formData.firstName.value} ${formData.middleName.value}
Дата рождения: ${format(formData.birthday.value, 'dd.MM.yyyy')}
Объект: ${formData.region.value.value}
Суть проблемы:`;
        setTimeout(async () => await navigator.clipboard.writeText(requestText));
        showModalInfo('Текст запроса в поддержку СК скопирован в буфер обмена');
      })
      .catch(() => {
        showModalInfo('Ошибка получения id расчета выбранной СК', 'error');
      });
  };

  const goToBuying = (e) => {
    e.preventDefault();
    if (!policy.is_demo) {
      saveDraft(e, true);
    } else {
      setShowNSCheckInfo(true);
    }
  };

  const goToFullForm = (e, companyCode, price, companyName, integration, insuranceLimit, risks) => {
    setChosenOffer({
      companyCode,
      companyName,
      integration,
      price,
    });
    if (companyCode === 'euroins') {
      const newFormData = { ...formData };
      newFormData.insuranceLimit.value = { value: 100000, label: 100000 };
      setFormData(newFormData);
    }
    if (companyCode === 'pari') {
      const newFormData = { ...formData };
      newFormData.insuranceLimit.value = { value: 100000, label: 100000 };
      setFormData(newFormData);
    }
    if (companyCode === 'renessans') {
      const newFormData = { ...formData };
      newFormData.insuranceLimit.value = insuranceLimit;
      setFormData(newFormData);
    }
    const newFormData = { ...formData };
    newFormData.risks.value = risks;
    setFormData(newFormData);
    setShowForm(draftInfo.type);
    setScrollTo('chosen-offer');
  };

  const backToForm = (e, scrollTarget = '', onlyShowData = false) => {
    e.preventDefault();
    setShowNSCheckInfo(false);
    if (scrollTarget === 'short') {
      setShowForm('short');
      if (!allPolices[draftInfo.type].length && !onlyShowData) {
        setGoCalcFlag(true);
        scrollTarget = 'searchResults';
      } else {
        scrollTarget = 'ns_container';
      }
    }
    setScrollTo(scrollTarget);
  };

  useEffect(() => {
    if (scrollTo !== '') {
      switch (scrollTo) {
        case 'searchResults':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -70,
          });
          break;
        case 'chosen-offer':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        case 'top':
          scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: true,
          });
          break;
        case 'errorBlock':
          scroller.scrollTo(errorBlockName, {
            duration: 1200,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        default:
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
      }
    }
    setScrollTo('');
  }, [scrollTo]);

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
    makeRequestForSupport: () => {
      makeRequestForSupport();
    },
  }));

  useEffect(() => {
    if (policy.id !== 0) {
      if (policy.status !== 'pending') {
        getCompaniesList('all');
      } else {
        getCompaniesList('ifl');
      }
    } else if (!match.params.id) {
      getCompaniesList('ifl');
    }
  }, [policy.id]);

  useEffect(() => {
    if (!loadInfoFlags.companies) {
      if (match.params.id) {
        if (policy.id !== 0) {
          loadPolicyData(policy.id);
        }
      } else {
        setLoadingIflInfo(false);
      }
    }
  }, [loadInfoFlags.companies, policy.id]);

  useEffect(() => {
    if (draftInfo.id) {
      let link = `/ifl/${draftInfo.id}`;
      if (urlParams.adminEdit) {
        link = `/ifl/${draftInfo.id}?adminEdit=1`;
      }
      window.history.pushState(null, null, link);
    }
  }, [draftInfo.id]);

  useEffect(() => {
    if (draftInfo.id) {
      setBlurClass('blur');
    }
  }, [draftInfo.type]);

  useEffect(() => {
    if (formData.period.value.value === 1) {
      if (formData.startDate.value) {
        let newEndDate = null;
        newEndDate = new Date(formData.startDate.value.getTime());
        newEndDate = newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        newEndDate = new Date(newEndDate);
        newEndDate = newEndDate.setDate(newEndDate.getDate() - 1);
        newEndDate = new Date(newEndDate);

        setFormData((prev) => ({
          ...prev,
          endDate: {
            ...prev.endDate,
            value: newEndDate,
            errorMessage: '',
          },
        }));
      }
    }
  }, [formData.startDate.value, formData.period.value.value]);

  useEffect(() => {
    if (formData.addressRegistrationFlag.value) {
      setFormData((prev) => ({
        ...prev,
        insuredAddressRegistration: {
          ...prev.insuredAddressRegistration,
          value: prev.insuredAddress.value ? prev.insuredAddress.value : {
            value: '',
            data: {},
          },
          errorMessage: '',
          validationRequired: true,
        },
      }));
      if (insuredAddressRegistrationRef.current) {
        insuredAddressRegistrationRef.current.state.query = formData.insuredAddress.value.value ? formData.insuredAddress.value.value : '';
        insuredAddressRegistrationRef.current.state.inputQuery = formData.insuredAddress.value.value ? formData.insuredAddress.value.value : '';
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        insuredAddressRegistration: {
          ...prev.insuredAddressRegistration,
          value: {
            value: '',
            data: {},
          },
          errorMessage: '',
          validationRequired: true,
        },
      }));
      if (insuredAddressRegistrationRef.current) {
        insuredAddressRegistrationRef.current.state.query = '';
        insuredAddressRegistrationRef.current.state.inputQuery = '';
      }
    }
  }, [formData.addressRegistrationFlag.value]);

  useEffect(() => {
    if (!formData.startDate.errorMessage && formData.endDate.value && formData.endDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        endDate: {
          ...prev.endDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.startDate.value]);

  useEffect(() => {
    if (!formData.endDate.errorMessage && formData.startDate.value && formData.startDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        startDate: {
          ...prev.startDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.endDate.value]);

  useEffect(() => {
    if (userFullTimeEmployee === 1 && process.env.REACT_APP_API_DOMAIN === 'https://api.polis.online/api') {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/lead_sources`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setLeadSourcesOptions(response.map((source) => ({ value: source.id, label: source['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/managers`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setManagerAttractedOptions(response.map((manager) => ({ value: manager.id, label: manager['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
    }
  }, [userFullTimeEmployee]);

  useEffect(() => {
    const flagPaths = {
      lastName: showCommercialOfferModalForm || showForm !== 'short',
      firstName: showCommercialOfferModalForm || showForm !== 'short',
      middleName: showCommercialOfferModalForm || showForm !== 'short',
      startDate: showCommercialOfferModalForm || showForm !== 'short',
      endDate: showCommercialOfferModalForm || showForm !== 'short',
      contactPhone: showCommercialOfferModalForm || showForm !== 'short',
      contactEmail: showCommercialOfferModalForm || showForm !== 'short',
      birthday: showForm === 'short' && !showCommercialOfferModalForm,
      gender: showForm === 'short' && !showCommercialOfferModalForm,
      passport: showForm !== 'short' && !showCommercialOfferModalForm,
      passportDate: showForm !== 'short' && !showCommercialOfferModalForm,
      insuredAddress: showForm !== 'short' && !showCommercialOfferModalForm,
      insuredAddressRegistration: showForm !== 'short' && !showCommercialOfferModalForm,
      passportDivision: showForm !== 'short' && !showCommercialOfferModalForm,
      passportUnitNumber: showForm !== 'short' && !showCommercialOfferModalForm,
      insuranceLimit: showForm !== 'short' && !showCommercialOfferModalForm,
      floor: showForm !== 'short' && !showCommercialOfferModalForm && draftInfo.type !== 'ifl',
      personalDataFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      contractInfoFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      insuranceRulesFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      birthPlace: showForm !== 'short' && !showCommercialOfferModalForm
                && chosenOffer.companyCode === 'renessans',
    };
    setDeepObjectValidationFlag(formData, setFormData, flagPaths);
  }, [showForm, showCommercialOfferModalForm, chosenOffer.companyCode]);

  useEffect(() => {
    getPrivacyPolicy();
    getCustomFieldOptions().then((response) => {
      if (response.options) {
        setCustomFieldOptions(response.options.map((option) => ({ value: option.id, label: option.name })));
        setCustomFieldName(response.name);
      }
    });
  }, []);

  return (
    <>
      {(policyStatus === 'processing' || policyStatus === 'calculated') && (
        <FormNotificationColorLine offsetBottom="1rem">Данный черновик был проведен до стадии формирования ссылки на оплату и заблокирован для дальнейших изменений. Если вы хотите произвести повторный расчет - скопируйте черновик.</FormNotificationColorLine>
      )}
      {showNSCheckInfo && (
        <IflCheckInfo
          history={history}
          formData={formData}
          companies={companies}
          backToForm={backToForm}
          chosenOffer={chosenOffer}
          setChosenOffer={setChosenOffer}
          draftInfo={draftInfo}
          setDisableField={setDisableField}
          blurClass={blurClass}
          policy={policy}
        />
      )}
      <div name="ns_container" style={{ display: showNSCheckInfo ? 'none' : 'block' }}>
        <LoadingBanner loadingFlag={loadingIflInfo}>
          {!chosenOffer.companyCode || showForm === 'short' ? (
            <>
              {showCommercialOfferModalForm ? (
                <ModalCommercialOfferForm
                  formData={formData}
                  genderOptions={genderOptions}
                  setFormData={setFormData}
                  saveDraft={saveDraft}
                  phoneCheckRequestFlag={phoneCheckRequestFlag}
                  handleValidate={handleValidate}
                  disableField={disableField}
                  showCommercialOfferModalForm={showCommercialOfferModalForm}
                  setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
                  loadingCommercialOffer={loadingCommercialOffer}
                  showForm={showForm}
                  toggleFlag={toggleFlag}
                />
              ) : null}
              <IflShortForm
                disableField={disableField}
                formData={formData}
                setFormData={setFormData}
                saveDraft={saveDraft}
                regionRef={regionRef}
                handleValidate={handleValidate}
                genderOptions={genderOptions}
                companies={companies}
                hideCommission={hideCommission}
                goToFullForm={goToFullForm}
                draftInfo={draftInfo}
                goCalcFlag={goCalcFlag}
                setGoCalcFlag={setGoCalcFlag}
                blurClass={blurClass}
                setBlurClass={setBlurClass}
                showForm={showForm}
                toggleFlag={toggleFlag}
                backToForm={backToForm}
                loadingInit={loadingInit}
                setLoadingInit={setLoadingInit}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
                policySortOrderUp={policySortOrderUp}
                setPolicySortOrderUp={setPolicySortOrderUp}
                policyIntegrationsData={policyIntegrationsData}
                setPolicyIntegrationsData={setPolicyIntegrationsData}
                allPolices={allPolices}
                setAllPolices={setAllPolices}
                showCommission={showCommission}
                setShowCommission={setShowCommission}
                commercialOfferCompanies={commercialOfferCompanies}
                setCommercialOfferCompanies={setCommercialOfferCompanies}
                setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
                showCommercialOfferModalForm={showCommercialOfferModalForm}
                commercialOfferRef={commercialOfferRef}
                setLoadingCommercialOffer={setLoadingCommercialOffer}
                setScrollTo={setScrollTo}
                policy={policy}
                setCursorToEnd={setCursorToEnd}
              />
            </>
          ) : null}
          {showForm !== 'short' ? (
            <>
              {chosenOffer.companyCode ? (
                <IflChosenOffer
                  disableField={disableField}
                  chosenOffer={chosenOffer}
                  companies={companies}
                  backToForm={backToForm}
                  formData={formData}
                  draftInfo={draftInfo}
                />
              ) : null}
              <div className="form-group row">
                <div className="col-lg-12">
                  <h3>Оформление страхового полиса</h3>
                </div>

                {!policy.is_demo && !disableField ? (
                  <div className="pl-3">
                    <a rel="noopener noreferrer" href="#" onClick={(e) => backToForm(e, 'short')} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {showForm !== 'short' ? (
            <IflForm
              customFieldName={customFieldName}
              customFieldOptions={customFieldOptions}
              setCustomField={setCustomField}
              customField={customField}
              linkToDeal={linkToDeal}
              regionRef={regionRef}
              leadSource={leadSource}
              setLeadSource={setLeadSource}
              managerAttracted={managerAttracted}
              setManagerAttracted={setManagerAttracted}
              setLinkToDeal={setLinkToDeal}
              remoteEmployee={remoteEmployee}
              leadSourcesOptions={leadSourcesOptions}
              managerAttractedOptions={managerAttractedOptions}
              userRole={userRole}
              userFullTimeEmployee={userFullTimeEmployee}
              setFormData={setFormData}
              companies={companies}
              privacyPolicy={privacyPolicy}
              formData={formData}
              disableField={disableField}
              genderOptions={genderOptions}
              handleValidate={handleValidate}
              toggleFlag={toggleFlag}
              setCursorToEnd={setCursorToEnd}
              insuredAddressRegistrationRef={insuredAddressRegistrationRef}
              phoneCheckRequestFlag={phoneCheckRequestFlag}
              policy={policy}
              urlParams={urlParams}
              saveDraft={saveDraft}
              goToBuying={goToBuying}
              draftInfo={draftInfo}
              chosenOffer={chosenOffer}
              showForm={showForm}
              newAgent={newAgent}
              userDigitalBroker={userDigitalBroker}
              setNewAgent={setNewAgent}
              domain={domain}
            />
          ) : null}
        </LoadingBanner>
      </div>
    </>
  );
}

export default forwardRef(IflFormContainer);
