import React, { Component } from 'react';
import {
  parseISO,
  subYears,
  addDays,
  format,
  addYears,
  differenceInYears,
  differenceInMonths,
} from 'date-fns';
import ReactLoading from 'react-loading';
import ru from 'date-fns/locale/ru';
import {
  Element, animateScroll as scroll, scroller,
} from 'react-scroll';
import { ReactDadata } from 'react-dadata';
import Modal from 'react-responsive-modal';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import 'formdata-polyfill';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ym from 'react-yandex-metrika';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-regular';
import {
  faLongArrowAltUp, faLongArrowAltDown, faStar
} from '@fortawesome/fontawesome-free-solid';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import { SlideDown } from 'react-slidedown';
import queryString from 'query-string';
import OsagoCheckInfo from './OsagoCheckInfo';
import {
  switchLanguage, customSelectStyle, customFetch, getCustomFieldOptions,
} from '../../Utils/Helpers';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import getMask from '../../Utils/Masks';
import getValidation from '../../Utils/Validation';
import CommercialOffer from '../CommercialOffer';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import ProgressBar from '../../Utils/UiComponents/ProgressBar';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import 'react-slidedown/lib/slidedown.css';
import AccountSystem from '../Blocks/AccountSystem';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';
//import useSocket from '../../Utils/CustomHooks/UseSocket';

let abortController = new AbortController();
let abortControllerCalc = new AbortController();

const timeoutKbm = [];
const targetOfUsingOptions = [
  { value: 'personal', label: 'Личная' },
  { value: 'taxi', label: 'Такси' },
  { value: 'training', label: 'Учебная' },
  { value: 'dangerous', label: 'Перевозка опасных и легковоспламеняющихся грузов' },
  { value: 'rental', label: 'Прокат/краткосрочная аренда' },
  { value: 'passenger', label: 'Регулярные пассажирские перевозки/перевозки пассажиров по заказам' },
  { value: 'special', label: 'Дорожные и специальные транспортные средства' },
  { value: 'services', label: 'Экстренные и коммунальные службы' },
  { value: 'other', label: 'Прочее' },
];

let checkInterval;

class OsagoForm extends Component {
  constructor(props) {
    super(props);
    this.insuredAddressInput = React.createRef();
    this.ownerAddressInput = React.createRef();
    const year = (new Date()).getFullYear();
    this.years = Array.from(new Array(100), (val, index) => year - index);
    this.state = {
      checkStatusFlag: false,
      searchIdSravni: false,
      leadSourcesOptions: [],
      managerAttractedOptions: [],
      loadingInfoFlags: {
        companies: true,
        marks: true,
        all: true,
      },
      loadingKbm: [],
      kbms: [],
      isKbmFound: [],
      loadingInit: false,
      policySortOrderUp: false,
      selectedSort: 'commissionCurrency',
      showCommission: true,
      formIdHash: '',
      commercialOfferCompanies: [],
      linkToDeal: null,
      leadSource: null,
      customField: null,
      customFieldOptions: [],
      customFieldName: '',
      managerAttracted: null,
      prolongationChangeDateFlag: false,
      newAgent: { value: false, label: false },
      formData: {
        prevPolicySerial: {
          value: { label: '', value: '' },
          errorMessage: false,
          validationType: 'select',
        },
        prevPolicyNumber: {
          value: '',
          errorMessage: false,
          validationType: 'policyNumber',
        },
        prevPolicyCheck: false,
        prevPolicyInsurance: {
          value: { label: '', value: '' },
          errorMessage: false,
          validationType: 'select',
        },
        carNumber: {
          value: '',
          errorMessage: false,
          validationType: 'plain',
        },
        category: {
          value: { label: 'B', value: 'B' },
          errorMessage: false,
          validationType: 'select',
        },
        targetOfUsing: {
          value: { label: 'Личная', value: 'personal' },
          errorMessage: false,
          validationType: 'select',
        },
        modelId: {
          value: { label: '', value: '' },
          errorMessage: false,
          validationType: 'select',
        },
        markId: {
          value: { label: '', value: '' },
          errorMessage: false,
          validationType: 'select',
        },
        checkboxOwner: false,
        policyPeriod: {
          value: { value: 12, label: '12 месяцев' },
          validationType: 'select',
        },
        unlimitedDrivers: {
          value: { value: false, label: '1 и более' },
          validationType: 'select',
        },
        vehicleTypeDocument: {
          value: { value: 'sertificate', label: 'СТС' },
          validationType: 'select',
        },
        vehicleIdentificationType: {
          value: { value: 'vin', label: 'VIN' },
          validationType: 'select',
        },
        carYear: {
          value: { label: '', value: '' },
          errorMessage: false,
          validationType: 'select',
        },
        horsePower: {
          value: '',
          errorMessage: false,
          validationType: 'decimal',
        },
        allowedMaxWeight: {
          value: '',
          errorMessage: false,
          validationType: 'decimal',
        },
        vehiclePassengersCapacity: {
          value: '',
          errorMessage: false,
          validationType: 'int',
        },
        vehicleIdentificationNumberVin: {
          value: '',
          errorMessage: false,
          validationType: 'vin',
        },
        vehicleIdentificationNumberBody: {
          value: '',
          errorMessage: false,
          validationType: 'bodyNumber',
        },
        vehicleIdentificationNumberChassis: {
          value: '',
          errorMessage: false,
          validationType: 'chassisNumber',
        },
        serialDocument: {
          value: '',
          errorMessage: false,
          validationType: 'serialDocument',
        },
        numberDocument: {
          value: '',
          errorMessage: false,
          validationType: 'numberDocument',
        },
        numberEDocument: {
          value: '',
          errorMessage: false,
          validationType: 'numberEDocument',
        },
        lastNameInsured: {
          value: '',
          errorMessage: false,
          validationType: 'name',
        },
        firstNameInsured: {
          value: '',
          errorMessage: false,
          validationType: 'name',
        },
        middleNameInsured: {
          value: '',
          errorMessage: false,
          validationType: 'disable',
        },
        insuredPassportNumberSerial: {
          value: '',
          errorMessage: false,
          validationType: 'passportNumberSerie',
        },
        insuredAddress: {
          value: {
            value: '',
            data: {},
          },
          errorMessage: false,
          validationType: 'address',
        },
        lastNameOwner: {
          value: '',
          errorMessage: false,
          validationType: 'name',
        },
        firstNameOwner: {
          value: '',
          errorMessage: false,
          validationType: 'name',
        },
        middleNameOwner: {
          value: '',
          errorMessage: false,
          validationType: 'disable',
        },
        ownerPassportNumberSerial: {
          value: '',
          errorMessage: false,
          validationType: 'passportNumberSerie',
        },
        ownerAddress: {
          value: {
            value: '',
            data: {},
          },
          errorMessage: false,
          validationType: 'address',
        },
        lastNameDriver: [
          {
            value: '',
            errorMessage: false,
            validationType: 'name',
          },
        ],
        prevLastNameDriver: [
          {
            value: '',
            errorMessage: false,
            validationType: 'name',
          },
        ],
        firstNameDriver: [
          {
            value: '',
            errorMessage: false,
            validationType: 'name',
          },
        ],
        middleNameDriver: [
          {
            value: '',
            errorMessage: false,
            validationType: 'disable',
          },
        ],
        driverLicenceSerial: [
          {
            value: '',
            errorMessage: false,
            validationType: 'serialDocument',
          },
        ],
        driverPrevLicenceSerial: [
          {
            value: '',
            errorMessage: false,
            validationType: 'serialDocument',
          },
        ],
        driverLicenceNumber: [
          {
            value: '',
            errorMessage: false,
            validationType: 'numberDocument',
          },
        ],
        driverPrevLicenceNumber: [
          {
            value: '',
            errorMessage: false,
            validationType: 'numberDocument',
          },
        ],
        driverBirthday: [
          {
            value: null,
            errorMessage: false,
            validationType: 'date',
          },
        ],
        driverExperienceDate: [
          {
            value: null,
            errorMessage: false,
            validationType: 'date',
          },
        ],
        driverCurrentLicenceDate: [
          {
            value: null,
            errorMessage: false,
            validationType: 'date',
          },
        ],
        driverForeignLicenceCountry: [
          {
            value: { value: '', label: '' },
            errorMessage: false,
            validationType: 'select',
          },
        ],
        driverPrevForeignLicenceCountry: [
          {
            value: { value: '', label: '' },
            errorMessage: false,
            validationType: 'select',
          },
        ],
        driverPrevLicence: [false],
        driverForeignLicence: [''],
        driverPrevForeignLicence: [false],
        phone: {
          value: '',
          errorMessage: false,
          validationType: 'phone',
        },
        email: {
          value: '',
          errorMessage: false,
          validationType: 'email',
        },
        dateDocument: {
          value: null,
          errorMessage: false,
          validationType: 'date',
        },
        insuredBirthday: {
          value: null,
          errorMessage: false,
          validationType: 'date',
        },
        ownerBirthday: {
          value: null,
          errorMessage: false,
          validationType: 'date',
        },
        insuredPassportDate: {
          value: null,
          errorMessage: false,
          validationType: 'date',
        },
        ownerPassportDate: {
          value: null,
          errorMessage: false,
          validationType: 'date',
        },
        insuredPassportIssuedBy: {
          value: null,
          errorMessage: '',
          validationType: 'plain',
        },
        ownerPassportIssuedBy: {
          value: null,
          errorMessage: '',
          validationType: 'plain',
        },
        insuredPassportUnitNumber: {
          value: null,
          errorMessage: '',
          validationType: 'passportUnitNumber',
        },
        ownerPassportUnitNumber: {
          value: null,
          errorMessage: '',
          validationType: 'passportUnitNumber',
        },
        policyDate: {
          value: addDays(new Date(), 4),
          errorMessage: false,
          validationType: 'date',
        },
        prevPolicyDate: {
          value: null,
          errorMessage: false,
          validationType: 'date',
        },
        registrationNumberBlank: false,
        useTrailer: false,
      },
      yandexGoals: {
        createQuote: false,
        getResult: false,
        chooseInsurance: false,
      },
      companies: {},
      sravniCompanies: [],
      choosenOffer: {},
      showOsagoCheckForm: false,
      blurClass: '',
      policyResultData: [],
      errorLoadingCalcData: false,
      disableField: false,
      policyStatus: 'not_exist',
      marks: [],
      models: [],
      loadingCarInfo: false,
      errorBlankRegNumber: false,
      errorTooManyRequests: false,
      errorWrongRegNumber: false,
      errorCantCompareCar: false,
      loadingCalcData: false,
      openModalSegmentation: false,
      endPolicy: null,
      countriesNames: [],
      allPolicyBlock: '',
      otherCompaniesBlock: '',
    };
  }

  componentDidMount() {
    document.title = 'Оформить полис';
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    getCustomFieldOptions().then((response) => {
      if (response.options) {
        this.setState({
          customFieldOptions: response.options.map((option) => ({ value: option.id, label: option.name })),
          customFieldName: response.name,
        });
      }
    });
    getCompanies(lsToken).then((companies) => {
      this.setState({ companies });
    }).finally(() => {
      this.setState((prevState) => ({
        ...prevState,
        loadingInfoFlags: {
          ...prevState.loadingInfoFlags,
          companies: false,
        },
      }));
    });
    getCompanies(lsToken, 'sravni').then((companies) => {
      const sravniCompanies = Object.values(companies).map((company) => ({ value: company.sravni_id, label: company.sravni_name }));
      this.setState({ sravniCompanies }, () => {
        const { formData } = this.state;
        if (formData.prevPolicyCheck && formData.prevPolicyInsurance.value.value) {
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              prevPolicyInsurance: {
                ...prevState.formData.prevPolicyInsurance,
                value: sravniCompanies.filter(({ value }) => value === formData.prevPolicyInsurance.value.value)[0],
              },
            },
          }));
        }
      });
    });

    this.loadCountriesNames();

    const { formData } = this.state;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_marks?category=${formData.category.value.value}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ marks: response.value });
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      })
      .finally(() => {
        this.setState((prevState) => ({
          ...prevState,
          loadingInfoFlags: {
            ...prevState.loadingInfoFlags,
            marks: false,
          },
        }));
      });
    const { userInfo } = this.props;
    if (userInfo.full_time_employee === 1) {
      this.downLoadLeadSources();
      this.downloadManagers();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      policy, match, userInfo, location,
    } = this.props;
    const urlParams = queryString.parse(location.search);
    const {
      policyResultData,
      loadingCalcData,
      selectedSort,
      policySortOrderUp,
      loadingInfoFlags,
      formIdHash,
    } = this.state;

    if (prevProps.userInfo.full_time_employee !== userInfo.full_time_employee && userInfo.full_time_employee === 1 && process.env.REACT_APP_API_DOMAIN === 'https://api.polis.online/api') {
      this.downLoadLeadSources();
      this.downloadManagers();
    }

    // Загружаем полис при получении полиса из props, если загружены все справочники
    if (prevProps.policy !== policy && Object.keys(policy).length !== 0) {
      if (!loadingInfoFlags.all || (!loadingInfoFlags.companies && !loadingInfoFlags.marks)) {
        this.loadPolicyInfo(policy);
      }
    }

    // После загрузки справочников проверяем есть ли id полиса и загружаем его, если получены данные
    if ((loadingInfoFlags.companies !== prevState.loadingInfoFlags.companies && !loadingInfoFlags.marks)
      || (loadingInfoFlags.marks !== prevState.loadingInfoFlags.marks && !loadingInfoFlags.companies)) {
      if (match.params.id) {
        if (Object.keys(policy).length !== 0) {
          this.loadPolicyInfo(policy);
        }
      } else {
        this.setState((prev) => ({
          ...prev,
          loadingInfoFlags: {
            ...prev.loadingInfoFlags,
            all: false,
          },
        }));
      }
    }

    if (((policyResultData.length !== prevState.policyResultData.length || loadingCalcData !== prevState.loadingCalcData)
      && policyResultData.length > 0)
      || selectedSort !== prevState.selectedSort
      || policySortOrderUp !== prevState.policySortOrderUp
    ) {
      const sortedPolicyResultData = this.sortAllPolicesArray(policyResultData);
      this.setState({ policyResultData: sortedPolicyResultData });
    }
    if (urlParams.getChosenOffer && policy.id && formIdHash && prevState.formIdHash !== formIdHash) {
      this.getChosenOffer();
    }
  }

  componentWillUnmount() {
    //const { leaveChanel } = useSocket();
    //const { policy } = this.props;
    //leaveChanel(`calculate.osago.${policy.id}`);
  }

  makeRequestForSupport = () => {
    const { formData } = this.state;
    const { showModalInfo } = this.context;
    const { policy } = this.props;

    let insuranceId = '';
    let vehicleNumber = '';

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/osago/${policy.id}/get-insurance-id`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        }

        switch (formData.vehicleIdentificationType.value.value) {
          case 'vin':
            vehicleNumber = `vin-номер ${formData.vehicleIdentificationNumberVin.value}`;
            break;
          case 'body':
            vehicleNumber = `номер кузова ${formData.vehicleIdentificationNumberBody.value}`;
            break;
          case 'chassis':
            vehicleNumber = `номер шасси: ${formData.vehicleIdentificationNumberChassis.value}`;
            break;
          default:
            break;
        }

        if (formData.carNumber.value) {
          vehicleNumber += `, гос. номер: ${formData.carNumber.value}`;
        }

        if (response.insuranceId) {
          insuranceId = `: ${response.insuranceId}`;
        }

        const requestText = `
Добрый день!

Посмотрите пож-та расчет${insuranceId}
Страхователь: ${formData.lastNameInsured.value} ${formData.firstNameInsured.value} ${formData.middleNameInsured.value}
Дата рождения: ${format(formData.insuredBirthday.value, 'dd.MM.yyyy')}
Объект: ${vehicleNumber}
Суть проблемы:`;

        setTimeout(async () => await navigator.clipboard.writeText(requestText));
        showModalInfo('Текст запроса в поддержку СК скопирован в буфер обмена');
      })
      .catch(() => {
        showModalInfo('Ошибка получения id расчета выбранной СК', 'error');
      });
  };

  getChosenOffer = () => {
    const { showModalInfo } = this.context;
    const { policy } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}/get-chosen-offer`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        }
        this.setState({ choosenOffer: response, showOsagoCheckForm: true }, () => {
          scroller.scrollTo('search_results__main', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -70,
          });
        });
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки справочников', 'error');
      });
  };

  loadCountriesNames = () => {
    const { showModalInfo } = this.context;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/countries-names`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        }
        const countriesNames = response.map((country) => ({ value: country.code, label: country.name }));
        this.setState({ countriesNames });
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки справочников', 'error');
      });
  };

  downLoadLeadSources = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/lead_sources`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ leadSourcesOptions: response.map((leadSource) => ({ value: leadSource.id, label: leadSource['Представление'] })) });
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      });
  };

  downloadManagers = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/managers`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ managerAttractedOptions: response.map((managerAttracted) => ({ value: managerAttracted.id, label: managerAttracted['Представление'] })) });
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      });
  };

  loadPolicyInfo = (policy) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const dataForm = policy.form_data;
    let vehicleIdentificationType;
    let vehicleTypeDocument;
    let unlimitedDrivers;
    let policyPeriod;

    if (dataForm.vehicleIdentificationType === 'vin') {
      vehicleIdentificationType = { value: 'vin', label: 'VIN' };
    } else if (dataForm.vehicleIdentificationType === 'body') {
      vehicleIdentificationType = { value: 'body', label: 'Номер кузова' };
    } else if (dataForm.vehicleIdentificationType === 'chassis') {
      vehicleIdentificationType = { value: 'chassis', label: 'Номер шасси' };
    }

    if (dataForm.vehicleTypeDocument === 'sertificate') {
      vehicleTypeDocument = { value: 'sertificate', label: 'СТС' };
    } else if (dataForm.vehicleTypeDocument === 'passport') {
      vehicleTypeDocument = { value: 'passport', label: 'ПТС' };
    } else if (dataForm.vehicleTypeDocument === 'epassport') {
      vehicleTypeDocument = { value: 'epassport', label: 'ЭПТС' };
    }
    if (dataForm.unlimitedDrivers === false) {
      unlimitedDrivers = { value: false, label: '1 и более' };
    } else if (dataForm.unlimitedDrivers === true) {
      unlimitedDrivers = { value: true, label: 'Неограниченное количество' };
    }
    switch (dataForm.policyPeriod) {
      case '12':
        policyPeriod = { value: 12, label: '12 Месяцев' };
        break;
      case '9':
        policyPeriod = { value: 9, label: '9 Месяцев' };
        break;
      case '7':
        policyPeriod = { value: 7, label: '7 Месяцев' };
        break;
      case '6':
        policyPeriod = { value: 6, label: '6 Месяцев' };
        break;
      case '5':
        policyPeriod = { value: 5, label: '5 Месяцев' };
        break;
      case '4':
        policyPeriod = { value: 4, label: '4 Месяца' };
        break;
      case '3':
        policyPeriod = { value: 3, label: '3 Месяца' };
        break;
      default:
        policyPeriod = { value: 12, label: '12 Месяцев' };
        break;
    }

    const insuredPassportDate = dataForm.insuredPassportDate && !dataForm.insuredPassportDate.includes('*') ? parseISO(dataForm.insuredPassportDate.split('.').reverse().join('-')) : null;
    const insuredBirthday = dataForm.insuredBirthday && !dataForm.insuredBirthday.includes('*') ? parseISO(dataForm.insuredBirthday.split('.').reverse().join('-')) : null;
    const ownerBirthday = dataForm.ownerBirthday && !dataForm.ownerBirthday.includes('*') ? parseISO(dataForm.ownerBirthday.split('.').reverse().join('-')) : null;
    const ownerPassportDate = dataForm.ownerPassportDate && !dataForm.ownerPassportDate.includes('*') ? parseISO(dataForm.ownerPassportDate.split('.').reverse().join('-')) : null;
    const prevPolicyDate = dataForm.prevPolicyDate && !dataForm.prevPolicyDate.includes('*') ? parseISO(dataForm.prevPolicyDate.split('.').reverse().join('-')) : null;

    const { formData } = this.state;
    const {
      driverBirthday,
      driverExperienceDate,
      driverCurrentLicenceDate,
      lastNameDriver,
      firstNameDriver,
      middleNameDriver,
      driverLicenceSerial,
      driverLicenceNumber,
      prevLastNameDriver,
      driverPrevLicence,
      driverForeignLicence,
      driverPrevForeignLicence,
      driverPrevLicenceSerial,
      driverPrevLicenceNumber,
      driverPrevForeignLicenceCountry,
      driverForeignLicenceCountry,
    } = formData;
    dataForm.driverBirthday.forEach((item, i) => {
      if (item && !item.includes('*')) {
        driverBirthday[i] = {
          ...driverBirthday[0],
          value: parseISO(item.split('.').reverse().join('-')),
          errorMessage: false,
        };
      } else {
        driverBirthday[i] = {
          ...driverBirthday[0],
          value: null,
          errorMessage: false,
        };
      }
    });

    dataForm.driverExperienceDate.forEach((item, i) => {
      if (item && !item.includes('*')) {
        driverExperienceDate[i] = {
          ...driverExperienceDate[0],
          value: parseISO(item.split('.').reverse().join('-')),
          errorMessage: false,
        };
      } else {
        driverExperienceDate[i] = {
          ...driverExperienceDate[0],
          value: null,
          errorMessage: false,
        };
      }
    });

    if (dataForm.driverCurrentLicenceDate && dataForm.driverCurrentLicenceDate.length !== 0) {
      dataForm.driverCurrentLicenceDate.forEach((item, i) => {
        if (item && !item.includes('*')) {
          driverCurrentLicenceDate[i] = {
            ...driverCurrentLicenceDate[0],
            value: parseISO(item.split('.').reverse().join('-')),
            errorMessage: false,
          };
        } else {
          driverCurrentLicenceDate[i] = {
            ...driverCurrentLicenceDate[0],
            value: null,
            errorMessage: false,
          };
        }
      });
    } else {
      dataForm.driverExperienceDate.forEach((item, i) => {
        driverCurrentLicenceDate[i] = {
          ...driverCurrentLicenceDate[0],
          value: null,
          errorMessage: false,
        };
      });
    }

    dataForm.lastNameDriver.forEach((item, i) => {
      lastNameDriver[i] = {
        ...lastNameDriver[0],
        value: item,
        errorMessage: false,
      };
    });

    dataForm.firstNameDriver.forEach((item, i) => {
      firstNameDriver[i] = {
        ...firstNameDriver[0],
        value: item,
        errorMessage: false,
      };
    });

    dataForm.middleNameDriver.forEach((item, i) => {
      middleNameDriver[i] = {
        ...middleNameDriver[0],
        value: item,
        errorMessage: false,
      };
    });

    if (dataForm.driverForeignLicence) {
      if (dataForm.driverForeignLicenceCountry) {
        dataForm.driverForeignLicenceCountry.forEach((item, i) => {
          const { countriesNames } = this.state;
          const countryIndex = countriesNames.findIndex((country) => item === country.label);
          driverForeignLicenceCountry[i] = {
            ...driverForeignLicenceCountry[i],
            value: countryIndex !== -1 ? countriesNames[countryIndex] : { value: '', label: '' },
            errorMessage: false,
            validationType: 'select',
          };
        });
      } else {
        dataForm.driverForeignLicence.forEach((item, i) => {
          driverForeignLicenceCountry[i] = {
            ...driverForeignLicenceCountry[i],
            value: { value: '', label: '' },
            errorMessage: false,
            validationType: 'select',
          };
        });
      }
    }

    if (dataForm.driverForeignLicence) {
      dataForm.driverForeignLicence.forEach((item, i) => {
        driverForeignLicence[i] = item === null ? '' : item;
      });
    }

    dataForm.driverLicenceSerial.forEach((item, i) => {
      driverLicenceSerial[i] = {
        ...driverLicenceSerial[i],
        value: item,
        errorMessage: false,
        validationType: driverForeignLicence[i] ? 'serialForeignDocument' : 'serialDocument',
      };
    });

    dataForm.driverLicenceNumber.forEach((item, i) => {
      driverLicenceNumber[i] = {
        ...driverLicenceNumber[i],
        value: item,
        errorMessage: false,
        validationType: driverForeignLicence[i] ? 'numberForeignDocument' : 'numberDocument',
      };
    });

    if (dataForm.driverPrevForeignLicence) {
      dataForm.driverPrevForeignLicence.forEach((item, i) => {
        driverPrevForeignLicence[i] = item === null ? false : item;
      });
    } else {
      dataForm.lastNameDriver.forEach((item, i) => {
        driverPrevForeignLicence[i] = false;
      });
    }
    if (dataForm.driverPrevLicence) {
      dataForm.driverPrevLicence.forEach((item, i) => {
        driverPrevLicence[i] = item === null ? false : item;
      });
      dataForm.prevLastNameDriver.forEach((item, i) => {
        prevLastNameDriver[i] = {
          ...prevLastNameDriver[i],
          value: item,
          errorMessage: false,
          validationType: 'name',
        };
      });
      dataForm.driverPrevLicenceSerial.forEach((item, i) => {
        driverPrevLicenceSerial[i] = {
          ...driverPrevLicenceSerial[i],
          value: item,
          errorMessage: false,
          validationType: driverPrevForeignLicence[i] ? 'serialForeignDocument' : 'serialDocument',
        };
      });
      dataForm.driverPrevLicenceNumber.forEach((item, i) => {
        driverPrevLicenceNumber[i] = {
          ...driverPrevLicenceNumber[i],
          value: item,
          errorMessage: false,
          validationType: driverPrevForeignLicence[i] ? 'serialForeignDocument' : 'numberDocument',
        };
      });
      if (dataForm.driverPrevForeignLicenceCountry) {
        dataForm.driverPrevForeignLicenceCountry.forEach((item, i) => {
          const { countriesNames } = this.state;
          const countryIndex = countriesNames.findIndex((country) => item === country.label);
          driverPrevForeignLicenceCountry[i] = {
            ...driverPrevForeignLicenceCountry[i],
            value: countryIndex !== -1 ? countriesNames[countryIndex] : { value: '', label: '' },
            errorMessage: false,
            validationType: 'select',
          };
        });
      } else {
        dataForm.driverPrevLicence.forEach((item, i) => {
          driverPrevForeignLicenceCountry[i] = {
            ...driverPrevForeignLicenceCountry[i],
            value: { value: '', label: '' },
            errorMessage: false,
            validationType: 'select',
          };
        });
      }
    } else {
      dataForm.lastNameDriver.forEach((item, i) => {
        driverPrevLicence[i] = false;
        driverPrevLicenceSerial[i] = {
          ...driverPrevLicenceSerial[i],
          value: '',
          errorMessage: false,
          validationType: 'serialDocument',
        };
        driverPrevLicenceNumber[i] = {
          ...driverPrevLicenceNumber[i],
          value: '',
          errorMessage: false,
          validationType: 'numberDocument',
        };
        prevLastNameDriver[i] = {
          ...prevLastNameDriver[i],
          value: '',
          errorMessage: false,
          validationType: 'name',
        };
        driverPrevForeignLicence[i] = false;
        driverPrevForeignLicenceCountry[i] = {
          ...driverPrevForeignLicenceCountry[i],
          value: { value: '', label: '' },
          errorMessage: false,
          validationType: 'select',
        };
      });
    }

    this.setState((prevState) => ({
      prolongationChangeDateFlag: dataForm.changeDateFlag ? dataForm.changeDateFlag : false,
      formData: {
        ...prevState.formData,
        lastNameDriver,
        firstNameDriver,
        middleNameDriver,
        driverLicenceSerial,
        driverLicenceNumber,
        driverBirthday,
        prevLastNameDriver,
        driverPrevLicence,
        driverForeignLicence,
        driverPrevLicenceSerial,
        driverPrevForeignLicence,
        driverPrevLicenceNumber,
        driverPrevForeignLicenceCountry,
        driverForeignLicenceCountry,
      },
    }), () => {
      if (policy.status === 'pending') {
        firstNameDriver.forEach((driver, index) => {
          this.getKbm(index);
        });
      }
    });

    const dateDocument = dataForm.dateDocument && !dataForm.dateDocument.includes('*') ? parseISO(dataForm.dateDocument.split('.').reverse().join('-')) : null;

    let policyDate;
    if (dataForm.policyDate) {
      if (parseISO(dataForm.policyDate.split('.').reverse().join('-')) <= new Date() && policy.status !== 'complete' && policy.status !== 'processing') {
        policyDate = addDays(new Date(), 4);
      } else {
        policyDate = parseISO(dataForm.policyDate.split('.').reverse().join('-'));
      }
    } else {
      policyDate = addDays(new Date(), 4);
    }

    const category = dataForm.category ? dataForm.category : 'B';

    if (parseISO(policy.created_at) > parseISO('2019-07-23')) {
      if (dataForm.markName && dataForm.markId) {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_marks?category=${category}`, {
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ marks: response.value });
          })
          .catch((err) => {
            console.log(`Error Reading data ${err}`);
          });

        customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${dataForm.markId}?category=${category}`, {
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((models) => {
            if (!models.Error) {
              this.setState((prevState) => ({
                models: models.value,
                formData: {
                  ...prevState.formData,
                  markId: {
                    ...prevState.formData.markId,
                    value: { label: dataForm.markName, value: dataForm.markId },
                  },
                  modelId: {
                    ...prevState.formData.modelId,
                    value: { label: dataForm.modelName, value: dataForm.modelId },
                  },
                },
              }));
            }
          })
          .catch((err) => {
            console.log(`Error Reading data ${err}`);
          });
      }
    }
    const { sravniCompanies } = this.state;
    let prevPolicyInsurance = { label: '', value: '' };
    if (sravniCompanies.length > 0 && dataForm.prevPolicyInsurance) {
      prevPolicyInsurance = sravniCompanies.filter(({ value }) => value === dataForm.prevPolicyInsurance)[0];
    } else if (dataForm.prevPolicyInsurance) {
      prevPolicyInsurance = { label: dataForm.prevPolicyInsurance, value: dataForm.prevPolicyInsurance };
    }

    let newAgent = { value: false, label: false };
    if (policy.digital_broker_id) {
      newAgent = {
        value: policy.agent.id,
        label: `${policy.agent.last_name} ${policy.agent.first_name} ${policy.agent.middle_name} - ${policy.agent.id}`,
      };
    }

    this.setState((prevState) => ({
      linkToDeal: policy.link_to_deal ? policy.link_to_deal : null,
      leadSource: policy.lead_source ? policy.lead_source : null,
      managerAttracted: policy.manager_attracted ? policy.manager_attracted : null,
      customField: policy.custom_field ? { label: policy.custom_field.name, value: policy.custom_field.id } : null,
      formIdHash: policy.hash,
      digital_broker_id: policy.digital_broker_id,
      newAgent,
      formData: {
        ...prevState.formData,
        carNumber: {
          ...prevState.formData.carNumber,
          value: dataForm.carNumber ? dataForm.carNumber.replace(/_/g, '') : '',
        },
        prevPolicyCheck: dataForm.prevPolicyCheck ? dataForm.prevPolicyCheck : false,
        useTrailer: dataForm.useTrailer ? dataForm.useTrailer : false,
        prevPolicyInsurance: {
          ...prevState.formData.prevPolicyInsurance,
          value: prevPolicyInsurance,
          errorMessage: false,
        },
        category: {
          ...prevState.formData.category,
          value: dataForm.category ? { label: dataForm.category, value: dataForm.category } : { label: 'B', value: 'B' },
          errorMessage: false,
        },
        targetOfUsing: {
          ...prevState.formData.targetOfUsing,
          errorMessage: false,
          value: dataForm.targetOfUsing ? targetOfUsingOptions.filter(({ value }) => value === dataForm.targetOfUsing)[0] : { label: 'Личная', value: 'personal' },
        },
        prevPolicySerial: {
          ...prevState.formData.prevPolicySerial,
          errorMessage: false,
          value: dataForm.prevPolicySerial ? { label: dataForm.prevPolicySerial, value: dataForm.prevPolicySerial } : { label: '', value: '' },
        },
        prevPolicyNumber: {
          ...prevState.formData.prevPolicyNumber,
          errorMessage: false,
          value: dataForm.prevPolicyNumber ? dataForm.prevPolicyNumber : '',
        },
        horsePower: {
          ...prevState.formData.horsePower,
          errorMessage: false,
          value: dataForm.horsePower,
        },
        allowedMaxWeight: {
          ...prevState.formData.allowedMaxWeight,
          errorMessage: false,
          value: dataForm.allowedMaxWeight ? dataForm.allowedMaxWeight : '',
        },
        vehiclePassengersCapacity: {
          ...prevState.formData.vehiclePassengersCapacity,
          errorMessage: false,
          value: dataForm.vehiclePassengersCapacity ? dataForm.vehiclePassengersCapacity : '',
        },
        serialDocument: {
          ...prevState.formData.serialDocument,
          errorMessage: false,
          value: dataForm.serialDocument,
        },
        numberDocument: {
          ...prevState.formData.numberDocument,
          errorMessage: false,
          value: dataForm.numberDocument,
        },
        numberEDocument: {
          ...prevState.formData.numberEDocument,
          errorMessage: false,
          value: dataForm.numberEDocument,
        },
        lastNameInsured: {
          ...prevState.formData.lastNameInsured,
          errorMessage: false,
          value: dataForm.lastNameInsured,
        },
        firstNameInsured: {
          ...prevState.formData.firstNameInsured,
          errorMessage: false,
          value: dataForm.firstNameInsured,
        },
        middleNameInsured: {
          ...prevState.formData.middleNameInsured,
          errorMessage: false,
          value: dataForm.middleNameInsured,
        },
        insuredPassportNumberSerial: {
          ...prevState.formData.insuredPassportNumberSerial,
          errorMessage: false,
          value: dataForm.insuredPassportNumberSerial,
        },
        lastNameOwner: {
          ...prevState.formData.lastNameOwner,
          errorMessage: false,
          value: dataForm.lastNameOwner,
        },
        firstNameOwner: {
          ...prevState.formData.firstNameOwner,
          errorMessage: false,
          value: dataForm.firstNameOwner,
        },
        middleNameOwner: {
          ...prevState.formData.middleNameOwner,
          errorMessage: false,
          value: dataForm.middleNameOwner,
        },
        ownerPassportNumberSerial: {
          ...prevState.formData.ownerPassportNumberSerial,
          errorMessage: false,
          value: dataForm.ownerPassportNumberSerial,
        },
        phone: {
          ...prevState.formData.phone,
          errorMessage: false,
          value: dataForm.phone,
        },
        email: {
          ...prevState.formData.email,
          errorMessage: false,
          value: dataForm.email,
        },
        dateDocument: {
          ...prevState.formData.dateDocument,
          errorMessage: false,
          value: dateDocument,
        },
        insuredBirthday: {
          ...prevState.formData.insuredBirthday,
          errorMessage: false,
          value: insuredBirthday,
        },
        ownerBirthday: {
          ...prevState.formData.ownerBirthday,
          errorMessage: false,
          value: ownerBirthday,
        },
        insuredPassportDate: {
          ...prevState.formData.insuredPassportDate,
          errorMessage: false,
          value: insuredPassportDate,
        },
        ownerPassportDate: {
          ...prevState.formData.ownerPassportDate,
          errorMessage: false,
          value: ownerPassportDate,
        },
        insuredPassportIssuedBy: {
          ...prevState.formData.insuredPassportIssuedBy,
          errorMessage: false,
          value: dataForm.insuredPassportIssuedBy || null,
        },
        ownerPassportIssuedBy: {
          ...prevState.formData.ownerPassportIssuedBy,
          errorMessage: false,
          value: dataForm.ownerPassportIssuedBy || null,
        },
        insuredPassportUnitNumber: {
          ...prevState.formData.insuredPassportUnitNumber,
          errorMessage: false,
          value: dataForm.insuredPassportUnitNumber ? dataForm.insuredPassportUnitNumber.replace('-', '') : null,
        },
        ownerPassportUnitNumber: {
          ...prevState.formData.ownerPassportUnitNumber,
          errorMessage: false,
          value: dataForm.ownerPassportUnitNumber ? dataForm.ownerPassportUnitNumber.replace('-', '') : null,
        },
        insuredAddress: {
          ...prevState.formData.insuredAddress,
          errorMessage: false,
          value: {
            data: dataForm.insuredAddressData,
            value: dataForm.insuredAddress,
          },
        },
        ownerAddress: {
          ...prevState.formData.ownerAddress,
          errorMessage: false,
          value: {
            data: dataForm.ownerAddressData,
            value: dataForm.ownerAddress,
          },
        },
        policyDate: {
          ...prevState.formData.policyDate,
          errorMessage: false,
          value: policyDate,
        },
        prevPolicyDate: {
          ...prevState.formData.prevPolicyDate,
          errorMessage: false,
          value: prevPolicyDate,
        },
        registrationNumberBlank: dataForm.registrationNumberBlank,
        carYear: {
          ...prevState.formData.carYear,
          errorMessage: false,
          value: { label: dataForm.carYear, value: dataForm.carYear },
        },
        vehicleIdentificationType: {
          ...prevState.formData.vehicleIdentificationType,
          errorMessage: false,
          value: vehicleIdentificationType,
        },
        vehicleIdentificationNumberVin: {
          ...prevState.formData.vehicleIdentificationNumberVin,
          errorMessage: false,
          value: dataForm.vehicleIdentificationNumberVin ? dataForm.vehicleIdentificationNumberVin : '',
        },
        vehicleIdentificationNumberBody: {
          ...prevState.formData.vehicleIdentificationNumberBody,
          errorMessage: false,
          value: dataForm.vehicleIdentificationNumberBody ? dataForm.vehicleIdentificationNumberBody : '',
        },
        vehicleIdentificationNumberChassis: {
          ...prevState.formData.vehicleIdentificationNumberChassis,
          errorMessage: false,
          value: dataForm.vehicleIdentificationNumberChassis ? dataForm.vehicleIdentificationNumberChassis : '',
        },
        vehicleTypeDocument: {
          ...prevState.formData.vehicleTypeDocument,
          errorMessage: false,
          value: vehicleTypeDocument,
        },
        unlimitedDrivers: {
          ...prevState.formData.unlimitedDrivers,
          errorMessage: false,
          value: unlimitedDrivers,
        },
        policyPeriod: {
          ...prevState.formData.policyPeriod,
          errorMessage: false,
          value: policyPeriod,
        },
        checkboxOwner: dataForm.checkboxOwner,
      },
      disableField: policy.status !== 'pending',
      policyStatus: policy.status,
      kbms: policy.status !== 'pending' && policy.kbms ? policy.kbms.split(',') : prevState.kbms,
      endPolicy: policy.end_police,
    }), () => {
      let loadingInfoFlag = false;
      if (policy.status === 'pending' && sravniCompanies.length > 0 && !dataForm.prevPolicyInsurance) {
        if (dataForm.carNumber) {
          this.getPrevPolicy('regNumber');
          loadingInfoFlag = true;
        }
        if (dataForm.registrationNumberBlank && dataForm.vehicleIdentificationType === 'vin' && dataForm.vehicleIdentificationNumberVin) {
          this.getPrevPolicy('vin');
          loadingInfoFlag = true;
        }
      }
      this.setState((prevState) => ({
        ...prevState,
        loadingInfoFlags: {
          ...prevState.loadingInfoFlags,
          all: loadingInfoFlag,
        },
      }));
    });
  }

  onCloseModalSegmentation = () => {
    this.setState({ openModalSegmentation: false });
  };

  validateAllFields = () => {
    const { formData } = this.state;
    let validationSuccess = true;
    let errorBlockName = false;
    Object.keys(formData).forEach((field) => {
      if (Array.isArray(formData[field])) {
        formData[field].forEach((driver, i) => {
          if (formData[field][i].validationType && document.getElementById(field)) {
            if (field === 'prevLastNameDriver' || field === 'driverPrevLicenceSerial' || field === 'driverPrevLicenceNumber' || field === 'driverPrevForeignLicenceCountry') {
              if (formData.driverPrevLicence[i]) {
                let { value } = formData[field][i];
                if (field === 'driverPrevForeignLicenceCountry' && formData.driverPrevForeignLicence[i]) {
                  value = value.value || null;
                }
                formData[field][i].errorMessage = getValidation(value, formData[field][i].validationType);
                if (formData[field][i].errorMessage) {
                  validationSuccess = false;
                  if (!errorBlockName) {
                    errorBlockName = field;
                  }
                }
              }
            } else {
              let { value } = formData[field][i];
              if (field === 'driverForeignLicenceCountry' && formData.driverForeignLicence[i]) {
                value = value.value || null;
              }
              formData[field][i].errorMessage = getValidation(value, formData[field][i].validationType);
              if (formData[field][i].errorMessage) {
                validationSuccess = false;
                if (!errorBlockName) {
                  errorBlockName = field;
                }
              }
            }
          }
        });
      } else if (formData[field].validationType && document.getElementById(field)) {
        if (field === 'carNumber' && formData.registrationNumberBlank) {
          formData.carNumber.value = '';
          formData.carNumber.errorMessage = false;
        } else {
          let value;
          if (formData[field].validationType === 'select') {
            value = formData[field].value ? formData[field].value.value : null;
          } else {
            value = formData[field].value;
          }
          formData[field].errorMessage = getValidation(value, formData[field].validationType);
          if (formData[field].errorMessage) {
            validationSuccess = false;
            if (!errorBlockName) {
              errorBlockName = field;
            }
          }
        }
      }
    });
    this.setState({
      formData,
    }, () => {
      if (errorBlockName) {
        scroller.scrollTo(errorBlockName, {
          duration: 1200,
          delay: 100,
          smooth: true,
          offset: -100,
        });
      }
    });
    return validationSuccess;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const promise = new Promise(this.handleCopyInfo);
    promise.then(() => {
      const {
        yandexGoals,
        formData,
      } = this.state;
      this.setState({
        policyResultData: [],
        searchIdSravni: false,
        commercialOfferCompanies: [],
        errorLoadingCalcData: false,
        blurClass: '',
        yandexGoals: {
          ...yandexGoals,
          getResult: false,
        },
      });

      const validationSuccess = this.validateAllFields();
      if (validationSuccess) {
        ym('reachGoal', 'createQuote');
        this.setState({
          loadingCalcData: true,
          loadingInit: true,
        }, () => {
          scroller.scrollTo('searchResults', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -170,
          });
        });

        const formFields = {};

        Object.entries(formData).forEach(([key, field]) => {
          if (Array.isArray(field)) {
            formFields[key] = field.map((item) => {
              let newItem = item;
              if (item.validationType) {
                newItem = (item.validationType === 'select' || item.validationType === 'address') ? item.value.value : item.value;
                if (key === 'driverPrevForeignLicenceCountry' || key === 'driverForeignLicenceCountry') {
                  newItem = item.value.label;
                }
                if (item.validationType === 'date') {
                  newItem = newItem !== null ? format(newItem, 'dd.MM.yyyy') : newItem;
                }
                if (field.validationType === 'select') {
                  newItem = newItem.toFixed(0);
                }
              }
              return newItem;
            });
          } else if (field.validationType) {
            formFields[key] = field.validationType === 'select' || field.validationType === 'address' ? field.value.value : field.value;
            if (field.validationType === 'date') {
              formFields[key] = formFields[key] !== null ? format(formFields[key], 'dd.MM.yyyy') : formFields[key];
            }
            if (field.validationType === 'select') {
              formFields[key] = typeof (formFields[key]) === 'number' ? formFields[key].toFixed(0) : formFields[key];
            }
          } else {
            formFields[key] = field;
          }
        });

        formFields.insuredAddressData = formData.insuredAddress.value.data;
        formFields.ownerAddressData = formData.ownerAddress.value.data;

        formFields.markName = formData.markId.value.label;
        formFields.modelName = formData.modelId.value.label;

        formFields.insuredPassportNumberSerial = formFields.insuredPassportNumberSerial.replace(/[^a-zа-я0-9]+/ig, '');
        formFields.ownerPassportNumberSerial = formFields.ownerPassportNumberSerial.replace(/[^a-zа-я0-9]+/ig, '');

        const upperInputs = [
          'serialDocument',
          'numberDocument',
          'carNumber',
        ];
        upperInputs.forEach((upperInput) => {
          if (formFields[upperInput]) {
            formFields[upperInput] = formFields[upperInput].toUpperCase();
          }
        });
        this.savePolicy(formFields).then(() => {
          this.calculate();
        });
      }
    }, (err) => {
      console.log(err);
    });
  };

  convertPolicyToDkp = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { history, policy } = this.props;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}/convertPolicyToDkp`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        history.push(`/dkp/${response.id}`);
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
      });
  };

  copyForm = (e) => {
    e.preventDefault();
    this.setState({ disableField: false }, function () {
      const promise = new Promise(this.handleCopyInfo);
      promise.then(() => {
        const { copyDraft } = this.props;
        copyDraft();
        this.setState({
          commercialOfferCompanies: [],
          loadingInit: false,
        });
      }, (err) => {
        console.log(err);
      });
    });
  };

  savePolicy = (osagoForm) => new Promise((resolve, reject) => {
    const {
      kbms, leadSource, linkToDeal, managerAttracted, customField, newAgent,
    } = this.state;
    const { policy } = this.props;
    const { showModalInfo } = this.context;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    if (policy.id) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}`, {
        method: 'put',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formData: osagoForm,
          kbms,
          leadSource,
          linkToDeal,
          managerAttracted,
          customField: customField ? customField.value : null,
          newAgent: newAgent.value,
        }),
      })
        .then((response) => response.json())
        .then(() => {
          showModalInfo('Черновик обновлен');
          resolve(policy.id);
        })
        .catch(() => {
          this.setState({
            loadingCalcData: false,
          });
        });
    } else {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formData: osagoForm,
          type: 'osago',
          kbms,
          leadSource,
          linkToDeal,
          managerAttracted,
          customField: customField ? customField.value : null,
          newAgent: newAgent.value,
        }),
      })
        .then((response) => response.json())
        .then((form) => {
          showModalInfo('Черновик сохранен');
          this.setState({ formIdHash: form.hash }, () => {
            const { loadPolicy } = this.props;
            loadPolicy(form.id).then(() => {
              window.history.pushState(null, null, `/osago/${form.id}`);
              resolve(form.id);
            });
          });
        })
        .catch(() => {
          this.setState({
            loadingCalcData: false,
          });
        });
    }
  })

  saveOsagoForm = () => {
    const { showModalInfo } = this.context;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { policy } = this.props;
    const promise = new Promise(this.handleCopyInfo);
    promise.then(() => {
      const {
        formData, kbms, linkToDeal, leadSource, managerAttracted, customField, newAgent,
      } = this.state;
      const formFields = {};
      Object.entries(formData).forEach(([key, field]) => {
        if (Array.isArray(field)) {
          formFields[key] = field.map((item) => {
            let newItem = item;
            if (item.validationType) {
              newItem = (item.validationType === 'select' || item.validationType === 'address') ? item.value.value : item.value;
              if (key === 'driverPrevForeignLicenceCountry' || key === 'driverForeignLicenceCountry') {
                newItem = item.value.label;
              }
              if (item.validationType === 'date') {
                newItem = newItem !== null ? format(newItem, 'dd.MM.yyyy') : newItem;
              }
              if (field.validationType === 'select') {
                newItem = newItem.toFixed(0);
              }
            }
            return newItem;
          });
        } else if (field.validationType) {
          formFields[key] = field.validationType === 'select' || field.validationType === 'address' ? field.value.value : field.value;
          if (field.validationType === 'date') {
            formFields[key] = formFields[key] !== null ? format(formFields[key], 'dd.MM.yyyy') : formFields[key];
          }
          if (field.validationType === 'select') {
            formFields[key] = typeof (formFields[key]) === 'number' ? formFields[key].toFixed(0) : formFields[key];
          }
        } else {
          formFields[key] = field;
        }
      });

      formFields.insuredAddressData = formData.insuredAddress.value.data;
      formFields.ownerAddressData = formData.ownerAddress.value.data;

      formFields.markName = formData.markId.value.label;
      formFields.modelName = formData.modelId.value.label;

      formFields.insuredPassportNumberSerial = formFields.insuredPassportNumberSerial.replace(/[^a-zа-я0-9]+/ig, '');
      formFields.ownerPassportNumberSerial = formFields.ownerPassportNumberSerial.replace(/[^a-zа-я0-9]+/ig, '');

      const upperInputs = [
        'serialDocument',
        'numberDocument',
        'carNumber',
      ];
      upperInputs.forEach((upperInput) => {
        formFields[upperInput] = formFields[upperInput].toUpperCase();
      });
      if (policy.id) {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}`, {
          method: 'put',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formData: formFields,
            kbms,
            leadSource,
            linkToDeal,
            managerAttracted,
            customField: customField ? customField.value : null,
            newAgent: newAgent.value,
          }),
        })
          .then((response) => response.json())
          .then(() => {
            showModalInfo('Черновик обновлен');
          });
      } else {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
          method: 'post',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formData: formFields,
            type: 'osago',
            kbms,
            leadSource,
            linkToDeal,
            managerAttracted,
            customField: customField ? customField.value : null,
            newAgent: newAgent.value,
          }),
        })
          .then((response) => response.json())
          .then((form) => {
            showModalInfo('Черновик сохранен');
            const { loadPolicy } = this.props;
            loadPolicy(form.id);
            window.history.pushState(null, null, `/osago/${form.id}`);
          });
      }
    }, (err) => {
      console.log(err);
    });
  };

  setDriverPrevLicence = (e, index) => {
    const { formData } = this.state;
    formData.driverPrevLicence[index] = e.target.checked;
    this.setBlurClass();
    this.setState({ formData });
  };

  setDriverForeignLicence = (e, index) => {
    const { formData } = this.state;
    formData.driverForeignLicence[index] = e.target.checked ? e.target.checked : '';
    if (e.target.checked) {
      formData.driverLicenceNumber[index].validationType = 'numberForeignDocument';
      formData.driverLicenceSerial[index].validationType = 'serialForeignDocument';
      formData.driverLicenceSerial[index].errorMessage = false;
    } else {
      formData.driverLicenceNumber[index].validationType = 'numberDocument';
      formData.driverLicenceSerial[index].validationType = 'serialDocument';
    }
    if (!e.target.checked) {
      formData.driverForeignLicenceCountry[index].errorMessage = '';
      formData.driverForeignLicenceCountry[index].value = { value: '', label: '' };
    }
    this.setBlurClass();
    this.setState({ formData });
  }

  setDriverPrevForeignLicence = (e, index) => {
    const { formData } = this.state;
    formData.driverPrevForeignLicence[index] = e.target.checked;
    formData.driverPrevLicenceNumber[index].validationType = e.target.checked ? 'numberForeignDocument' : 'numberDocument';
    formData.driverPrevLicenceSerial[index].validationType = e.target.checked ? 'serialForeignDocument' : 'serialDocument';
    if (!e.target.checked) {
      formData.driverPrevForeignLicenceCountry[index].errorMessage = '';
      formData.driverPrevForeignLicenceCountry[index].value = { value: '', label: '' };
    }
    this.setBlurClass();
    this.setState({ formData });
  }

  setCheckOwner = () => {
    const { showModalInfo } = this.context;
    this.setBlurClass();
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        checkboxOwner: !prevState.formData.checkboxOwner,
      },
    }), () => {
      const { formData } = this.state;
      if (formData.checkboxOwner) {
        const promise = new Promise(this.handleCopyInfo);
        promise.then(() => {
          showModalInfo('Данные страхователя скопированы в данные собственника');
        }).catch(() => {
          showModalInfo('Ошибка');
        });
      }
    });
  };

  /*getCalcDataFromEvent = (eventData) => {
    const { leaveChanel } = useSocket();
    const { policy } = this.props;
    if (eventData.data && eventData.data.result) {
      const { data } = eventData;
      console.log(data.result);
      this.setState({ policyResultData: data.result });
      if (data.calcStatus && data.calcStatus === 'complete') {
        this.setState({ loadingCalcData: false });
        leaveChanel(`calculate.osago.${policy.id}`);
      }
    }
  };*/

  calculate = () => {
    abortControllerCalc.abort();
    abortControllerCalc = new AbortController();
    const { formIdHash } = this.state;
    const { policy } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    //const { listenChanel } = useSocket();
    //listenChanel(`calculate.osago.${policy.id}`, 'calculateStatus', this.getCalcDataFromEvent);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
      method: 'POST',
      signal: abortControllerCalc.signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: lsToken,
      },
      body: JSON.stringify({ draftHash: formIdHash, useEvents: false }),
    })
      .then((response) => response.json())
      .then(() => {
        let intervalCount = 0;
        checkInterval = setInterval(() => {
          const { policyResultData } = this.state;
          if (intervalCount === 150) {
            if (policyResultData.length === 0) {
              this.setState({ errorLoadingCalcData: true });
            }
            this.setState({ loadingCalcData: false });
            clearInterval(checkInterval);
          } else {
            const { checkStatusFlag } = this.state;
            if (!checkStatusFlag) {
              this.setState({ checkStatusFlag: true }, () => {
                customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation-status?draftHash=${formIdHash}`, {
                  signal: abortControllerCalc.signal,
                  headers: {
                    Authorization: lsToken,
                    'Content-Type': 'application/json',
                  },
                }).then((response) => response.json())
                  .then((response) => {
                    if (!response.error) {
                      if (response.resultStatus === 'changed') {
                        const result = response.result.map((v) => ({ ...v, commissionCurrency: v.price * v.commission / 100 })).sort((a, b) => { if (a.isSuccess < b.isSuccess) { return 1; } if (a.isSuccess > b.isSuccess) { return -1; } return 0; });
                        this.setState({ policyResultData: result });
                      }
                      if (response.calcStatus === 'complete') {
                        this.setState({ loadingCalcData: false });
                        clearInterval(checkInterval);
                      }
                    } else {
                      this.setState({ loadingCalcData: false });
                    }
                  })
                  .catch(() => {
                    this.setState({ loadingCalcData: false });
                  })
                  .finally(() => {
                    this.setState({ checkStatusFlag: false });
                  });
                intervalCount += 5;
              });
            }
          }
        }, 5000);
      });
  }

  handleChangeCategory = (event) => {
    const { formData } = this.state;
    formData.category.errorMessage = false;
    formData.category.value = event;
    formData.modelId.value = { label: '', value: '' };
    formData.markId.value = { label: '', value: '' };
    if (event.value !== 'C') {
      formData.allowedMaxWeight.value = '';
      formData.allowedMaxWeight.errorMessage = false;
    }
    if (event.value !== 'D') {
      formData.vehiclePassengersCapacity.value = '';
      formData.vehiclePassengersCapacity.errorMessage = false;
    }
    this.setBlurClass();
    this.setState({
      formData,
    });

    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_marks?category=${event.value}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ marks: response.value });
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      });
  };

  handleChangeMark = (event) => {
    const { formData } = this.state;
    formData.markId.errorMessage = false;
    formData.markId.value = event;
    formData.modelId.value = { label: '', value: '' };
    this.setBlurClass();
    this.setState({
      formData,
    });

    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${event.value}?category=${formData.category.value.value}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ models: response.value });
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      });
  };

  handleChangeModel = (event) => {
    const { formData } = this.state;
    formData.modelId.errorMessage = false;
    formData.modelId.value = event;
    this.setBlurClass();
    this.setState({
      formData,
      errorCantCompareCar: false,
    });
  };

  copyInsurer = (number) => {
    const {
      formData,
    } = this.state;
    formData.lastNameDriver[number].value = formData.lastNameInsured.value;
    formData.firstNameDriver[number].value = formData.firstNameInsured.value;
    formData.middleNameDriver[number].value = formData.middleNameInsured.value;
    formData.driverBirthday[number].value = formData.insuredBirthday.value;
    if (formData.lastNameDriver[number].value) {
      formData.lastNameDriver[number].errorMessage = false;
    }
    if (formData.firstNameDriver[number].value) {
      formData.firstNameDriver[number].errorMessage = false;
    }
    if (formData.middleNameDriver[number].value) {
      formData.middleNameDriver[number].errorMessage = false;
    }
    if (formData.driverBirthday[number].value) {
      formData.driverBirthday[number].errorMessage = false;
    }
    this.setBlurClass();
    this.setState({ formData });
  };

  copyOwner = (number) => {
    const { formData } = this.state;
    formData.lastNameDriver[number].value = formData.lastNameOwner.value;
    formData.firstNameDriver[number].value = formData.firstNameOwner.value;
    formData.middleNameDriver[number].value = formData.middleNameOwner.value;
    formData.driverBirthday[number].value = formData.ownerBirthday.value;
    if (formData.lastNameDriver[number].value) {
      formData.lastNameDriver[number].errorMessage = false;
    }
    if (formData.firstNameDriver[number].value) {
      formData.firstNameDriver[number].errorMessage = false;
    }
    if (formData.middleNameDriver[number].value) {
      formData.middleNameDriver[number].errorMessage = false;
    }
    if (formData.driverBirthday[number].value) {
      formData.driverBirthday[number].errorMessage = false;
    }
    this.setBlurClass();
    this.setState({ formData });
  };

  handleCopyInfo = (resolve) => {
    const { formData } = this.state;
    let ownerSameInsurer = false;
    if (formData.firstNameOwner.value === formData.firstNameInsured.value
      && formData.lastNameOwner.value === formData.lastNameInsured.value
      && formData.middleNameOwner.value === formData.middleNameInsured.value
      && formData.ownerPassportNumberSerial.value === formData.insuredPassportNumberSerial.value
      && formData.ownerBirthday.value?.toDateString() === formData.insuredBirthday.value?.toDateString()
      && formData.ownerPassportDate.value?.toDateString() === formData.insuredPassportDate.value?.toDateString()
    ) {
      ownerSameInsurer = true;
    }
    if (formData.checkboxOwner || ownerSameInsurer) {
      formData.checkboxOwner = true;
      formData.ownerAddress.value = formData.insuredAddress.value;
      formData.firstNameOwner.value = formData.firstNameInsured.value;
      formData.lastNameOwner.value = formData.lastNameInsured.value;
      formData.middleNameOwner.value = formData.middleNameInsured.value;
      formData.ownerPassportNumberSerial.value = formData.insuredPassportNumberSerial.value;
      formData.ownerBirthday.value = formData.insuredBirthday.value;
      formData.ownerPassportDate.value = formData.insuredPassportDate.value;
      formData.ownerPassportIssuedBy.value = formData.insuredPassportIssuedBy.value;
      formData.ownerPassportUnitNumber.value = formData.insuredPassportUnitNumber.value;
      this.setBlurClass();
      this.setState({ formData }, () => {
        resolve('Сработало!');
      });
    } else {
      resolve('Сработало!');
    }
  };

  setCursorToEnd = (e) => {
    e.target.onfocus = this.setAddressEnd;
  };

  handleChangeAddress = (query, name) => {
    let { formData } = this.state;
    formData[name].value = query;
    formData[name].errorMessage = getValidation(query, formData[name].validationType);
    if (name === 'insuredAddress') {
      const target = name.replace('insured', 'owner');
      formData = this.clearOwner(formData, target);
    }
    this.setBlurClass();
    this.setState({ formData });
  };

  submitPayment = (e, policy, coefficients, crossProduct = null) => {
    ym('reachGoal', 'chooseInsurance');
    abortControllerCalc.abort();
    abortControllerCalc = new AbortController();
    clearInterval(checkInterval);
    this.setState({
      loadingCalcData: false,
      choosenOffer: {
        policyCost: policy.price,
        companyCode: policy.polis_online_code,
        commission: policy.commission,
        coefficients,
        crossProduct,
      },
      showOsagoCheckForm: true,
    }, () => {
      scroller.scrollTo('search_results__main', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -70,
      });
    });
  };

  getVehicleInfo = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { formData } = this.state;
    const regNumber = formData.carNumber.value;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car/info?checkType=number_plate&value=${regNumber}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((info) => {
        if (info.error === 'Too many requests') {
          this.setState({ loadingCarInfo: false, errorTooManyRequests: true });
        } else if (info.is_error) {
          this.setState({ loadingCarInfo: false, errorWrongRegNumber: true });
        } else {
          const { marks } = this.state;
          let findMark = false;
          let findModel = false;
          if (info.result[0].car_mark) {
            Object.keys(marks).some((value) => {
              if (marks[value].toLowerCase() === info.result[0].car_mark.toLowerCase()) {
                formData.markId.value.value = value;
                formData.markId.value.label = marks[value];
                findMark = true;
                return true;
              }
            });
            const markIdObj = Object.assign({}, formData.markId.value);
            if (findMark) {
              this.setState((prevState) => ({
                formData: {
                  ...prevState.formData,
                  markId: {
                    ...prevState.formData.markId,
                    errorMessage: false,
                    value: markIdObj,
                  },
                },
              }));
              customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${markIdObj.value}?category=${formData.category.value.value}`, {
                headers: {
                  Authorization: token,
                },
              })
                .then((response) => response.json())
                .then((models) => {
                  if (!models.Error) {
                    this.setState({
                      models: models.value,
                    });
                    if (info.result[0].car_model) {
                      Object.keys(models.value).some((id) => {
                        if (models.value[id].toLowerCase() === info.result[0].car_model.toLowerCase()) {
                          formData.modelId.value.value = id;
                          formData.modelId.value.label = models.value[id];
                          findModel = true;
                          return true;
                        }
                      });
                    }
                    const modelIdObj = Object.assign({}, formData.modelId.value);
                    if (findModel) {
                      this.setState((prevState) => ({
                        formData: {
                          ...prevState.formData,
                          modelId: {
                            ...prevState.formData.modelId,
                            errorMessage: false,
                            value: modelIdObj,
                          },
                        },
                      }));
                    } else {
                      this.setState({ errorCantCompareCar: true });
                    }
                  }
                })
                .catch((err) => {
                  console.log(`Error Reading data ${err}`);
                });
            } else {
              this.setState({ errorCantCompareCar: true });
            }
          }

          let serialDocument;
          let numberDocument;
          let numberEDocument;
          let vehicleTypeDocument;
          let vehicleIdentificationType;
          if (info.result[0].credential.credential_type === 'VEHICLE_REGISTRATION') {
            serialDocument = info.result[0].credential.series;
            numberDocument = info.result[0].credential.number;
            vehicleTypeDocument = { value: 'sertificate', label: 'СТС' };
            numberEDocument = '';
          } else if (info.result[0].credential.credential_type === 'VEHICLE_PASSPORT') {
            if (info.result[0].credential.number > 10) {
              serialDocument = '';
              numberDocument = '';
              numberEDocument = info.result[0].credential.number;
              vehicleTypeDocument = { value: 'epassport', label: 'ЭПТС' };
            } else {
              serialDocument = info.result[0].credential.series;
              numberDocument = info.result[0].credential.number;
              numberEDocument = '';
              vehicleTypeDocument = { value: 'passport', label: 'ПТС' };
            }
          } else {
            serialDocument = '';
            numberDocument = '';
            numberEDocument = '';
            vehicleTypeDocument = { value: 'sertificate', label: 'СТС' };
          }

          if (info.result[0].vin_number) {
            vehicleIdentificationType = { value: 'vin', label: 'VIN' };
          } else if (info.result[0].car_body_number) {
            vehicleIdentificationType = { value: 'body', label: 'Номер кузова' };
          } else {
            vehicleIdentificationType = { value: 'vin', label: 'VIN' };
          }

          this.setState((prevState) => ({
            loadingCarInfo: false,
            formData: {
              ...prevState.formData,
              vehicleIdentificationType: {
                ...prevState.formData.vehicleIdentificationType,
                value: vehicleIdentificationType,
              },
              serialDocument: {
                ...prevState.formData.serialDocument,
                value: serialDocument,
                errorMessage: false,
              },
              vehicleTypeDocument: {
                ...prevState.formData.vehicleTypeDocument,
                value: vehicleTypeDocument,
              },
              numberDocument: {
                ...prevState.formData.numberDocument,
                value: numberDocument,
                errorMessage: false,
              },
              numberEDocument: {
                ...prevState.formData.numberEDocument,
                value: numberEDocument,
                errorMessage: false,
              },
              vehicleIdentificationNumberVin: {
                ...prevState.formData.vehicleIdentificationNumberVin,
                value: info.result[0].vin_number ? info.result[0].vin_number : '',
                errorMessage: false,
              },
              vehicleIdentificationNumberBody: {
                ...prevState.formData.vehicleIdentificationNumberBody,
                value: info.result[0].car_body_number ? info.result[0].car_body_number : '',
                errorMessage: false,
              },
              dateDocument: {
                ...prevState.formData.dateDocument,
                value: info.result[0].credential.issue_date ? parseISO(info.result[0].credential.issue_date) : '',
                errorMessage: false,
              },
              horsePower: {
                ...prevState.formData.horsePower,
                value: info.result[0].engine_power ? info.result[0].engine_power.toFixed(2) : '',
                errorMessage: false,
              },
              carYear: {
                ...prevState.formData.carYear,
                value: info.result[0].manufacturing_year ? { value: info.result[0].manufacturing_year, label: info.result[0].manufacturing_year } : { value: '', label: '' },
                errorMessage: false,
              },
            },
          }));
          return info;
        }
      })
      .catch(() => {
        this.setState({ loadingCarInfo: false, errorWrongRegNumber: true });
      });
  };

  getPrevPolicy = (checkType) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { formData } = this.state;
    const value = checkType === 'regNumber' ? formData.carNumber.value : formData.vehicleIdentificationNumberVin.value;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policyInfo`, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        parameters: [{
          checkType,
          value,
        }],
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          if (response[0].number && response[0].company) {
            const { sravniCompanies } = this.state;
            const findedCompany = sravniCompanies.find((company) => response[0].company.includes(company.label) === true);
            if (findedCompany) {
              if (response[0].end_date) {
                const newPolicyData = addDays(parseISO(response[0].end_date), 1);
                if (newPolicyData > new Date() && differenceInMonths(newPolicyData, new Date()) < 3) {
                  this.setState((prevState) => ({
                    formData: {
                      ...prevState.formData,
                      prevPolicyCheck: true,
                      prevPolicyNumber: {
                        ...prevState.formData.prevPolicyNumber,
                        errorMessage: false,
                        value: response[0].number,
                      },
                      prevPolicySerial: {
                        ...prevState.formData.prevPolicySerial,
                        value: { label: response[0].series, value: response[0].series },
                      },
                      prevPolicyInsurance: {
                        ...prevState.formData.prevPolicyInsurance,
                        value: { label: findedCompany.label, value: findedCompany.value },
                      },
                      prevPolicyDate: {
                        ...prevState.formData.prevPolicyDate,
                        value: parseISO(response[0].end_date),
                      },
                      policyDate: {
                        ...prevState.formData.policyDate,
                        value: newPolicyData,
                      },
                    },
                  }));
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState((prevState) => ({
          ...prevState,
          loadingInfoFlags: {
            ...prevState.loadingInfoFlags,
            all: false,
          },
        }));
      });
  };

  checkVehicleInfo = () => {
    const { formData } = this.state;
    if (formData.carNumber.value.replace(/_/g, '').length > 7) {
      this.setBlurClass();
      this.setState({
        loadingCarInfo: true,
        errorBlankRegNumber: false,
        errorWrongRegNumber: false,
        errorTooManyRequests: false,
        errorCantCompareCar: false,
      });
      this.getPrevPolicy('regNumber');
      this.getVehicleInfo();
    } else {
      this.setState({ errorBlankRegNumber: true, errorWrongRegNumber: false });
    }
  };

  handleChangeDate = (date, field) => {
    let { formData } = this.state;
    formData[field].value = date;
    if (date) {
      formData[field].errorMessage = false;
      if (field === 'ownerBirthday' && formData.ownerPassportDate.value && differenceInYears(formData.ownerPassportDate.value, date) < 14) {
        formData.ownerPassportDate.value = null;
        formData.ownerPassportDate.errorMessage = 'Не раньше 14 лет с даты рождения';
      }
      if (field === 'insuredBirthday' && formData.insuredPassportDate.value && differenceInYears(formData.insuredPassportDate.value, date) < 14) {
        formData.insuredPassportDate.value = null;
        formData.insuredPassportDate.errorMessage = 'Не раньше 14 лет с даты рождения';
      }
    }
    if (field === 'insuredPassportDate'
      || field === 'insuredBirthday') {
      const target = field.replace('insured', 'owner');
      formData = this.clearOwner(formData, target);
    }
    this.setBlurClass();
    this.setState({ formData });
  };

  handleChangeDateDriver = (date, field, number) => {
    const { formData } = this.state;
    formData[field][number].value = date;
    if (date) {
      formData[field][number].errorMessage = false;
    }
    this.setBlurClass();
    this.setState({ formData }, () => {
      this.getKbm(number);
    });
  };

  handleChangeCheckBox = (e) => {
    const { target } = e;
    this.setBlurClass();
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [target.id]: target.checked,
      },
    }));
  };

  handleChangeLinkToDeal = (e) => {
    this.setState({
      linkToDeal: e,
    });
  };

  handleChangePassportIssuedBy = (query, field) => {
    const { formData } = this.state;
    formData[field].value = query.value;
    if (field === 'insuredPassportIssuedBy' && query.data) {
      formData.insuredPassportUnitNumber.value = query.data.code;
    }
    if (field === 'ownerPassportIssuedBy' && query.data) {
      formData.ownerPassportUnitNumber.value = query.data.code;
    }
    this.setState({ formData });
  };

  handleChangeInput = (e, translate = false, upperCase = false, pureValue = false) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (pureValue) {
      value = value.replace(/[^a-zа-я0-9]+/ig, '');
    }
    if (translate) {
      value = switchLanguage(value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }

    const { formData } = this.state;
    formData[e.target.id].value = value;
    formData[e.target.id].errorMessage = false;

    if ((e.target.id === 'vehicleIdentificationNumberVin' || e.target.id === 'numberDocument') && formData[e.target.id].value.includes('*')) {
      formData[e.target.id].value = '';
    }
    this.setBlurClass();
    this.setState({ formData });
  };

  handleChangeShowCommission = () => {
    this.setState((prevState) => ({
      showCommission: !prevState.showCommission,
    }));
  };

  addNewDriver = (e) => {
    e.preventDefault();
    const { formData } = this.state;
    if (formData.lastNameDriver.length < 5) {
      formData.lastNameDriver.push({
        value: '',
        errorMessage: false,
        validationType: 'name',
      });
      formData.middleNameDriver.push({
        value: '',
        errorMessage: false,
        validationType: 'disable',
      });
      formData.firstNameDriver.push({
        value: '',
        errorMessage: false,
        validationType: 'name',
      });
      formData.driverLicenceSerial.push({
        value: '',
        errorMessage: false,
        validationType: 'serialDocument',
      });
      formData.driverLicenceNumber.push({
        value: '',
        errorMessage: false,
        validationType: 'numberDocument',
      });
      formData.driverBirthday.push({
        value: null,
        errorMessage: false,
        validationType: 'date',
      });
      formData.driverExperienceDate.push({
        value: null,
        errorMessage: false,
        validationType: 'date',
      });
      formData.driverCurrentLicenceDate.push({
        value: null,
        errorMessage: false,
        validationType: 'date',
      });
      formData.driverPrevLicence.push(false);
      formData.driverForeignLicence.push('');
      formData.driverPrevForeignLicence.push(false);
      formData.prevLastNameDriver.push({
        value: '',
        errorMessage: false,
        validationType: 'name',
      });
      formData.driverPrevLicenceSerial.push({
        value: '',
        errorMessage: false,
        validationType: 'serialDocument',
      });
      formData.driverPrevLicenceNumber.push({
        value: '',
        errorMessage: false,
        validationType: 'numberDocument',
      });
      formData.driverForeignLicenceCountry.push({
        value: { value: '', label: '' },
        errorMessage: false,
        validationType: 'select',
      });
      formData.driverPrevForeignLicenceCountry.push({
        value: { value: '', label: '' },
        errorMessage: false,
        validationType: 'select',
      });
      this.setBlurClass();
      this.setState({ formData });
    }
  };

  setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  deleteDriver = (e, number) => {
    e.preventDefault();
    const { formData } = this.state;
    if (formData.firstNameDriver.length > 1) {
      formData.firstNameDriver.splice(number, 1);
      formData.middleNameDriver.splice(number, 1);
      formData.lastNameDriver.splice(number, 1);
      formData.driverLicenceSerial.splice(number, 1);
      formData.driverLicenceNumber.splice(number, 1);
      formData.driverBirthday.splice(number, 1);
      formData.driverExperienceDate.splice(number, 1);
      formData.driverCurrentLicenceDate.splice(number, 1);
      formData.prevLastNameDriver.splice(number, 1);
      formData.driverPrevLicence.splice(number, 1);
      formData.driverForeignLicence.splice(number, 1);
      formData.driverPrevForeignLicence.splice(number, 1);
      formData.driverPrevLicenceNumber.splice(number, 1);
      formData.driverPrevLicenceSerial.splice(number, 1);
      formData.driverForeignLicenceCountry.splice(number, 1);
      formData.driverPrevForeignLicenceCountry.splice(number, 1);
      this.setBlurClass();
      this.setState({ formData });
    }
  };

  handleValidate = (e) => {
    let { formData } = this.state;
    const { value } = formData[e.target.id];
    if (value && typeof value === 'string') {
      formData[e.target.id].value = value.trim();
    }
    formData[e.target.id].errorMessage = getValidation(value, formData[e.target.id].validationType);
    if (e.target.id === 'vehicleIdentificationNumberVin' && formData.registrationNumberBlank && formData[e.target.id].errorMessage === false) {
      this.getPrevPolicy('vin');
    }
    if (e.target.id === 'lastNameInsured'
      || e.target.id === 'firstNameInsured'
      || e.target.id === 'middleNameInsured'
      || e.target.id === 'insuredPassportNumberSerial'
      || e.target.id === 'insuredPassportIssuedBy'
      || e.target.id === 'insuredPassportUnitNumber') {
      let target = e.target.id.replace('Insured', 'Owner');
      target = target.replace('insured', 'owner');
      formData = this.clearOwner(formData, target);
    }
    this.setState({ formData });
  };

  clearOwner = (formData, field) => {
    if (formData.checkboxOwner === true) {
      const { showModalInfo } = this.context;
      if (field === 'ownerAddress') {
        formData[field].value.value = null;
        formData[field].value.data = [];
      } else if (field === 'ownerPassportNumberSerial' || field === 'ownerPassportUnitNumber' || field === 'ownerPassportIssuedBy') {
        formData[field].value = '';
      } else {
        formData[field].value = null;
      }
      formData[field].errorMessage = '';
      formData.checkboxOwner = false;
      showModalInfo('Изменены данные страхователя');
    }

    return formData;
  };

  handleValidateDrivers = (e, number) => {
    const { formData } = this.state;
    let { value } = formData[e.target.id][number];
    if (e.target.id === 'driverForeignLicenceCountry' && value !== null) {
      value = value.value || null;
    }
    if (value && formData[e.target.id][number].validationType === 'name') {
      value = value.trim();
      formData[e.target.id][number].value = value;
    }
    formData[e.target.id][number].errorMessage = getValidation(value, formData[e.target.id][number].validationType);
    this.setState({ formData });
  };

  handleValidateSelect = (e) => {
    const { formData } = this.state;
    const value = formData[e.target.id].value ? formData[e.target.id].value.value : null;
    formData[e.target.id].errorMessage = getValidation(value, formData[e.target.id].validationType);
    this.setState({ formData });
  };

  getKbm = (key) => {
    const {
      formData, loadingKbm, kbms, isKbmFound,
    } = this.state;
    if (formData.driverBirthday[key].value
      && formData.lastNameDriver[key].value
      && formData.firstNameDriver[key].value
      && formData.middleNameDriver[key].value
      && formData.driverExperienceDate[key].value
      && formData.driverLicenceNumber[key].value
      && formData.driverLicenceSerial[key].value) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      const drivers = [];
      drivers[0] = {
        lastname: formData.lastNameDriver[key].value,
        firstname: formData.firstNameDriver[key].value,
        middlename: formData.middleNameDriver[key].value,
        birthdate: format(formData.driverBirthday[key].value, 'yyyy-MM-dd'),
        expdate: format(formData.driverExperienceDate[key].value, 'yyyy-MM-dd'),
        licenseSerial: formData.driverLicenceSerial[key].value,
        licenseNumber: formData.driverLicenceNumber[key].value,
        licenseDate: format(formData.driverExperienceDate[key].value, 'yyyy-MM-dd'),
        licenseForeign: formData.driverForeignLicence[key] ? 1 : 0,
      };
      if (formData.driverPrevLicence[key]) {
        drivers[0].prevLicenseDate = format(formData.driverExperienceDate[key].value, 'yyyy-MM-dd');
        drivers[0].prevLicenseSerial = formData.driverPrevLicenceSerial[key].value;
        drivers[0].prevLicenseNumber = formData.driverPrevLicenceNumber[key].value;
        drivers[0].prevLicenseLastname = formData.prevLastNameDriver[key].value;
      }

      abortController.abort();
      abortController = new AbortController();
      clearTimeout(timeoutKbm[key]);
      timeoutKbm[key] = setTimeout(() => {
        loadingKbm[key] = true;
        kbms[key] = '';
        isKbmFound[key] = true;
        this.setState({
          loadingKbm,
          kbms,
          isKbmFound,
        }, () => {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/getKbm`, {
            signal: abortController.signal,
            method: 'post',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ drivers }),
          })
            .then((response) => response.json())
            .then((response) => {
              kbms[key] = response.results.factor;
              isKbmFound[key] = response.results.found;
              loadingKbm[key] = false;
              this.setState({ kbms, loadingKbm, isKbmFound });
            })
            .catch(() => {
              loadingKbm[key] = false;
              this.setState({ loadingKbm });
            });
        });
      }, 1000);
    }
  };

  handleChangeDriverInput = (e, number, translate = false, upperCase = false) => {
    let { value } = e.target;
    if (translate) {
      value = switchLanguage(value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formData } = this.state;
    formData[e.target.id][number].value = value;
    formData[e.target.id][number].errorMessage = false;
    this.setBlurClass();
    this.setState({ formData }, () => {
      this.getKbm(number);
    });
  };

  handleChangeLeadSource = (e) => {
    this.setState({ leadSource: e });
  };

  handleChangeManagerAttracted = (e) => {
    this.setState({ managerAttracted: e });
  };

  handleChangeCustomField = (e) => {
    this.setState({ customField: e });
  };

  handleChangeSelect = (event, field) => {
    const { formData } = this.state;
    formData[field].errorMessage = false;
    if (field === 'vehicleTypeDocument') {
      if (event.value === 'sertificate') {
        formData.registrationNumberBlank = false;
      }
      formData.serialDocument.value = '';
      formData.numberDocument.value = '';
      formData.serialDocument.errorMessage = false;
      formData.numberDocument.errorMessage = false;
    }
    if (field === 'vehicleIdentificationType') {
      formData.vehicleIdentificationNumberVin.value = '';
      formData.vehicleIdentificationNumberBody.value = '';
      formData.vehicleIdentificationNumberChassis.value = '';
    }
    if (field === 'unlimitedDrivers') {
      if (event.value === true) {
        formData.firstNameDriver.splice(0);
        formData.middleNameDriver.splice(0);
        formData.lastNameDriver.splice(0);
        formData.driverLicenceSerial.splice(0);
        formData.driverLicenceNumber.splice(0);
        formData.driverBirthday.splice(0);
        formData.driverExperienceDate.splice(0);
        formData.driverCurrentLicenceDate.splice(0);
        formData.prevLastNameDriver.splice(0);
        formData.driverPrevLicence.splice(0);
        formData.driverForeignLicence.splice(0);
        formData.driverPrevForeignLicence.splice(0);
        formData.driverPrevLicenceNumber.splice(0);
        formData.driverPrevLicenceSerial.splice(0);
        formData.driverForeignLicenceCountry.splice(0);
        formData.driverPrevForeignLicenceCountry.splice(0);
      } else {
        formData.lastNameDriver = [{
          value: '',
          errorMessage: false,
          validationType: 'name',
        }];
        formData.middleNameDriver = [{
          value: '',
          errorMessage: false,
          validationType: 'disable',
        }];
        formData.firstNameDriver = [{
          value: '',
          errorMessage: false,
          validationType: 'name',
        }];
        formData.driverLicenceSerial = [{
          value: '',
          errorMessage: false,
          validationType: 'serialDocument',
        }];
        formData.driverLicenceNumber = [{
          value: '',
          errorMessage: false,
          validationType: 'numberDocument',
        }];
        formData.driverBirthday = [{
          value: null,
          errorMessage: false,
          validationType: 'date',
        }];
        formData.driverExperienceDate = [{
          value: null,
          errorMessage: false,
          validationType: 'date',
        }];
        formData.driverCurrentLicenceDate = [{
          value: null,
          errorMessage: false,
          validationType: 'date',
        }];
        formData.driverPrevLicence = [false];
        formData.driverForeignLicence = [''];
        formData.driverPrevForeignLicence = [false];
        formData.prevLastNameDriver = [{
          value: '',
          errorMessage: false,
          validationType: 'name',
        }];
        formData.driverPrevLicenceSerial = [{
          value: '',
          errorMessage: false,
          validationType: 'serialDocument',
        }];
        formData.driverPrevLicenceNumber = [{
          value: '',
          errorMessage: false,
          validationType: 'numberDocument',
        }];
        formData.driverForeignLicenceCountry = [{
          value: { value: '', label: '' },
          errorMessage: false,
          validationType: 'select',
        }];
        formData.driverPrevForeignLicenceCountry = [{
          value: { value: '', label: '' },
          errorMessage: false,
          validationType: 'select',
        }];
      }
    }
    formData[field].value = event;
    this.setBlurClass();
    this.setState({ formData });
  };

  handleChangeSelectDriver = (event, field, index) => {
    const { formData } = this.state;
    formData[field][index].errorMessage = false;
    formData[field][index].value = event;
    this.setBlurClass();
    this.setState({ formData });
  };

  changePrevPolicyCheck = (e) => {
    const { formData } = this.state;
    if (e.target.checked === false) {
      formData.prevPolicySerial.value = { label: '', value: '' };
      formData.prevPolicySerial.errorMessage = false;
      formData.prevPolicyNumber.value = '';
      formData.prevPolicyNumber.errorMessage = false;
      formData.prevPolicyDate.value = null;
      formData.prevPolicyDate.errorMessage = false;
      formData.prevPolicyInsurance.value = { label: '', value: '' };
      formData.prevPolicyInsurance.errorMessage = false;
    }
    formData.prevPolicyCheck = e.target.checked;
    this.setBlurClass();
    this.setState({ formData });
  };

  registrationNumberBlank = (e) => {
    const { formData } = this.state;
    if (e.target.checked === true) {
      formData.carNumber.value = '';
      if (formData.vehicleTypeDocument.value.value === 'sertificate') {
        formData.vehicleTypeDocument.value = { value: 'passport', label: 'ПТС' };
        formData.serialDocument.value = '';
        formData.numberDocument.value = '';
        formData.serialDocument.errorMessage = false;
        formData.numberDocument.errorMessage = false;
      }
      if (formData.vehicleIdentificationNumberVin.value.includes('*')) {
        formData.vehicleIdentificationNumberVin.value = '';
      }
      if (formData.vehicleIdentificationNumberVin.value && formData.vehicleIdentificationNumberVin.errorMessage === false) {
        this.getPrevPolicy('vin');
      }
    }
    formData.registrationNumberBlank = e.target.checked;
    this.setState({ formData });
  };

  hideOsagoCheckForm = (e) => {
    e.preventDefault();
    this.setState({
      showOsagoCheckForm: false,
    }, () => {
      scroll.scrollToTop({
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    });
  };

  backToPickCompany = (e) => {
    e.preventDefault();
    this.setState({
      showOsagoCheckForm: false,
    }, () => {
      scroller.scrollTo('searchResults', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -70,
      });
    });
  };

  setDisableField = () => {
    this.setState({ disableField: true });
  };

  yandexReachGoal = (goal) => {
    ym('reachGoal', goal);
    const { yandexGoals } = this.state;
    this.setState({
      yandexGoals: {
        ...yandexGoals,
        [goal]: true,
      },
    });
  };

  sortAllPolicesArray = (array, sortBy, direction) => {
    if (typeof sortBy === 'undefined') {
      const {
        selectedSort,
        policySortOrderUp,
      } = this.state;
      sortBy = selectedSort;
      direction = policySortOrderUp ? 'up' : 'down';
    } else {
      direction = typeof direction !== 'undefined' ? direction : 'up';
    }
    array.sort((a, b) => {
      if (a[sortBy] > b[sortBy]) {
        if (direction === 'up') {
          return 1;
        }
        return -1;
      }
      if (a[sortBy] < b[sortBy]) {
        if (direction === 'up') {
          return -1;
        }
        return 1;
      }
      if (a.priority < b.priority) return 1;
      if (a.priority > b.priority) return -1;
      return 0;
    });
    array.forEach((item) => {
      if (item.crossProductBlock) {
        item.crossProductBlock.sort((a, b) => {
          if (a[sortBy] > b[sortBy]) {
            if (direction === 'up') {
              return 1;
            }
            return -1;
          }
          if (a[sortBy] < b[sortBy]) {
            if (direction === 'up') {
              return -1;
            }
            return 1;
          }
          return 0;
        });
      }
    });
    return array.sort((a, b) => { if (a.isSuccess < b.isSuccess) { return 1; } if (a.isSuccess > b.isSuccess) { return -1; } return 0; });
  };

  changeSort = (e, newSort) => {
    e.preventDefault();
    const { selectedSort } = this.state;
    if (selectedSort === newSort) {
      this.setState((prevState) => ({
        policySortOrderUp: !prevState.policySortOrderUp,
      }));
    } else {
      this.setState({ selectedSort: newSort });
    }
  };

  toggleCompanyCommercialOffer = (e, companyCode, crossProductCode = null) => {
    const { showModalInfo } = this.context;
    const { commercialOfferCompanies } = this.state;
    const { policy } = this.props;
    const commercialOfferCompaniesCopy = [...commercialOfferCompanies];

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    const insuranceIndex = commercialOfferCompaniesCopy.findIndex((offer) => offer.companyCode === companyCode && offer.crossProductCode === crossProductCode);
    let type = 'set';
    if (insuranceIndex !== -1) {
      type = 'delete';
      commercialOfferCompaniesCopy.splice(insuranceIndex, 1);
    } else {
      commercialOfferCompaniesCopy.push({ companyCode, crossProductCode });
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/osago/${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurance: { companyCode, crossProductCode },
        id: policy.id,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        this.setState({
          commercialOfferCompanies: commercialOfferCompaniesCopy,
        });
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      });
  };

  toggleAllCompaniesCommercialOffer = (e, policyList) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { policy } = this.props;
    let { commercialOfferCompanies } = this.state;

    let type = 'set';
    if (commercialOfferCompanies.length) {
      type = 'delete';
      commercialOfferCompanies = [];
    } else {
      commercialOfferCompanies = [];
      policyList.forEach((policy) => {
        commercialOfferCompanies.push({
          companyCode: policy.polis_online_code,
          crossProductCode: policy.upSale ? policy.upSale.code : null,
        });
        if (policy.upSales) {
          policy.upSales.forEach((upSale) => {
            if (commercialOfferCompanies.findIndex((item) => item.crossProductCode === upSale.code && item.companyCode === policy.polis_online_code) === -1) {
              commercialOfferCompanies.push({
                companyCode: policy.polis_online_code,
                crossProductCode: upSale.code,
              });
            }
          });
        }
      });
    }

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/osago/all-insurances-${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurances: commercialOfferCompanies,
        id: policy.id,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        this.setState({
          commercialOfferCompanies,
        });
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      });
  }

  handleChangeFio = (query, name, driverNumber = false) => {
    if (driverNumber || driverNumber === 0) {
      const { formData } = this.state;
      const newDriverFIOArr = [...formData[name]];
      newDriverFIOArr[driverNumber].value = query.value;
      newDriverFIOArr[driverNumber].errorMessage = '';
      this.setBlurClass();
      this.setState((prev) => ({
        formData: {
          ...prev.formData,
          [name]: newDriverFIOArr,
        },
      }), () => {
        this.getKbm(driverNumber);
      });
    } else {
      this.setBlurClass();
      this.setState((prev) => {
        const newState = { ...prev };

        newState.formData[name].value = query.value;
        newState.formData[name].errorMessage = false;

        return newState;
      });
    }
  };

  handleChangeNewAgentSelect = (e) => {
    this.setState(() => ({
      newAgent: e,
    }));
  };

  simulateCalculation = (e) => {
    e.preventDefault();
    this.setState({
      loadingCalcData: true,
      loadingInit: true,
    });
    const { policy } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}/integrationAnswers?noFilter=true`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          this.setState({
            policyResultData: response,
          });
        }
      })
      .catch(() => {
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({
            loadingCalcData: false,
          });
        }, 3000);
      });
  };

  goToInsurexWidget = (e) => {
    e.preventDefault();
    const { policy } = this.props;
    const url = `${window.location.origin}/insurex_widget?policyId=${policy.id}`;
    window.open(url, '_blank').focus();
  }

  createCrossProductsList = (crossProductBlock, policy, coefficients, showCommission, hideCommission, isDemo, commercialOfferCompanies, userInfo, companies, blurClass) => crossProductBlock.map((crossProduct) => (
    <div key={`${policy.polis_online_code}-${crossProduct.upSale.code}`} name={policy.polis_online_code} className="search_results__table--row">
      <div className="search_results__table--cell">
        <span className="color-light label">Страховая:</span>
        <div>
          <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[policy.polis_online_code].logo}`} alt="logo" className="search_results__logo"/>
        </div>
        <div className="search_results__company-name">
          {companies[policy.polis_online_code].name}
        </div>
      </div>
      <div className="search_results__table--cell">
        <span className="color-light label">Стоимость полиса:</span>
        <div>
          <div>
            {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((crossProduct.price + policy.price))}
            <div className="search_results__cross-price">({new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(policy.price)} + {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(crossProduct.price)})</div>
          </div>
        </div>
      </div>
      <div className="search_results__table--cell">
        {showCommission && hideCommission === 0 ? (
          <>
            <span className="color-light label">Вознаграждение:</span>
            <div>
              <div className="search_results__commission--currency">{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((((policy.commission * policy.price) / 100) + ((crossProduct.commission * crossProduct.price) / 100)))}</div>
              <div className="search_results__commission--percentage">{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((policy.commission * policy.price) / 100)} + {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((crossProduct.commission * crossProduct.price) / 100)}</div>
              <div className="search_results__commission--percentage">{policy.commission}% + {crossProduct.commission}%</div>
            </div>
          </>
        ) : (
          <>
            <span className="color-light label">Рейтинг:</span>
            <div className="company_item__star">
              {[...Array(companies[policy.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
            </div>
          </>
        )}
      </div>
      <div className="search_results__table--cell search_results__table--cell--flex">
        <span className="color-light label">Комментарий:</span>
        <div>
          Договор успешно согласован!{(userInfo.full_time_employee === 1) && <b>({policy.integration})</b>}
        </div>
        <Tippy
          className="tippy-custom"
          arrow={false}
          offset={[0, 5]}
          content={crossProduct.upSale.text}
          hideOnClick={false}
        >
          <SimpleNotification>Полис ОСАГО + кросс-продукт &laquo;{crossProduct.upSale.name}&raquo;</SimpleNotification>
        </Tippy>
      </div>
      <div className="search_results__table--cell">
        <div>
          <button
            type="button"
            onClick={(e) => this.submitPayment(e, policy, coefficients, crossProduct.upSale)}
            className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass.length > 0 })}
          >
            Оформить
          </button>
          {!isDemo ? (
            <button
              type="button"
              onClick={(e) => this.toggleCompanyCommercialOffer(e, policy.polis_online_code, crossProduct.upSale.code)}
              className={classnames('btn btn-secondary search_results__table--button btn-commercial-offer', { disabled: blurClass.length > 0 })}
            >
              {commercialOfferCompanies.findIndex((offer) => offer.companyCode === policy.polis_online_code && offer.crossProductCode === crossProduct.upSale.code) !== -1 ? (<><strong>-</strong> из предложения</>) : (<><strong>+</strong> в предложение</>)}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  ));

  toggleCrossListShow = (e, integration, companyCode) => {
    e.preventDefault();
    const { policyResultData } = this.state;
    const offerIndex = policyResultData.findIndex((item) => item.integration === integration && item.polis_online_code === companyCode);
    if (offerIndex !== -1) {
      const newAllPolicyData = [...policyResultData];
      newAllPolicyData[offerIndex].crossProductFlag = !policyResultData[offerIndex].crossProductFlag;
      newAllPolicyData[offerIndex].crossProductClassFlag = !policyResultData[offerIndex].crossProductClassFlag;
      this.setState({ policyResultData: newAllPolicyData });
    }
  };

  setBlurClass = () => {
    abortControllerCalc.abort();
    abortControllerCalc = new AbortController();
    clearInterval(checkInterval);
    this.setState({
      blurClass: 'blur',
      loadingCalcData: false,
    });
  };

  makeAllPolicyBlock = () => {
    const {
      policyResultData, commercialOfferCompanies, companies, isDemo, showCommission, blurClass, loadingInit,
    } = this.state;
    const { userInfo, hideCommission, domain } = this.props;

    if (!loadingInit || policyResultData.length === 0) {
      return '';
    }

    return policyResultData.map((policy) => (
      <React.Fragment key={policy.polis_online_code}>
        <div name={policy.polis_online_code} className={classnames('search_results__table--row', { 'search_results__table--row-with-cross': policy.crossProductClassFlag })}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[policy.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[policy.polis_online_code].name}
            </div>
            {policy.crossProductBlock ? (
              <a className="search_results__table--cross-block-open-button dotted_link" href="" onClick={(e) => { this.toggleCrossListShow(e, policy.integration, policy.polis_online_code); }}>
                {`Еще ${policy.crossProductBlock.length} варианта `}
                {policy.crossProductClassFlag ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />) : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
              </a>
            ) : null}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            {policy.isSuccess ? (
              <div>
                <div>
                  {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(policy.price + (policy.chosenCrossProduct ? policy.chosenCrossProduct.price : 0))}
                </div>
                {policy.coefficients ? (
                  <div className="search_results__coefficient">
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={(
                        <table className="search_results__coefficient--table">
                          <tbody>
                            <tr><td>КБМ - бонус-малус</td><td>{policy.coefficients.kbm}</td></tr>
                            <tr><td>КМ - мощность</td><td>{policy.coefficients.km}</td></tr>
                          </tbody>
                        </table>
                      )}
                      hideOnClick={false}
                    >
                      <span className="dotted_link">Коэффициенты</span>
                    </Tippy>
                  </div>
                ) : null}
              </div>
            ) : (<div>-</div>)}
          </div>
          <div className="search_results__table--cell">
            {showCommission && hideCommission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                {policy.isSuccess ? (
                  <div>
                    <div className="search_results__commission--currency">{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((policy.commission * policy.price) / 100)}</div>
                    <div className="search_results__commission--percentage">{policy.commission}%</div>
                  </div>
                ) : (<div>-</div>)}
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[policy.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            <div className="px-2">
              {policy.isSuccess ? (
                <>
                  {'Договор успешно согласован!'}{(policy.integration) ? <b>({policy.integration})</b> : null}
                  {policy.isPool ? <div className="main-color">Оформление через пул перестрахования. В стоимость входит кросс-продукт. {policy.chosenCrossProduct ? `(${policy.chosenCrossProduct.name} - ${new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(policy.chosenCrossProduct.price)}, комиссионное вознаграждение - ${policy.chosenCrossProduct.commission}%)` : null}</div> : null}
                </>
              ) : <span className="text-danger">{policy.errorMessage}</span>}
            </div>
            {!policy.isSuccess && domain !== 'igk23' ? (
              <div className="search_results__insurex_bg">
                <span className="text-black">Онлайн оформление недоступно. Оставьте заявку, чтобы мы помогли вам с оформлением. Все данные черновика автоматически будут заполнены в заявке 😎</span>
              </div>
            ) : null}
            {policy.customContract && !policy.isPolisOnlineContract ? <div className="main-color">Оформление через личный договор</div> : null}
          </div>
          <div className="search_results__table--cell">
            {policy.isSuccess ? (
              <div>
                <button
                  type="button"
                  onClick={(e) => this.submitPayment(e, policy, policy.coefficients)}
                  className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass.length > 0 })}
                >
                  Оформить
                </button>
                {!isDemo ? (
                  <button
                    type="button"
                    onClick={(e) => this.toggleCompanyCommercialOffer(e, policy.polis_online_code)}
                    className={classnames('btn btn-secondary search_results__table--button btn-commercial-offer', { disabled: blurClass.length > 0 })}
                  >
                    {commercialOfferCompanies.findIndex((offer) => offer.companyCode === policy.polis_online_code && !offer.crossProductCode) !== -1 ? (<><strong>-</strong> из предложения</>) : (<><strong>+</strong> в предложение</>)}
                  </button>
                ) : null}
              </div>
            ) : (
              domain !== 'igk23' ? (
                <div className="buttons">
                  <a onClick={this.goToInsurexWidget} style={{ textDecoration: 'none' }}>
                    <button
                      type="button"
                      className={classnames('btn btn-border btn-success mb-1', { disabled: blurClass.length > 0 })}
                    >
                      Оформить в бирже
                    </button>
                  </a>
                </div>
              ) : null
            )}
          </div>
        </div>
        <SlideDown closed={!policy.crossProductClassFlag} className="my-dropdown-slidedown search_results__table--cross-block">
          {policy.crossProductBlock && policy.crossProductFlag ? this.createCrossProductsList(policy.crossProductBlock, policy, policy.coefficients, showCommission, hideCommission, isDemo, commercialOfferCompanies, userInfo, companies, blurClass) : null}
        </SlideDown>
      </React.Fragment>
    )).filter((result) => result !== null);
  };

  makeOtherCompaniesBlock = () => {
    const {
      companies, policyResultData, loadingCalcData, showCommission, hideCommission, loadingInit, blurClass,
    } = this.state;

    const { domain } = this.props;

    // Проверяем условия и пересобираем блок предложений по оставшимся компаниям
    if (!loadingInit || Object.keys(companies).length === 0) {
      return '';
    }

    return Object.entries(companies).filter(([companyName, value]) => policyResultData.findIndex((company) => company.polis_online_code === companyName) === -1 && !value.hide).map(([companyName, value]) => (
      <div key={companyName} name={companyName} className="search_results__table--row">
        <div className="search_results__table--cell">
          <span className="color-light label">Страховая:</span>
          <div>
            <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${value.logo}`} alt="logo" className="search_results__logo" />
          </div>
          <div className="search_results__company-name">
            {companies[companyName].name}
          </div>
        </div>
        {loadingCalcData
          ? <div className="search_results__table--cell"><ReactLoading className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} /></div>
          : (
            <>
              <div className="search_results__table--cell">
                <span className="color-light label">Стоимость полиса:</span>
                <div>-</div>
              </div>
              <div className="search_results__table--cell">
                {(showCommission && hideCommission === 0 ? (
                  <>
                    <span className="color-light label">Вознаграждение:</span>
                    <div>-</div>
                  </>
                ) : (
                  <>
                    <span className="color-light label">Рейтинг:</span>
                    <div className="company_item__star">
                      {[...Array(companies[companyName].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw"/>)}
                    </div>
                  </>
                ))}
              </div>
              <div className="search_results__table--cell">
                <span className="color-light label">Комментарий:</span>
                <div className="px-2">
                  <span className="text-danger">Отказ</span>
                </div>
                {domain !== 'igk23' ? (
                  <div className="search_results__insurex_bg">
                    <span className="text-black">Онлайн оформление недоступно. Оставьте заявку, чтобы мы помогли вам с оформлением. Все данные черновика автоматически будут заполнены в заявке 😎</span>
                  </div>
                ) : null}
              </div>
              {domain !== 'igk23' ? (
                <div className="search_results__table--cell">
                  <div className="buttons">
                    <a onClick={this.goToInsurexWidget} style={{ textDecoration: 'none' }}>
                      <button
                        type="button"
                        className={classnames('btn btn-border btn-success mb-1', { disabled: blurClass.length > 0 })}
                      >
                        Оформить в бирже
                      </button>
                    </a>
                  </div>
                </div>
              ) : null}
            </>
          )}
      </div>
    ));
  };

  render() {
    const {
      customFieldOptions,
      customFieldName,
      customField,
      linkToDeal,
      leadSource,
      managerAttracted,
      leadSourcesOptions,
      managerAttractedOptions,
      loadingInit,
      policySortOrderUp,
      selectedSort,
      formData,
      yandexGoals,
      companies,
      choosenOffer,
      searchIdSravni,
      blurClass,
      marks,
      models,
      loadingCarInfo,
      loadingCalcData,
      openModalSegmentation,
      formIdHash,
      disableField,
      errorBlankRegNumber,
      errorWrongRegNumber,
      errorTooManyRequests,
      errorCantCompareCar,
      policyStatus,
      errorLoadingCalcData,
      showOsagoCheckForm,
      showCommission,
      kbms,
      isKbmFound,
      loadingKbm,
      commercialOfferCompanies,
      sravniCompanies,
      loadingInfoFlags,
      countriesNames,
      prolongationChangeDateFlag,
      policyResultData,
      newAgent,
    } = this.state;

    const {
      history, hideCommission, userInfo, policy, location, policyType, remoteEmployee,
    } = this.props;
    const isDemo = policy ? policy.is_demo : false;

    const urlParams = queryString.parse(location.search);

    const customStyles = customSelectStyle();

    const optionItemsMarks = Object.keys(marks).map((id) => ({ value: id, label: marks[id] })).sort((a, b) => a.label.localeCompare(b.label));
    const optionItemsModels = Object.keys(models).map((id) => ({ value: id, label: models[id] })).sort((a, b) => a.label.localeCompare(b.label));

    const yearOption = this.years.map((year) => ({ value: year, label: year }));
    const minExperienceDate = [];
    const maxDriverBirthday = [];
    formData.driverBirthday.forEach((item, i) => {
      minExperienceDate[i] = item.value
        ? new Date(new Date(item.value).setFullYear(item.value.getFullYear() + 17))
        : null;
    });
    formData.driverExperienceDate.forEach((item, i) => {
      maxDriverBirthday[i] = item.value
        ? new Date(new Date(item.value).setFullYear(item.value.getFullYear() - 17))
        : null;
    });
    formData.driverCurrentLicenceDate.forEach((item, i) => {
      maxDriverBirthday[i] = item.value
        ? new Date(new Date(item.value).setFullYear(item.value.getFullYear() - 17))
        : null;
    });

    const allPolicyBlock = this.makeAllPolicyBlock();
    const otherCompaniesBlock = this.makeOtherCompaniesBlock();
    const listDrivers = formData.lastNameDriver.length > 0 ? formData.lastNameDriver.map((driver, number) => (
      <div key={number}>
        <div className="form-group row">
          <div className="col-lg-12">
            <span className="d-inline-block search_filter__section_subtitle">Водитель №{number + 1}</span>
            {!disableField && (formData.lastNameDriver.length > 1) && (<a onClick={(e) => this.deleteDriver(e, number)} className="close-driver">×</a>)}
            {loadingKbm[number] && <ReactLoading className="loading-circle d-inline-block ml-2" type="spin" height={28} width={28} />}
            {kbms[number] ? <> | <b className="main-color">КБМ - {kbms[number]}{isKbmFound.length > 0 && !isKbmFound[number] ? '(не найден)' : null}</b></> : ''}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12 column-margin">
            <button disabled={disableField} type="button" className="btn btn-success mr-2 column-margin" onClick={() => this.copyInsurer(number)}>Скопировать данные страхователя</button>
            <button disabled={disableField} type="button" className="btn btn-success" onClick={() => (formData.checkboxOwner ? this.copyInsurer(number) : this.copyOwner(number))}>Скопировать данные собственника</button>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <label htmlFor="lastNameDriver">Фамилия</label>
            <ReactDadata
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              suggestionType="fio"
              id="lastNameDriver"
              placeholder="Иванов"
              query={formData.lastNameDriver[number].value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => this.handleChangeFio(e, 'lastNameDriver', number)}
              parts={['SURNAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.lastNameDriver[number].errorMessage })}
            />
            {formData.lastNameDriver[number].errorMessage && (
              <div className="validation-error">
                {formData.lastNameDriver[number].errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-3 mb-lg-0">
            <label htmlFor="firstNameDriver">Имя</label>
            <ReactDadata
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              suggestionType="fio"
              id="firstNameDriver"
              placeholder="Иван"
              query={formData.firstNameDriver[number].value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => this.handleChangeFio(e, 'firstNameDriver', number)}
              parts={['NAME']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.firstNameDriver[number].errorMessage })}
            />
            {formData.firstNameDriver[number].errorMessage && (
              <div className="validation-error">
                {formData.firstNameDriver[number].errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3">
            <label htmlFor="middleNameDriver">Отчество</label>
            <ReactDadata
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              suggestionType="fio"
              id="middleNameDriver"
              placeholder="Иван"
              query={formData.middleNameDriver[number].value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => this.handleChangeFio(e, 'middleNameDriver', number)}
              parts={['PATRONYMIC']}
              disabled={disableField}
              firstCapital
              translate
              className={classnames('form-control', { error: formData.middleNameDriver[number].errorMessage })}
            />
            {formData.middleNameDriver[number].errorMessage && (
              <div className="validation-error">
                {formData.middleNameDriver[number].errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-3 mb-lg-0">
            <label htmlFor="driverBirthday">Дата рождения</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              className={classnames('form-control', { error: formData.driverBirthday[number].errorMessage })}
              selected={formData.driverBirthday[number].value}
              onChange={(date) => this.handleChangeDateDriver(date, 'driverBirthday', number)}
              id="driverBirthday"
              locale={ru}
              maxDate={formData.driverExperienceDate[number].value ? maxDriverBirthday[number] : subYears(new Date(), 18)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.driverBirthday[number].errorMessage && (
              <div className="validation-error">
                {formData.driverBirthday[number].errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <label htmlFor="driverLicenceSerial">Серия В/У</label>
            <InputMask
              maskChar=""
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              disabled={disableField}
              onChange={(e) => this.handleChangeDriverInput(e, number, !formData.driverForeignLicence[number], true)}
              id="driverLicenceSerial"
              placeholder="11АА"
              className={classnames('form-control upperCase', { error: formData.driverLicenceSerial[number].errorMessage })}
              mask={getMask(formData.driverLicenceSerial[number].validationType).mask}
              formatChars={getMask(formData.driverLicenceSerial[number].validationType).formatChars}
              value={formData.driverLicenceSerial[number].value}
            />
            {formData.driverLicenceSerial[number].errorMessage && (
              <div className="validation-error">
                {formData.driverLicenceSerial[number].errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-3 mb-lg-0">
            <label htmlFor="driverLicenceNumber">Номер В/У</label>
            <InputMask
              maskChar=""
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              disabled={disableField}
              onChange={(e) => this.handleChangeDriverInput(e, number, !formData.driverForeignLicence[number], true)}
              id="driverLicenceNumber"
              placeholder="111111"
              className={classnames('form-control upperCase', { error: formData.driverLicenceNumber[number].errorMessage })}
              mask={getMask(formData.driverLicenceNumber[number].validationType).mask}
              formatChars={getMask(formData.driverLicenceNumber[number].validationType).formatChars}
              value={formData.driverLicenceNumber[number].value}
            />
            {formData.driverLicenceNumber[number].errorMessage && (
              <div className="validation-error">
                {formData.driverLicenceNumber[number].errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3">
            <label htmlFor="driverCurrentLicenceDate">Дата выдачи В/У</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              className={classnames('form-control', { error: formData.driverCurrentLicenceDate[number].errorMessage })}
              selected={formData.driverCurrentLicenceDate[number].value}
              onChange={(date) => this.handleChangeDateDriver(date, 'driverCurrentLicenceDate', number)}
              id="driverCurrentLicenceDate"
              locale={ru}
              maxDate={new Date()}
              minDate={subYears(new Date(), 15)}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.driverCurrentLicenceDate[number].errorMessage && (
              <div className="validation-error">
                {formData.driverCurrentLicenceDate[number].errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3">
            <label htmlFor="driverExperienceDate">Дата начала стажа</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              disabled={disableField}
              onBlur={(e) => this.handleValidateDrivers(e, number)}
              className={classnames('form-control', { error: formData.driverExperienceDate[number].errorMessage })}
              selected={formData.driverExperienceDate[number].value}
              onChange={(date) => this.handleChangeDateDriver(date, 'driverExperienceDate', number)}
              id="driverExperienceDate"
              locale={ru}
              maxDate={new Date()}
              minDate={minExperienceDate[number]}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            {formData.driverExperienceDate[number].errorMessage && (
              <div className="validation-error">
                {formData.driverExperienceDate[number].errorMessage}
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div className="form-check custom-checkbox">
            <input
              disabled={disableField}
              onChange={(e) => this.setDriverForeignLicence(e, number)}
              checked={formData.driverForeignLicence[number]}
              className="form-check-input checkbox-styled"
              type="checkbox"
              id="driverForeignLicence"
            />
            <label className="form-check-label">Иностранное В/У</label>
          </div>
        </div>
        {formData.driverForeignLicence[number] ? (
          <div hidden={!formData.driverForeignLicence[number]}>
            <div className="form-group row">
              <div className="col-lg-3 mb-3">
                <label htmlFor="driverForeignLicenceCountry">Страна выдачи иностранного В/У</label>
                <Select
                  styles={customStyles}
                  classNamePrefix="react-select"
                  isDisabled={disableField}
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="driverForeignLicenceCountry"
                  placeholder="Выберите страну из списка"
                  isSearchable
                  className={classnames('form-control-custom', { error: formData.driverForeignLicenceCountry[number].errorMessage })}
                  value={formData.driverForeignLicenceCountry[number].value.value ? formData.driverForeignLicenceCountry[number].value : null}
                  onChange={(e) => this.handleChangeSelectDriver(e, 'driverForeignLicenceCountry', number)}
                  options={countriesNames}
                  onBlur={(e) => this.handleValidateDrivers(e, number)}
                />
                {formData.driverForeignLicenceCountry[number].errorMessage && (
                  <div className="validation-error">
                    {formData.driverForeignLicenceCountry[number].errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-group">
          <div className="form-check custom-checkbox">
            <input
              disabled={disableField}
              onChange={(e) => this.setDriverPrevLicence(e, number)}
              checked={formData.driverPrevLicence[number]}
              className="form-check-input checkbox-styled"
              type="checkbox"
              id="driverPrevLicence"
            />
            <label className="form-check-label">Указать данные предыдущего В/У</label>
          </div>
        </div>
        <div hidden={!formData.driverPrevLicence[number]}>
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="prevLastNameDriver">Фамилия</label>
              <ReactDadata
                onBlur={(e) => this.handleValidateDrivers(e, number)}
                suggestionType="fio"
                id="prevLastNameDriver"
                placeholder="Иванов"
                query={formData.prevLastNameDriver[number].value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => this.handleChangeFio(e, 'prevLastNameDriver', number)}
                parts={['SURNAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.prevLastNameDriver[number].errorMessage })}
              />
              {formData.prevLastNameDriver[number].errorMessage && (
                <div className="validation-error">
                  {formData.prevLastNameDriver[number].errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="driverPrevLicenceSerial">Серия В/У</label>
              <InputMask
                maskChar=""
                onBlur={(e) => this.handleValidateDrivers(e, number)}
                disabled={disableField}
                onChange={(e) => this.handleChangeDriverInput(e, number, !formData.driverPrevForeignLicence[number], true)}
                id="driverPrevLicenceSerial"
                placeholder="11АА"
                className={classnames('form-control upperCase', { error: formData.driverPrevLicenceSerial[number].errorMessage })}
                mask={getMask(formData.driverPrevLicenceSerial[number].validationType).mask}
                formatChars={getMask(formData.driverPrevLicenceSerial[number].validationType).formatChars}
                value={formData.driverPrevLicenceSerial[number].value}
              />
              {formData.driverPrevLicenceSerial[number].errorMessage && (
                <div className="validation-error">
                  {formData.driverPrevLicenceSerial[number].errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label htmlFor="driverPrevLicenceNumber">Номер В/У</label>
              <InputMask
                maskChar=""
                onBlur={(e) => this.handleValidateDrivers(e, number)}
                disabled={disableField}
                onChange={(e) => this.handleChangeDriverInput(e, number, !formData.driverPrevForeignLicence[number], true)}
                id="driverPrevLicenceNumber"
                placeholder="111111"
                className={classnames('form-control upperCase', { error: formData.driverPrevLicenceNumber[number].errorMessage })}
                mask={getMask(formData.driverPrevLicenceNumber[number].validationType).mask}
                formatChars={getMask(formData.driverPrevLicenceNumber[number].validationType).formatChars}
                value={formData.driverPrevLicenceNumber[number].value}
              />
              {formData.driverPrevLicenceNumber[number].errorMessage && (
                <div className="validation-error">
                  {formData.driverPrevLicenceNumber[number].errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="form-check custom-checkbox">
                <input
                  disabled={disableField}
                  onChange={(e) => this.setDriverPrevForeignLicence(e, number)}
                  checked={formData.driverPrevForeignLicence[number]}
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="driverPrevForeignLicence"
                />
                <label className="form-check-label">Иностранное предыдущее В/У</label>
              </div>
            </div>
          </div>
          {formData.driverPrevForeignLicence[number] ? (
            <div hidden={!formData.driverPrevForeignLicence[number]}>
              <div className="form-group row">
                <div className="col-lg-3 mb-3">
                  <label htmlFor="driverPrevForeignLicenceCountry">Страна выдачи предыдущего иностранного В/У</label>
                  <Select
                    styles={customStyles}
                    classNamePrefix="react-select"
                    isDisabled={disableField}
                    noOptionsMessage={() => 'Не найдено'}
                    inputId="driverPrevForeignLicenceCountry"
                    placeholder="Выберите страну из списка"
                    isSearchable
                    className={classnames('form-control-custom', { error: formData.driverPrevForeignLicenceCountry[number].errorMessage })}
                    value={formData.driverPrevForeignLicenceCountry[number].value.value ? formData.driverPrevForeignLicenceCountry[number].value : null}
                    onChange={(e) => this.handleChangeSelectDriver(e, 'driverPrevForeignLicenceCountry', number)}
                    options={countriesNames}
                    onBlur={(e) => this.handleValidateDrivers(e, number)}
                  />
                  {formData.driverPrevForeignLicenceCountry[number].errorMessage && (
                    <div className="validation-error">
                      {formData.driverPrevForeignLicenceCountry[number].errorMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )) : [];

    if (allPolicyBlock.length > 0 && !yandexGoals.getResult) {
      this.yandexReachGoal('getResult');
    }

    return (
      <>
        {showOsagoCheckForm ? (
          <OsagoCheckInfo
            prolongationChangeDateFlag={prolongationChangeDateFlag}
            disableField={this.setDisableField}
            history={history}
            formData={formData}
            companies={companies}
            formId={policy.id}
            formIdHash={formIdHash}
            backToPickCompany={this.backToPickCompany}
            backToEditForm={this.hideOsagoCheckForm}
            choosenOffer={choosenOffer}
            searchIdSravni={searchIdSravni}
            hideCommission={hideCommission}
            policy={policy}
          />
        ) : null}
        {(policyStatus === 'processing' || policyStatus === 'calculated') ? (
          <FormNotificationColorLine offsetBottom="1rem">Данный черновик был проведен до стадии формирования ссылки на оплату и заблокирован для дальнейших изменений. Если вы хотите произвести повторный расчет - скопируйте черновик.</FormNotificationColorLine>
        ) : null}
        <div style={{ display: showOsagoCheckForm ? 'none' : 'block' }}>
          <LoadingBanner loadingFlag={loadingInfoFlags.all}>
            <form name="osagoform" id="osago_form" onSubmit={this.handleSubmit}>
              <div className="card mb-4">
                <div className="card-header fill_section__title">
                  <span className="title_num">1</span>
                  <span>Данные транспортного средства</span>
                </div>
                <div className="card-body">
                  {!formData.registrationNumberBlank && (
                    <div className="form-group row">
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="carNumber">Гос. номер</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={(e) => this.handleChangeInput(e, true, true)}
                          id="carNumber"
                          placeholder="А111АА111"
                          className={classnames('form-control column-margin upperCase', { error: formData.carNumber.errorMessage })}
                          mask={formData.category.value.value === 'A' ? getMask('motorcycleNumber').mask : getMask('carNumber').mask}
                          formatChars={formData.category.value.value === 'A' ? getMask('motorcycleNumber').formatChars : getMask('carNumber').formatChars}
                          value={formData.carNumber.value}
                        />
                        {formData.carNumber.errorMessage && (
                          <div className="validation-error">
                            {formData.carNumber.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3">
                        <label className="hidden d-none d-lg-block">hide</label>
                        <button disabled={disableField} type="button" className="btn btn-success mb-2 mb-md-0" onClick={this.checkVehicleInfo}>Найти данные по гос.номеру</button>
                      </div>
                      <div className="col-lg-5 force-to-bottom">
                        {loadingCarInfo
                        && <ReactLoading className="loading-circle" type="spin" height={37} width={37} />}
                        {errorBlankRegNumber
                        && <div className="error_block">Неправильно заполнен номер</div>}
                        {errorTooManyRequests
                        && <div className="error_block">Превышена частота или лимит запросов, повторите попытку позже или заполните данные вручную</div>}
                        {errorWrongRegNumber
                        && <div className="error_block">Данные не найдены</div>}
                        {errorCantCompareCar
                        && <div className="error_block">Введите марку и модель вручную</div>}
                      </div>
                    </div>
                  )}
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <div className="form-check custom-checkbox">
                        <input
                          disabled={disableField}
                          onChange={this.registrationNumberBlank}
                          checked={formData.registrationNumberBlank}
                          className="form-check-input checkbox-styled"
                          type="checkbox"
                          id="registrationNumberBlank"
                        />
                        <label className="form-check-label" htmlFor="registrationNumberBlank">
                          Гос. номер отсутствует
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-check custom-checkbox">
                        <input
                          disabled={disableField}
                          onChange={this.handleChangeCheckBox}
                          checked={formData.useTrailer}
                          name="useTrailer"
                          className="form-check-input checkbox-styled"
                          type="checkbox"
                          id="useTrailer"
                        />
                        <label className="form-check-label" htmlFor="useTrailer">
                          ТС с прицепом
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label>Категория</label>
                      <Select
                        styles={customStyles}
                        name="category"
                        isDisabled={disableField}
                        placeholder="Выберите категорию"
                        classNamePrefix="react-select"
                        inputId="category"
                        value={formData.category.value}
                        onChange={this.handleChangeCategory}
                        onBlur={this.handleValidateSelect}
                        className={classnames('form-control-custom', { error: formData.category.errorMessage })}
                        options={[
                          { value: 'A', label: 'A' },
                          { value: 'B', label: 'B' },
                          { value: 'C', label: 'C' },
                          { value: 'D', label: 'D' },
                        ]}
                        noOptionsMessage={() => 'Не найдено'}
                      />
                      {formData.category.errorMessage && (
                        <div className="validation-error">
                          {formData.category.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label>Марка автомобиля</label>
                      <Select
                        styles={customStyles}
                        isDisabled={disableField}
                        placeholder="Выберите марку"
                        classNamePrefix="react-select"
                        inputId="markId"
                        value={formData.markId.value.value ? formData.markId.value : null}
                        onChange={this.handleChangeMark}
                        onBlur={this.handleValidateSelect}
                        className={classnames('form-control-custom', { error: formData.markId.errorMessage })}
                        options={optionItemsMarks}
                        noOptionsMessage={() => 'Не найдено'}
                      />
                      {formData.markId.errorMessage && (
                        <div className="validation-error">
                          {formData.markId.errorMessage}
                        </div>
                      )}
                      <input value={formData.markId.value.label} type="hidden" className="form-control" id="carBrand" placeholder="" />
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label>Модель автомобиля</label>
                      <Select
                        styles={customStyles}
                        isDisabled={disableField}
                        placeholder="Выберите модель"
                        classNamePrefix="react-select"
                        inputId="modelId"
                        value={formData.modelId.value.value ? formData.modelId.value : null}
                        onChange={this.handleChangeModel}
                        onBlur={this.handleValidateSelect}
                        className={classnames('form-control-custom', { error: formData.modelId.errorMessage })}
                        options={optionItemsModels}
                        noOptionsMessage={() => 'Не найдено'}
                      />
                      {formData.modelId.errorMessage && (
                        <div className="validation-error">
                          {formData.modelId.errorMessage}
                        </div>
                      )}
                      <input value={formData.modelId.value.label} type="hidden" className="form-control" id="carModel" placeholder="" />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="carYear">Год выпуска</label>
                      <Select
                        styles={customStyles}
                        isDisabled={disableField}
                        placeholder="Выберите год"
                        classNamePrefix="react-select"
                        inputId="carYear"
                        value={formData.carYear.value.value ? formData.carYear.value : null}
                        onChange={(e) => this.handleChangeSelect(e, 'carYear')}
                        onBlur={this.handleValidateSelect}
                        className={classnames('form-control-custom', { error: formData.carYear.errorMessage })}
                        options={yearOption}
                        noOptionsMessage={() => 'Не найдено'}
                      />
                      {formData.carYear.errorMessage && (
                        <div className="validation-error">
                          {formData.carYear.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="targetOfUsing">Цель использования</label>
                      <Select
                        styles={customStyles}
                        name="targetOfUsing"
                        isDisabled={disableField}
                        placeholder="Выберите цель"
                        classNamePrefix="react-select"
                        inputId="targetOfUsing"
                        value={formData.targetOfUsing.value.value ? formData.targetOfUsing.value : null}
                        onChange={(e) => this.handleChangeSelect(e, 'targetOfUsing')}
                        className={classnames('form-control-custom', { error: formData.targetOfUsing.errorMessage })}
                        options={targetOfUsingOptions}
                      />
                      {formData.targetOfUsing.errorMessage && (
                        <div className="validation-error">
                          {formData.targetOfUsing.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="horsePower">Мощность, л.с.</label>
                      <InputMask
                        maskChar=""
                        onBlur={this.handleValidate}
                        disabled={disableField}
                        onChange={this.handleChangeInput}
                        id="horsePower"
                        placeholder="120"
                        className={classnames('form-control', { error: formData.horsePower.errorMessage })}
                        mask={getMask('decimal').mask}
                        formatChars={getMask('decimal').formatChars}
                        value={formData.horsePower.value}
                      />
                      {formData.horsePower.errorMessage && (
                        <div className="validation-error">
                          {formData.horsePower.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="vehicleIdentificationType">Идентификатор ТС</label>
                      <Select
                        styles={customStyles}
                        classNamePrefix="react-select"
                        isDisabled={disableField}
                        isSearchable={false}
                        inputId="vehicleIdentificationType"
                        noOptionsMessage={() => 'Не найдено'}
                        id="vehicleIdentificationType"
                        value={formData.vehicleIdentificationType.value.value ? formData.vehicleIdentificationType.value : null}
                        onChange={(e) => this.handleChangeSelect(e, 'vehicleIdentificationType')}
                        options={[
                          { value: 'vin', label: 'VIN' },
                          { value: 'body', label: 'Номер кузова' },
                          { value: 'chassis', label: 'Номер шасси' },
                        ]}
                      />
                    </div>
                    {(formData.vehicleIdentificationType.value.value === 'vin') && (
                      <div className="col-lg-3">
                        <label htmlFor="vin">VIN</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          autoComplete="off"
                          onChange={(e) => this.handleChangeInput(e, false, true)}
                          id="vehicleIdentificationNumberVin"
                          placeholder="ААА12345ААААААААА"
                          className={classnames('form-control upperCase', { error: formData.vehicleIdentificationNumberVin.errorMessage })}
                          mask={getMask('vin').mask}
                          formatChars={getMask('vin').formatChars}
                          value={formData.vehicleIdentificationNumberVin.value}
                        />
                        {formData.vehicleIdentificationNumberVin.errorMessage && (
                          <div className="validation-error">
                            {formData.vehicleIdentificationNumberVin.errorMessage}
                          </div>
                        )}
                      </div>
                    )}
                    {(formData.vehicleIdentificationType.value.value === 'body') && (
                      <div className="col-lg-3">
                        <label htmlFor="body">Номер кузова</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={this.handleChangeInput}
                          id="vehicleIdentificationNumberBody"
                          placeholder="ААА12345ААААААААА"
                          className={classnames('form-control upperCase', { error: formData.vehicleIdentificationNumberBody.errorMessage })}
                          mask={getMask('bodyNumber').mask}
                          formatChars={getMask('bodyNumber').formatChars}
                          value={formData.vehicleIdentificationNumberBody.value}
                        />
                        {formData.vehicleIdentificationNumberBody.errorMessage && (
                          <div className="validation-error">
                            {formData.vehicleIdentificationNumberBody.errorMessage}
                          </div>
                        )}
                      </div>
                    )}
                    {(formData.vehicleIdentificationType.value.value === 'chassis') && (
                      <div className="col-lg-3">
                        <label htmlFor="chassis">Номер шасси</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={this.handleChangeInput}
                          id="vehicleIdentificationNumberChassis"
                          placeholder="ААА12345ААААААААА"
                          className={classnames('form-control upperCase', { error: formData.vehicleIdentificationNumberChassis.errorMessage })}
                          mask={getMask('chassisNumber').mask}
                          formatChars={getMask('chassisNumber').formatChars}
                          value={formData.vehicleIdentificationNumberChassis.value}
                        />
                        {formData.vehicleIdentificationNumberChassis.errorMessage && (
                          <div className="validation-error">
                            {formData.vehicleIdentificationNumberChassis.errorMessage}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {(formData.category.value.value === 'C') && (
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="allowedMaxWeight">Максимальная разрешенная масса, КГ</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={(e) => this.handleChangeInput(e)}
                          id="allowedMaxWeight"
                          placeholder="1500"
                          name="allowedMaxWeight"
                          className={classnames('form-control', { error: formData.allowedMaxWeight.errorMessage })}
                          mask={getMask('decimal').mask}
                          formatChars={getMask('decimal').formatChars}
                          value={formData.allowedMaxWeight.value}
                        />
                        {formData.allowedMaxWeight.errorMessage && (
                          <div className="validation-error">
                            {formData.allowedMaxWeight.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(formData.category.value.value === 'D') && (
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="vehiclePassengersCapacity">Количество пассажирских мест</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={(e) => this.handleChangeInput(e)}
                          id="vehiclePassengersCapacity"
                          placeholder="10"
                          name="vehiclePassengersCapacity"
                          className={classnames('form-control', { error: formData.vehiclePassengersCapacity.errorMessage })}
                          mask={getMask('int').mask}
                          formatChars={getMask('int').formatChars}
                          value={formData.vehiclePassengersCapacity.value}
                          inputMode="tel"
                        />
                        {formData.vehiclePassengersCapacity.errorMessage && (
                          <div className="validation-error">
                            {formData.vehiclePassengersCapacity.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="vehicleTypeDocument">Тип документа</label>
                      <Select
                        styles={customStyles}
                        classNamePrefix="react-select"
                        isDisabled={disableField}
                        noOptionsMessage={() => 'Не найдено'}
                        inputId="vehicleTypeDocument"
                        isSearchable={false}
                        value={formData.vehicleTypeDocument.value.value ? formData.vehicleTypeDocument.value : null}
                        onChange={(e) => this.handleChangeSelect(e, 'vehicleTypeDocument')}
                        options={[
                          { value: 'sertificate', label: 'СТС' },
                          { value: 'passport', label: 'ПТС' },
                          { value: 'epassport', label: 'ЭПТС' },
                        ]}
                      />
                    </div>
                    { formData.vehicleTypeDocument.value.value !== 'epassport' ? (
                      <>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                          <label htmlFor="serialDocument">Серия</label>
                          <InputMask
                            autoComplete="off"
                            maskChar=""
                            onBlur={this.handleValidate}
                            disabled={disableField}
                            onChange={(e) => this.handleChangeInput(e, true, true)}
                            id="serialDocument"
                            placeholder="11АА"
                            className={classnames('form-control upperCase', { error: formData.serialDocument.errorMessage })}
                            mask={getMask('serialDocument').mask}
                            formatChars={getMask('serialDocument').formatChars}
                            value={formData.serialDocument.value}
                          />
                          {formData.serialDocument.errorMessage && (
                            <div className="validation-error">
                              {formData.serialDocument.errorMessage}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3 mb-3 mb-lg-0">
                          <label htmlFor="numberDocument">Номер</label>
                          <InputMask
                            autoComplete="off"
                            maskChar=""
                            onBlur={this.handleValidate}
                            disabled={disableField}
                            onChange={this.handleChangeInput}
                            id="numberDocument"
                            placeholder="111111"
                            className={classnames('form-control upperCase', { error: formData.numberDocument.errorMessage })}
                            mask={getMask('numberDocument').mask}
                            formatChars={getMask('numberDocument').formatChars}
                            value={formData.numberDocument.value}
                          />
                          {formData.numberDocument.errorMessage && (
                            <div className="validation-error">
                              {formData.numberDocument.errorMessage}
                            </div>
                          )}
                        </div>
                      </>
                    )
                      : (
                        <div className="col-lg-6 mb-3 mb-lg-0">
                          <label htmlFor="numberEDocument">Номер</label>
                          <InputMask
                            maskChar=""
                            onBlur={this.handleValidate}
                            disabled={disableField}
                            onChange={this.handleChangeInput}
                            id="numberEDocument"
                            placeholder="123456789012345"
                            className={classnames('form-control', { error: formData.numberEDocument.errorMessage })}
                            mask={getMask('numberEDocument').mask}
                            value={formData.numberEDocument.value}
                          />
                          {formData.numberEDocument.errorMessage && (
                            <div className="validation-error">
                              {formData.numberEDocument.errorMessage}
                            </div>
                          )}
                        </div>
                      )}
                    <div className="col-lg-3">
                      <label htmlFor="dateDocument">Дата выдачи</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        disabled={disableField}
                        onBlur={this.handleValidate}
                        className={classnames('form-control', { error: formData.dateDocument.errorMessage })}
                        selected={formData.dateDocument.value}
                        onChange={(date) => this.handleChangeDate(date, 'dateDocument')}
                        id="dateDocument"
                        locale={ru}
                        maxDate={new Date()}
                        minDate={formData.carYear.value.value ? new Date(formData.carYear.value.value, 0) : subYears(new Date(), 100)}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                      {formData.dateDocument.errorMessage && (
                        <div className="validation-error">
                          {formData.dateDocument.errorMessage}
                        </div>
                      )}
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header fill_section__title">
                  <span className="title_num">2</span>
                  <span>Персональные данные страхователя</span>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <SimpleNotification>Страхователем является лицо, заключающее договор страхования и оплачивающее страховую премию</SimpleNotification>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="lastNameInsured">Фамилия</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="lastNameInsured"
                        onBlur={this.handleValidate}
                        placeholder="Иванов"
                        query={formData.lastNameInsured.value}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'lastNameInsured')}
                        parts={['SURNAME']}
                        disabled={disableField}
                        firstCapital
                        translate
                        className={classnames('form-control', { error: formData.lastNameInsured.errorMessage })}
                      />
                      {formData.lastNameInsured.errorMessage && (
                      <div className="validation-error">
                        {formData.lastNameInsured.errorMessage}
                      </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="firstNameInsured">Имя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="firstNameInsured"
                        onBlur={this.handleValidate}
                        placeholder="Иван"
                        query={formData.firstNameInsured.value}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'firstNameInsured')}
                        parts={['NAME']}
                        disabled={disableField}
                        firstCapital
                        translate
                        className={classnames('form-control', { error: formData.firstNameInsured.errorMessage })}
                      />
                      {formData.firstNameInsured.errorMessage && (
                        <div className="validation-error">
                          {formData.firstNameInsured.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="middleNameInsured">Отчество</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="middleNameInsured"
                        onBlur={this.handleValidate}
                        placeholder="Иванович"
                        query={formData.middleNameInsured.value}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'middleNameInsured')}
                        parts={['PATRONYMIC']}
                        disabled={disableField}
                        firstCapital
                        translate
                        className={classnames('form-control', { error: formData.middleNameInsured.errorMessage })}
                      />
                      {formData.middleNameInsured.errorMessage && (
                        <div className="validation-error">
                          {formData.middleNameInsured.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="insuredBirthday">Дата рождения</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        disabled={disableField}
                        onBlur={this.handleValidate}
                        className={classnames('form-control', { error: formData.insuredBirthday.errorMessage })}
                        selected={formData.insuredBirthday.value}
                        onChange={(date) => this.handleChangeDate(date, 'insuredBirthday')}
                        id="insuredBirthday"
                        locale={ru}
                        maxDate={subYears(new Date(), 18)}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                      {formData.insuredBirthday.errorMessage && (
                        <div className="validation-error">
                          {formData.insuredBirthday.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="insuredPassportNumberSerial">Серия и номер паспорта</label>
                      <InputMask
                        maskChar=""
                        onBlur={this.handleValidate}
                        disabled={disableField}
                        onChange={(e) => this.handleChangeInput(e, false, false, true)}
                        id="insuredPassportNumberSerial"
                        placeholder="1111 111111"
                        className={classnames('form-control', { error: formData.insuredPassportNumberSerial.errorMessage })}
                        mask={getMask('passportNumberSerie').mask}
                        value={formData.insuredPassportNumberSerial.value}
                        inputMode="tel"
                      />
                      {formData.insuredPassportNumberSerial.errorMessage && (
                        <div className="validation-error">
                          {formData.insuredPassportNumberSerial.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="insuredPassportDate">Дата выдачи паспорта</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        disabled={disableField}
                        onBlur={this.handleValidate}
                        className={classnames('form-control', { error: formData.insuredPassportDate.errorMessage })}
                        selected={formData.insuredPassportDate.value}
                        onChange={(date) => this.handleChangeDate(date, 'insuredPassportDate')}
                        id="insuredPassportDate"
                        locale={ru}
                        maxDate={new Date()}
                        minDate={formData.insuredBirthday.value ? addYears(formData.insuredBirthday.value, 14) : subYears(new Date(), 100)}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                      {formData.insuredPassportDate.errorMessage && (
                        <div className="validation-error">
                          {formData.insuredPassportDate.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-9">
                      <label htmlFor="insuredPassportIssuedBy">Кем выдан паспорт</label>
                      <ReactDadata
                        suggestionType="fms"
                        name="insuredPassportIssuedBy"
                        id="insuredPassportIssuedBy"
                        className={classnames('form-control', { error: formData.insuredPassportIssuedBy.errorMessage })}
                        query={formData.insuredPassportIssuedBy.value}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangePassportIssuedBy(e, 'insuredPassportIssuedBy')}
                        placeholder=""
                        disabled={disableField}
                      />
                      {formData.insuredPassportIssuedBy.errorMessage && (
                        <div className="validation-error">
                          {formData.insuredPassportIssuedBy.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="insuredPassportUnitNumber">Код подразделения</label>
                      <InputMask
                        maskChar=""
                        onBlur={this.handleValidate}
                        disabled={disableField}
                        onChange={(e) => this.handleChangeInput(e, false, false, true)}
                        id="insuredPassportUnitNumber"
                        name="insuredPassportUnitNumber"
                        mask={getMask('passportUnitNumber').mask}
                        placeholder="123-456"
                        formatChars={getMask('passportUnitNumber').formatChars}
                        className={classnames('form-control', { error: formData.insuredPassportUnitNumber.errorMessage })}
                        value={formData.insuredPassportUnitNumber.value}
                      />
                      {formData.insuredPassportUnitNumber.errorMessage && (
                        <div className="validation-error">
                          {formData.insuredPassportUnitNumber.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-9">
                      <label htmlFor="insuredAddress">Адрес</label>
                      <ReactDadata
                        id="insuredAddress"
                        className={classnames('form-control', { error: formData.insuredAddress.errorMessage })}
                        query={formData.insuredAddress.value.value}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeAddress(e, 'insuredAddress')}
                        placeholder="Можете начать с ввода улицы"
                        disabled={disableField}
                        onFocus={this.setCursorToEnd}
                        ref={this.insuredAddressInput}
                      />
                      {formData.insuredAddress.errorMessage && (
                        <div className="validation-error">
                          {formData.insuredAddress.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header fill_section__title">
                  <span className="title_num">3</span>
                  <span>Персональные данные собственника</span>
                </div>
                <div className="card-body">
                  <div className={formData.checkboxOwner ? 'form-row' : 'form-group row'}>
                    <div className="col-lg-12">
                      <div className="form-check custom-checkbox">
                        <input
                          disabled={disableField}
                          onChange={this.setCheckOwner}
                          checked={formData.checkboxOwner}
                          name="copyInfo"
                          className="form-check-input checkbox-styled"
                          type="checkbox"
                          id="copyInfo"
                        />
                        <label className="form-check-label" htmlFor="copyInfo">Собственник является страхователем</label>
                      </div>
                    </div>
                  </div>
                  <div hidden={formData.checkboxOwner}>
                    <div className="form-group row">
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="lastNameOwner">Фамилия</label>
                        <ReactDadata
                          suggestionType="fio"
                          onBlur={this.handleValidate}
                          id="lastNameOwner"
                          placeholder="Иванов"
                          query={formData.lastNameOwner.value}
                          token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                          onChange={(e) => this.handleChangeFio(e, 'lastNameOwner')}
                          parts={['SURNAME']}
                          disabled={disableField}
                          firstCapital
                          translate
                          className={classnames('form-control', { error: formData.lastNameOwner.errorMessage })}
                        />
                        {formData.lastNameOwner.errorMessage && (
                          <div className="validation-error">
                            {formData.lastNameOwner.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="firstNameOwner">Имя</label>
                        <ReactDadata
                          suggestionType="fio"
                          id="firstNameOwner"
                          onBlur={this.handleValidate}
                          placeholder="Иван"
                          query={formData.firstNameOwner.value}
                          token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                          onChange={(e) => this.handleChangeFio(e, 'firstNameOwner')}
                          parts={['NAME']}
                          disabled={disableField}
                          firstCapital
                          translate
                          className={classnames('form-control', { error: formData.firstNameOwner.errorMessage })}
                        />
                        {formData.firstNameOwner.errorMessage && (
                          <div className="validation-error">
                            {formData.firstNameOwner.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="middleNameOwner">Отчество</label>
                        <ReactDadata
                          suggestionType="fio"
                          id="middleNameOwner"
                          onBlur={this.handleValidate}
                          placeholder="Иванович"
                          query={formData.middleNameOwner.value}
                          token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                          onChange={(e) => this.handleChangeFio(e, 'middleNameOwner')}
                          parts={['PATRONYMIC']}
                          disabled={disableField}
                          firstCapital
                          translate
                          className={classnames('form-control', { error: formData.middleNameOwner.errorMessage })}
                        />
                        {formData.middleNameOwner.errorMessage && (
                          <div className="validation-error">
                            {formData.middleNameOwner.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="ownerBirthday">Дата рождения</label>
                        <DatePicker
                          popperModifiers={{
                            computeStyle: { gpuAcceleration: false },
                          }}
                          disabled={disableField}
                          onBlur={this.handleValidate}
                          className={classnames('form-control', { error: formData.ownerBirthday.errorMessage })}
                          selected={formData.ownerBirthday.value}
                          onChange={(date) => this.handleChangeDate(date, 'ownerBirthday')}
                          id="ownerBirthday"
                          locale={ru}
                          maxDate={subYears(new Date(), 16)}
                          showYearDropdown
                          showMonthDropdown
                          dateFormat="dd.MM.yyyy"
                          placeholderText="ДД.ММ.ГГГГ"
                          customInput={
                            <InputMask mask="99.99.9999" inputMode="tel" />
                          }
                        />
                        <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                        {formData.ownerBirthday.errorMessage && (
                          <div className="validation-error">
                            {formData.ownerBirthday.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="ownerPassportNumberSerial">Серия и номер паспорта</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={(e) => this.handleChangeInput(e, false, false, true)}
                          id="ownerPassportNumberSerial"
                          placeholder="1111 111111"
                          className={classnames('form-control', { error: formData.ownerPassportNumberSerial.errorMessage })}
                          mask={getMask('passportNumberSerie').mask}
                          value={formData.ownerPassportNumberSerial.value}
                          inputMode="tel"
                        />
                        {formData.ownerPassportNumberSerial.errorMessage && (
                          <div className="validation-error">
                            {formData.ownerPassportNumberSerial.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3">
                        <label htmlFor="ownerPassportDate">Дата выдачи паспорта</label>
                        <DatePicker
                          popperModifiers={{
                            computeStyle: { gpuAcceleration: false },
                          }}
                          disabled={disableField}
                          onBlur={this.handleValidate}
                          className={classnames('form-control', { error: formData.ownerPassportDate.errorMessage })}
                          selected={formData.ownerPassportDate.value}
                          onChange={(date) => this.handleChangeDate(date, 'ownerPassportDate')}
                          id="ownerPassportDate"
                          locale={ru}
                          maxDate={new Date()}
                          minDate={formData.ownerBirthday.value ? addYears(formData.ownerBirthday.value, 14) : subYears(new Date(), 100)}
                          showYearDropdown
                          showMonthDropdown
                          dateFormat="dd.MM.yyyy"
                          placeholderText="ДД.ММ.ГГГГ"
                          customInput={
                            <InputMask mask="99.99.9999" inputMode="tel" />
                          }
                        />
                        <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                        {formData.ownerPassportDate.errorMessage && (
                          <div className="validation-error">
                            {formData.ownerPassportDate.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-9">
                        <label htmlFor="ownerPassportIssuedBy">Кем выдан паспорт</label>
                        <ReactDadata
                          suggestionType="fms"
                          name="ownerPassportIssuedBy"
                          id="ownerPassportIssuedBy"
                          className={classnames('form-control', { error: formData.ownerPassportIssuedBy.errorMessage })}
                          query={formData.ownerPassportIssuedBy.value}
                          token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                          onChange={(e) => this.handleChangePassportIssuedBy(e, 'ownerPassportIssuedBy')}
                          placeholder=""
                          disabled={disableField}
                        />
                        {formData.ownerPassportIssuedBy.errorMessage && (
                          <div className="validation-error">
                            {formData.ownerPassportIssuedBy.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="ownerPassportUnitNumber">Код подразделения</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={(e) => this.handleChangeInput(e, false, false, true)}
                          id="ownerPassportUnitNumber"
                          name="ownerPassportUnitNumber"
                          mask={getMask('passportUnitNumber').mask}
                          placeholder="123-456"
                          formatChars={getMask('passportUnitNumber').formatChars}
                          className={classnames('form-control', { error: formData.ownerPassportUnitNumber.errorMessage })}
                          value={formData.ownerPassportUnitNumber.value}
                        />
                        {formData.ownerPassportUnitNumber.errorMessage && (
                          <div className="validation-error">
                            {formData.ownerPassportUnitNumber.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-9">
                        <label htmlFor="ownerAddress">Адрес</label>
                        <ReactDadata
                          id="ownerAddress"
                          className={classnames('form-control', { error: formData.ownerAddress.errorMessage })}
                          query={formData.ownerAddress.value.value}
                          token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                          onChange={(e) => this.handleChangeAddress(e, 'ownerAddress')}
                          placeholder="Можете начать с ввода улицы"
                          disabled={disableField}
                          onFocus={this.setCursorToEnd}
                          ref={this.ownerAddressInput}
                        />
                        {formData.ownerAddress.errorMessage && (
                          <div className="validation-error">
                            {formData.ownerAddress.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header fill_section__title">
                  <span className="title_num">4</span>
                  <span>Данные водителей</span>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="limitation">Ограничение по водителям</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        isDisabled={disableField}
                        noOptionsMessage={() => 'Не найдено'}
                        inputId="unlimitedDrivers"
                        isSearchable={false}
                        value={formData.unlimitedDrivers.value ? formData.unlimitedDrivers.value : null}
                        onChange={(e) => this.handleChangeSelect(e, 'unlimitedDrivers')}
                        options={[
                          { value: false, label: '1 и более' },
                          { value: true, label: 'Неограниченное количество' },
                        ]}
                      />
                    </div>
                    {formData.unlimitedDrivers.value.value === true && (
                      <div className="col-lg-9">
                        <SimpleNotification>Неограниченный доступ к управлению автомобилем увеличивает стоимость страхового полиса</SimpleNotification>
                      </div>
                    )}
                  </div>
                  {formData.unlimitedDrivers.value.value === false && listDrivers}
                  {formData.unlimitedDrivers.value.value === false && (
                    <>
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <SimpleNotification>Новое В/У с сохранением КБМ по старым правам доступно не по всем страховым компаниям. Возможно оформление полиса на старые права.</SimpleNotification>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-3">
                          <button disabled={disableField} onClick={this.addNewDriver} type="button" className="btn btn-border btn-success">+ Еще один водитель</button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header fill_section__title">
                  <span className="title_num">5</span>
                  <span>Данные о полисе</span>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="policyDate">Дата начала действия</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        disabled={disableField}
                        onBlur={this.handleValidate}
                        className={classnames('form-control', { error: formData.policyDate.errorMessage })}
                        selected={formData.policyDate.value}
                        onChange={(date) => this.handleChangeDate(date, 'policyDate')}
                        id="policyDate"
                        locale={ru}
                        minDate={addDays(new Date(), 1)}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                      {formData.policyDate.errorMessage && (
                        <div className="validation-error">
                          {formData.policyDate.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="policyPeriod">Срок действия</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        isDisabled={disableField}
                        name="policyPeriod"
                        noOptionsMessage={() => 'Не найдено'}
                        inputId="policyPeriod"
                        isSearchable={false}
                        value={formData.policyPeriod.value.value ? formData.policyPeriod.value : null}
                        onChange={(e) => this.handleChangeSelect(e, 'policyPeriod')}
                        options={[
                          { value: 12, label: '12 Месяцев' },
                          { value: 9, label: '9 Месяцев' },
                          { value: 7, label: '7 Месяцев' },
                          { value: 6, label: '6 Месяцев' },
                          { value: 5, label: '5 Месяцев' },
                          { value: 4, label: '4 Месяца' },
                          { value: 3, label: '3 Месяца' },
                        ]}
                      />
                    </div>
                    <div className="col-lg-6">
                      <SimpleNotification>Количество доступных предложений может увеличится при установке даты начала действия полиса +4 дня от текущей даты. Краткосрочные полисы доступны не для всех СК.</SimpleNotification>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <div className="form-check custom-checkbox">
                        <input
                          disabled={disableField}
                          onChange={this.changePrevPolicyCheck}
                          checked={formData.prevPolicyCheck}
                          name="prevPolicyCheck"
                          className="form-check-input checkbox-styled"
                          type="checkbox"
                          id="prevPolicyCheck"
                        />
                        <label className="form-check-label" htmlFor="prevPolicyCheck">
                          Указать предыдущий полис (необходим для «зеленого коридора»)
                        </label>
                      </div>
                    </div>
                  </div>
                  {formData.prevPolicyCheck ? (
                    <div className="form-group row">
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="prevPolicyInsurance">Страховая компания</label>
                        <Select
                          styles={customStyles}
                          name="prevPolicyInsurance"
                          isDisabled={disableField}
                          placeholder="Выберите страховую"
                          classNamePrefix="react-select"
                          inputId="prevPolicyInsurance"
                          value={formData.prevPolicyInsurance.value.value ? formData.prevPolicyInsurance.value : null}
                          onChange={(e) => this.handleChangeSelect(e, 'prevPolicyInsurance')}
                          onBlur={this.handleValidateSelect}
                          className={classnames('form-control-custom', { error: formData.prevPolicyInsurance.errorMessage })}
                          options={sravniCompanies}
                          noOptionsMessage={() => 'Не найдено'}
                        />
                        {formData.prevPolicyInsurance.errorMessage && (
                          <div className="validation-error">
                            {formData.prevPolicyInsurance.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="prevPolicySerial">Серия полиса</label>
                        <Select
                          styles={customStyles}
                          name="prevPolicySerial"
                          isDisabled={disableField}
                          placeholder="ХХХ"
                          classNamePrefix="react-select"
                          inputId="prevPolicySerial"
                          value={formData.prevPolicySerial.value.value ? formData.prevPolicySerial.value : null}
                          onChange={(e) => this.handleChangeSelect(e, 'prevPolicySerial')}
                          onBlur={this.handleValidateSelect}
                          className={classnames('form-control-custom', { error: formData.prevPolicySerial.errorMessage })}
                          options={[
                            { value: 'ХХХ', label: 'ХХХ' },
                            { value: 'ССС', label: 'ССС' },
                            { value: 'РРР', label: 'РРР' },
                            { value: 'ННН', label: 'ННН' },
                            { value: 'МММ', label: 'МММ' },
                            { value: 'ККК', label: 'ККК' },
                            { value: 'ЕЕЕ', label: 'ЕЕЕ' },
                            { value: 'ВВВ', label: 'ВВВ' },
                            { value: 'ААС', label: 'ААС' },
                            { value: 'ААВ', label: 'ААВ' },
                            { value: 'ТТТ', label: 'ТТТ' },
                          ]}
                          noOptionsMessage={() => 'Не найдено'}
                        />
                        {formData.prevPolicySerial.errorMessage && (
                          <div className="validation-error">
                            {formData.prevPolicySerial.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="prevPolicyNumber">Номер полиса</label>
                        <InputMask
                          maskChar=""
                          onBlur={this.handleValidate}
                          disabled={disableField}
                          onChange={this.handleChangeInput}
                          id="prevPolicyNumber"
                          placeholder="123456789012"
                          name="prevPolicyNumber"
                          className={classnames('form-control', { error: formData.prevPolicyNumber.errorMessage })}
                          mask={getMask('policyNumber').mask}
                          value={formData.prevPolicyNumber.value}
                        />
                        {formData.prevPolicyNumber.errorMessage && (
                          <div className="validation-error">
                            {formData.prevPolicyNumber.errorMessage}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-3 mb-3 mb-lg-0">
                        <label htmlFor="prevPolicyDate">Дата окончания</label>
                        <DatePicker
                          popperModifiers={{
                            computeStyle: { gpuAcceleration: false },
                          }}
                          disabled={disableField}
                          onBlur={this.handleValidate}
                          className={classnames('form-control', { error: formData.prevPolicyDate.errorMessage })}
                          selected={formData.prevPolicyDate.value}
                          onChange={(date) => this.handleChangeDate(date, 'prevPolicyDate')}
                          name="prevPolicyDate"
                          id="prevPolicyDate"
                          locale={ru}
                          showYearDropdown
                          showMonthDropdown
                          dateFormat="dd.MM.yyyy"
                          placeholderText="ДД.ММ.ГГГГ"
                          customInput={
                            <InputMask mask="99.99.9999" inputMode="tel" />
                          }
                        />
                        <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                        {formData.prevPolicyDate.errorMessage && (
                          <div className="validation-error">
                            {formData.prevPolicyDate.errorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header fill_section__title">
                  <span className="title_num">6</span>
                  <span>Контактные данные страхователя</span>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="phone">Телефон</label>
                      <InputMask
                        maskChar=""
                        onBlur={this.handleValidate}
                        disabled={disableField}
                        onChange={this.handleChangeInput}
                        id="phone"
                        placeholder="+7 (999) 999-99-99"
                        className={classnames('form-control', { error: formData.phone.errorMessage })}
                        mask={getMask('phone').mask}
                        formatChars={getMask('phone').formatChars}
                        value={formData.phone.value}
                      />
                      {formData.phone.errorMessage && (
                        <div className="validation-error">
                          {formData.phone.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-3 mb-lg-0">
                      <label htmlFor="email">Email</label>
                      <InputMask
                        maskChar=""
                        onBlur={this.handleValidate}
                        disabled={disableField}
                        onChange={this.handleChangeInput}
                        id="email"
                        placeholder="your@email.ru"
                        className={classnames('form-control', { error: formData.email.errorMessage })}
                        value={formData.email.value}
                        mask={getMask('email').mask}
                        formatChars={getMask('email').formatChars}
                      />
                      {formData.email.errorMessage && (
                        <div className="validation-error">
                          {formData.email.errorMessage}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <SimpleNotification>Вы получите электронную версию документа на указанный адрес электронной почты. Некоторые страховые компании ограничивают количество полисов на 1 номер телефона, <b>указывайте уникальный действительный номер</b>.</SimpleNotification>
                    </div>
                  </div>
                </div>
              </div>
              {userInfo.full_time_employee === 1 || customFieldOptions.length > 0 ? (
                <AccountSystem
                  customFieldName={customFieldName}
                  customFieldOptions={customFieldOptions}
                  customField={customField}
                  setCustomField={this.handleChangeCustomField}
                  userFullTimeEmployee={userInfo.full_time_employee}
                  blockNumber="7"
                  digitalBroker={userInfo.is_digital_broker}
                  userRole={userInfo.role}
                  disableField={disableField}
                  setLinkToDeal={this.handleChangeLinkToDeal}
                  linkToDeal={linkToDeal}
                  leadSourcesOptions={leadSourcesOptions}
                  leadSource={leadSource}
                  setLeadSource={this.handleChangeLeadSource}
                  remoteEmployee={remoteEmployee}
                  managerAttractedOptions={managerAttractedOptions}
                  managerAttracted={managerAttracted}
                  setManagerAttracted={this.handleChangeManagerAttracted}
                  customStyles={customStyles}
                  newAgent={newAgent}
                  setNewAgent={this.handleChangeNewAgentSelect}
                  domain={userInfo.domain}
                />
              ) : null}
              {policyType !== 'accident' ? (
                <div className="row row-grid">
                  <div className="col-lg-12">
                    {isDemo || urlParams.adminEdit ? (
                      <button onClick={this.simulateCalculation} type="submit" className="btn btn-success mr-3 mb-3">Рассчитать</button>
                    ) : (
                      <>
                        <button disabled={disableField} type="button" className="btn btn-success mr-3 mb-3" onClick={this.saveOsagoForm}>Сохранить</button>
                        <button disabled={disableField} type="submit" className="btn btn-success mr-3 mb-3">Рассчитать</button>
                      </>
                    )}
                    {policyStatus === 'complete' && (<button type="button" className="btn btn-success mb-3" onClick={this.copyForm}>Пролонгировать</button>)}
                  </div>
                  {(loadingCalcData) && (
                    <div className="col-lg-6">
                      <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={38} width={38} />
                      <span>Идет подбор предложений..</span>
                    </div>
                  )}
                </div>
              ) : null}
              {errorLoadingCalcData && (
                <div className="row mt-3">
                  <div className="col-lg-6">
                    <div className="error_block">Повторите попытку позже (возможно непопадание в сегмент)</div>
                  </div>
                </div>
              )}
            </form>
          </LoadingBanner>
          <Modal
            classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
            closeIconSize={16}
            open={openModalSegmentation}
            onClose={this.onCloseModalSegmentation}
            center
          >
            <h4>Сегментация Гайде, ВСК, Альфа, Мафин</h4>
            Гайде:
            <ul>
              <li>менее 1 года</li>
              <li>менее 101 л.с.</li>
              <li>КБМ {'>'}=0.95</li>
              <li>ВАЗ, ГАЗ, DAEWOO, DATSUN, LADA, Nissan Almera, VW Polo, Skoda Rapid, Kia Rio, Hyundai Solaris</li>
              <li>мультидрайв</li>
              <li>цель использования не равно личная</li>
            </ul>
            ВСК:
            <ul>
              <li>«Мультидрайв», который подразумевает отсутствие ограничений по возрасту ЛДУ, а
                соответственно автомобилем может управлять водитель в возрасте младше 24 лет;
              </li>
              <li>из региона не указанного в КЛАДР;</li>
              <li>оформленным на авто, которым уже в этом году наступает 15 лет и более</li>
              <li>в которые внесены ЛДУ и им на день оформления полиса не исполнилось 24 года;</li>
              <li>аннулированным полисам;</li>
              <li>в которые внесены изменения позволяющие отнести его к вышеперечисленным
                категориям;
              </li>
              <li>оплата по которым не поступила на счёт ВСК или поступила не в полном объеме.</li>
            </ul>
            Альфа:
            <ul>
              <li>Новый бизнес - полисы с иностранными В/У</li>
              <li>Мощность ТС отличается от марки/модели</li>
              <li>Адрес страхователя передан не полностью (город/улица/дом/кв – исключения
                составляет прописка без № квартиры)
              </li>
              <li>Новый бизнес - не соблюдена временная франшиза +3д от даты оформления</li>
              <li>Новый бизнес - полисы в которых срок страхования менее 365д</li>
              <li>VIN - повторяется более чем в одном оформленном полисе в отчетном месяц</li>
              <li>Транзитные полисы с периодом 20д</li>
              <li>Страхователь собственник – не резидент (иностранный паспорт)</li>
              <li>Новый бизнес - категории отличающиеся от В</li>
              <li>Расторгнутые полисы по заявлению клиента/агента</li>
              <li>Аннулированные полисы</li>
            </ul>
            Мафин:
            <ul>
              <li>не соблюдена временная франшиза +3д от даты оформления</li>
              <li>Без VIN номера</li>
              <li>Полис без ограничений</li>
              <li>Если страхователь не равно собственник и не равно водитель</li>
              <li>Срок действия менее 1 года</li>
              <li>Мощность менее 101лс (Км {'<'} 1.2)</li>
              <li>Машина старше 15 лет</li>
              <li>КБМ 1 и хуже</li>
            </ul>
          </Modal>
          {loadingCalcData ? (
            <ProgressBar />
          ) : null}
          {loadingInit ? (
            <div className="position-relative mt-2">
              <div className={`search_results__cont ${blurClass}`}>
                <Element name="searchResults"><div className="search_results__title h1">Результаты поиска</div></Element>
                {hideCommission === 0 && (
                  <div className="form-inline mt-2 mb-4">
                    <span className="switch-text">Режим работы с клиентом</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span onClick={this.handleChangeShowCommission} className="slider round" />
                    </label>
                  </div>
                )}
                <div className="search_results__table">
                  {policyResultData.length && !isDemo ? (
                    <button
                      onClick={(e) => this.toggleAllCompaniesCommercialOffer(e, policyResultData)}
                      type="button"
                      className="btn btn-secondary btn-commercial-offer-add-all show-mobile"
                    >
                      {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                    </button>
                  ) : null}
                  <div className="search_results__table--head">
                    <div className="search_results__table--row">
                      <div className="search_results__table--cell">Страховая компания</div>
                      <div className="search_results__table--cell">
                        <a className={classnames({ dotted_link: selectedSort === 'price' })} href="" onClick={(e) => { this.changeSort(e, 'price'); }}>
                          Стоимость
                          {policySortOrderUp && selectedSort === 'price' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                            : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                        </a>
                      </div>
                      <div className="search_results__table--cell">
                        {showCommission && hideCommission === 0 ? (
                          <a className={classnames({ dotted_link: selectedSort === 'commissionCurrency' })} href="" onClick={(e) => { this.changeSort(e, 'commissionCurrency'); }}>
                            Вознаграждение
                            {policySortOrderUp && selectedSort === 'commissionCurrency' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        ) : (
                          <a className={classnames({ dotted_link: selectedSort === 'rating' })} href="" onClick={(e) => { this.changeSort(e, 'rating'); }}>
                            Рейтинг
                            {policySortOrderUp && selectedSort === 'rating' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        )}
                      </div>
                      <div className="search_results__table--cell">Комментарий</div>
                      <div className="search_results__table--cell">
                        {policyResultData.length && !isDemo ? (
                          <button
                            onClick={(e) => this.toggleAllCompaniesCommercialOffer(e, policyResultData)}
                            type="button"
                            className="btn btn-secondary btn-commercial-offer-add-all"
                          >
                            {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="search_results__table--body">
                    {allPolicyBlock}
                    {otherCompaniesBlock}
                  </div>
                </div>
              </div>
              {blurClass && (
                <div className="blur_message">
                  Внесены изменения в данные для расчета - необходимо обновить расчёт. Нажмите кнопку «рассчитать»
                </div>
              )}
            </div>
          ) : null}
        </div>
        {!showOsagoCheckForm ? (
          <CommercialOffer
            policySortOrderUp={policySortOrderUp}
            selectedSort={selectedSort}
            contactPhone={formData.phone.value}
            contactEmail={formData.email.value}
            commercialOfferCompanies={commercialOfferCompanies}
            showModalInfo={this.showModalInfo}
            policyId={policy ? policy.id : null}
            policyIdHash={formIdHash}
            product="osago"
            productName="ОСАГО"
          />
        ) : null}
      </>
    );
  }
}

OsagoForm.contextType = ModalMessagesContext;

export default OsagoForm;
