import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { ReactDadata } from 'react-dadata';
import Select, { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import ReactLoading from 'react-loading';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import {
  customSelectStyle,
  customFetch,
  downloadDescriptionOptions,
  loadCRMOptionsLists,
  loadSuggestions,
  getThemes,
} from '../Utils/Helpers';
import getValidation from '../Utils/Validation';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import getMask from '../Utils/Masks';
import ModalBlockedForm from './ModalBlockedForm';

/* TO-DO: БРАТЬ ДАННЫЕ ДЛЯ СЕЛЕКТОРОВ ИЗ БД */

const optionType = [
  { value: 'natural', label: 'Физ. лицо' },
  { value: 'self_employed', label: 'Физ. лицо (самозанятый)' },
  { value: 'individual', label: 'Индивидуальный предприниматель' },
  { value: 'legal', label: 'Юр. лицо' },
];

const optionSalesVolume = [
  { value: 1, label: 'до 10' },
  { value: 2, label: 'до 50' },
  { value: 3, label: 'до 100' },
  { value: 4, label: '100+' },
];

const optionsEGarant = [
  { value: 0, label: 'Скрыть' },
  { value: 1, label: 'Показывать в случае отказа' },
  { value: 2, label: 'Показывать всегда' },
];

class AgentInfo extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      crmUsers: [],
      optionCategory: [
        { value: 'vip', label: 'Вип' },
        { value: 'pro', label: 'Профессионал' },
        { value: 'standard', label: 'Стандарт' },
        { value: 'novice', label: 'Новичок' },
        { value: 'blank', label: 'Без категории' },
      ],
      descriptionOptions: [],
      isToggleAgentForm: false,
      emailSended: false,
      agent: this.props.agent,
      emailSending: false,
      erpAgentsOptions: [],
      techAgents: [],
      domains: [],
      saving: false,
      showModal: false,
      password: {
        value: '',
        errorMessage: '',
        validationType: 'password',
      },
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { userRole } = this.props;
    if (userRole === 'admin' || userRole === 'superadmin') {
      this.downloadAdminInfo();
      loadCRMOptionsLists(null, 'admin', ['user']).then((data) => {
        const crmUsers = data.users.map((user) => ({ value: user.ID, label: `${user.LAST_NAME} ${user.NAME} ${user.SECOND_NAME}` }));
        this.setState({
          crmUsers,
        });
      });
    }
    getThemes().then((response) => {
      const domains = response.map((theme) => ({ value: theme.domain, label: theme.domain }));
      this._isMounted && this.setState({ domains });
    });
  }

  componentDidUpdate(prevProps) {
    const { agent, userRole } = this.props;
    if (prevProps.agent !== agent) {
      this._isMounted && this.setState({ agent }, () => {
        if (userRole === 'admin' || userRole === 'superadmin') {
          this.downloadAdminInfo();
          loadCRMOptionsLists(null, 'admin', ['user']).then((data) => {
            const crmUsers = data.users.map((user) => ({ value: user.ID, label: `${user.LAST_NAME} ${user.NAME} ${user.SECOND_NAME}` }));
            this.setState({
              crmUsers,
            });
          });
        }
      });
    }
    if (prevProps.userRole !== userRole) {
      if (userRole === 'admin' || userRole === 'superadmin') {
        this.downloadAdminInfo();
        loadCRMOptionsLists(null, 'admin', ['user']).then((data) => {
          const crmUsers = data.users.map((user) => ({ value: user.ID, label: `${user.LAST_NAME} ${user.NAME} ${user.SECOND_NAME}` }));
          this.setState({
            crmUsers,
          });
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  downloadErpIds = () => {
    if (process.env.REACT_APP_API_DOMAIN === 'http://127.0.0.1:8000/api') return;
    const { agent } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/erp/agents?full_time_employee=${agent.full_time_employee}`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const agents = response.map((erpAgent) => ({ value: erpAgent.id, label: erpAgent['Представление'] }));
          this._isMounted && this.setState({ erpAgentsOptions: agents.concat([{ value: null, label: 'Отсутствует' }]) });
        } else {
          const { showModalInfo } = this.context;
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        showModalInfo('Ошибка загрузки справочника ERP', 'error');
      });
  };

  setShowModal = (flag) => {
    this.setState({ showModal: flag });
  }

  downloadAdminInfo = () => {
    const { agent, optionCategory } = this.state;
    this.downloadErpIds();
    if (agent.agent_type_code === 'natural') {
      this.setState({
        optionCategory: optionCategory.map((category) => { category.value !== 'novice' ? category.isDisabled = true : category.isDisabled = false; return category; }),
      });
    }
    if (agent.parentAgentIsFullTimeEmployee) {
      this.setState({
        optionCategory: optionCategory.map((category) => { (category.value === 'blank' || (agent.agent_type_code === 'natural' && category.value !== 'novice')) ? category.isDisabled = true : category.isDisabled = false; return category; }),
      });
    }
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/techAgents`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const agents = response.map((techAgent) => ({ value: techAgent.id, label: `${techAgent.last_name} ${techAgent.first_name} ${techAgent.middle_name}`, isDisabled: agent.agent_type_code !== 'natural' }));
          this._isMounted && this.setState({ techAgents: agents.concat([{ value: null, label: 'Без тех. агента (через Polis.Online)' }]) });
        }
      });
  };

  handleUnblockAgent = () => {
    const { agent } = this.state;
    this.setState({ saving: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { showModalInfo } = this.context;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/unblock`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selected: {
          [agent.id]: true,
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          this.setState((prevState) => ({
            agent: {
              ...agent,
              status: response.status,
            },
          }));
          showModalInfo('Агент разблокирован', 'error');
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  };

  sendInvite = () => {
    const { agent } = this.state;
    this.setState({ emailSending: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mail/send`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agentId: agent.id }),
    })
      .then((response) => response.json())
      .then(() => {
        this.setState({
          emailSended: true,
          emailSending: false,
        });
      });
  };

  handleChangeToggle = () => {
    this.setState((prevState) => ({
      isToggleAgentForm: !prevState.isToggleAgentForm,
    }));
  };

  handleCancel = () => {
    const { agent } = this.props;
    this.setState((prevState) => ({
      agent,
      isToggleAgentForm: !prevState.isToggleAgentForm,
    }));
  };

  handleInput = (key, e) => {
    const { value } = e.target;
    const { agent } = this.state;
    this.setState({
      agent: {
        ...agent,
        [key]: value,
      },
    });
  };

  handleChangeSelect = (event, actionMeta) => {
    let value = null;
    if (event) {
      value = event.value;
    }
    const { agent } = this.state;
    let { techAgents, optionCategory } = this.state;
    let { curatorName, partner_agent_name } = agent;
    if (actionMeta.name === 'curator') {
      curatorName = event.label;
    }
    if (actionMeta.name === 'partner_agent_id') {
      partner_agent_name = event ? event.label : 'Отсутствует';
    }
    if (actionMeta.name === 'agent_type_code' && value !== 'legal') {
      agent.tech_agent = false;
    }
    if (actionMeta.name === 'agent_type_code' && value !== 'natural') {
      agent.tech_agent_id = null;
      optionCategory = optionCategory.map((category) => { category.isDisabled = false; return category; });
      techAgents = techAgents.map((techAgent) => { techAgent.value ? techAgent.isDisabled = true : techAgent.isDisabled = false; return techAgent; });
    } else if (actionMeta.name === 'agent_type_code') {
      agent.category = 'novice';
      optionCategory = optionCategory.map((category) => { category.value !== 'novice' ? category.isDisabled = true : category.isDisabled = false; return category; });
      techAgents = techAgents.map((techAgent) => { techAgent.isDisabled = false; return techAgent; });
    }
    this.setState({
      agent: {
        ...agent,
        [actionMeta.name]: value,
        curatorName,
        partner_agent_name,
      },
      techAgents,
      optionCategory,
    });
  };

  handleChangeDescription = (value, actionMeta) => {
    const { agent } = this.state;
    let descriptionList = '';
    if (value) {
      descriptionList = value.map((description) => description.value).join(',');
    }
    this.setState({
      agent: {
        ...agent,
        [actionMeta.name]: descriptionList,
      },
    });
  }

  handleChange = (event) => {
    const {
      name, value, checked, type,
    } = event.target;
    const { agent } = this.state;
    if (name === 'tech_agent' && checked) {
      agent.agent_type_code = 'legal';
    }
    if (type === 'checkbox') {
      this.setState({
        agent: {
          ...agent,
          [name]: checked,
        },
      }, () => {
        if (name === 'full_time_employee') {
          this.downloadErpIds();
        }
      });
    } else {
      this.setState({
        agent: {
          ...agent,
          [name]: value,
        },
      });
    }
  };

  handleChangePassword = (e) => {
    const { value } = e.target;
    const { password } = this.state;
    if (password.errorMessage) {
      password.errorMessage = '';
    }
    password.value = value;
    this.setState({ password });
  };

  handleChangeAddress = (query) => {
    const { agent } = this.state;
    this.setState({
      agent: {
        ...agent,
        city: query.value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { agent } = this.state;
    this.setState({ saving: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { showModalInfo } = this.context;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}`, {
      method: 'put',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(agent),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          this.props.updateAgent(agent);
          this.setState((prevState) => ({
            isToggleAgentForm: !prevState.isToggleAgentForm,
            saving: false,
          }));
        } else {
          this.setState((prevState) => ({
            saving: false,
          }));
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        this.setState((prevState) => ({
          saving: false,
        }));
        showModalInfo('Ошибка', 'error');
      });
  };

  handleSendPassword = () => {
    const { showModalInfo } = this.context;
    const { password } = this.state;
    const errorMessage = getValidation(password.value, password.validationType);
    if (errorMessage) {
      this.setState({
        password: {
          ...password,
          errorMessage,
        },
      });
    } else {
      this.setState({ saving: true });
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      const { agent } = this.state;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/changePassword`, {
        method: 'post',
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          agent: agent.id,
          password: password.value,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            showModalInfo('Пароль успешно изменен');
          } else {
            showModalInfo('Ошибка');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка');
        })
        .finally(() => {
          this.setState({ saving: false });
        });
    }
  };

  downloadOptions = () => {
    downloadDescriptionOptions().then((descriptionOptions) => {
      this.setState({ descriptionOptions });
    });
  };

  render() {
    const {
      isToggleAgentForm,
      emailSended,
      agent,
      emailSending,
      saving,
      techAgents,
      domains,
      password,
      descriptionOptions,
      optionCategory,
      erpAgentsOptions,
      crmUsers,
      showModal,
    } = this.state;
    const {
      userRole, minKbmCost, remoteEmployee, domain, employee
    } = this.props;

    const SelectContainer = (props) => {
      if (props.isDisabled) {
        if (props.selectProps.id === 'partner_agent_id' && agent.partner_agent_id) {
          return (
            <a target="_blank" href={`/agent/${agent.partner_agent_id}`} rel="noreferrer">
              <components.SelectContainer
                {...props}
              />
            </a>
          );
        }
        if (props.selectProps.id === 'curator') {
          return (
            <a target="_blank" href={`/agent/${agent.curator}`} rel="noreferrer">
              <components.SelectContainer
                {...props}
              />
            </a>
          );
        }
        return (
          <components.SelectContainer
            {...props}
          />
        );
      }
      return (<components.SelectContainer {...props} />);
    };

    let status = '';
    switch (agent.status) {
      case 'active':
        status = 'Активирован';
        break;
      case 'inactive':
        status = 'Не активирован';
        break;
      case 'ban':
        status = 'Заблокирован';
        break;
      case 'black_list':
        status = 'В черном списке';
        break;
      default:
        status = '-';
        break;
    }
    const crmUser = crmUsers.filter(({ value }) => value === agent.crm_user_id);
    return (
      <>
        {isToggleAgentForm
          ? <h2>Редактирование данных агента</h2>
          : <button type="button" className="btn btn-success mb-4" onClick={this.handleChangeToggle}>Редактировать</button>}
        {showModal ? <ModalBlockedForm showAcceptModalForm={showModal} setShowAcceptModalForm={this.setShowModal} agent={agent} /> : null}
        <div className="card">
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="phone">Номер телефона</label>
                <InputMask
                  placeholder="Номер телефона"
                  mask={getMask('phone').mask}
                  formatChars={getMask('phone').formatChars}
                  value={agent.phone ? agent.phone : ''}
                  onChange={this.handleChange}
                  id="phone"
                  name="phone"
                  className="form-control"
                  type="text"
                  disabled={!isToggleAgentForm || (userRole !== 'admin' && userRole !== 'superadmin')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="phone">Электронная почта</label>
                <input type="text" name="email" id="email" className="form-control" onChange={this.handleChange} disabled={!isToggleAgentForm || (userRole !== 'admin' && userRole !== 'superadmin')} value={agent.email} />
              </div>
              <div className="form-group col-md-9">
                <label className="hidden d-block">hide</label>
                <button type="button" className="btn btn-success mr-2 mb-2" onClick={this.sendInvite}>{agent.last_activity ? 'Отправить напоминание' : 'Отправить приглашение'}</button>
                {emailSending && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
                {emailSended && (
                  <div className="d-inline-block">
                    <FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="fa-fw fa-lg" />
                    Приглашение отправлено
                  </div>
                )}
              </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputLastName">Фамилия</label>
                  <input type="text" name="last_name" id="inputLastName" className="form-control" disabled={!isToggleAgentForm || (userRole !== 'admin' && userRole !== 'superadmin')} value={agent.last_name} onChange={this.handleChange} />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputFirstName">Имя</label>
                  <input type="text" name="first_name" id="inputName" className="form-control" disabled={!isToggleAgentForm || (userRole !== 'admin' && userRole !== 'superadmin')} value={agent.first_name} onChange={this.handleChange} />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputMiddleName">Отчество</label>
                  <input type="text" name="middle_name" id="inputMiddleName" className="form-control" disabled={!isToggleAgentForm || (userRole !== 'admin' && userRole !== 'superadmin')} value={agent.middle_name} onChange={this.handleChange} />
                </div>
                {(domain === 'samoletplus' || employee) ? (
                  <div className="form-group col-md-3">
                    <label htmlFor="inputOfficeNumber">Номер офиса</label>
                    <InputMask maskChar="" placeholder="Номер офиса" formatChars={getMask('officeNumber').formatChars} mask={getMask('officeNumber').mask} type="text" name="office_number" id="inputOfficeNumber" className="form-control" disabled={!isToggleAgentForm} value={agent.office_number} onChange={this.handleChange} />
                  </div>
                ) : null}
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputPhone">Телефон</label>
                  <InputMask onChange={(e) => this.handleInput('phone', e)} required value={agent.phone} disabled className="form-control" id="inputPhone" mask="+7 (999) 999-99-99" placeholder="+7 (999) 999-99-99" />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">Город</label>
                  <ReactDadata
                    className="form-control"
                    bounds="city"
                    disabled={!isToggleAgentForm || (userRole !== 'admin' && userRole !== 'superadmin')}
                    query={agent.city}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={this.handleChangeAddress}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputComment">Комментарий</label>
                  <input type="text" name="comment" id="inputComment" className="form-control" disabled={!isToggleAgentForm} value={agent.comment ? agent.comment : ''} onChange={this.handleChange} />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="kbm_cost">Стоимость КБМ</label>
                  <input disabled={!isToggleAgentForm} onChange={this.handleChange} type="number" min={minKbmCost} className="form-control" id="kbm_cost" name="kbm_cost" value={agent.kbm_cost} placeholder="Стоимость КБМ" />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="status">Статус</label>
                  <input type="text" disabled className="form-control" id="status" name="status" value={status} placeholder="Статус" />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="registrationType">Тип регистрации</label>
                  <input type="text" disabled className="form-control" id="registrationType" name="registrationType" value={agent.registrationType === 'invite' ? 'Приглашение' : 'Автоматическая'} placeholder="Тип регистрации" />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="balance">Баланс</label>
                  <input type="text" disabled className="form-control" id="balance" name="balance" value={agent.balance} placeholder="Баланс" />
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="agentType">Тип</label>
                  <Select
                    classNamePrefix="react-select"
                    styles={customSelectStyle()}
                    isDisabled={!isToggleAgentForm}
                    name="agent_type_code"
                    isSearchable={false}
                    placeholder="Выберите тип"
                    id="agentType"
                    value={optionType.filter(({ value }) => value === agent.agent_type_code)}
                    onChange={this.handleChangeSelect}
                    options={optionType}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-check custom-checkbox">
                  <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.allow_curator_drafts} name="allow_curator_drafts" className="form-check-input checkbox-styled" type="checkbox" id="allow_curator_drafts" />
                  <label className="form-check-label" htmlFor="allow_curator_drafts">
                    Разрешить доступ к черновикам/полисам/лидам/агентам агентской сети
                  </label>
                </div>
              </div>
              {remoteEmployee ? (
                <div className="form-row">
                  <div className="form-group col-lg-3">
                    <label htmlFor="agentCategory">Категория</label>
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={<div style={{ maxWidth: 450 }}>Для физ. лиц только новичок</div>}
                      hideOnClick={false}
                    >
                      <div className="d-inline-block">
                        <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                      </div>
                    </Tippy>
                    <Select
                      classNamePrefix="react-select"
                      styles={customSelectStyle()}
                      isDisabled={!isToggleAgentForm || !agent.parentAgentIsFullTimeEmployee}
                      isOptionDisabled={(option) => option.isDisabled}
                      name="category"
                      isSearchable={false}
                      placeholder="Выберите категорию"
                      id="agentCategory"
                      value={optionCategory.filter(({ value }) => value === agent.category)}
                      onChange={this.handleChangeSelect}
                      options={optionCategory}
                    />
                  </div>
                </div>
              ) : null}
              {(userRole === 'admin' || userRole === 'superadmin') ? (
                <>
                  <div className="form-row">
                    <div className="form-group col-lg-12">
                      <span>Для администратора:</span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-lg-3">
                      <label htmlFor="agentSalesVolume">Объем продаж</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customSelectStyle()}
                        isDisabled={!isToggleAgentForm}
                        name="sales_volume"
                        isSearchable={false}
                        placeholder="Выберите объем продаж"
                        id="agentSalesVolume"
                        value={optionSalesVolume.filter(({ value }) => value === agent.sales_volume)}
                        onChange={this.handleChangeSelect}
                        options={optionSalesVolume}
                      />
                    </div>
                    <div className="form-group col-lg-3">
                      <label htmlFor="agentCategory">Категория</label>
                      <Tippy
                        className="tippy-custom"
                        arrow={false}
                        offset={[0, 5]}
                        content={<div style={{ maxWidth: 450 }}>Для физ. лиц только новичок</div>}
                        hideOnClick={false}
                      >
                        <div className="d-inline-block">
                          <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                        </div>
                      </Tippy>
                      <Select
                        classNamePrefix="react-select"
                        styles={customSelectStyle()}
                        isDisabled={!isToggleAgentForm || !agent.parentAgentIsFullTimeEmployee}
                        isOptionDisabled={(option) => option.isDisabled}
                        name="category"
                        isSearchable={false}
                        placeholder="Выберите категорию"
                        id="agentCategory"
                        value={optionCategory.filter(({ value }) => value === agent.category)}
                        onChange={this.handleChangeSelect}
                        options={optionCategory}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="curator">Куратор</label>
                      <AsyncSelect
                        isDisabled={!isToggleAgentForm}
                        components={{ SelectContainer }}
                        value={{
                          value: agent.curator,
                          label: agent.curatorName,
                        }}
                        classNamePrefix="react-select"
                        placeholder="Выберите куратора"
                        name="curator"
                        id="curator"
                        loadingMessage={() => 'Загрузка'}
                        noOptionsMessage={() => 'Не найдено'}
                        onChange={this.handleChangeSelect}
                        styles={customSelectStyle()}
                        loadOptions={debounce(loadSuggestions(''), 500)}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="">Тех. агент</label>
                      <Tippy
                        className="tippy-custom"
                        arrow={false}
                        offset={[0, 5]}
                        content={<div style={{ maxWidth: 450 }}>Тех. агент доступен только для физ.лиц</div>}
                        hideOnClick={false}
                      >
                        <div className="d-inline-block">
                          <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                        </div>
                      </Tippy>
                      <Select
                        classNamePrefix="react-select"
                        styles={customSelectStyle()}
                        isDisabled={!isToggleAgentForm || !agent.parentAgentIsFullTimeEmployee}
                        isOptionDisabled={(option) => option.isDisabled}
                        name="tech_agent_id"
                        placeholder="Выберите тех. агента"
                        id="techAgentId"
                        value={techAgents.filter(({ value }) => value === agent.tech_agent_id)}
                        onChange={this.handleChangeSelect}
                        options={techAgents}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="partner_agent_id">Партнер</label>
                      <AsyncSelect
                        isDisabled={!isToggleAgentForm || !agent.parentAgentIsFullTimeEmployee}
                        components={{ SelectContainer }}
                        value={{
                          value: agent.partner_agent_id,
                          label: agent.partner_agent_name,
                        }}
                        classNamePrefix="react-select"
                        placeholder="Выберите партнера"
                        name="partner_agent_id"
                        id="partner_agent_id"
                        isClearable
                        loadingMessage={() => 'Загрузка'}
                        noOptionsMessage={() => 'Не найдено'}
                        onChange={this.handleChangeSelect}
                        styles={customSelectStyle()}
                        loadOptions={debounce(loadSuggestions(''), 500)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="linkToCrm">Ссылка на лид в CRM</label>
                      {!isToggleAgentForm
                        ? (<a href={agent.link_to_crm} className="form-control disabled text-truncate d-inline-block" rel="noopener noreferrer" target="_blank">{agent.link_to_crm}</a>)
                        : (<input type="text" className="form-control" id="linkToCrm" name="link_to_crm" disabled={!isToggleAgentForm} value={agent.link_to_crm} onChange={this.handleChange} placeholder="Ссылка на лид в CRM" />)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="crm_contact_id">ID контакта в CRM</label>
                      {!isToggleAgentForm
                        ? (<a href={`https://crm.infullbroker.ru/crm/contact/details/${agent.crm_contact_id}/`} className="form-control disabled text-truncate d-inline-block" rel="noopener noreferrer" target="_blank">{agent.crm_contact_id}</a>)
                        : (<input type="text" className="form-control" id="crm_contact_id" name="crm_contact_id" required disabled={!isToggleAgentForm} value={agent.crm_contact_id} onChange={this.handleChange} placeholder="ID контакта в CRM" />)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="crm_deal_id">ID сделки в CRM</label>
                      {!isToggleAgentForm
                        ? (<a href={`https://crm.infullbroker.ru/crm/deal/details/${agent.crm_deal_id}/`} className="form-control disabled text-truncate d-inline-block" rel="noopener noreferrer" target="_blank">{agent.crm_deal_id}</a>)
                        : (<input type="text" className="form-control" id="crm_deal_id" name="crm_deal_id" disabled={!isToggleAgentForm} value={agent.crm_deal_id} onChange={this.handleChange} placeholder="ID сделки в CRM" />)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_meet_date">Дата последней встречи</label>
                      <input type="text" className="form-control" id="last_meet_date" name="last_meet_date" disabled value={agent.last_meet_date} placeholder="Дата последней встречи в CRM" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="last_meet_date">Комментарий в CRM</label>
                      <input type="text" className="form-control" id="crm_comment" name="crm_comment" disabled value={agent.crm_comment} placeholder="Комментарий в CRM" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-lg-3">
                      <label htmlFor="showEarantBlock">Е-Гарант</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customSelectStyle()}
                        isDisabled={!isToggleAgentForm}
                        name="show_egarant_block"
                        isSearchable={false}
                        placeholder="Блок Е-Гарант"
                        id="showEarantBlock"
                        value={optionsEGarant.filter(({ value }) => value === agent.show_egarant_block)}
                        onChange={this.handleChangeSelect}
                        options={optionsEGarant}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="property_cost">Стоимость Проверки недвижимости</label>
                      <input disabled={!isToggleAgentForm} onChange={this.handleChange} type="number" min="150" className="form-control" id="property_cost" name="property_cost" value={agent.property_cost} placeholder="Стоимость Проверки недвижимости" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="parser_api_limit_counter">Кол-во запросов parser-api</label>
                      <input type="text" disabled className="form-control" id="parser_api_limit_counter" name="parser_api_limit" value={agent.parser_api_limit_counter} placeholder="Кол-во запросов" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="parser_api_limit">Лимит parser-api</label>
                      <input disabled={!isToggleAgentForm} onChange={this.handleChange} type="number" className="form-control" id="parser_api_limit" name="parser_api_limit" value={agent.parser_api_limit} placeholder="Лимит parser-api" />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="domain">Домен</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customSelectStyle()}
                        isDisabled={!isToggleAgentForm}
                        name="domain"
                        placeholder="Домен"
                        id="domain"
                        value={domains.filter(({ value }) => value === agent.domain)}
                        onChange={this.handleChangeSelect}
                        options={domains}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="field_of_activity">Сфера деятельности</label>
                      <input type="text" name="field_of_activity" id="field_of_activity" className="form-control" disabled={!isToggleAgentForm} value={agent.field_of_activity ? agent.field_of_activity : ''} onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="1c_id">ERP ID</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customSelectStyle()}
                        isDisabled={!isToggleAgentForm}
                        name="1c_id"
                        placeholder="Выберите карточку из ERP"
                        id="1c_id"
                        value={erpAgentsOptions.filter(({ value }) => value === agent['1c_id'])}
                        onChange={this.handleChangeSelect}
                        options={erpAgentsOptions}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="crm_user_id">Пользователь в CRM (для штатных)</label>
                      {!isToggleAgentForm
                        ? (
                          <a
                            href={`https://crm.infullbroker.ru/company/personal/user/${agent.crm_user_id}/`}
                            className="form-control disabled text-truncate d-inline-block"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {crmUser.length === 1 ? crmUser[0].label : ''}
                          </a>
                        )
                        : (
                          <Select
                            id="crm_user_id"
                            name="crm_user_id"
                            required={agent.remote_employee || agent.full_time_employee}
                            placeholder="Ссылка на пользователя"
                            classNamePrefix="react-select"
                            styles={customSelectStyle()}
                            isClearable
                            isDisabled={!isToggleAgentForm}
                            value={crmUsers.filter(({ value }) => value === agent.crm_user_id)}
                            onChange={this.handleChangeSelect}
                            options={crmUsers}
                          />
                        )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="description">Описание</label>
                      <AsyncCreatableSelect
                        classNamePrefix="react-select"
                        isDisabled={!isToggleAgentForm}
                        name="description"
                        placeholder="Описание"
                        isMulti
                        onChange={this.handleChangeDescription}
                        styles={customSelectStyle()}
                        formatCreateLabel={(userInnput) => `Добавить ${userInnput}`}
                        value={agent.description ? agent.description.split(',').map((value) => ({
                          label: value,
                          value,
                        })) : null}
                        options={descriptionOptions}
                        onFocus={descriptionOptions.length === 0 ? this.downloadOptions : () => {}}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-check custom-checkbox">
                      <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.show_balance} name="show_balance" className="form-check-input checkbox-styled" type="checkbox" id="show_balance" />
                      <label className="form-check-label" htmlFor="show_balance">
                        Раздел баланс и выплаты
                      </label>
                    </div>
                    <div className="form-check custom-checkbox">
                      <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.parser_api_limit_is_paid} name="parser_api_limit_is_paid" className="form-check-input checkbox-styled" type="checkbox" id="parserApiLimitIsPaid" />
                      <label className="form-check-label" htmlFor="parserApiLimitIsPaid">
                        Parser-api оплачен
                      </label>
                    </div>
                    {userRole === 'superadmin' ? (
                      <div className="form-check custom-checkbox">
                        <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.remote_employee} name="remote_employee" className="form-check-input checkbox-styled" type="checkbox" id="remoteEmployee" />
                        <label className="form-check-label" htmlFor="remoteEmployee">
                          Куратор агентов
                        </label>
                      </div>
                    ) : null}
                    {(userRole === 'superadmin' || userRole === 'admin') ? (
                      <>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.full_time_employee} name="full_time_employee" className="form-check-input checkbox-styled" type="checkbox" id="fullTimeEmployee" />
                          <label className="form-check-label" htmlFor="fullTimeEmployee">
                            Штатный сотрудник
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.sbi_agent} name="sbi_agent" className="form-check-input checkbox-styled" type="checkbox" id="sbiAgent" />
                          <label className="form-check-label" htmlFor="sbiAgent">
                            Агент СБИ
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.tech_agent} name="tech_agent" className="form-check-input checkbox-styled" type="checkbox" id="techAgent" />
                          <label className="form-check-label" htmlFor="techAgent">
                            Тех. агент
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.post_paid} name="post_paid" className="form-check-input checkbox-styled" type="checkbox" id="postPaid" />
                          <label className="form-check-label" htmlFor="postPaid">
                            Оплата услуг постфактум
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.allow_checkbox_payment_partner} name="allow_checkbox_payment_partner" className="form-check-input checkbox-styled" type="checkbox" id="allow_checkbox_payment_partner" />
                          <label className="form-check-label" htmlFor="allow_checkbox_payment_partner">
                            Разрешить агенту добавлять вручную агентов по партнерской программе (в статистике будут учитываться партнеры)
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.allow_agent_network} name="allow_agent_network" className="form-check-input checkbox-styled" type="checkbox" id="allow_agent_network" />
                          <label className="form-check-label" htmlFor="allow_agent_network">
                            Дать доступ к агентской сети своих субагентов
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.is_digital_broker} name="is_digital_broker" className="form-check-input checkbox-styled" type="checkbox" id="is_digital_broker" />
                          <label className="form-check-label" htmlFor="is_digital_broker">
                            Цифровой брокер
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input disabled={!isToggleAgentForm} onChange={this.handleChange} checked={agent.is_middle_office_user} name="is_middle_office_user" className="form-check-input checkbox-styled" type="checkbox" id="is_middle_office_user" />
                          <label className="form-check-label" htmlFor="is_middle_office_user">
                            Является сотрудником мидл-офиса
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {isToggleAgentForm ? ([
                <div key="4" className="form-row">
                  <div className="form-group col-md-9">
                    <button disabled={saving} type="submit" className="btn btn-success mr-2">Сохранить</button>
                    <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>Отмена</button>
                    {saving ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} /> : null}
                  </div>
                  {agent.status === 'ban' ? (
                    <div className="form-group col-md-2">
                      <button type="button" className="btn btn-danger" onClick={this.handleUnblockAgent}>Разблокировать</button>
                    </div>
                  ) : (
                    <div className="form-group col-md-2">
                      <button type="button" className="btn btn-danger" onClick={() => this.setShowModal(true)}>Заблокировать</button>
                    </div>
                  )}
                </div>,
              ]) : null}
              {(userRole === 'admin' || userRole === 'superadmin') ? (
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <input
                      onChange={this.handleChangePassword}
                      id="password"
                      placeholder="Введите новый пароль"
                      name="password"
                      className={classnames('form-control', { error: password.errorMessage })}
                      value={password.value}
                      disabled={saving}
                    />
                    {password.errorMessage && (
                      <div className="validation-error">
                        {password.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <button type="button" className="btn btn-danger" disabled={saving} onClick={this.handleSendPassword}>Сменить пароль</button>
                    {saving && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
                  </div>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </>
    );
  }
}

AgentInfo.contextType = ModalMessagesContext;

export default AgentInfo;
