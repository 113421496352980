import React, {
  useState, useEffect, useContext, useImperativeHandle, forwardRef,
} from 'react';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/fontawesome-free-solid';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

const colors = {
  redColor: 'rgb(255, 0, 0)',
  greenColor: 'rgb(0, 197, 60)',
};

function ZprIntegrationAnswers(props, ref) {
  const { hideCommission, copyDraft, formId } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [integrationAnswers, setIntegrationAnswers] = useState([]);
  const [calcErrors, setCalcErrors] = useState({
    notExist: false,
  });
  const [loadingInfo, setLoadingInfo] = useState(false);

  const copyForm = () => {
    copyDraft();
  };

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
  }));

  const loadIntegrationAnswers = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingInfo(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${formId}/integrationAnswers?noFilter=true`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error === 'not_exist') {
          setCalcErrors((prev) => ({
            ...prev,
            notExist: true,
          }));
        } else {
          setIntegrationAnswers([...response.filtered_by_priority]);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        setLoadingInfo(false);
      });
  };

  useEffect(() => {
    if (formId) {
      loadIntegrationAnswers();
    }
  }, [formId]);

  return (
    <>
      {loadingInfo
        ? <ReactLoading className="loading-circle ml-auto mr-auto mt-2" type="spin" height={70} width={70} />
        : (
          <>
            {!calcErrors.notExist ? (
              <>
                <h2>Предварительные данные, которые были получены в предыдущем расчете:</h2>
                {integrationAnswers.map((item) => (
                  <div key={item.id} className="company_item">
                    <div className="company_item__image_block">
                      <div className="company_item__image">
                        <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${item.logo}`} alt="" width="142" height="62" />
                        <div className="company_item__star">
                          <FontAwesomeIcon icon={faStar} className="fa-fw" />
                          <FontAwesomeIcon icon={faStar} className="fa-fw" />
                          <FontAwesomeIcon icon={faStar} className="fa-fw" />
                          <FontAwesomeIcon icon={faStar} className="fa-fw" />
                          <FontAwesomeIcon icon={faStar} className="fa-fw" />
                        </div>
                      </div>
                      <div className="company_item__match_info">
                        <span>Соответствие запросу </span>
                        <span className="main-color">100%</span>
                      </div>
                    </div>
                    <div className="company_item__content">
                      <h2><span>Страхование ипотеки{item.integration ? ` - ${item.integration}` : null}</span></h2>
                      <div className="company_item__content--body">
                        {item.price ? (
                          <>
                            <h3>Страховая премия: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.price)}</h3>
                            {hideCommission === 0 && (
                              <SimpleNotification>Предварительный размер КВ: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((item.commission * item.price) / 100)} = {item.commission}%.</SimpleNotification>
                            )}
                          </>
                        ) : (<div className="error_block">Отказ</div>)}
                        {item.integrations ? (
                          <div className="table-responsive mt-2">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Интеграция</th>
                                  <th>Статус интеграции</th>
                                  <th>Сумма СП / Ошибка</th>
                                  <th>Вх. КВ (заявленная)</th>
                                  <th>Вх. КВ (факт)</th>
                                  <th>Анализ КВ</th>
                                  <th>ID расчета</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(item.integrations).map(([key, value]) => (value.status ? (
                                  <tr key={key}>
                                    <td>{key === 'direct' ? 'Прямая интеграция' : key}</td>
                                    <td>Работает</td>
                                    <td>{value.errorMessage ? value.errorMessage : new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value.premium)}</td>
                                    <td>{value.polisOnlineCommission !== false ? `${value.polisOnlineCommission}%` : '-'}</td>
                                    <td>{value.innerCommission !== false ? `${value.innerCommission}%` : '-'}</td>
                                    <td>
                                      {value.polisOnlineCommission < value.innerCommission
                                      && value.polisOnlineCommission !== false
                                      && value.innerCommission !== false
                                      && <span style={{ color: colors.greenColor }}>Выше заявленной</span>}
                                      {value.polisOnlineCommission > value.innerCommission
                                      && value.polisOnlineCommission !== false
                                      && value.innerCommission !== false
                                      && <span style={{ color: colors.redColor }}>Ниже заявленной</span>}
                                      {value.polisOnlineCommission === value.innerCommission
                                      && value.polisOnlineCommission !== false
                                      && value.innerCommission !== false
                                      && <span>Соответствует заявленной</span>}
                                    </td>
                                    <td>{value.integrationRequestId}</td>
                                  </tr>
                                ) : (
                                  <tr key={key}>
                                    <td>{key === 'direct' ? 'Прямая интеграция' : key}</td>
                                    <td>Нет СК</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                  </tr>
                                )))}
                              </tbody>
                            </table>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : <h2>Расчет не производился</h2>}
          </>
        )}
    </>
  );
}

export default forwardRef(ZprIntegrationAnswers);
