import styled from 'styled-components';
import React from 'react';

const PointList = styled.ul`
  margin-top: 26px;
  margin-bottom: 3px;
  list-style: disc;
  padding-left: ${(props) => (props.listStyle || '14px')};
 `;

export default function DashboardPointList(props) {
  const {
    children, disabled, width, listStyle,
  } = props;

  return (
    <PointList disabled={disabled} width={width} listStyle={listStyle}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </PointList>
  );
}
