import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import CustomIcon from '../CustomIcon';

const ModalContainer = styled.div`
  overflow: auto;
  scrollbar-color: #737373 transparent;
  scrollbar-width: thin;
  padding: 11px 24px 18px;
  max-height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 0;
    min-height: calc(100vh - 64px);
  }
`;

const Menu = styled.div`
  position: relative;
  padding: 0 0 10px;
`;

const MenuItem = styled.div`
  margin: 0 -24px;

  @media (max-width: 767px) {
    margin: 0;
    border-bottom: 1px solid #f6f6f6;
  }

  a{
    display: flex;
    justify-content: space-between;
    align-items: center;    
    padding: 13px 24px;
    width: 100%;
    color: ${(props) => (props.color || '#fff')};
    background-color: transparent;
    transition: background-color .15s ease;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #3d3d3b;
      @media (max-width: 767px) {
        background-color: transparent;
      }
    }

    @media (max-width: 767px) {
      padding: 20px 16px 19px;
      color: #3d3d3b;
      font-size: 15px;
    }

    @media (min-width: 767px) {
        ${(props) => (props.lineAfter ? `
          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            content: "";
            width: 100%;
            height: 1px;
            background-color: #282826;
          }
          ` : '')}
    }
  }
`;

const LogOutButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-weight: 500;

  svg {
    margin: 0 0 0 8px;

    @media (max-width: 767px) {
      color: #ff4d00;
    }
  }
`;

const CuratorInfoWrapper = styled.div`
  padding: 24px 0 0;

  @media (max-width: 767px) {
    margin-top: auto;
    padding: 16px;
    background-color: #f6f6f6;
  }
`;

const CuratorInfoHeader = styled.div`
  margin: 0;
  color: #a3a3a3;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
`;

const CuratorInfoName = styled.div`
  margin: 5px 0 16px;
  font-weight: 600;
  color: #fff;

  @media (max-width: 767px) {
    color: #3d3d3b;
  }
`;

const CuratorContact = styled.a`
  display: flex;
  align-items: center;    
  margin: 0;
  color: #a3a3a3;
  transition: .15s ease;
  cursor: pointer;
  
  svg{
    margin: 0 12px 0 0;
  }

  span{
    position: relative;
  }
  
  span:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: transparent;
    transition: background-color .15s ease;

    @media (max-width: 767px) {
      background-color: transparent !important;
    }
  }

  @media (max-width: 767px) {
    color: #737373;
  }

  &:hover{
    span:after {
      background-color: #fff;
    }
  }
`;

const CustomNavLink = styled.a`
  display: none !important;

  @media (max-width: 767px) {
    display: flex !important;
  }
`;

const Count = styled.div`
  margin: 0 0 0 20px;
  width: 29px;
  height: 22px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 23px;
  background-color: #01e250;
  color: #fff;
  line-height: 1;
  display: flex;
`;

export default function UserProfilePopup(props) {
  const {
    userInfo, handleLogout, closeTippy, notificationsListLength, notificationsTippy, faq,
  } = props;

  const openNotifications = () => {
    closeTippy();
    notificationsTippy.current.click();
  };

  return (
    <ModalContainer className="tour_2_dt_step_2">
      <Menu>
        <MenuItem onClick={openNotifications}>
          <CustomNavLink>
            Уведомления
            <Count>{notificationsListLength}</Count>
          </CustomNavLink>
        </MenuItem>
        <MenuItem onClick={closeTippy}>
          <NavLink to="/profile">
            Профиль (ID: {userInfo.id})
          </NavLink>
        </MenuItem>
        {userInfo.curator_obj.full_time_employee === 1 && userInfo.show_balance === 1 ? (
          <MenuItem onClick={closeTippy}>
            <NavLink to="/balance">
              Балансы и выплаты
            </NavLink>
          </MenuItem>
        ) : null}
        <MenuItem onClick={closeTippy}>
          <NavLink to="/bonuses">
            Бонусы
          </NavLink>
        </MenuItem>
        <MenuItem onClick={closeTippy} lineAfter={faq ? 0 : 1}>
          <NavLink to="/news">
            Новости
          </NavLink>
        </MenuItem>
        {faq ? (
          <MenuItem lineAfter={1} onClick={closeTippy}>
            <a href={faq} target="_blank" rel="noreferrer">
              Инструкция
            </a>
          </MenuItem>
        ) : null}
        <MenuItem lineAfter={1} color="#ff4d00" onClick={handleLogout}>
          <NavLink to="">
            <LogOutButton>
              Выход
              <CustomIcon icon="logout" />
            </LogOutButton>
          </NavLink>
        </MenuItem>
      </Menu>
      <CuratorInfoWrapper>
        <CuratorInfoHeader>
          ваш куратор
        </CuratorInfoHeader>
        <CuratorInfoName>
          {userInfo.curator_obj.last_name} {userInfo.curator_obj.first_name} {userInfo.curator_obj.middle_name}
        </CuratorInfoName>
        <div>
          <CuratorContact href={`mailto:${userInfo.curator_obj.email}`}>
            <CustomIcon icon="email" width="32px" />
            <span>{userInfo.curator_obj.email}</span>
          </CuratorContact>
          <CuratorContact href={`tel:${userInfo.curator_obj.phone}`}>
            <CustomIcon icon="phone" width="32px" />
            <span>{userInfo.curator_obj.phone}</span>
          </CuratorContact>
        </div>
      </CuratorInfoWrapper>
    </ModalContainer>
  );
}
