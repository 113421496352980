import Slider from 'react-slick';
import React, { useEffect } from 'react';
import ReactSVG from 'react-svg';
import Slide from './Slide';
import telegramBanner from '../../../img/banners/telegram_banner.png';
import kaskoBanner from '../../../img/banners/kasko_banner.png';
import leasingBanner from '../../../img/banners/leasing_banner.png';
import mortgageBanner from '../../../img/banners/mortgage_request_banner.png';
import finsBanner from '../../../img/banners/fins_banner.png';
import arrowRight from '../../../img/arrow_right.svg';
import arrowLeft from '../../../img/arrow_left.svg';

const Dots = (dots) => (
  <div
    style={{
      position: 'absolute',
      bottom: '30px',
      height: '12px',
    }}
  >
    <ul
      style={{
        margin: '0px',
        padding: 0,
        height: '12px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {dots.map((item, index) => (
        <li
          className={item.props.className}
          key={index}
          style={{
            width: '32px',
            height: '4px',
            borderRadius: '22px',

          }}
        >
          <button
            style={{
              width: '32px',
              height: '4px',
              padding: '0px',
              borderRadius: '22px',
            }}
            onClick={(e) => item.props.children.props.onClick(e)}
          />
        </li>
      ))}
    </ul>
  </div>
);

function NextArrow(props) {
  const { onClick, bannersHideLength, bannersCount } = props;
  const sliderCount = bannersCount - bannersHideLength;
  return (
    <ReactSVG
      className="slick-slider--arrow slick-slider--arrow_next"
      onClick={onClick}
      src={arrowRight}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '22px',
        height: '22px',
        cursor: 'pointer',
        position: 'absolute',
        left: `calc(80.6px + 42*${sliderCount}px)`,
        bottom: '25px',
        zIndex: 1,
      }}
    />
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <ReactSVG
      className="slick-slider--arrow"
      onClick={onClick}
      src={arrowLeft}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '22px',
        height: '22px',
        cursor: 'pointer',
        position: 'absolute',
        left: '25.4px',
        bottom: '25px',
        zIndex: 1,
      }}
    />
  );
}

export default function NotificationSlider(props) {
  const {
    hideSlider, history, bannersHide, linkToTelegram, title,
  } = props;
  const bitrixForm = (w, d, u) => {
    const s = d.createElement('script');
    s.async = true;
    s.src = u + '?' + (Date.now() / 180000 | 0);
    const h = d.getElementsByTagName('script')[0];
    h.parentNode.insertBefore(s, h);
  };
  useEffect(() => {
    bitrixForm(window, document, 'https://crm.infullbroker.ru/upload/crm/form/loader_219_76t3k0.js');
  }, []);
  const banners = [
    {
      id: 0,
      alt: 'telegram_banner',
      img: telegramBanner,
      title: 'Подписывайтесь на наш Telegram канал!',
      body: `Актуальные новости рынка страхования без воды + бонусы от ${title}.`,
      button: {
        text: 'Присоединиться',
        path: linkToTelegram,
        target: '_blank',
      },
    },
    {
      id: 1,
      alt: 'kasko_banner',
      img: kaskoBanner,
      title: 'Каско в Polis.online',
      body: 'Заявки на КАСКО в мидл-офис!',
      button: {
        text: 'Сделать расчет КАСКО',
        path: '/kasko_request',
        target: '_self',
      },
    },
    {
      id: 2,
      alt: 'fins_banner',
      img: finsBanner,
      title: 'Пройди обучение в «Школе Финансиста» и заработай 5 000 ₽',
      body: '',
      button: {
        text: 'Записаться на обучение',
        path: '/finances',
        target: '_self',
      },
    },
    {
      id: 3,
      alt: 'leasing_banner',
      img: leasingBanner,
      title: 'Заработайте на лизинге до 100 000 р на одной сделке',
      body: 'Лизинг авто/спецтехники/оборудования/водного,воздушного, жд транспорта/недвижимости',
      button: {
        text: 'Отправить заявку',
        bitrix24Form: true,
      },
    },
    {
      id: 5,
      alt: 'mortgage_banner',
      img: mortgageBanner,
      title: 'Оформить ипотеку в Polis.online',
      body: '- 21 банк \n- до 12%',
      button: {
        text: 'Оформить заявку',
        path: 'https://ipoteka.polis.online/auth/',
        target: '_blank',
      },
    },
  ];
  return (
    <Slider
      draggable={false}
      autoplay
      dots
      infinite
      autoplaySpeed={5000}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      style={{
        height: '264px',
        border: '1px solid #E9E9E9',
        boxShadow: '0px 6px 12px rgba(143, 143, 143, 0.08)',
        borderRadius: '10px 0px 10px 10px',
        marginBottom: '10px',
      }}
      appendDots={(dots) => Dots(dots)}
      nextArrow={<NextArrow bannersHideLength={bannersHide ? bannersHide.filter((bannerHide) => banners.findIndex((banner) => bannerHide.value === banner.id) !== -1).length : 0} bannersCount={banners.length} />}
      prevArrow={<PrevArrow />}
    >
      {banners.map((banner) => (!bannersHide || bannersHide.findIndex((bannerHide) => bannerHide.value === banner.id) === -1 ? (
        <Slide
          key={banner.id}
          history={history}
          alt={banner.alt}
          img={banner.img}
          hideSlider={hideSlider}
          title={banner.title}
          body={banner.body}
          button={banner.button}
        />
      ) : null))}
    </Slider>
  );
}
