import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import classnames from 'classnames';
import Products from './Products/Products';
import PolicyContainer from './Products/PolicyContainer';
import Drafts from './Products/Drafts';
import Agent from './Agent/Agent';
import Reports from './Reports/Reports';
import Policies from './Products/Policies';
import Agents from './Agents/Agents';
import Sidebar from './Layout/Sidebar';
import Navbar from './Layout/Navbar';
import Profile from './Profile/Profile';
import withAuth from './Utils/withAuth';
import Admin from './Admin/Admin';
import Footer from './Layout/Footer';
import AboutUs from './Layout/AboutUs';
import News from './News/News';
import NewsDetail from './News/NewsDetail';
import Notifications from './Layout/Notifications';
import WidgetsList from './Widgets/WidgetsList';
import Widget from './Widgets/Widget';
import AdminWidget from './Admin/AdminWidget';
import RequestsList from './Widgets/RequestsList';
import Dkp from './Products/Dkp/Dkp';
import CovidForm from './Products/Covid/CovidForm';
import DkpForm from './Products/Dkp/DkpForm';
import ThemeForm from './Admin/ThemeForm';
import Balance from './Balance/Balance';
import { customFetch } from './Utils/Helpers';
import Dashboard from './Dashboard/Dashboard';
import AgentPoll from './Agent/AgentPoll';
import Bonuses from './Bonuses/Bonuses';
import PaymentRealizationsDetail from './Admin/PaymentRealizations/PaymentRealizationsDetail';
import InsuranceProgram from './InsuranceProgramFactory/InsuranceProgram';
import ModalInfoList from './Utils/ModalNotification/ModalInfoList';
import ScrollToTop from './Utils/ScrollToTop';
import CheckOsago from './Products/CheckOsago/CheckOsago';
import Mortgage from './Products/Mortgage/Mortgage';
import Universal from './Products/Universal/Universal';
import Zpr from './Products/Zpr/Zpr';
import ServiceTour from './InterfaceTours/ServiceTour';
import Finances from './Products/Finances/Finances';
import KaskoRequest from './Products/KaskoRequest/KaskoRequest';
import NsRequest from './Products/NsRequest/NsRequest';
import InsuranceRequest from './Products/InsuranceRequest/InsuranceRequest';
import Ifl from './Products/Ifl/Ifl';
import FaqKnowledge from './Faq/FaqKnowledge';
import FaqAnswer from './Faq/FaqAnswer';
import FaqEditAdd from './Faq/FaqEditAdd';
import CargoInsurance from './Products/CargoInsurance/CargoInsurance';
import ReferralLink from './Bonuses/ReferralLink';
import CheckProperty from './Products/CheckPropeprty/CheckProperty';
import InsurexWidget from './Products/InsurexWidget/InsurexWidget';
//import useSocket from './Utils/CustomHooks/UseSocket';
import AuthService from './Utils/AuthService';
import RegisterSelfEmployed from './Layout/RegisterSelfEmployed';

const ContentContainer = styled.div`
    background-color: #f6f6f6;
    padding-top: 88px;
    transition: padding-left .15s ease;

    @media (min-width: 769px) {
        padding-left: 75px;
        &.full-sidebar{
            padding-left: 283px
        }
    }
    
    @media (max-width: 620px) {
        padding-top: 64px;
    }
`;

const Auth = new AuthService();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {
        confirmed: 'no_need',
        kbm_cost: 0,
        remote_employee: 0,
        created_at: null,
        egarant_cost: 0,
        parser_api_limit_is_paid: false,
        id: null,
        role: null,
        curator_obj: {},
        allow_checkbox_payment_partner: false,
        allow_agent_network: false,
        bonus_sum: 0,
        ref_quota: 0,
        balance: 0,
        currentProfit: 0,
        full_time_employee: 0,
        show_balance: 0,
        policiesCount: 0,
        premium: 0,
        hide_commission: 0,
        show_egarant_block: 0,
        unread_news_count: 0,
        post_paid: 0,
      },
      faq: '',
      isFramed: 0,
      sidebarActive: false,
      sidebarIsFull: false,
      modalInfoMessages: [],
      messageIdGen: 1,
    };
    this.serviceTour = React.createRef();
  }

  componentDidMount() {
    let isFramed = false;
    try {
      isFramed = window !== window.top || document !== top.document || self.location !== top.location;
    } catch (e) {
      isFramed = 1;
    }
    if (process.env.FORCE_WIDGET) isFramed = 1;
    this.setState({ isFramed });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    // const { createSocketConnection, listenChanel } = useSocket();
    // createSocketConnection(lsToken);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq_file`, {
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((faq) => {
        this.setState({ faq: faq.url });
      });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/info`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((userInfo) => {
        if (userInfo.message === 'Token Signature could not be verified.') {
          localStorage.removeItem('id_token');
          window.location.href = '/login';
        } else {
          this.setState({ userInfo }, () => {
            const domain = window.location.host.split('.')[0];
            if (userInfo.domain !== domain && domain !== 'localhost:3000' && userInfo.role !== 'admin' && userInfo.role !== 'superadmin') {
              localStorage.removeItem('id_token');
              window.location.href = `https://${userInfo.domain}.polis.online${window.location.pathname}`;
            }
            // listenChanel(`news.${userInfo.id}`, 'newItem');
          });
        }
      });

    if (window.innerWidth > 768) {
      let sidebarIsFullFlag = localStorage.getItem('sidebarIsFull');
      sidebarIsFullFlag = sidebarIsFullFlag === null ? 'false' : sidebarIsFullFlag;
      sidebarIsFullFlag = sidebarIsFullFlag !== 'false';
      this.setState({ sidebarIsFull: sidebarIsFullFlag });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidebarIsFull } = this.state;
    if (prevState.sidebarIsFull !== sidebarIsFull) {
      localStorage.setItem('sidebarIsFull', sidebarIsFull);
    }
  }

  updateUser = (value) => {
    this.setState({ userInfo: value });
  };

  updateUnreadNewsCount = () => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo,
        unread_news_count: prevState.userInfo.unread_news_count - 1,
      },
    }));
  };

  toggleSidebar = (sidebarActiveFlag) => {
    const { sidebarIsFull, sidebarActive } = this.state;
    if (!sidebarIsFull && sidebarActiveFlag !== sidebarActive) {
      this.setState({ sidebarActive: sidebarActiveFlag });
    }
  };

  setSidebarFull = (sidebarIsFull) => { this.setState({ sidebarIsFull }); };

  showModalInfo = (message) => {
    this.setState((prevState) => ({
      modalInfoMessages: prevState.modalInfoMessages.concat({ text: message, timeout: 5000, id: prevState.messageIdGen + 1 }),
      messageIdGen: prevState.messageIdGen + 1,
    }));
  };

  flushMessages = (messages) => {
    this.setState({ modalInfoMessages: messages });
  };

  setTour = (tourName) => {
    this.serviceTour.current.setTour(tourName);
  };

  getTourList = () => this.serviceTour.current.getTourList();

  setDataLoadFunctions = (loadDemoData, loadRealData) => {
    this.serviceTour.current.setDataLoadFunctions(loadDemoData, loadRealData);
  };

  handleLogout = (e) => {
    e.preventDefault();
    const { history } = this.props;
    Auth.logout();
    history.replace('/login');
  };

  render() {
    const {
      userInfo, sidebarActive, modalInfoMessages, faq, isFramed, sidebarIsFull,
    } = this.state;
    const { theme, history } = this.props;

    return (
      <div className="wrapper">
        <ServiceTour
          theme={theme}
          ref={this.serviceTour}
          history={history}
          updateUser={this.updateUser}
          userInfo={userInfo}
          toggleSidebar={this.toggleSidebar}
          sidebarActive={sidebarActive}
        />
        <Navbar
          faq={faq}
          toggleSidebar={this.toggleSidebar}
          isFramed={isFramed}
          history={history}
          theme={theme}
          userInfo={userInfo}
          sidebarIsFull={sidebarIsFull}
          sidebarActive={sidebarActive}
          setSidebarFull={this.setSidebarFull}
          handleLogout={this.handleLogout}
        />
        <div className="main d-flex">
          <Sidebar
            faq={faq}
            sidebarActive={sidebarActive}
            userInfo={userInfo}
            employee={userInfo.full_time_employee}
            updateUser={this.updateUser}
            getTourList={this.getTourList}
            setTour={this.setTour}
            theme={theme}
            toggleSidebar={this.toggleSidebar}
            sidebarIsFull={sidebarIsFull}
            setSidebarFull={this.setSidebarFull}
            handleLogout={this.handleLogout}
          />
          <ContentContainer id="content" className={classnames('container-fluid d-flex flex-fill flex-column', { 'full-sidebar': sidebarIsFull })}>
            <Route component={ScrollToTop} />
            <Notifications title={theme.title} linkToTelegram={theme.link_to_telegram} bannersHide={theme.banners_hide} history={history} userInfo={userInfo} theme={theme} />
            <div className="flex-fill">
              <ModalInfoList messages={modalInfoMessages} flushMessages={this.flushMessages} />
              <Route exact path="/register_self_employed" render={(props) => <RegisterSelfEmployed {...props} />} />
              <Route path="/products/" render={(props) => <Products domain={theme.domain} employee={userInfo.full_time_employee} setTour={this.setTour} userRole={userInfo.role} userId={userInfo.id} {...props} />} />
              <Route exact path="/news" render={(props) => <News userInfo={userInfo} {...props} />} />
              <Route exact path="/news/:id" render={(props) => <NewsDetail updateUnreadNewsCount={this.updateUnreadNewsCount} userRole={userInfo.role} {...props} />} />
              <Route exact path="/covid" render={(props) => <CovidForm employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} {...props} />} />
              <Route exact path="/covid/:id" render={(props) => <CovidForm employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} {...props} />} />
              <Route exact path="/osago" render={(props) => <PolicyContainer domain={theme.domain} remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} policyType="osago" userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
              <Route exact path="/osago/:id" render={(props) => <PolicyContainer domain={theme.domain} remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} policyType="osago" userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
              <Route exact path="/accident" render={(props) => <PolicyContainer domain={theme.domain} employee={userInfo.full_time_employee} policyType="accident" userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
              <Route exact path="/accident/:id" render={(props) => <PolicyContainer domain={theme.domain} employee={userInfo.full_time_employee} policyType="accident" userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
              {userInfo.full_time_employee ? (
                <>
                  <Route exact path="/kasko" render={(props) => <PolicyContainer domain={theme.domain} employee={userInfo.full_time_employee} policyType="kasko" userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                  <Route exact path="/kasko/:id" render={(props) => <PolicyContainer domain={theme.domain} employee={userInfo.full_time_employee} policyType="kasko" userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} timestamp={new Date().toString()} {...props} />} />
                </>
              ) : null}
              {/* <Route exact path="/dmsStudent" render={(props) => <DmsStudent employee={userInfo.full_time_employee} userRole={userInfo.role} hideCommission={userInfo.hide_commission} timestamp={new Date().toString()} {...props} />} />
              <Route exact path="/dmsStudent/:id" render={(props) => <DmsStudent employee={userInfo.full_time_employee} userRole={userInfo.role} hideCommission={userInfo.hide_commission} timestamp={new Date().toString()} {...props} />} /> */}
              <Route exact path="/mortgage" render={(props) => <Mortgage remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/mortgage/:id" render={(props) => <Mortgage remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />

              <Route exact path="/zpr" render={(props) => <Zpr remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/zpr/:id" render={(props) => <Zpr remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />

              <Route exact path="/universal" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/universal/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/cargoInsurance" render={(props) => <CargoInsurance employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/cargoInsurance/:id" render={(props) => <CargoInsurance employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />

              <Route exact path="/dago" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/dago/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/vzr" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/vzr/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/dmsStudent" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/dmsStudent/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/ns" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/ns/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/telemedicine" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/telemedicine/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/gruz" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/gruz/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/infullLizing" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/infullLizing/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/infullBroker" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/infullBroker/:id" render={(props) => <Universal employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />

              <Route exact path="/ifl" render={(props) => <Ifl employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route exact path="/ifl/:id" render={(props) => <Ifl employee={userInfo.full_time_employee} userRole={userInfo.role} userInfo={userInfo} hideCommission={userInfo.hide_commission} setTour={this.setTour} {...props} />} />
              <Route path="/drafts" render={(props) => <Drafts remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} userRole={userInfo.role} {...props} />} />
              <Route path="/reports" render={(props) => <Reports remoteEmployee={userInfo.remote_employee} employee={userInfo.full_time_employee} userRole={userInfo.role} userId={userInfo.id} {...props} />} />
              <Route path="/agents" render={(props) => <Agents userRole={userInfo.role} userInfo={userInfo} {...props} />} />
              {(theme.domain !== 'samoletplus' || userInfo.id === 8270) ? (
                <Route path="/bonuses" render={(props) => <Bonuses setTour={this.setTour} userInfo={userInfo} updateUser={this.updateUser} {...props} history={history} />} />
              ) : null}
              <Route path="/referral" render={(props) => <ReferralLink setTour={this.setTour} userInfo={userInfo} {...props} history={history} />} />
              <Route path="/policies" render={(props) => <Policies customField={theme.custom_field ? theme.custom_field.name : null} domain={theme.domain} remoteEmployee={userInfo.remote_employee} userId={userInfo.id} employee={userInfo.full_time_employee} userRole={userInfo.role} hideCommission={userInfo.hide_commission} {...props} />} />
              {userInfo.role === 'superadmin' ? (
                <>
                  <Route exact path="/admin/widgets/:id" render={(props) => <AdminWidget userId={userInfo.id} {...props} />} />
                  <Route exact path="/paymentRealizations/:id" render={(props) => <PaymentRealizationsDetail {...props} history={history} />} />
                </>
              ) : null}
              {(userInfo.role === 'superadmin' || userInfo.role === 'admin') ? (
                <>
                  <Route exact path="/admin" render={(props) => <Admin userRole={userInfo.role} {...props} />} />
                  <Route exact path="/themes/:id" render={(props) => <ThemeForm {...props} />} />
                </>
              ) : null}
              <Route path="/agent/:id" render={(props) => <Agent customField={theme.custom_field ? theme.custom_field.name : null} domain={theme.domain} employee={userInfo.full_time_employee} hideCommission={userInfo.hide_commission} userInfo={userInfo} {...props} />} />
              <Route path="/profile" render={(props) => <Profile history={history} userInfo={userInfo} updateUser={this.updateUser} {...props} />} />
              <Route exact path="/dkp" render={(props) => <Dkp userInfo={userInfo} {...props} />} />
              <Route exact path="/dkp/:id" render={(props) => <DkpForm userInfo={userInfo} {...props} />} />
              <Route path="/about_us" component={AboutUs} />
              <Route exact path="/faq_knowledge" render={(props) => <FaqKnowledge agent={userInfo} userInfo={userInfo} userRole={userInfo.role} history={history} {...props} />} />
              <Route exact path="/faq_knowledge/:id" render={(props) => <FaqKnowledge agent={userInfo} userInfo={userInfo} userRole={userInfo.role} history={history} {...props} />} />
              <Route exact path="/faq_answer/add" render={(props) => <FaqEditAdd userRole={userInfo.role} history={history} {...props} />} />
              <Route exact path="/faq_answer/:id([0-9]+)" render={(props) => <FaqAnswer userRole={userInfo.role} history={history} {...props} />} />
              <Route path="/faq_answer/:id/edit" render={(props) => <FaqEditAdd userRole={userInfo.role} history={history} {...props} />} />
              {userInfo.show_balance === 1 ? (
                <Route path="/balance" render={(props) => <Balance {...props} userRole={userInfo.role} userInfo={userInfo} />} />
              ) : null}
              <Route exact path="/finances" render={(props) => <Finances userId={userInfo.id} {...props} />} />
              <Route exact path="/insurance_request" render={(props) => <InsuranceRequest domain={theme.domain} userId={userInfo.id} {...props} />} />
              <Route exact path="/ns_request" render={(props) => <NsRequest userId={userInfo.id} {...props} />} />
              <Route exact path="/check_property" render={(props) => <CheckProperty setTour={this.setTour} userInfo={userInfo} updateUser={this.updateUser} history={history} userId={userInfo.id} {...props} />} />
              <Route exact path="/check_property/:id" render={(props) => <CheckProperty setTour={this.setTour} userInfo={userInfo} updateUser={this.updateUser} history={history} userId={userInfo.id} {...props} />} />
              <Route exact path="/insurex_widget" render={(props) => <InsurexWidget agent={userInfo} {...props} />} />
              <Route exact path="/kasko_request" render={(props) => <KaskoRequest history={history} userId={userInfo.id} {...props} />} />
              <Route exact path="/widgets/" render={(props) => <WidgetsList employee={userInfo.full_time_employee} userRole={userInfo.role} {...props} setTour={this.setTour} />} />
              <Route exact path="/widgets/:id" render={(props) => <Widget userId={userInfo.id} userRole={userInfo.role} {...props} setTour={this.setTour} setDataLoadFunctions={this.setDataLoadFunctions} />} />
              <Route exact path="/requests-list" render={(props) => <RequestsList userRole={userInfo.role} employee={userInfo.full_time_employee} {...props} />} />
              <Route exact path="/dashboard" render={(props) => <Dashboard userRole={userInfo.role} setTour={this.setTour} {...props} />} />
              {(userInfo.role === 'admin' || userInfo.role === 'superadmin' || (userInfo.curator_obj.full_time_employee === 1 && userInfo.full_time_employee === 0)) ? (
                <Route exact path="/agent-poll" render={(props) => <AgentPoll userInfo={userInfo} history={history} {...props} />} />
              ) : null}
              {(userInfo.role === 'admin' || userInfo.role === 'superadmin') ? (
                <Route exact path="/insurance-program/:id" render={(props) => <InsuranceProgram showModalInfo={this.showModalInfo} userInfo={userInfo} history={history} {...props} />} />
              ) : null}
              <Route exact path="/check-osago" render={(props) => <CheckOsago parserApiIsPaid={userInfo.parser_api_limit_is_paid} employee={userInfo.full_time_employee} {...props} />} />
            </div>
            <Footer history={history} theme={theme} />
          </ContentContainer>
        </div>
      </div>
    );
  }
}
export default withAuth(App);
