import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Tippy from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import Icon from './CustomIcons';
import LinkBreadcrumbs from '../../Layout/LinkBreadcrumbs';

function PolicyInfo(props) {
  const {
    product,
    policy,
    policyInfo,
    userInfo,
    copyForm,
    checkPayment,
    setShowAcceptModalForm,
    checkAgreement,
    makeRequestForSupport,
    getApiLogs,
    onOpenModalSure,
    goToAgent,
    goToWidget,
    goToPolicy,
    convertPolicyToDkp,
    employee,
    history,
  } = props;

  const [dropDown, setDropDown] = useState('');

  const typeDeclarationMapper = {
    'middle-office': 'Мидл-офис',
    'digital-broker': 'Цифровой брокер',
    curator: 'Куратор СК',
    direct: 'Доступ B2B СК',
    widget: 'Виджет',
    lead: 'Передача лидов',
  };

  const productH2Mapper = {
    mortgage: 'Оформите полис страхования ипотеки',
    osago: 'Оформите полис ОСАГО',
    kasko: 'Оформите полис КАСКО',
    greenCard: 'Рассчитайте стоимость полиса',
    dmsStudent: 'Оформите полис «ДМС для студентов»',
    covid: 'Оформите полис Коронавирус.НЕТ',
    cargoInsurance: 'Оформите полис страхования грузов',
    ifl: 'Оформите полис страхования имущества',
    zpr: 'Оформите полис защиты от потери работы',
    medicineWithoutBorders: 'Оформите полис медицина без границ+',
    multipolis: 'Оформите полис страхования Мультиполис',
    universal: 'Универсальная форма страхования',
  };

  const statusInWork = ['pending', 'processing', 'agreement', 'calculated', 'ordered', 'need_help'];

  const statusComplete = ['complete', 'canceled'];

  const toPoliciesList = (event) => {
    event.preventDefault();
    if (statusComplete.includes(policyInfo.status)) {
      history.push('/policies');
    } else {
      history.push('/drafts');
    }
  };

  const RequestSupportAndApiLogsBlock = (
    <>
      {makeRequestForSupport ? (
        <li><a href="" onClick={(e) => { setDropDown(''); makeRequestForSupport(e); }}>Сформировать запрос в поддержку СК</a></li>
      ) : null}
      {getApiLogs ? (
        <li><a href="" onClick={(e) => { setDropDown(''); getApiLogs(e); }}>Скачать лог</a></li>
      ) : null}
    </>
  );

  return (
    <>
      {(policyInfo.status === 'not_exist' || !policyInfo.status) ? (
        <>
          <h1 className="page_title d-inline-block mr-3">Оформить полис</h1>
          <h2>{productH2Mapper.product}</h2>
        </>
      ) : (
        <>
          <h1 className="page_title d-inline-block mr-3">
            {(statusInWork.includes(policyInfo.status)) ? 'Оформить полис' : null}
            {statusComplete.includes(policyInfo.status)
              ? (['osago', 'kasko'].includes(product) ? `Полис ${policy.policy_type.name}` : `Полис страхования «${policyInfo.objectInsurance}»`)
              : null}
          </h1>
          <div className="page_header__control page_header__control-custom mb-2 mb-md-0">
            <div className="dropdown_absolute">
              <div className={`dropdown_block js-dropdown_block mr-1 ${dropDown}`}>
                <OutsideClickHandler
                  onOutsideClick={() => setDropDown('')}
                >
                  <div onClick={() => setDropDown('opened')} className="dropdown_block__target">Действие с {statusInWork.includes(policyInfo.status) ? 'черновиком' : 'полисом'}</div>
                  <ul className="dropdown_block__list js-dropdown_block__list">
                    <li><a href="#" onClick={(e) => { setDropDown(''); copyForm(e); }}>{statusComplete.includes(policyInfo.status) ? 'Пролонгировать' : 'Скопировать'}</a></li>
                    {['osago', 'kasko'].includes(product) && convertPolicyToDkp ? (
                      <li><a href="#" onClick={(e) => { setDropDown(''); convertPolicyToDkp(e); }}>Создать ДКП</a></li>
                    ) : null}
                    {policyInfo.status === 'processing' && checkPayment
                      ? (
                        <Tippy
                          content="Нажимаете, ждете 5 минут, пьете кофе, обновляете страницу и происходит магия. Если не помогает - пишите в поддержку - ссылка на бота https://t.me/b2bPolisonlineBot"
                          className="tippy-custom"
                          arrow={false}
                          offset={[0, 5]}
                        >
                          <li><a href="" onClick={(e) => { setDropDown(''); checkPayment(e); }}>Запросить полис у страховой</a></li>
                        </Tippy>
                      )
                      : null}
                    {(policyInfo.status === 'processing' && userInfo?.full_time_employee === 1 && (userInfo?.role === 'admin' || userInfo?.role === 'superadmin' || userInfo?.id === 401) && setShowAcceptModalForm)
                      ? (
                        <li><a href="#" onClick={() => setShowAcceptModalForm(true)}>Перевести полис в продажи</a></li>
                      ) : null}
                    {(policyInfo.status === 'agreement' && userInfo?.full_time_employee === 1 && (userInfo?.role === 'admin' || userInfo?.role === 'superadmin' || userInfo?.id === 401) && checkAgreement)
                      ? (
                        <li><a href="#" onClick={checkAgreement}>Полис согласован</a></li>
                      ) : null}
                    {policyInfo.linkPdf && policyInfo.linkPdf.map((link, i) => (<li key={link}><a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${link}`} download="policy.pdf">Скачать PDF {policyInfo.linkPdf.length > 1 ? `№${i + 1}` : null}</a></li>))}
                    {policyInfo.linkArchive ? <li><a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${policyInfo.linkArchive}`} download="policy.zip">Скачать архив</a></li> : null}
                    {policyInfo.receipt ? <li><a rel="noopener noreferrer" target="_blank" href={policyInfo.receipt}>Скачать чек</a></li> : null}
                    {(policyInfo.status === 'complete' && userInfo?.full_time_employee) ? (<li><a href="" onClick={(e) => { setDropDown(''); onOpenModalSure(e, 'add'); }}>{policyInfo.erp_status ? 'Обновить' : 'Добавить'} в ERP</a></li>) : null}
                    {(policyInfo.status === 'complete' && (userInfo?.role === 'admin' || userInfo?.role === 'superadmin')) ? (<li><a href="" onClick={(e) => { setDropDown(''); onOpenModalSure(e, 'cancel'); }}>Аннулировать</a></li>) : null}
                    {(policyInfo.status === 'complete' && (userInfo?.role === 'admin' || userInfo?.role === 'superadmin')) ? (<li><a href="" onClick={(e) => { setDropDown(''); onOpenModalSure(e, 'nullify'); }}>Обнулить КВ</a></li>) : null}
                    {(policyInfo.status === 'complete' && userInfo?.role === 'superadmin') ? (<li><a href="" onClick={(e) => { setDropDown(''); onOpenModalSure(e, 'changeCost'); }}>Изменить СП</a></li>) : null}
                    {userInfo?.full_time_employee ? (
                      RequestSupportAndApiLogsBlock
                    ) : null}
                    {userInfo?.role === 'superadmin' ? (<li><a href="" onClick={(e) => { setDropDown(''); onOpenModalSure(e, 'changeAgent'); }}>Сменить агента</a></li>) : null}
                  </ul>
                </OutsideClickHandler>
              </div>
              {policyInfo.linkPdf && statusComplete.includes(policyInfo.status) ? (
                policyInfo.linkPdf.map((link) => <a key={link} aria-label="Скачать форму" title="Скачать форму" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${link}`} className="btn btn-outline mr-1"><i className="icon icon_print" /></a>)
              ) : null}
              {!policyInfo.linkPdf && statusComplete.includes(policyInfo.status) ? (
                <a href="" className="btn btn-outline disabled" aria-label="Печатная форма отсутствует"><i className="icon icon_print" /></a>
              ) : null}
            </div>
          </div>
          <div>
            <LinkBreadcrumbs onClick={(e) => toPoliciesList(e)}>К списку {statusComplete.includes(policyInfo.status) ? 'полисов' : 'черновиков'}</LinkBreadcrumbs>
          </div>
          <div className="policy_info">
            {statusInWork.includes(policyInfo.status) ? (
              <div className="mb-0">Черновик №{policy.id} от {policyInfo.createdDate}</div>
            ) : null}
            <div>Статус: {policyInfo.policyStatus}</div>
            {userInfo.full_time_employee === 1 && policyInfo.status === 'canceled' && policy.reason_for_cancel ? <div>Причина аннулирования - {policy.reason_for_cancel.label}</div> : null}
            {policyInfo.widgetId
              ? <div>{statusComplete.includes(policyInfo.status) ? 'Оформлен' : 'Создан'} в виджете <a className="dotted_link" href={`/widgets/${policyInfo.widgetId}`} onClick={goToWidget}>№{policyInfo.widgetId}</a></div>
              : null}
            <div>Автор: <a className="dotted_link" onClick={(e) => goToAgent(e, policyInfo.agent_id)}>{policyInfo.agentFIO}</a>
              <Tippy
                placement="bottom-start"
                interactive
                trigger="click"
                className="page-popup page-popup--agents-list"
                arrow={false}
                offset={[0, 12]}
                content={policyInfo.chain
                  ? (
                    <div className="page-popup__content">
                      <button onClick={hideAll} className="btn btn--circle-btn page-popup__close" type="button">
                        <svg width="30" height="30" aria-hidden="true">
                          <Icon type="round-cross" />
                        </svg>
                      </button>
                      <div className="popup-agents">
                        <ul className="popup-agents__list">
                          {policyInfo.chain.map((element, i, { length }) => (
                            <>
                              {i < length && (policyInfo.chain[i].first_level || (policyInfo.chain[0].first_level && i === 0)) && policy.bonus_obj && policy.partner ? (
                                <li key={policy.partner.id} className={classnames('popup-agents__item is-partner')}>
                                  <div className="popup-agents__icon">
                                    <svg width="14" height="14" aria-hidden="true">
                                      <Icon type="icon-agent" />
                                    </svg>
                                  </div>
                                  <div onClick={(e) => goToAgent(e, policy.partner.id)} className="popup-agents__name-wrapper">
                                    <span className="popup-agents__name">
                                      {policy.partner.name}
                                      {statusComplete.includes(policyInfo.status)
                                        ? (policy.partner.commission !== null ? ` - ${(policy.partner.commission * 100 / policyInfo.cost).toFixed(2)}% (${new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(policy.partner.commission)})` : null)
                                        : null}
                                    </span>
                                    <span className="popup-agents__status">Партнер</span>
                                  </div>
                                </li>
                              ) : null}
                              <li key={element.id} className={classnames('popup-agents__item', { 'is-current': i + 1 === length })}>
                                <div className="popup-agents__icon">
                                  <svg width="14" height="14" aria-hidden="true">
                                    <Icon type="icon-agent" />
                                  </svg>
                                </div>
                                <div onClick={(e) => goToAgent(e, element.id)} className="popup-agents__name-wrapper">
                                  <span className="popup-agents__name">
                                    {element.name}
                                    {statusComplete.includes(policyInfo.status)
                                      ? (element.commission !== null ? ` - ${element.commission}%` : null)
                                      : null}
                                  </span>
                                  {element.first_level ? <span className="popup-agents__status">Агент первого уровня</span> : null}
                                </div>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : null}
              >
                <div className="d-inline-block ml-1">
                  <div className="is-select btn--circle-btn btn-circle-white">
                    <svg width="12" height="12" aria-hidden="true">
                      <Icon type="icon-agent-list" />
                    </svg>
                  </div>
                </div>
              </Tippy>
            </div>
            {policyInfo.updatedDate ? <div>Дата последнего изменения: {policyInfo.updatedDate}</div> : null}
            {policyInfo.prolongation_parent_id ? <div>Создан на основании: <a className="dotted_link" href={`/${product}/${policyInfo.prolongation_parent_id}`} onClick={goToPolicy}>{policyInfo.prolongation_parent_id}</a></div> : null}
            {policyInfo.company ? <div>{policyInfo.company}{policyInfo.integration && (userInfo?.full_time_employee === 1 || userInfo?.role === 'admin' || userInfo?.role === 'superadmin') ? ` - ${policyInfo.integration}` : null}</div> : null}
            {policy.custom_contract && !policy.custom_contract.is_polis_online_contract ? <div>Оформлен через личный договор</div> : null}
            {employee && statusComplete.includes(policyInfo.status) ? <div>{policyInfo.erp_status ? `Полис загружен в ERP ${policy.erp_id || ''}` : 'Полис отсутствует в ERP'}</div> : null}
            {employee ? <div>{policyInfo.bitrix24_lead_id ? <a className="dotted_link" rel="noreferrer" target="_blank" href={`https://crm.infullbroker.ru/crm/lead/details/${policyInfo.bitrix24_lead_id}/`}>https://crm.infullbroker.ru/crm/lead/details/{policyInfo.bitrix24_lead_id}/</a> : null}</div> : null}
            {policyInfo.insuranceId && policyInfo.orderDate && (policyInfo.status === 'complete' || policyInfo.status === 'canceling') ? <div>№{policyInfo.insuranceId} от {policyInfo.orderDate}</div> : null}
            {employee && policy.bonus_obj ? <div>Партнер: <a className="dotted_link" onClick={(e) => goToAgent(e, policy.partner.id)}>{policy.partner.name}</a></div> : null}
            {employee && policy.bonus_obj ? (
              <div>Размер вознаграждения партнера: {new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
              }).format(policy.partner.commission)}({((policy.partner.commission * 100) / policyInfo.cost).toFixed(2)}%)
              </div>
            ) : null}
            {product !== 'cargoInsurance' && policyInfo.cost && statusComplete.includes(policyInfo.status) ? (
              <div>Сумма страховой премии: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(policyInfo.cost)}</div>
            ) : null}
            {product === 'cargoInsurance' && policyInfo.cost && statusComplete.includes(policyInfo.status) ? (
              <div>Сумма страховой премии: {policyInfo.cost} {policy.form_data.valute.label}</div>
            ) : null}
            {policyInfo.status === 'complete' && policyInfo.commission ? (
              product !== 'cargoInsurance' ? (
                <div>
                  Размер вознаграждения: {new Intl.NumberFormat('ru-RU', {
                  style: 'currency',
                  currency: 'RUB',
                }).format(policyInfo.commission)}({((policyInfo.commission * 100) / policyInfo.cost).toFixed(2)}%)
                </div>
              ) : (
                <div>
                  Размер вознаграждения: {policyInfo.commission} {policy.form_data.valute.label} ({((policyInfo.commission * 100) / policyInfo.cost).toFixed(2)}%)
                  {policyInfo?.commissionInRuble ? (
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={<div style={{ maxWidth: 500 }}>Ваша сумма вознаграждения по курсу ЦБ составит: <b>{policyInfo.commissionInRuble} рублей</b></div>}
                      hideOnClick={false}
                    >
                      <div className="d-inline-block">
                        <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                      </div>
                    </Tippy>
                  ) : null}
                </div>
              )
            ) : null}
            {employee && policyInfo.status === 'complete' ? <div>Доход компании: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(policy.companyProfit)}</div> : null}
            {policyInfo.typeDeclaration ? <div>Тип оформления: {typeDeclarationMapper[policyInfo.typeDeclaration]}</div> : null}
            {policyInfo.typeDeclaration === 'middle-office' ? <div>Агент мидл-офиса оформил: <a className="dotted_link" onClick={(e) => goToAgent(e, policyInfo.middleOfficeAgent.id)}>{policyInfo.middleOfficeAgent.last_name} {policyInfo.middleOfficeAgent.first_name} {policyInfo.middleOfficeAgent.middle_name}</a></div> : null}
          </div>
        </>
      )}
    </>
  );
}

export default PolicyInfo;
