import React from 'react';
import styled from 'styled-components';
import NavbarPopupLink from '../ NavbarPopupLink';
import CustomIcon from '../CustomIcon';
import LoadingSpinner from '../LoadingSpinner';

const ModalContainer = styled.div`
    overflow: auto;
    scrollbar-color: #737373 transparent;
    scrollbar-width: thin;
    padding: 11px 24px 18px;
    max-height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0;
`;

const TopContainer = styled.div`
  padding: 0 0 15px;
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    left: -24px;
    right: -24px;
    content: "";
    width: calc(100% + 48px);
    height: 1px;
    background-color: #2f2f2d;
  }
`;

const TopHeader = styled.div`
  margin: 0;
  color: #a3a3a3;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
`;

const TopInfo = styled.div`
  margin: 6px 0 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #fff;
`;

const InfoList = styled.ul`
  margin: 0;
  list-style: none;
  position: relative;
  padding: 16px 0;
`;

const InfoItem = styled.li`
  margin: 0 0 16px;
`;

const InfoItemHeader = styled.div`
  margin: 0;
  color: #a3a3a3;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
`;

const InfoItemValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 7px 0 0;
`;

const InfoItemValue = styled.div`
  font-weight: 600;
  color: #fff;
`;

const InfoItemPercentContainer = styled.div`    
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.diffUp ? '#0bd95d' : '#ff4d00')};

  svg {
    transform: rotate(${(props) => (props.diffUp ? '0deg' : '180deg')});
  }
`;

const InfoItemPercent = styled.span`
  margin: 0 0 0 4px;
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export default function BalancePopUp(props) {
  const {
    infoData, theme, userBalance, loadingFlag, closeTippy,
  } = props;

  return (
    <ModalContainer className="tour_2_dt_step_3">
      {loadingFlag ? (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      ) : (
        <>
          <TopContainer>
            <TopHeader>Ваш Баланс</TopHeader>
            <TopInfo>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(userBalance)}</TopInfo>
          </TopContainer>
          <InfoList>
            {Object.entries(infoData).map(([key, value]) => (
              <InfoItem>
                <InfoItemHeader>{value.name}</InfoItemHeader>
                <InfoItemValueContainer>
                  <InfoItemValue>
                    {value.type === 'rub' ? (
                      <>
                        {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value.sum)}
                      </>
                    ) : (
                      <>
                        {new Intl.NumberFormat('ru-RU').format(value.sum)} шт.
                      </>
                    )}
                  </InfoItemValue>
                  <InfoItemPercentContainer diffUp={value.diffUp}>
                    <CustomIcon icon="triangleArrow" width="5px" height="19px" />
                    <InfoItemPercent>{value.diff}</InfoItemPercent>
                    <span>%</span>
                  </InfoItemPercentContainer>
                </InfoItemValueContainer>
              </InfoItem>
            ))}
          </InfoList>
        </>
      )}
      <NavbarPopupLink text="Полная статистика" theme={theme} link="/reports" closeTippy={closeTippy} />
    </ModalContainer>
  );
}
