import React, { Component } from 'react';
import queryString from 'query-string';
import Footer from './Footer';

class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFramed: 0,
    };
  }

  componentDidMount() {
    document.title = 'Спасибо за покупку!';
    const { location, history } = this.props;
    let isFramed;
    try {
      isFramed = window !== window.top || document !== top.document || self.location !== top.location;
    } catch (e) {
      isFramed = 1;
    }
    if (process.env.FORCE_WIDGET) isFramed = 1;
    this.setState({ isFramed });
    const urlParams = queryString.parse(location.search);
    if (urlParams.result === 'fail') {
      history.push('/payment_fail');
    }
  }

  render() {
    const { theme, history } = this.props;
    const { isFramed } = this.state;
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        {!isFramed ? (
          <nav className="navbar navbar-expand-lg navbar-dark">
            <a className="navbar-brand mr-auto" href="/">
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logoNav}`} alt="" />
            </a>
          </nav>
        ) : null }
        <div id="tech-work" className="container-fluid d-flex flex-fill flex-column">
          <div className="flex-fill">
            <h1>
              Поздравляем с успешной оплатой!
            </h1>
          </div>
          {!isFramed ? (
            <Footer history={history} theme={theme} />
          ) : null}
        </div>
      </div>
    );
  }
}

export default ThankYou;
