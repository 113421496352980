import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import ReactTable from 'react-table-6';
import ReactLoading from 'react-loading';
import { format, parse, parseISO } from 'date-fns';
import matchSorter from 'match-sorter';
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  customFetch,
  customSelectStyle, getCustomizedColumns, getDefaultSorting, getProducts,
  setTableCustomized, setTableSettings,
  setTableSorting,
} from '../Utils/Helpers';
import Regions from '../Admin/Regions';
import CommissionInput from '../Admin/CommissionInput';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';

let abortController = new AbortController();

const regionName = {
  Москва: 'moscow',
  'Московская обл.': 'moscowObl',
  'Санкт-Петербург': 'spb',
  'Ленинградская обл.': 'spbObl',
  Россия: 'other',
};

const regionNameMortgage = {
  Россия: 'other',
};

const regionList = {
  moscow: 'Москва',
  moscowObl: 'Московская обл.',
  other: 'Россия',
  spb: 'Санкт-Петербург',
  spbObl: 'Ленинградская обл.',
};

const optionCommissionType = [
  { value: 'quota', label: 'Квота от входящей' },
  { value: 'hide', label: 'Скрыть' },
];

class AgentCommission extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.customizeTableRef = React.createRef();
    this.state = {
      products: [],
      sliderValue: '',
      columns: [],
      loadingCommissions: false,
      loadingCompanies: false,
      selectedButtonCommission: false,
      commissionType: 'quota',
      uploading: false,
      companyNames: {},
      filtered: [],
      excelcolumns: [],
      dataSet: [],
      loading: false,
      isToggleCommissionForm: false,
      commissions: [],
      ownCommissions: [],
      companies: {},
      quota: {},
      openModalTableCustomized: false,
      product: { value: 'osago', label: 'ОСАГО' },
    };
  }

  componentDidMount() {
    const { agentId, userInfo } = this.props;
    const { product } = this.state;
    this.downloadCompanies(product.value);
    if (agentId && userInfo.id) {
      this.downloadCommissions(product.value);
    }
    getProducts(true).then((response) => {
      this.setState({
        products: response.map((product) => ({ value: product.code, label: product.name })),
      });
    });
    this.updateTableColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { agentId, userInfo } = this.props;
    const { openModalTableCustomized, product } = this.state;
    if (openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) {
      this.updateTableColumns();
    }
    if ((prevProps.agentId !== agentId) || (prevProps.userInfo.id !== userInfo.id)) {
      if (agentId && userInfo.id) {
        this.downloadCommissions(product.value);
      }
    }
  }

  downloadCompanies = (product) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loadingCompanies: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/companies?type=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((companies) => companies.json())
      .then((companies) => {
        const companyNames = {};
        const companyList = {};
        Object.keys(companies).forEach((company) => {
          companyNames[companies[company].name] = company;
          companyList[company] = {
            name: companies[company].name,
            banks: companies[company].banks,
          };
        });
        this.setState({ companyNames, companyList, loadingCompanies: false });
      });
  };

  downloadCommissions = (product) => {
    const {
      agentId, userInfo, curator, hideCommission,
    } = this.props;
    this.setState({ loading: true });
    const commissionType = hideCommission ? 'hide' : 'quota';
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let url = '';
    if (userInfo.role === 'admin' || userInfo.role === 'superadmin') {
      url = `commission/inner/higherValue?product=${product}`;
    } else {
      url = `currentCommissions/${curator}?product=${product}`;
    }
    this.setState({ loadingCommissions: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/currentCommissions/${agentId}?product=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((currentCommissions) => currentCommissions.json())
      .then((currentCommissions) => {
        this.setState({
          commissions: Object.values(currentCommissions),
          commissionType,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/${url}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ ownCommissions: Object.values(response) }, () => {
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/init?product=${product}`, {
            signal: abortController.signal,
            headers: {
              Authorization: lsToken,
            },
          })
            .then((response) => response.json())
            .then((initValues) => {
              this.setState({
                companies: JSON.parse(JSON.stringify(initValues)),
                quota: JSON.parse(JSON.stringify(initValues)),
              }, () => {
                customFetch(`${process.env.REACT_APP_API_DOMAIN}/currentCommissions/${agentId}/actual?type=${commissionType}&product=${product}`, {
                  signal: abortController.signal,
                  headers: {
                    Authorization: lsToken,
                  },
                })
                  .then((commissions) => commissions.json())
                  .then((commissions) => {
                    const {
                      companies, quota, ownCommissions, companyList,
                    } = this.state;
                    Object.keys(companies).forEach((company) => {
                      if (product === 'mortgage') {
                        Object.keys(companies[company]).forEach((bank) => {
                          const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.bank.code === bank && commission.product.code === product);
                          quota[company][bank] = commissions[company][bank];
                          companies[company][bank] = (commissions[company][bank] / 100) * ownCommissions[findedQuota].price;
                        });
                      } else {
                        Object.keys(companies[company]).forEach((region) => {
                          if (region === 'custom') {
                            companies[company][region].forEach((customRegion, index) => {
                              const finded = commissions[company][region].findIndex((commission) => JSON.stringify(commission.regions.sort()) === JSON.stringify(customRegion.regions.sort()));
                              const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === customRegion.regions[0] && commission.product.code === product);
                              if (customRegion.regions.length > 0 && finded !== -1) {
                                quota[company][region][index].value = commissions[company][region][finded].value;
                                companies[company][region][index].value = (commissions[company][region][finded].value / 100) * ownCommissions[findedQuota].price;
                              }
                            });
                          } else {
                            const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === regionList[region] && commission.product.code === product);
                            quota[company][region] = commissions[company][region];
                            companies[company][region] = (commissions[company][region] / 100) * ownCommissions[findedQuota].price;
                          }
                        });
                      }
                    });
                    this.setState({ companies, quota, loadingCommissions: false });
                  });
              });
            });
        });
      });
  };

  updateTableColumns = () => {
    const { product } = this.state;
    const columns = [{
      Header: 'Продукт',
      id: 'product',
      accessor: (d) => d.product.name,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['product'] }),
      filterAll: true,
      showCustomized: true,
      width: reactLocalStorage.getObject('agentCommissionTableSettings').product,
      show: reactLocalStorage.getObject('agentCommissionTableSettings').show_product !== false,
    }, {
      Header: 'Страховая',
      accessor: 'company',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
      filterAll: true,
      showCustomized: true,
      width: reactLocalStorage.getObject('agentCommissionTableSettings').company,
      show: reactLocalStorage.getObject('agentCommissionTableSettings').show_company !== false,
    }, {
      Header: 'Регион',
      accessor: 'region',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
      filterAll: true,
      showCustomized: true,
      width: reactLocalStorage.getObject('agentCommissionTableSettings').region,
      show: product.value !== 'mortgage' && reactLocalStorage.getObject('agentCommissionTableSettings').show_region !== false,
    }, {
      Header: 'Банк',
      id: 'bank',
      accessor: (d) => (d.bank ? d.bank.name : null),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['bank'] }),
      filterAll: true,
      showCustomized: true,
      show: product.value === 'mortgage' && reactLocalStorage.getObject('agentCommissionTableSettings').show_bank !== false,
    }, {
      Header: 'Дата от',
      id: 'date_from',
      accessor: (d) => format(parseISO(d.date_from), 'dd.MM.yyyy'),
      sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
      showCustomized: true,
      width: reactLocalStorage.getObject('agentCommissionTableSettings').date_from,
      show: reactLocalStorage.getObject('agentCommissionTableSettings').show_date_from !== false,
    }, {
      id: 'price',
      Header: 'Размер вознаграждения, %',
      accessor: (d) => d.price,
      showCustomized: true,
      width: reactLocalStorage.getObject('agentCommissionTableSettings').price,
      show: reactLocalStorage.getObject('agentCommissionTableSettings').show_price !== false,
    },
    ];
    this.setState({ columns });
  };

  addCommission = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const {
      companies, commissionType, quota, product,
    } = this.state;
    const { agentId } = this.props;
    this.setState({ uploading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/group`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companies,
        quota,
        agents: {
          [agentId]: true,
        },
        update: true,
        type: commissionType,
        product: product.value,
      }),
    })
      .then((response) => response.json())
      .then((commissions) => {
        if (commissions.error) {
          showModalInfo(commissions.error);
        } else {
          this.setState({isToggleCommissionForm: false});
          showModalInfo('Изменение вознаграждения может занять несколько минут');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ uploading: false });
      });
  };

  downloadReport = () => {
    const { ExcelColumn } = ReactExport.ExcelFile;
    const { columns } = this.state;
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    const dataToDownload = [];
    for (let index = 0; index < currentRecords.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (currentRecords[index][columns[colIndex].accessor]) {
          if (currentRecords[index][columns[colIndex].accessor] && currentRecords[index][columns[colIndex].accessor].$$typeof) {
            recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor].props.children[0];
          } else {
            recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
          }
        } else if (currentRecords[index][columns[colIndex].id] && currentRecords[index][columns[colIndex].id].$$typeof) {
          recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id].props.children[0];
        } else {
          recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id];
        }
      }
      dataToDownload.push(recordToDownload);
    }
    const excelcolumns = columns.map(
      (column) => <ExcelColumn label={column.Header} value={column.Header} />,
    );
    this.setState({ excelcolumns, dataSet: dataToDownload }, function () {
      this.excelFile.handleDownload();
    });
  };

  toggleCommission = () => {
    this.setState((prevState) => ({
      isToggleCommissionForm: !prevState.isToggleCommissionForm,
    }));
  };

  handleChangeProduct = (e) => {
    abortController.abort();
    abortController = new AbortController();
    this.setState({
      product: e, commissions: [], companyNames: {}, companyList: {}, companies: {}, quota: {}, ownCommissions: [], selectedButtonCommission: false,
    }, () => {
      this.downloadCompanies(e.value);
      this.updateTableColumns();
      this.downloadCommissions(e.value);
    });
  };

  handleChangeSliderFastButton = (value) => {
    const {
      companies, ownCommissions, companyList, quota, product,
    } = this.state;
    if (product.value === 'mortgage') {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((bank) => {
          const finded = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.bank.code === bank && commission.product.code === product.value);
          if (finded !== -1) {
            const parentCommission = ownCommissions[finded].price;
            quota[company][bank] = 100 - parseFloat(value);
            if (quota[company][bank] < 0) quota[company][bank] = 0;
            companies[company][bank] = (quota[company][bank] / 100) * parentCommission;
          }
        });
      });
    } else {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((region) => {
          if (region === 'custom') {
            companies[company][region].forEach((customRegion, index) => {
              const finded = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === customRegion.regions[0] && commission.product.code === product.value);
              if (customRegion.regions.length > 0 && finded !== -1) {
                const parentCommission = ownCommissions[finded].price;
                quota[company][region][index].value = 100 - parseFloat(value);
                if (quota[company][region][index].value < 0) quota[company][region][index].value = 0;
                companies[company][region][index].value = (quota[company][region][index].value / 100) * parentCommission;
              }
            });
          } else {
            const finded = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === regionList[region] && commission.product.code === product.value);
            if (finded !== -1) {
              const parentCommission = ownCommissions[finded].price;
              quota[company][region] = 100 - parseFloat(value);
              if (quota[company][region] < 0) quota[company][region] = 0;
              companies[company][region] = (quota[company][region] / 100) * parentCommission;
            }
          }
        });
      });
    }

    this.setState({
      companies,
      quota,
      sliderValue: -value,
      selectedButtonCommission: value,
    });
  };

  handleChangeSlider = (e) => {
    const {
      companies, ownCommissions, companyList, quota, product,
    } = this.state;
    if (product.value === 'mortgage') {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((bank) => {
          const finded = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.bank.code === bank && commission.product.code === product.value);
          if (finded !== -1) {
            const parentCommission = ownCommissions[finded].price;
            quota[company][bank] = 100 + e;
            if (quota[company][bank] < 0) quota[company][bank] = 0;
            companies[company][bank] = (quota[company][bank] / 100) * parentCommission;
          }
        });
      });
    } else {
      Object.keys(companies).forEach((company) => {
        Object.keys(companies[company]).forEach((region) => {
          if (region === 'custom') {
            companies[company][region].forEach((customRegion, index) => {
              const finded = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === customRegion.regions[0] && commission.product.code === product.value);
              if (customRegion.regions.length > 0 && finded !== -1) {
                const parentCommission = ownCommissions[finded].price;
                quota[company][region][index].value = 100 + e;
                if (quota[company][region][index].value < 0) {
                  quota[company][region][index].value = 0;
                }
                companies[company][region][index].value = (quota[company][region][index].value / 100) * parentCommission;
              }
            });
          } else {
            const finded = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === regionList[region] && commission.product.code === product.value);
            if (finded !== -1) {
              const parentCommission = ownCommissions[finded].price;
              quota[company][region] = 100 + e;
              if (quota[company][region] < 0) quota[company][region] = 0;
              companies[company][region] = (quota[company][region] / 100) * parentCommission;
            }
          }
        });
      });
    }

    this.setState({
      companies,
      quota,
      selectedButtonCommission: false,
    });
  };

  handleInputCommission = (company, key, e) => {
    const {
      companies, quota, ownCommissions, companyList, product,
    } = this.state;
    companies[company][key] = e.target.value;
    const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === regionList[key] && commission.product.code === product.value);
    quota[company][key] = ownCommissions[findedQuota].price === 0 ? 0 : (e.target.value * 100) / ownCommissions[findedQuota].price;
    this.setState({ companies, quota });
  };

  handleInputQuota = (company, key, e) => {
    const {
      quota, companies, ownCommissions, companyList, product,
    } = this.state;
    quota[company][key] = e.target.value;
    let findedQuota;
    if (product.value === 'mortgage') {
      findedQuota = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.bank.code === key && commission.product.code === product.value);
    } else {
      findedQuota = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === regionList[key] && commission.product.code === product.value);
    }
    companies[company][key] = e.target.value * (ownCommissions[findedQuota].price / 100);
    this.setState({ quota, companies });
  };

  handleInputCustomCommission = (company, key, e) => {
    const { companies } = this.state;
    companies[company].custom.forEach((element) => {
      element.regions.sort();
      key.sort();
      if (JSON.stringify(element.regions) === JSON.stringify(key)) {
        element.value = e.target.value;
      }
    });
    this.setState({ companies });
  };

  handleInputCustomQuota = (company, key, e) => {
    const {
      quota, companies, ownCommissions, companyList, product,
    } = this.state;
    quota[company].custom.forEach((element, index) => {
      element.regions.sort();
      key.sort();
      if (JSON.stringify(element.regions) === JSON.stringify(key)) {
        element.value = e.target.value;
        const findedQuota = ownCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === key[0] && commission.product.code === product.value);
        companies[company].custom[index].value = (e.target.value / 100) * ownCommissions[findedQuota].price;
      }
    });
    this.setState({ quota, companies });
  };

  customRegionBlock = (company) => {
    const {
      ownCommissions, companies, companyNames, commissionType, quota, product,
    } = this.state;
    const customCommissions = ownCommissions.filter((commission) => !regionName.hasOwnProperty(commission.region)
      && commission.company === company && commission.product.code === product.value);
    Array.prototype.groupBy = function (prop) {
      return this.reduce((groups, item) => {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    };
    const groupedByGroup = customCommissions.groupBy('group_id');
    return Object.keys(groupedByGroup).map((group, index) => {
      const regionsArray = Object.keys(groupedByGroup[group].groupBy('region')).map((value) => (value));
      const regions = regionsArray.join(', ');
      const ownCommissionCustomRegion = ownCommissions[ownCommissions.findIndex((commission) => (commission.company === company && commission.region.toUpperCase() === regionsArray[0].toUpperCase()))];
      const customCommission = companies[companyNames[company]].custom.find((element) => {
        if (element.groupId === group) {
          return element;
        }
        return false;
      });
      const customQuota = quota[companyNames[company]].custom.find((element) => {
        if (element.groupId === group) {
          return element;
        }
        return false;
      });
      const yourCommission = ownCommissionCustomRegion ? ownCommissionCustomRegion.price : 0;
      const commission = customCommission ? customCommission.value : 0;
      if (companyNames[company] === 'rgs') {

      }
      return (
        <div key={`${companyNames[company]}custom${group}`} className="form-group row">
          <Regions
            regions={regions}
            index={index}
          />
          <div className="col-md-2">
            <CommissionInput
              handleInputCommission={this.handleInputCustomCommission}
              value={commission}
              region={regionsArray}
              company={companyNames[company]}
              disabled={commissionType === 'quota'}
            />
          </div>
          <div className="col-md-2">
            <input value={yourCommission} disabled className="form-control" />
          </div>
          {commissionType === 'quota' && (
            <>
              <div className="col-md-2">
                <CommissionInput
                  disabled
                  value={+(yourCommission - commission).toFixed(2)}
                  region={regionsArray}
                  company={companyNames[company]}
                />
              </div>
              <div className="col-md-2">
                <CommissionInput
                  handleInputCommission={this.handleInputCustomQuota}
                  value={customQuota ? customQuota.value : 0}
                  region={regionsArray}
                  company={companyNames[company]}
                />
              </div>
            </>
          )}
        </div>
      );
    });
  };

  onFilteredChange = (filtered) => {
    this.setState({ filtered });
  };

  handleChangeCommissionType = (e) => {
    this.setState({ commissionType: e.value });
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    setTableCustomized(e.target.elements, 'agentCommissionTableSettings');
    this.setState({ openModalTableCustomized: false });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  banksBlock = (company) => {
    const {
      ownCommissions,
      companies,
      companyNames,
      quota,
      product,
      companyList,
    } = this.state;
    return (
      <>
        {Object.keys(companies[companyNames[company]]).map((bank) => {
          const yourCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === company && commission.bank.code === bank && commission.product.code === product.value)] ? ownCommissions[ownCommissions.findIndex((commission) => commission.company === company && commission.bank.code === bank && commission.product.code === product.value)].price : '0';
          const agentCommission = companies[companyNames[company]][bank];
          return (
            <div key={`${companyNames[company]}${bank}`} className="form-group row">
              <label className="col-md-2 col-form-label">{companyList[companyNames[company]].banks[bank]}</label>
              <div className="col-md-2">
                <CommissionInput
                  handleInputCommission={this.handleInputCommission}
                  value={agentCommission}
                  region={bank}
                  company={companyNames[company]}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <input value={yourCommission} disabled className="form-control" />
              </div>
              <div className="col-md-2">
                <CommissionInput
                  disabled
                  value={+(yourCommission - agentCommission).toFixed(2)}
                  region={bank}
                  company={companyNames[company]}
                />
              </div>
              <div className="col-md-2">
                <CommissionInput
                  handleInputCommission={this.handleInputQuota}
                  value={quota[companyNames[company]][bank]}
                  region={bank}
                  company={companyNames[company]}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  };

  regionsBlock = (company) => {
    const {
      ownCommissions,
      companies,
      companyNames,
      quota,
      commissionType,
      product,
    } = this.state;

    let filteredRegions = regionName;
    if (product.value !== 'osago') {
      filteredRegions = regionNameMortgage;
    }
    return (
      <>
        {Object.keys(filteredRegions).map((region) => {
          const yourCommission = ownCommissions[ownCommissions.findIndex((commission) => commission.company === company && commission.region === region && commission.product.code === product.value)] ? ownCommissions[ownCommissions.findIndex((commission) => commission.company === company && commission.region === region && commission.product.code === product.value)].price : '0';
          const agentCommission = companies[companyNames[company]][filteredRegions[region]];
          return (
            <div key={`${companyNames[company]}${filteredRegions[region]}`} className="form-group row">
              <label className="col-md-2 col-form-label">{region}</label>
              <div className="col-md-2">
                <CommissionInput
                  handleInputCommission={this.handleInputCommission}
                  value={agentCommission}
                  region={filteredRegions[region]}
                  company={companyNames[company]}
                  disabled={commissionType === 'quota'}
                />
              </div>
              <div className="col-md-2">
                <input value={yourCommission} disabled className="form-control" />
              </div>
              {commissionType === 'quota' && (
                <>
                  <div className="col-md-2">
                    <CommissionInput
                      disabled
                      value={+(yourCommission - agentCommission).toFixed(2)}
                      region={filteredRegions[region]}
                      company={companyNames[company]}
                    />
                  </div>
                  <div className="col-md-2">
                    <CommissionInput
                      handleInputCommission={this.handleInputQuota}
                      value={quota[companyNames[company]][filteredRegions[region]]}
                      region={filteredRegions[region]}
                      company={companyNames[company]}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </>
    );
  };

  setSliderValue = (val) => {
    this.setState({
      sliderValue: val,
    });
  };

  render() {
    const {
      excelcolumns, dataSet, loading, isToggleCommissionForm, commissions, selectedButtonCommission, openModalTableCustomized, products,
      companies, companyNames, uploading, commissionType, loadingCommissions, columns, product, loadingCompanies, sliderValue,
    } = this.state;
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    const commissionForm = (
      <form onSubmit={this.addCommission} className="mb-4">
        <div className="form-group row">
          <div className="col-md-3">
            <label htmlFor="commissionType">Тип начисления вознаграждения</label>
            <Select
              classNamePrefix="react-select"
              styles={customSelectStyle()}
              name="commissionType"
              isSearchable={false}
              placeholder="Выберите тип"
              id="commissionType"
              value={optionCommissionType.filter(({ value }) => value === commissionType)}
              onChange={this.handleChangeCommissionType}
              options={optionCommissionType}
            />
          </div>
        </div>
        {commissionType === 'quota' ? (
          <div className="form-group row">
            <div className="col-md-6 my-auto">
              <Slider
                onAfterChange={this.handleChangeSlider}
                min={-100}
                max={0}
                step={0.5}
                onChange={(val) => this.setSliderValue(val)}
                value={sliderValue}
                handleRender={(node, handleProps) => (
                  <Tooltip
                    prefixCls="rc-slider-tooltip"
                    overlay={`${handleProps.value}%`}
                    placement="top"
                    visible={handleProps.dragging}
                  >
                    {node}
                  </Tooltip>
                )}
                defaultValue={0}
              />
            </div>
            <div className="col-md-3">
              <div className="form_check_group form_check_group-circle">
                <label className="form_check_group__row">
                  <input type="radio" checked={selectedButtonCommission === 10} name="commissionFastButton" value="10" className="js-currency-radio" />
                  <span onClick={() => this.handleChangeSliderFastButton(10)} className="form_check_group__btn">-10%</span>
                </label>
                <label className="form_check_group__row">
                  <input type="radio" checked={selectedButtonCommission === 20} name="commissionFastButton" value="20" className="js-currency-radio" />
                  <span onClick={() => this.handleChangeSliderFastButton(20)} className="form_check_group__btn">-20%</span>
                </label>
                <label className="form_check_group__row">
                  <input type="radio" checked={selectedButtonCommission === 30} name="commissionFastButton" value="30" className="js-currency-radio" />
                  <span onClick={() => this.handleChangeSliderFastButton(30)} className="form_check_group__btn">-30%</span>
                </label>
              </div>
            </div>
          </div>
        ) : null}
        {commissionType !== 'hide'
          && (
            <>
              <div className="form-group">
                <SimpleNotification>Условия вознаграждения могут быть изменены, точный размер вознаграждения определяется в момент расчета стоимости полиса и указывается в выдаче результатов.</SimpleNotification>
              </div>
              <h2>{product.label}</h2>
              {Object.keys(companyNames).map((company) => {
                if (companies[companyNames[company]]) {
                  return (
                    <div key={companyNames[company]} className="form-group row">
                      <div className="col">
                        <div className="form-group row">
                          <div className="col-md-2"><p className="h5">{company}</p></div>
                          <div className="col-md-2"><p className="h5">Вознаграждение агента, %.</p></div>
                          <div className="col-md-2"><p className="h5">Ваша вознаграждение, %.</p></div>
                          {commissionType === 'quota' && (
                            <>
                              <div className="col-md-2"><p className="h5">Оставляете себе, %.</p></div>
                              <div className="col-md-2"><p className="h5">Квота от общего входящего вознаграждения, %.</p></div>
                            </>
                          )}
                        </div>
                        {product.value !== 'mortgage' ? (
                          <>
                            {this.regionsBlock(company)}
                            {product.value === 'osago' ? this.customRegionBlock(company) : null}
                          </>
                        ) : this.banksBlock(company)}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </>
          )}
        <button disabled={uploading} type="submit" className="btn btn-success mr-2">Сохранить</button>
        <button disabled={uploading} type="button" className="btn btn-secondary" onClick={this.toggleCommission}>Отмена</button>
        {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
      </form>
    );
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {getCustomizedColumns(columns, this.simulateClick)}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button>Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="form-group row">
          <div className="col-md-3">
            <Select
              classNamePrefix="react-select"
              styles={customSelectStyle()}
              name="product"
              isSearchable={false}
              placeholder="Выберите продукт"
              id="product"
              value={product}
              onChange={this.handleChangeProduct}
              options={products}
            />
          </div>
        </div>
        {isToggleCommissionForm
          ? <LoadingBanner loadingFlag={loadingCompanies || loadingCommissions}>{commissionForm}</LoadingBanner>
          : <button type="button" className="btn btn-success mb-4" onClick={this.toggleCommission}>Редактировать условия вознаграждения</button>}
        <ReactTable
          className="table"
          filtered={this.state.filtered}
          onFilteredChange={(filtered) => this.onFilteredChange(filtered)}
          ref={(r) => this.reactTable = r}
          data={commissions}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          defaultSorted={[
            {
              id: getDefaultSorting('agentCommissionTableSettings').field,
              desc: getDefaultSorting('agentCommissionTableSettings').order,
            },
          ]}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'agentCommissionTableSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings(newResized, 'agentCommissionTableSettings');
          }}
        />
      </>
    );
  }
}

AgentCommission.contextType = ModalMessagesContext;

export default AgentCommission;
