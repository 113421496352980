import React, { Component } from 'react';
import { faCalendarAlt, faCheckCircle } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { snakeCase } from 'change-case';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { ReactDadata } from 'react-dadata';
import { format, parseISO, subYears } from 'date-fns';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import ReactLoading from 'react-loading';
import { scroller } from 'react-scroll';
import Modal from 'react-responsive-modal';
import { faCheck, faTimes } from '@fortawesome/fontawesome-free-solid';
import getMask from '../Utils/Masks';
import { customFetch, switchLanguage } from '../Utils/Helpers';
import getValidation from '../Utils/Validation';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import AutoResizingTextarea from '../Utils/UiComponents/AutoResizingTextarea';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';

class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneCode: '',
      verifyingPhoneCode: false,
      phoneCodeVerfied: false,
      openModalChangeEmail: false,
      offerForLegal: '',
      offerForNatural: '',
      uploading: false,
      userId: '',
      subscribeEmail: true,
      curator: {
        firstName: '',
        middleName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      checkingStatusInFNS: false,
      formFields: {
        newEmail: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'email',
        },
        offer: {
          value: false,
          errorMessage: '',
          errorVisible: false,
          validationType: 'checkbox',
        },
        agentTypeCode: {
          value: 'self_employed',
        },
        actualMatchLegal: {
          value: false,
        },
        lastName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        firstName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        middleName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefLastName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefFirstName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefMiddleName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefPosition: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        chiefEmail: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'email',
        },
        chiefPhone: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'phone',
        },
        chiefPassportSerial: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportSerial',
        },
        chiefPassportNumber: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportNumber',
        },
        chiefPassportDate: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        chiefPassportIssuedBy: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportIssuedBy',
        },
        birthday: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        passportSerial: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportSerial',
        },
        passportNumber: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportNumber',
        },
        passportDate: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        passportIssuedByCode: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'plain',
        },
        passportIssuedBy: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportIssuedBy',
        },
        userAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'address',
        },
        birthdayAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportIssuedBy',
        },
        legalAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'address',
        },
        actualAddress: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'address',
        },
        organizationName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'plain',
        },
        ogrn: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'ogrn',
        },
        ogrnip: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'ogrnip',
        },
        inn: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'inn',
        },
        snils: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'snils',
        },
        kpp: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'kpp',
        },
        bic: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'bic',
        },
        correspondentAccount: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'paymentAccount',
        },
        settlementAccount: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'paymentAccount',
        },
        bankName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'bankName',
        },
        paymentInfoComment: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'comment',
        },
        paymentCard: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'paymentCard',
        },
      },
      documents: {
        offer: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        passport_page_one: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        passport_page_two: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        inn: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        snils: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        registration_certificate: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        self_employed_document: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
        custom_contract: {
          name: '',
          file: null,
          downloaded: false,
          errorMessage: null,
          validationType: 'image',
          path: '',
        },
      },
    };
  }

  componentDidMount() {
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          offerForNatural: response.offer_for_natural,
          offerForLegal: response.offer_for_legal,
        });
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки оферты');
      });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/documents`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((newDocuments) => {
        if (newDocuments !== 'not_found') {
          const { documents } = this.state;
          Object.keys(newDocuments).forEach((document) => {
            if (newDocuments[document]) {
              documents[document].name = newDocuments[document].split('/').splice(-1)[0];
              documents[document].downloaded = true;
              documents[document].path = newDocuments[document];
            }
          });
          this.setState({ documents });
        }
      })
      .catch(() => {
        showModalInfo('Ошибка загрузки документов');
      });
  }

  static getDerivedStateFromProps(props, state) {
    if ((props.userInfo.id !== state.userId) && (props.userInfo.id !== null)) {
      const { formFields } = state;
      Object.keys(formFields).forEach((name) => {
        if (snakeCase(name) in props.userInfo) {
          if (props.userInfo[snakeCase(name)]) {
            formFields[name].value = props.userInfo[snakeCase(name)];
          }
        }
      });
      return {
        userId: props.userInfo.id,
        subscribeEmail: props.userInfo.subscribe_email,
        formFields,
        curator: {
          firstName: props.userInfo.curator_obj.first_name,
          middleName: props.userInfo.curator_obj.middle_name,
          lastName: props.userInfo.curator_obj.last_name,
          phone: props.userInfo.curator_obj.phone,
          email: props.userInfo.curator_obj.email,
        },
      };
    }
    return null;
  }

  handleChangeInput = (e, translate = false, upperCase = false) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (translate) {
      value = switchLanguage(e.target.value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formFields } = this.state;
    formFields[e.target.name].value = value;
    if (formFields[e.target.name].errorVisible) {
      formFields[e.target.name].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangePaymentCard = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const { formFields } = this.state;
    formFields[e.target.name].value = value;
    if (formFields[e.target.name].errorVisible) {
      formFields[e.target.name].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeDate = (date, field) => {
    const { formFields } = this.state;
    formFields[field].value = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    if (date) {
      formFields[field].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeAddress = (query, name) => {
    const { formFields } = this.state;
    formFields[name].value = query;
    if (name === 'actualAddress' && formFields.actualMatchLegal.value) {
      formFields.actualAddress.value = query;
      formFields.actualAddress.errorVisible = false;
    }
    if (formFields[name].errorMessage) {
      formFields[name].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangePassportIssuedBy = (query, field) => {
    const { formFields } = this.state;
    formFields[field].value = query.value;
    if (formFields[field].errorMessage) {
      formFields[field].errorVisible = false;
    }
    if (field === 'passportIssuedBy' && query.data) {
      formFields.passportIssuedByCode.value = query.data.code;
    }
    this.setState({ formFields });
  };

  handleSetActualMatchLegal = (e) => {
    const value = e.target.checked;
    const { formFields } = this.state;
    formFields.actualMatchLegal.value = value;
    formFields.actualAddress.value = formFields.legalAddress.value;
    this.setState({ formFields });
  };

  handleChangeFile = (e) => {
    const { documents } = this.state;
    if (e.target.files[0]) {
      documents[e.target.name].name = e.target.files[0].name;
      documents[e.target.name].file = e.target.files[0];
      documents[e.target.name].downloaded = false;
    }
    documents[e.target.name].errorMessage = getValidation(documents[e.target.name].name, documents[e.target.name].validationType);
    this.setState({ documents });
  };

  handleDeleteFile = (fileName) => {
    const { documents } = this.state;
    documents[fileName].name = '';
    documents[fileName].file = null;
    documents[fileName].downloaded = false;
    documents[fileName].errorMessage = null;
    documents[fileName].path = '';
    this.setState({ documents });
  };

  handleValidate = (e) => {
    const { formFields } = this.state;
    const { value } = formFields[e.target.name];
    formFields[e.target.name].errorMessage = getValidation(value, formFields[e.target.name].validationType);
    if (formFields[e.target.name].errorMessage) {
      formFields[e.target.name].errorVisible = true;
    }
    this.setState({ formFields });
  };

  validateAllFields = () => {
    const { formFields, documents } = this.state;
    let validationSuccess = true;
    let fields = [];
    let documentsArray = [];
    let errorBlockName = false;
    if (formFields.agentTypeCode.value === 'natural') {
      fields = [
        'lastName', 'firstName', 'middleName', 'inn', 'birthday', 'passportIssuedBy', 'passportSerial',
        'passportNumber', 'passportDate', 'userAddress', 'bankName', 'paymentCard', 'snils', 'birthdayAddress',
        'offer', 'bic', 'correspondentAccount', 'settlementAccount', 'paymentInfoComment', 'passportIssuedByCode',
      ];
      documentsArray = ['inn', 'snils', 'passport_page_one', 'passport_page_two'];
    } else if (formFields.agentTypeCode.value === 'self_employed') {
      fields = [
        'lastName', 'firstName', 'middleName', 'inn', 'birthday', 'passportIssuedBy', 'passportSerial',
        'passportNumber', 'passportDate', 'userAddress', 'bankName', 'paymentCard', 'birthdayAddress',
        'offer', 'snils', 'bic', 'correspondentAccount', 'settlementAccount', 'paymentInfoComment', 'passportIssuedByCode',
      ];
      documentsArray = ['inn', 'snils', 'passport_page_one', 'passport_page_two', 'self_employed_document'];
    } else if (formFields.agentTypeCode.value === 'legal') {
      fields = [
        'lastName', 'firstName', 'middleName', 'chiefFirstName', 'paymentInfoComment',
        'chiefPhone', 'legalAddress', 'actualAddress', 'organizationName', 'ogrn', 'inn', 'kpp',
        'bic', 'correspondentAccount', 'settlementAccount', 'bankName', 'chiefMiddleName',
        'chiefLastName', 'chiefPosition', 'chiefEmail', 'chiefPassportSerial', 'chiefPassportNumber', 'chiefPassportDate', 'chiefPassportIssuedBy',
      ];
      documentsArray = ['inn', 'registration_certificate', 'passport_page_one', 'passport_page_two', 'offer'];
    } else if (formFields.agentTypeCode.value === 'individual') {
      fields = [
        'lastName', 'firstName', 'middleName', 'ogrnip', 'organizationName', 'inn', 'bic', 'paymentInfoComment',
        'correspondentAccount', 'settlementAccount', 'bankName', 'passportSerial', 'legalAddress', 'actualAddress',
        'passportNumber', 'passportDate', 'birthday', 'passportIssuedBy', 'userAddress',
      ];
      documentsArray = ['inn', 'registration_certificate', 'snils', 'passport_page_one', 'passport_page_two', 'offer'];
    }
    fields.forEach((field) => {
      if (formFields[field].validationType) {
        const { value } = formFields[field];
        formFields[field].errorMessage = getValidation(value, formFields[field].validationType);
        if (formFields[field].errorMessage) {
          validationSuccess = false;
          formFields[field].errorVisible = true;
          if (!errorBlockName) {
            errorBlockName = field;
          }
        }
      }
    });
    documentsArray.forEach((document) => {
      if (documents[document].validationType) {
        const { name } = documents[document];
        documents[document].errorMessage = getValidation(name, documents[document].validationType);
        if (documents[document].errorMessage) {
          validationSuccess = false;
          if (!errorBlockName) {
            errorBlockName = document;
          }
        }
      }
    });
    this.setState({
      formFields, documents,
    }, () => {
      if (errorBlockName) {
        const { showModalInfo } = this.context;
        showModalInfo(`Ошибка в поле - ${errorBlockName}`);
        scroller.scrollTo(errorBlockName, {
          duration: 1200,
          delay: 100,
          smooth: true,
          offset: -100,
        });
      }
    });
    return validationSuccess;
  };

  handleSubmit = (e, ignoreValidationFlag = false) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { formFields, documents } = this.state;
    this.setState({ uploading: true });
    const validationSuccess = ignoreValidationFlag || this.validateAllFields();
    if (validationSuccess) {
      const formData = new FormData();
      Object.keys(documents).forEach((document) => {
        if (documents[document].file) {
          formData.append(document, documents[document].file);
        } else {
          formData.append(document, documents[document].path);
        }
      });
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/update/documents`, {
        method: 'post',
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((docs) => {
          if (docs.error === null) {
            Object.keys(docs.documents).forEach((document) => {
              if (documents[document]) {
                documents[document].downloaded = true;
                documents[document].errorMessage = null;
                documents[document].path = docs.documents[document];
              }
            });
            this.setState({ documents });
            const data = Object.fromEntries(
              Object.entries(formFields).map(
                ([name]) => [name, formFields[name].value],
              ),
            );
            data.onlyUpdate = ignoreValidationFlag;
            customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/update`, {
              method: 'put',
              headers: {
                Authorization: token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
              .then((response) => response.json())
              .then((response) => {
                if (!response.error) {
                  this.props.updateUser(response.userInfo);
                  showModalInfo('Данные обновлены');
                } else {
                  showModalInfo(response.error);
                }
              })
              .catch(() => {
                showModalInfo('Ошибка');
              })
              .finally(() => {
                this.setState({ uploading: false });
              });
          } else {
            Object.keys(docs.error).forEach((document) => {
              if (documents[document]) {
                documents[document].errorMessage = docs.error[document];
                documents[document].errorVisible = true;
                showModalInfo(docs.error[document]);
              }
            });
            this.setState({ documents });
          }
        })
        .catch(() => {
          showModalInfo('Ошибка');
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    } else {
      this.setState({ uploading: false });
    }
  };

  handleChangePhoneCode = (e) => {
    this.setState({ phoneCode: e.target.value });
  };

  handleChangePhone = () => {
    const { showModalInfo } = this.context;
    this.setState({ uploading: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/generateResetToken?changePhone=true`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'success') {
          showModalInfo('На почту отправлена ссылка для смены номера телефона');
        } else {
          showModalInfo('Ошибка');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ uploading: false });
      });
  };

  onCloseModalChangeEmail = () => {
    this.setState({ openModalChangeEmail: false });
  };

  onOpenModalChangeEmail = () => {
    this.sendSms();
    this.setState({ openModalChangeEmail: true });
  };

  handleChangeEmailSubmit = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    this.setState({ uploading: true });
    const { formFields } = this.state;
    const { value } = formFields.newEmail;
    formFields.newEmail.errorMessage = getValidation(value, formFields.newEmail.validationType);
    if (formFields.newEmail.errorMessage) {
      formFields.newEmail.errorVisible = true;
      this.setState({
        uploading: false,
        formFields,
      });
    } else {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/generateResetToken?newEmail=${value}`, {
        method: 'post',
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response === 'success') {
            showModalInfo('На указанный адрес отправлена ссылка для подтверждения изменения');
          } else {
            showModalInfo('Ошибка');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка');
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    }
  };

  handleChangeBankName = (query) => {
    const { formFields } = this.state;
    formFields.bankName.value = query.value;
    formFields.bic.value = query.data.bic;
    formFields.correspondentAccount.value = query.data.correspondent_account;
    if (formFields.bankName.errorMessage) {
      formFields.bankName.errorVisible = false;
    }
    if (formFields.bic.errorMessage) {
      formFields.bic.errorVisible = false;
    }
    if (formFields.correspondentAccount.errorMessage) {
      formFields.correspondentAccount.errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeOrganizationName = (query) => {
    const { formFields } = this.state;
    formFields.organizationName.value = query.value;
    formFields.inn.value = query.data.inn;
    formFields.kpp.value = query.data.kpp;
    if (formFields.agentTypeCode.value === 'individual') {
      formFields.ogrnip.value = query.data.ogrn;
    }
    if (formFields.agentTypeCode.value === 'legal') {
      formFields.ogrn.value = query.data.ogrn;
    }
    if (query.data.management) {
      formFields.chiefPosition.value = query.data.management.post;
      const fullNameArray = query.data.management.name.split(' ');
      formFields.chiefLastName.value = fullNameArray[0];
      formFields.chiefFirstName.value = fullNameArray[1];
      formFields.chiefMiddleName.value = fullNameArray[2];
      if (formFields.chiefPosition.errorMessage) {
        formFields.chiefPosition.errorVisible = false;
      }
      if (formFields.chiefLastName.errorMessage) {
        formFields.chiefLastName.errorVisible = false;
      }
      if (formFields.chiefFirstName.errorMessage) {
        formFields.chiefFirstName.errorVisible = false;
      }
      if (formFields.chiefMiddleName.errorMessage) {
        formFields.chiefMiddleName.errorVisible = false;
      }
    }
    if (formFields.organizationName.errorMessage) {
      formFields.organizationName.errorVisible = false;
    }
    if (formFields.inn.errorMessage) {
      formFields.inn.errorVisible = false;
    }
    if (formFields.kpp.errorMessage) {
      formFields.kpp.errorVisible = false;
    }
    if (formFields.ogrnip.errorMessage) {
      formFields.ogrnip.errorVisible = false;
    }
    if (formFields.ogrn.errorMessage) {
      formFields.ogrn.errorVisible = false;
    }
    this.setState({ formFields });
  };

  sendSms = () => {
    const { showModalInfo } = this.context;
    const { userInfo } = this.props;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/sendSms`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        phone: userInfo.phone,
      }),
    })
      .then((response) => response.json())
      .then(() => {
      })
      .catch(() => {
        showModalInfo('Произошла ошибка, повторите попытку.');
      });
  };

  sendVerifyPhone = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const { phoneCode } = this.state;
    const { userInfo } = this.props;
    this.setState({ verifyingPhoneCode: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/registration/verifyPhone`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        phone: userInfo.phone,
        code: phoneCode,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ verifyingPhoneCode: false });
        if (response === 'verified') {
          this.setState({ phoneCodeVerfied: true });
        } else {
          showModalInfo('Вы ввели неправильный код.');
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка, повторите попытку.');
        this.setState({ verifyingPhoneCode: false });
      });
  };

  resetPassword = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    this.setState({ uploading: true });
    const { userInfo } = this.props;
    fetch(`${process.env.REACT_APP_API_DOMAIN}/password/create`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: userInfo.email }),
    })
      .then((response) => response.json())
      .then(() => {
        showModalInfo('Пароль выслан вам на почту');
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ uploading: false });
      });
  };

  handleChangeSubscribeEmail = () => {
    this.setState({ uploading: true });
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState((prevState) => ({
      subscribeEmail: !prevState.subscribeEmail,
    }), () => {
      const { subscribeEmail } = this.state;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/changeSubscribeEmail`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ subscribe_email: subscribeEmail }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error);
          } else {
            const text = subscribeEmail ? 'Вы успешно подписались на рассылку' : 'Вы успешно отписались от рассылки';
            showModalInfo(text);
          }
        })
        .catch(() => {
          showModalInfo('Произошла ошибка, повторите попытку.');
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    });
  };

  checkStatusInFNS = (e) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }

    const { showModalInfo } = this.context;
    const { userInfo, updateUser } = this.props;
    const { formFields } = this.state;

    this.setState({ checkingStatusInFNS: true });

    const token = `Bearer ${localStorage.getItem('id_token')}`;

    userInfo.self_employed_verified = 2;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/check-self-employed/${userInfo.id}?inn=${formFields.inn.value}`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          if (response.status) {
            userInfo.self_employed_verified = 1;
          }
          showModalInfo(response.message);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        updateUser(userInfo);
        this.setState({ checkingStatusInFNS: false });
      });
  };

  render() {
    const {
      formFields,
      documents,
      uploading,
      offerForLegal,
      offerForNatural,
      openModalChangeEmail,
      phoneCodeVerfied,
      verifyingPhoneCode,
      phoneCode,
      subscribeEmail,
      curator,
      checkingStatusInFNS,
    } = this.state;
    const { userInfo } = this.props;
    let userStatus;
    if (userInfo.confirmed === 'yes') {
      userStatus = 'Подтвержден';
    } else if (userInfo.confirmed === 'no') {
      userStatus = 'Не подтвержден';
    } else if (userInfo.confirmed === 'no_need') {
      userStatus = 'Не нуждается в подтверждении';
    } else if (userInfo.confirmed === 'under_review') {
      userStatus = 'На проверке';
    } else if (userInfo.confirmed === 'need_update') {
      userStatus = 'Требуется уточнение';
    }
    const naturalForm = (
      <>
        <h2>Реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="inn">ИНН</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    value={formFields.inn.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.inn.errorVisible })}
                    placeholder="111111111111"
                    name="inn"
                    id="inn"
                    mask={getMask('inn').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.agentTypeCode.value === 'self_employed' ? (
                    <>
                      {userInfo.self_employed_verified === 1 && <div className="ml-3 d-inline-block"><FontAwesomeIcon icon={faCheck} className="fa-fw" color="green" size="2x" /></div>}
                      {userInfo.self_employed_verified === 2 && <div className="ml-3 d-inline-block"><FontAwesomeIcon icon={faTimes} className="fa-fw" color="red" size="2x" /></div>}
                    </>
                  ) : null}
                </div>
                {formFields.inn.errorVisible && (
                  <div className="validation-error">
                    {formFields.inn.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="snils">СНИЛС</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    value={formFields.snils.value}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.snils.errorVisible })}
                    placeholder="111111111111"
                    name="snils"
                    id="snils"
                    mask={getMask('snils').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                </div>
                {formFields.snils.errorVisible && (
                  <div className="validation-error">
                    {formFields.snils.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {formFields.agentTypeCode.value === 'self_employed' ? (
          <div className="form-group row">
            <div className="col-12">
              <button className="btn btn-success" type="button" onClick={this.checkStatusInFNS}>Проверить статус в ФНС</button>
              {checkingStatusInFNS && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
            </div>
          </div>
        ) : null}
        <h2>Паспортные данные</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="passportSerial">Серия</label>
                <InputMask
                  value={formFields.passportSerial.value}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.passportSerial.errorVisible })}
                  placeholder="1111"
                  name="passportSerial"
                  id="passportSerial"
                  mask={getMask('passportSerial').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.passportSerial.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportSerial.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="passportNumber">Номер</label>
                <InputMask
                  value={formFields.passportNumber.value}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.passportNumber.errorVisible })}
                  placeholder="111111"
                  name="passportNumber"
                  id="passportNumber"
                  mask={getMask('passportNumber').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.passportNumber.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportNumber.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="passportDate">Дата выдачи</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  onBlur={this.handleValidate}
                  className={classnames('form-control', { error: formFields.passportDate.errorVisible })}
                  selected={formFields.passportDate.value ? parseISO(formFields.passportDate.value) : null}
                  onChange={(date) => this.handleChangeDate(date, 'passportDate')}
                  name="passportDate"
                  id="passportDate"
                  locale={ru}
                  maxDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formFields.passportDate.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportDate.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="birthday">Дата рождения</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  onBlur={this.handleValidate}
                  className={classnames('form-control', { error: formFields.birthday.errorVisible })}
                  selected={formFields.birthday.value ? parseISO(formFields.birthday.value) : null}
                  onChange={(date) => this.handleChangeDate(date, 'birthday')}
                  name="birthday"
                  id="birthday"
                  locale={ru}
                  maxDate={subYears(new Date(), 18)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formFields.birthday.errorVisible && (
                  <div className="validation-error">
                    {formFields.birthday.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-9">
                <label htmlFor="passportIssuedBy">Кем выдан</label>
                <ReactDadata
                  suggestionType="fms"
                  name="passportIssuedBy"
                  className={classnames('form-control', { error: formFields.passportIssuedBy.errorVisible })}
                  query={formFields.passportIssuedBy.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangePassportIssuedBy(e, 'passportIssuedBy')}
                  placeholder=""
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                />
                {formFields.passportIssuedBy.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportIssuedBy.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3">
                <label htmlFor="passportIssuedByCode">Код подразделения</label>
                <InputMask
                  value={formFields.passportIssuedByCode.value}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.passportIssuedByCode.errorVisible })}
                  placeholder="1111"
                  name="passportIssuedByCode"
                  id="passportIssuedByCode"
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                />
                {formFields.passportIssuedByCode.errorVisible && (
                  <div className="validation-error">
                    {formFields.passportIssuedByCode.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-9">
                <label htmlFor="userAddress">Адрес регистрации</label>
                <ReactDadata
                  name="userAddress"
                  className={classnames('form-control', { error: formFields.userAddress.errorVisible })}
                  query={formFields.userAddress.value ? formFields.userAddress.value.value : ''}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangeAddress(e, 'userAddress')}
                  placeholder=""
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                />
                {formFields.userAddress.errorVisible && (
                  <div className="validation-error">
                    {formFields.userAddress.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-9">
                <label htmlFor="birthdayAddress">Место рождения</label>
                <ReactDadata
                  country="*"
                  from_bound="city"
                  to_bound="settlement"
                  name="birthdayAddress"
                  className={classnames('form-control', { error: formFields.birthdayAddress.errorVisible })}
                  query={formFields.birthdayAddress.value ? formFields.birthdayAddress.value.value : ''}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangeAddress(e, 'birthdayAddress')}
                  placeholder=""
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                />
                {formFields.birthdayAddress.errorVisible && (
                  <div className="validation-error">
                    {formFields.birthdayAddress.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <h2>Банковские реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-6">
                <label htmlFor="bankName">Наименование банка</label>
                <ReactDadata
                  suggestionType="bank"
                  name="bankName"
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  className={classnames('form-control', { error: formFields.bankName.errorVisible })}
                  query={formFields.bankName.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={this.handleChangeBankName}
                  placeholder="Введите название, БИК или адрес"
                />
                {formFields.bankName.errorVisible && (
                  <div className="validation-error">
                    {formFields.bankName.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="paymentCard">Номер карты</label>
                <InputMask
                  value={formFields.paymentCard.value}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.paymentCard.errorVisible })}
                  placeholder="1111 1111 1111 1111"
                  name="paymentCard"
                  id="paymentCard"
                  mask={getMask('paymentCard').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangePaymentCard}
                />
                {formFields.paymentCard.errorVisible && (
                  <div className="validation-error">
                    {formFields.paymentCard.errorMessage}
                  </div>
                )}
              </div>
            </div>
            {formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'self_employed' ? (
              <div className="form-group row">
                <div className="col-md-3">
                  <label htmlFor="bic">БИК банка</label>
                  <InputMask
                    value={formFields.bic.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.bic.errorVisible })}
                    placeholder="111111111"
                    name="bic"
                    id="bic"
                    mask={getMask('bic').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.bic.errorVisible && (
                    <div className="validation-error">
                      {formFields.bic.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="correspondentAccount">Корреспондентский счет</label>
                  <InputMask
                    value={formFields.correspondentAccount.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.correspondentAccount.errorVisible })}
                    placeholder="111111111"
                    name="correspondentAccount"
                    id="correspondentAccount"
                    mask={getMask('paymentAccount').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.correspondentAccount.errorVisible && (
                    <div className="validation-error">
                      {formFields.correspondentAccount.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="settlementAccount">Расчетный счет</label>
                  <InputMask
                    value={formFields.settlementAccount.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.settlementAccount.errorVisible })}
                    placeholder="111111111"
                    name="settlementAccount"
                    id="settlementAccount"
                    mask={getMask('paymentAccount').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.settlementAccount.errorVisible && (
                    <div className="validation-error">
                      {formFields.settlementAccount.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
    const legalForm = (
      <>
        <h2>Реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-6">
                <label htmlFor="organizationName">Наименование</label>
                <ReactDadata
                  suggestionType="party"
                  name="organizationName"
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  className={classnames('form-control', { error: formFields.organizationName.errorVisible })}
                  query={formFields.organizationName.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={this.handleChangeOrganizationName}
                  placeholder="Введите название, ИНН или ОГРН"
                />
                {formFields.organizationName.errorVisible && (
                  <div className="validation-error">
                    {formFields.organizationName.errorMessage}
                  </div>
                )}
              </div>
              {formFields.agentTypeCode.value === 'legal' && (
                <div className="col-md-3">
                  <label htmlFor="ogrn">ОГРН</label>
                  <InputMask
                    value={formFields.ogrn.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.ogrn.errorVisible })}
                    placeholder="1111111111111"
                    name="ogrn"
                    id="ogrn"
                    mask={getMask('ogrn').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.ogrn.errorVisible && (
                    <div className="validation-error">
                      {formFields.ogrn.errorMessage}
                    </div>
                  )}
                </div>
              )}
              {formFields.agentTypeCode.value === 'individual' && (
                <div className="col-md-3">
                  <label htmlFor="ogrnip">ОГРНИП</label>
                  <InputMask
                    value={formFields.ogrnip.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.ogrnip.errorVisible })}
                    placeholder="1111111111111"
                    name="ogrnip"
                    id="ogrnip"
                    mask={getMask('ogrnip').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.ogrnip.errorVisible && (
                    <div className="validation-error">
                      {formFields.ogrnip.errorMessage}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="inn">{formFields.agentTypeCode.value === 'legal' ? 'ИНН ЮЛ или ЕГРЮЛ' : 'ИНН ИП или ЕГРИП'}</label>
                <InputMask
                  value={formFields.inn.value}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.inn.errorVisible })}
                  placeholder="1111111111"
                  name="inn"
                  id="inn"
                  mask={getMask('inn').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.inn.errorVisible && (
                  <div className="validation-error">
                    {formFields.inn.errorMessage}
                  </div>
                )}
              </div>
              {formFields.agentTypeCode.value === 'legal' && (
                <div className="col-md-3">
                  <label htmlFor="kpp">КПП</label>
                  <InputMask
                    value={formFields.kpp.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.kpp.errorVisible })}
                    placeholder="111111111"
                    name="kpp"
                    id="kpp"
                    mask={getMask('kpp').mask}
                    onBlur={this.handleValidate}
                    onChange={this.handleChangeInput}
                  />
                  {formFields.kpp.errorVisible && (
                    <div className="validation-error">
                      {formFields.kpp.errorMessage}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {formFields.agentTypeCode.value === 'individual' ? (
          <>
            <h2>Паспортные данные</h2>
            <div className="card mb-3">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="passportSerial">Серия</label>
                    <InputMask
                      value={formFields.passportSerial.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.passportSerial.errorVisible })}
                      placeholder="1111"
                      name="passportSerial"
                      id="passportSerial"
                      mask={getMask('passportSerial').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.passportSerial.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportSerial.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="passportNumber">Номер</label>
                    <InputMask
                      value={formFields.passportNumber.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.passportNumber.errorVisible })}
                      placeholder="111111"
                      name="passportNumber"
                      id="passportNumber"
                      mask={getMask('passportNumber').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.passportNumber.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportNumber.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="passportDate">Дата выдачи</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formFields.passportDate.errorVisible })}
                      selected={formFields.passportDate.value ? parseISO(formFields.passportDate.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'passportDate')}
                      name="passportDate"
                      id="passportDate"
                      locale={ru}
                      maxDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formFields.passportDate.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportDate.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="birthday">Дата рождения</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formFields.birthday.errorVisible })}
                      selected={formFields.birthday.value ? parseISO(formFields.birthday.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'birthday')}
                      name="birthday"
                      id="birthday"
                      locale={ru}
                      maxDate={subYears(new Date(), 18)}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formFields.birthday.errorVisible && (
                      <div className="validation-error">
                        {formFields.birthday.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-9">
                    <label htmlFor="passportIssuedBy">Кем выдан</label>
                    <ReactDadata
                      suggestionType="fms"
                      name="passportIssuedBy"
                      className={classnames('form-control', { error: formFields.passportIssuedBy.errorVisible })}
                      query={formFields.passportIssuedBy.value}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangePassportIssuedBy(e, 'passportIssuedBy')}
                      placeholder=""
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    />
                    {formFields.passportIssuedBy.errorVisible && (
                      <div className="validation-error">
                        {formFields.passportIssuedBy.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-9">
                    <label htmlFor="userAddress">Адрес регистрации</label>
                    <ReactDadata
                      name="userAddress"
                      className={classnames('form-control', { error: formFields.userAddress.errorVisible })}
                      query={formFields.userAddress.value ? formFields.userAddress.value.value : ''}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangeAddress(e, 'userAddress')}
                      placeholder=""
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    />
                    {formFields.userAddress.errorVisible && (
                      <div className="validation-error">
                        {formFields.userAddress.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <h2>Юридический адрес</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-9">
                <ReactDadata
                  name="legalAddress"
                  className={classnames('form-control', { error: formFields.legalAddress.errorVisible })}
                  query={formFields.legalAddress.value ? formFields.legalAddress.value.value : ''}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => this.handleChangeAddress(e, 'legalAddress')}
                  placeholder=""
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                />
                {formFields.legalAddress.errorVisible && (
                  <div className="validation-error">
                    {formFields.legalAddress.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <h2>Фактический адрес</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-12">
                <div className="form-check custom-checkbox">
                  <input
                    onChange={this.handleSetActualMatchLegal}
                    checked={formFields.actualMatchLegal.value}
                    className="form-check-input checkbox-styled"
                    type="checkbox"
                    name="actualMatchLegal"
                    id="actualMatchLegal"
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  />
                  <label className="form-check-label" htmlFor="actualMatchLegal">
                    Совпадает с юридическим адресом
                  </label>
                </div>
              </div>
            </div>
            {!formFields.actualMatchLegal.value && (
              <div className="form-group row">
                <div className="col-lg-9">
                  <ReactDadata
                    name="actualAddress"
                    className={classnames('form-control', { error: formFields.actualAddress.errorVisible })}
                    query={formFields.actualAddress.value ? formFields.actualAddress.value.value : ''}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => this.handleChangeAddress(e, 'actualAddress')}
                    placeholder=""
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  />
                  {formFields.actualAddress.errorVisible && (
                    <div className="validation-error">
                      {formFields.actualAddress.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <h2>Банковские реквизиты</h2>
        <div className="card mb-3">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-9">
                <label htmlFor="bankName">Наименование банка</label>
                <ReactDadata
                  suggestionType="bank"
                  name="bankName"
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  className={classnames('form-control', { error: formFields.bankName.errorVisible })}
                  query={formFields.bankName.value}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={this.handleChangeBankName}
                  placeholder="Введите название, БИК или адрес"
                />
                {formFields.bankName.errorVisible && (
                  <div className="validation-error">
                    {formFields.bankName.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="bic">БИК банка</label>
                <InputMask
                  value={formFields.bic.value}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.bic.errorVisible })}
                  placeholder="111111111"
                  name="bic"
                  id="bic"
                  mask={getMask('bic').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.bic.errorVisible && (
                  <div className="validation-error">
                    {formFields.bic.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="correspondentAccount">Корреспондентский счет</label>
                <InputMask
                  value={formFields.correspondentAccount.value}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.correspondentAccount.errorVisible })}
                  placeholder="111111111"
                  name="correspondentAccount"
                  id="correspondentAccount"
                  mask={getMask('paymentAccount').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.correspondentAccount.errorVisible && (
                  <div className="validation-error">
                    {formFields.correspondentAccount.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label htmlFor="settlementAccount">Расчетный счет</label>
                <InputMask
                  value={formFields.settlementAccount.value}
                  disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                  maskChar=""
                  className={classnames('form-control', { error: formFields.settlementAccount.errorVisible })}
                  placeholder="111111111"
                  name="settlementAccount"
                  id="settlementAccount"
                  mask={getMask('paymentAccount').mask}
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.settlementAccount.errorVisible && (
                  <div className="validation-error">
                    {formFields.settlementAccount.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {formFields.agentTypeCode.value === 'legal' && (
          <>
            <h2>Руководитель организации</h2>
            <div className="card mb-3">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="chiefPosition">Должность руководителя</label>
                    <input
                      value={formFields.chiefPosition.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      className={classnames('form-control', { error: formFields.chiefPosition.errorVisible })}
                      placeholder="Директор"
                      name="chiefPosition"
                      id="chiefPosition"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPosition.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPosition.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="chiefLastName">Фамилия</label>
                    <InputMask
                      formatChars={getMask('name').formatChars}
                      value={formFields.chiefLastName.value}
                      maskChar=""
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      className={classnames('form-control', { error: formFields.chiefLastName.errorVisible })}
                      placeholder="Иванов"
                      name="chiefLastName"
                      id="chiefLastName"
                      mask={getMask('name').mask}
                      onBlur={this.handleValidate}
                      onChange={(e) => this.handleChangeInput(e, true)}
                    />
                    {formFields.chiefLastName.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefLastName.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefFirstName">Имя</label>
                    <InputMask
                      formatChars={getMask('name').formatChars}
                      value={formFields.chiefFirstName.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefFirstName.errorVisible })}
                      placeholder="Иван"
                      name="chiefFirstName"
                      id="chiefFirstName"
                      mask={getMask('name').mask}
                      onBlur={this.handleValidate}
                      onChange={(e) => this.handleChangeInput(e, true)}
                    />
                    {formFields.chiefFirstName.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefFirstName.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefMiddleName">Отчество</label>
                    <InputMask
                      formatChars={getMask('name').formatChars}
                      value={formFields.chiefMiddleName.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefMiddleName.errorVisible })}
                      placeholder="Иванович"
                      name="chiefMiddleName"
                      id="chiefMiddleName"
                      mask={getMask('name').mask}
                      onBlur={this.handleValidate}
                      onChange={(e) => this.handleChangeInput(e, true)}
                    />
                    {formFields.chiefMiddleName.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefMiddleName.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="passportSerial">Серия</label>
                    <InputMask
                      value={formFields.chiefPassportSerial.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefPassportSerial.errorVisible })}
                      placeholder="1111"
                      name="chiefPassportSerial"
                      id="chiefPassportSerial"
                      mask={getMask('passportSerial').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPassportSerial.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportSerial.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefPassportNumber">Номер</label>
                    <InputMask
                      value={formFields.chiefPassportNumber.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefPassportNumber.errorVisible })}
                      placeholder="111111"
                      name="chiefPassportNumber"
                      id="chiefPassportNumber"
                      mask={getMask('passportNumber').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPassportNumber.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportNumber.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefPassportDate">Дата выдачи</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formFields.chiefPassportDate.errorVisible })}
                      selected={formFields.chiefPassportDate.value ? parseISO(formFields.chiefPassportDate.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'chiefPassportDate')}
                      name="chiefPassportDate"
                      id="chiefPassportDate"
                      locale={ru}
                      maxDate={new Date()}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formFields.chiefPassportDate.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportDate.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-9">
                    <label htmlFor="chiefPassportIssuedBy">Кем выдан</label>
                    <ReactDadata
                      suggestionType="fms"
                      name="passportIssuedBy"
                      className={classnames('form-control', { error: formFields.chiefPassportIssuedBy.errorVisible })}
                      query={formFields.chiefPassportIssuedBy.value}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangePassportIssuedBy(e, 'chiefPassportIssuedBy')}
                      placeholder=""
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    />
                    {formFields.chiefPassportIssuedBy.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPassportIssuedBy.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label htmlFor="chiefEmail">Email</label>
                    <input
                      value={formFields.chiefEmail.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      className={classnames('form-control', { error: formFields.chiefEmail.errorVisible })}
                      placeholder="director@gmail.com"
                      name="chiefEmail"
                      id="chiefEmail"
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefEmail.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefEmail.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="chiefPhone">Телефон</label>
                    <InputMask
                      formatChars={getMask('phone').formatChars}
                      value={formFields.chiefPhone.value}
                      disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      maskChar=""
                      className={classnames('form-control', { error: formFields.chiefPhone.errorVisible })}
                      placeholder="+7 (911) 999-99-99"
                      name="chiefPhone"
                      id="chiefPhone"
                      mask={getMask('phone').mask}
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                    />
                    {formFields.chiefPhone.errorVisible && (
                      <div className="validation-error">
                        {formFields.chiefPhone.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
    return (
      <>
        <div className="form-inline mt-2 mb-2">
          <span className="switch-text">Подписка на e-mail рассылку</span>
          <label className="switch">
            <input disabled={uploading} checked={subscribeEmail} type="checkbox" />
            <span onClick={this.handleChangeSubscribeEmail} className="slider round" />
          </label>
        </div>
        <button disabled={uploading} className="btn btn-lg btn-success mb-3" type="button" onClick={this.resetPassword}>Сбросить пароль</button>
        {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
          closeIconSize={16}
          open={openModalChangeEmail}
          onClose={this.onCloseModalChangeEmail}
          center
        >
          {phoneCodeVerfied
            ? (
              <form onSubmit={this.handleChangeEmailSubmit}>
                <h4>Изменение адреса электронной почты</h4>
                <SimpleNotification offsetBottom="1rem">На новую почту будет отправлена ссылка для подтверждения изменения.</SimpleNotification>
                <label htmlFor="newEmail">Введите адрес</label>
                <input
                  value={formFields.newEmail.value}
                  disabled={uploading}
                  className={classnames('form-control', { error: formFields.newEmail.errorVisible })}
                  placeholder="yyy@gmail.com"
                  name="newEmail"
                  id="newEmail"
                  onBlur={this.handleValidate}
                  onChange={this.handleChangeInput}
                />
                {formFields.newEmail.errorVisible && (
                  <div className="validation-error">
                    {formFields.newEmail.errorMessage}
                  </div>
                )}
                <button disabled={uploading} className="btn btn-success mt-2" type="submit">Отправить</button>
                {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </form>
            )
            : (
              <form onSubmit={this.sendVerifyPhone}>
                <h4>Проверка номера телефона</h4>
                <SimpleNotification>На ваш номер телефона {userInfo.phone} был отправлен код подтверждения. Пожалуйста, введите его в строке ниже.</SimpleNotification>
                <label htmlFor="phoneCode">Введите код</label>
                <input disabled={verifyingPhoneCode} id="phoneCode" name="phoneCode" value={phoneCode} onChange={this.handleChangePhoneCode} className="form-control mb-2" placeholder="Код" required="" type="text" />
                <button disabled={verifyingPhoneCode} className="btn btn-success" type="submit">Отправить</button>
                {verifyingPhoneCode && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </form>
            )}
        </Modal>
        <div className="card mb-3">
          <div className="form-group row">
            <div className="col-md-3">
              <label htmlFor="status">Статус профиля</label>
              <input disabled type="text" className="form-control" id="status" name="status" value={userStatus}/>
              {userInfo.confirmed === 'yes' && <FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="fa-fw fa-lg"/>}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-3">
              <label htmlFor="phone">Номер телефона</label>
              <input value={userInfo.phone ? userInfo.phone : ''} id="phone" name="phone" className="form-control" type="text" disabled/>
            </div>
            <div className="col-lg-9">
              <label className="hidden d-block">hide</label>
              {uploading ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38}/> : <button type="button" className="btn btn-success mb-2 mb-md-0" onClick={this.handleChangePhone}>Изменить номер</button>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="mail">Электронная почта</label>
              <input value={userInfo.email ? userInfo.email : ''} id="mail" name="mail" className="form-control" type="text" disabled/>
            </div>
            <div className="col-lg-9">
              <label className="hidden d-block">hide</label>
              <button type="button" className="btn btn-success mb-2 mb-md-0" onClick={this.onOpenModalChangeEmail}>Изменить почту</button>
            </div>
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <h2>Ваше ФИО</h2>
          <div className="card mb-3">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-3">
                  <label htmlFor="lastName">Фамилия</label>
                  <InputMask
                    formatChars={getMask('name').formatChars}
                    value={formFields.lastName.value}
                    maskChar=""
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    className={classnames('form-control', { error: formFields.lastName.errorVisible })}
                    placeholder="Иванов"
                    name="lastName"
                    id="lastName"
                    mask={getMask('name').mask}
                    onBlur={this.handleValidate}
                    onChange={(e) => this.handleChangeInput(e, true)}
                  />
                  {formFields.lastName.errorVisible && (
                    <div className="validation-error">
                      {formFields.lastName.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="firstName">Имя</label>
                  <InputMask
                    formatChars={getMask('name').formatChars}
                    value={formFields.firstName.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.firstName.errorVisible })}
                    placeholder="Иван"
                    name="firstName"
                    id="firstName"
                    mask={getMask('name').mask}
                    onBlur={this.handleValidate}
                    onChange={(e) => this.handleChangeInput(e, true)}
                  />
                  {formFields.firstName.errorVisible && (
                    <div className="validation-error">
                      {formFields.firstName.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <label htmlFor="middleName">Отчество</label>
                  <InputMask
                    formatChars={getMask('name').formatChars}
                    value={formFields.middleName.value}
                    disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                    maskChar=""
                    className={classnames('form-control', { error: formFields.middleName.errorVisible })}
                    placeholder="Иванович"
                    name="middleName"
                    id="middleName"
                    mask={getMask('name').mask}
                    onBlur={this.handleValidate}
                    onChange={(e) => this.handleChangeInput(e, true)}
                  />
                  {formFields.middleName.errorVisible && (
                    <div className="validation-error">
                      {formFields.middleName.errorMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <h2>Ваш куратор</h2>
          <div className="card mb-3">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-3">
                  <label htmlFor="curatorPhone">Номер телефона</label>
                  <input value={curator.phone ? curator.phone : ''} className="form-control" id="curatorPhone" type="text" disabled />
                </div>
                <div className="col-md-3">
                  <label htmlFor="curatorEmail">Электронная почта</label>
                  <input value={curator.email ? curator.email : ''} className="form-control" id="curatorEmail" type="text" disabled />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-3">
                  <label htmlFor="curatorLastName">Фамилия</label>
                  <input value={curator.lastName ? curator.lastName : ''} className="form-control" id="curatorLastName" type="text" disabled />
                </div>
                <div className="col-md-3">
                  <label htmlFor="curatorFirstName">Имя</label>
                  <input value={curator.firstName ? curator.firstName : ''} className="form-control" id="curatorFirstName" type="text" disabled />
                </div>
                <div className="col-md-3">
                  <label htmlFor="curatorMiddleName">Отчество</label>
                  <input value={curator.middleName ? curator.middleName : ''} className="form-control" id="curatorMiddleName" type="text" disabled />
                </div>
              </div>
            </div>
          </div>
          <h2>Вы работаете как</h2>
          <div className="card mb-3">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-12">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} checked={formFields.agentTypeCode.value === 'self_employed'} type="radio" value="self_employed" id="paymentInfoSelfEmployed" name="agentTypeCode" className="custom-control-input" onChange={this.handleChangeInput} />
                    <label className="custom-control-label" htmlFor="paymentInfoSelfEmployed">Физическое лицо (самозанятый)</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} checked={formFields.agentTypeCode.value === 'legal'} type="radio" value="legal" id="paymentInfoLegal" name="agentTypeCode" className="custom-control-input" onChange={this.handleChangeInput} />
                    <label className="custom-control-label" htmlFor="paymentInfoLegal">Юридическое лицо</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} checked={formFields.agentTypeCode.value === 'individual'} type="radio" id="paymentInfoIndividual" value="individual" name="agentTypeCode" className="custom-control-input" onChange={this.handleChangeInput} />
                    <label className="custom-control-label" htmlFor="paymentInfoIndividual">ИП</label>
                  </div>
                  {userInfo.partner_agent_id !== 8270 ? (
                    <div className="custom-control custom-radio custom-control-inline">
                      <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} checked={formFields.agentTypeCode.value === 'natural'} type="radio" id="paymentInfoNatural" value="natural" name="agentTypeCode" className="custom-control-input" onChange={this.handleChangeInput} />
                      <label className="custom-control-label" htmlFor="paymentInfoNatural">Физическое лицо</label>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {(formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'self_employed') ? naturalForm : legalForm }
          <h2>Документы</h2>
          <div className="card mb-3">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-lg-5">
                  <label className="d-block" htmlFor="passport_page_one">{formFields.agentTypeCode.value === 'legal' ? 'Фото паспорта руководителя с датой рождения' : 'Фото паспорта с датой рождения'}</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{documents.passport_page_one.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.passport_page_one.name}</div>
                    {(documents.passport_page_one.path || documents.passport_page_one.file)
                      ? (<div aria-disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onClick={() => userInfo.confirmed === 'no' && this.handleDeleteFile('passport_page_one')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onChange={this.handleChangeFile} type="file" id="passport_page_one" name="passport_page_one" className="custom-input-file" />
                  </div>
                  {documents.passport_page_one.path && (
                    <div className="jq-file__download">
                      <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.passport_page_one.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                    </div>
                  )}
                  {documents.passport_page_one.errorMessage && (
                    <div className="validation-error">
                      {documents.passport_page_one.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-5">
                  <label className="d-block" htmlFor="passport_page_two">{formFields.agentTypeCode.value === 'legal' ? 'Фото паспорта руководителя с адресом регистрации' : 'Фото паспорта с адресом регистрации'}</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{documents.passport_page_two.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.passport_page_two.name}</div>
                    {(documents.passport_page_two.path || documents.passport_page_two.file)
                      ? (<div aria-disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onClick={() => userInfo.confirmed === 'no' && this.handleDeleteFile('passport_page_two')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onChange={this.handleChangeFile} type="file" id="passport_page_two" name="passport_page_two" className="custom-input-file" />
                  </div>
                  {documents.passport_page_two.path && (
                    <div className="jq-file__download">
                      <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.passport_page_two.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                    </div>
                  )}
                  {documents.passport_page_two.errorMessage && (
                    <div className="validation-error">
                      {documents.passport_page_two.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-5">
                  <label className="d-block" htmlFor="innDocument">ИНН</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{documents.inn.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.inn.name}</div>
                    {(documents.inn.path || documents.inn.file)
                      ? (<div aria-disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onClick={() => userInfo.confirmed === 'no' && this.handleDeleteFile('inn')} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />)}
                    <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onChange={this.handleChangeFile} type="file" id="innDocument" name="inn" className="custom-input-file" />
                  </div>
                  {documents.inn.path && (
                    <div className="jq-file__download">
                      <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.inn.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                    </div>
                  )}
                  {documents.inn.errorMessage && (
                    <div className="validation-error">
                      {documents.inn.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              {(formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'individual' || formFields.agentTypeCode.value === 'self_employed') && (
                <div className="form-group row">
                  <div className="col-lg-5">
                    <label className="d-block" htmlFor="snils">СНИЛС</label>
                    <div className="jq-file styler">
                      <div className="jq-file__name">{documents.snils.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.snils.name}</div>
                      {(documents.snils.path || documents.snils.file)
                        ? (<div aria-disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onClick={() => userInfo.confirmed === 'no' && this.handleDeleteFile('snils')} className="jq-file__remove" />)
                        : (<div className="jq-file__browse" />)}
                      <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onChange={this.handleChangeFile} type="file" id="snils" name="snils" className="custom-input-file" />
                    </div>
                    {documents.snils.path && (
                      <div className="jq-file__download">
                        <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.snils.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                      </div>
                    )}
                    {documents.snils.errorMessage && (
                      <div className="validation-error">
                        {documents.snils.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(formFields.agentTypeCode.value === 'legal' || formFields.agentTypeCode.value === 'individual') && (
                <div className="form-group row">
                  <div className="col-lg-5">
                    <label className="d-block" htmlFor="registration_certificate">{formFields.agentTypeCode.value === 'legal' ? 'Свидетельство о регистрации ЮЛ' : 'Свидетельство о регистрации ИП'}</label>
                    <div className="jq-file styler">
                      <div className="jq-file__name">{documents.registration_certificate.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.registration_certificate.name}</div>
                      {(documents.registration_certificate.path || documents.registration_certificate.file)
                        ? (<div aria-disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onClick={() => userInfo.confirmed === 'no' && this.handleDeleteFile('registration_certificate')} className="jq-file__remove" />)
                        : (<div className="jq-file__browse" />)}
                      <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onChange={this.handleChangeFile} type="file" id="registration_certificate" name="registration_certificate" className="custom-input-file" />
                    </div>
                    {documents.registration_certificate.path && (
                      <div className="jq-file__download">
                        <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.registration_certificate.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                      </div>
                    )}
                    {documents.registration_certificate.errorMessage && (
                      <div className="validation-error">
                        {documents.registration_certificate.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(formFields.agentTypeCode.value === 'self_employed') && (
                <div className="form-group row">
                  <div className="col-lg-5">
                    <label className="d-block" htmlFor="self_employed_document">Справка о постановке на учет самозанятого</label>
                    <div className="jq-file styler">
                      <div className="jq-file__name">{documents.self_employed_document.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.self_employed_document.name}</div>
                      {(documents.self_employed_document.path || documents.self_employed_document.file)
                        ? (<div aria-disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onClick={() => userInfo.confirmed === 'no' && this.handleDeleteFile('self_employed_document')} className="jq-file__remove" />)
                        : (<div className="jq-file__browse" />)}
                      <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onChange={this.handleChangeFile} type="file" id="self_employed_document" name="self_employed_document" className="custom-input-file" />
                    </div>
                    {documents.self_employed_document.path && (
                      <div className="jq-file__download">
                        <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.self_employed_document.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                      </div>
                    )}
                    {documents.self_employed_document.errorMessage && (
                      <div className="validation-error">
                        {documents.self_employed_document.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(formFields.agentTypeCode.value === 'natural' || formFields.agentTypeCode.value === 'self_employed') && (
                <div className="form-group row">
                  <div className="col-lg-12">
                    <div className="form-check custom-checkbox">
                      <input
                        onChange={this.handleChangeInput}
                        checked={formFields.offer.value}
                        className="form-check-input checkbox-styled"
                        type="checkbox"
                        name="offer"
                        id="offer"
                        disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'}
                      />
                      <label className="form-check-label" htmlFor="offer">
                        C <a rel="noopener noreferrer" target="_blank" className="dotted_link" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${offerForNatural}`}>документом оферты</a> ознакомлен
                      </label>
                      {formFields.offer.errorVisible && (
                        <div className="validation-error">
                          {formFields.offer.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {(formFields.agentTypeCode.value === 'individual' || formFields.agentTypeCode.value === 'legal') && (
                <div className="form-group row">
                  <div className="col-lg-5">
                    <label className="d-block" htmlFor="offer">Заявление на присоединение к Договору-оферте</label>
                    <div className="jq-file styler">
                      <div className="jq-file__name">{documents.offer.downloaded ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /></> : documents.offer.name}</div>
                      {(documents.offer.path || documents.offer.file)
                        ? (<div aria-disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onClick={() => userInfo.confirmed === 'no' && this.handleDeleteFile('offer')} className="jq-file__remove" />)
                        : (<div className="jq-file__browse" />)}
                      <input disabled={userInfo.confirmed !== 'no' && userInfo.confirmed !== 'need_update' && userInfo.confirmed !== 'no_need'} onChange={this.handleChangeFile} type="file" id="offer" name="offer" className="custom-input-file" />
                    </div>
                    {documents.offer.path && (
                      <div className="jq-file__download">
                        <a aria-label="Скачать" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${documents.offer.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                      </div>
                    )}
                    {documents.offer.errorMessage && (
                      <div className="validation-error">
                        {documents.offer.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 mt-2 mt-md-0">
                    <label className="hidden d-none d-lg-block">hide</label>
                    <a type="button" rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${offerForLegal}`} className="btn custom-btn">Скачать форму</a>
                  </div>
                </div>
              )}
              {formFields.paymentInfoComment.value ? (
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label htmlFor="paymentInfoComment">Комментарий по уточнению платежных данных (от куратора)</label>
                    <AutoResizingTextarea
                      disabled
                      onBlur={this.handleValidate}
                      onChange={this.handleChangeInput}
                      id="paymentInfoComment"
                      placeholder="Оставьте свой комментарий здесь"
                      name="paymentInfoComment"
                      className={classnames('form-control', { error: formFields.paymentInfoComment.errorVisible })}
                      value={formFields.paymentInfoComment.value}
                    />
                    {formFields.paymentInfoComment.errorVisible && (
                      <div className="validation-error">
                        {formFields.paymentInfoComment.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <button disabled={uploading || userInfo.confirmed === 'yes' || userInfo.confirmed === 'under_review'} className="btn btn-success mr-2" type="submit">Отправить данные</button>
          <button onClick={(e) => this.handleSubmit(e, true)} disabled={uploading || userInfo.confirmed === 'yes' || userInfo.confirmed === 'under_review'} className="btn btn-success" type="button">Сохранить</button>
          {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
        </form>
      </>
    );
  }
}

PaymentInfo.contextType = ModalMessagesContext;

export default PaymentInfo;
