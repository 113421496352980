import React, { useState, useRef, useContext } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import CheckOsagoTable from './CheckOsagoTable';
import CheckOsagoForm from './CheckOsagoForm';
import CheckOsagoFileLoad from './CheckOsagoFileLoad';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import TabNavList from '../../Layout/Tabs/TabNavList';
import TabNavItem from '../../Layout/Tabs/TabNavItem';
import Button from '../../Layout/Button';
import CustomIcon from '../../Layout/CustomIcon';

export default function CheckOsago(props) {
  const { employee, parserApiIsPaid } = props;

  const tableRef = useRef();

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [requestWaitingFlag, setRequestWaitingFlag] = useState(false);
  const [policyRequestedData, setPolicyRequestedData] = useState([]);
  const [searchInitFlag, setSearchInitFlag] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const checkPolicy = (parameters) => {
    if (requestWaitingFlag) {
      showModalInfo('Дождитесь окончания предыдущего расчета');
    } else {
      setPolicyRequestedData([]);
      setSearchInitFlag(false);
      setRequestWaitingFlag(true);
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policyInfo`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          parameters,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error, 'error');
          } else {
            const responseFilteredData = [];
            response.forEach((element) => {
              if (!('error' in element) && element.number) {
                responseFilteredData.push(element);
              }
              return false;
            });
            setPolicyRequestedData([...responseFilteredData]);
          }
        })
        .catch((error) => {
          error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
          showModalInfo(error.customMessage, 'error');
        })
        .finally(() => {
          setSearchInitFlag(true);
          setRequestWaitingFlag(false);
        });
    }
  };

  const downloadReport = (e) => {
    e.preventDefault();
    tableRef.current.downloadReport();
  };

  const openModalTableCustomized = () => {
    tableRef.current.openModalTableCustomized(true);
  };

  return (
    <>
      <div className="position-relative">
        <h1 className="page_title d-inline-block mr-3">Проверка полиса ОСАГО</h1>
        {policyRequestedData.length ? (
          <>
            <div className="page_header__control mb-2 mb-md-0 mr-2">
              <Button colorType="grayBordered" type="border" width="auto" onClick={openModalTableCustomized}>
                <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                Настроить таблицу
              </Button>
            </div>
            <div className="page_header__control mb-2 mb-md-0">
              <Button colorType="grayBordered" type="border" width="auto" onClick={downloadReport}>
                <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
                Скачать в Excel
              </Button>
            </div>
          </>
        ) : null}
        <h2>Получение данных полиса ОСАГО по ВИН и гос. номеру</h2>
      </div>
      <TabNavList>
        <TabNavItem
          active={activeTab === '1'}
          onClick={() => { setActiveTab('1'); }}
          text="Ручной ввод"
        />
        {(employee === 1 || parserApiIsPaid === 1) && (
          <TabNavItem
            active={activeTab === '2'}
            onClick={() => { setActiveTab('2'); }}
            text="Загрузка из файла"
          />
        )}
      </TabNavList>
      <TabContent animation="false" activeTab={activeTab}>
        <TabPane tabId="1">
          <CheckOsagoForm checkPolicy={checkPolicy} requestWaitingFlag={requestWaitingFlag} />
        </TabPane>
        {(employee === 1 || parserApiIsPaid === 1) && (
          <TabPane tabId="2">
            <CheckOsagoFileLoad requestWaitingFlag={requestWaitingFlag} checkPolicy={checkPolicy} />
          </TabPane>
        )}
      </TabContent>
      {searchInitFlag && (
        <>
          {!policyRequestedData.length ? (
            <div className="row">
              <div className="col-12">
                <h2>Не удалось получить данные по полису. Попробуйте ввести другой ВИН или гос. номер.</h2>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <h2>Полученные данные</h2>
                </div>
              </div>
              <CheckOsagoTable policyDataList={policyRequestedData} tab={activeTab} ref={tableRef} requestWaitingFlag={requestWaitingFlag} />
            </>
          )}
        </>
      )}
    </>
  );
}
