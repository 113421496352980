import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleEditForm: false,
      saving: false,
      subscribeEmail: false,
      user: {
        firstName: '',
        middleName: '',
        lastName: '',
        id: '',
      },
      curator: {
        firstName: '',
        middleName: '',
        lastName: '',
        phone: '',
        email: '',
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userInfo.id && props.userInfo.id !== state.user.id) {
      return {
        subscribeEmail: props.userInfo.subscribe_email,
        user: {
          firstName: props.userInfo.first_name,
          middleName: props.userInfo.middle_name,
          lastName: props.userInfo.last_name,
          id: props.userInfo.id,
        },
        curator: {
          firstName: props.userInfo.curator_obj.first_name,
          middleName: props.userInfo.curator_obj.middle_name,
          lastName: props.userInfo.curator_obj.last_name,
          phone: props.userInfo.curator_obj.phone,
          email: props.userInfo.curator_obj.email,
        },
      };
    }
    return null;
  }

  handleChangeToggle = () => {
    this.setState((prevState) => ({
      isToggleEditForm: !prevState.isToggleEditForm,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { user } = this.state;
    const { userInfo } = this.props;
    this.setState({ saving: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    userInfo.first_name = user.firstName;
    userInfo.last_name = user.lastName;
    userInfo.middle_name = user.middleName;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${userInfo.id}`, {
      method: 'put',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: user.firstName,
        middle_name: user.middleName,
        last_name: user.lastName,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        this.props.updateUser(userInfo);
        this.setState({
          isToggleEditForm: false,
          saving: false,
        });
      });
  };

  handleChange = (event) => {
    const {
      name, value,
    } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  resetPassword = (e) => {
    const { showModalInfo } = this.context;
    e.preventDefault();
    this.setState({ saving: true });
    const { userInfo } = this.props;
    fetch(`${process.env.REACT_APP_API_DOMAIN}/password/create`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: userInfo.email }),
    })
      .then((response) => response.json())
      .then(() => {
        showModalInfo('Пароль выслан вам на почту');
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ saving: false });
      });
  };

  handleChangeSubscribeEmail = () => {
    const { showModalInfo } = this.context;
    this.setState({ saving: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState((prevState) => ({
      subscribeEmail: !prevState.subscribeEmail,
    }), () => {
      const { subscribeEmail } = this.state;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/changeSubscribeEmail`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ subscribe_email: subscribeEmail }),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ saving: false });
          if (response.error) {
            showModalInfo(response.error);
          } else {
            const text = subscribeEmail ? 'Вы успешно подписались на рассылку' : 'Вы успешно отписались от рассылки';
            showModalInfo(text);
          }
        })
        .catch(() => {
          showModalInfo('Произошла ошибка, повторите попытку.');
          this.setState({ saving: false });
        });
    });
  };

  render() {
    const { userInfo } = this.props;
    const {
      isToggleEditForm, saving, user, subscribeEmail, curator,
    } = this.state;
    return (
      <>
        <div className="form-inline mt-2 mb-2">
          <span className="switch-text">Подписка на e-mail рассылку</span>
          <label className="switch">
            <input disabled={saving} checked={subscribeEmail} type="checkbox" />
            <span onClick={this.handleChangeSubscribeEmail} className="slider round" />
          </label>
        </div>
        <button disabled={saving} className="btn btn-lg btn-success mb-2 d-block" type="button" onClick={this.resetPassword}>Сбросить пароль</button>
        {saving && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
        {(userInfo.confirmed === 'no' || userInfo.confirmed === 'no_need') && !isToggleEditForm
        && (<button type="button" className="btn btn-success mb-3" onClick={this.handleChangeToggle}>Редактировать</button>)}
        <form className="mb-4" onSubmit={this.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-3">
                  <label htmlFor="phone">Номер телефона</label>
                  <input value={userInfo.phone ? userInfo.phone : ''} name="phone" className="form-control" id="phone" type="text" disabled />
                </div>
                <div className="col-md-3">
                  <label htmlFor="mail">Электронная почта</label>
                  <input value={userInfo.email ? userInfo.email : ''} name="mail" className="form-control" id="mail" type="text" disabled />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-3">
                  <label htmlFor="lastName">Фамилия</label>
                  <input value={user.lastName} name="lastName" className="form-control" id="lastName" type="text" disabled={!isToggleEditForm || (userInfo.confirmed !== 'no' && userInfo.confirmed !== 'no_need')} onChange={this.handleChange} />
                </div>
                <div className="col-md-3">
                  <label htmlFor="firstName">Имя</label>
                  <input value={user.firstName} name="firstName" className="form-control" id="firstName" type="text" disabled={!isToggleEditForm || (userInfo.confirmed !== 'no' && userInfo.confirmed !== 'no_need')} onChange={this.handleChange} />
                </div>
                <div className="col-md-3">
                  <label htmlFor="middleName">Отчество</label>
                  <input value={user.middleName} name="middleName" className="form-control" id="middleName" type="text" disabled={!isToggleEditForm || (userInfo.confirmed !== 'no' && userInfo.confirmed !== 'no_need')} onChange={this.handleChange} />
                </div>
                <div className="col-md-3">
                  <label htmlFor="id">ID</label>
                  <input value={user.id} name="id" className="form-control" id="id" type="text" disabled />
                </div>
              </div>
              {isToggleEditForm && ([
                <div key="4" className="form-row mt-3">
                  <div className="col-md-9">
                    <button disabled={saving} type="submit" className="btn btn-success mr-2">Сохранить</button>
                    <button type="button" className="btn btn-secondary" onClick={this.handleChangeToggle}>Отмена</button>
                    {saving && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
                  </div>
                </div>,
              ])}
            </div>
          </div>
        </form>
        <div className="card">
          <div className="card-body">
            <h2>Ваш куратор</h2>
            <div className="form-group row">
              <div className="col-md-3">
                <label htmlFor="curatorPhone">Номер телефона</label>
                <input value={curator.phone ? curator.phone : ''} className="form-control" id="curatorPhone" type="text" disabled />
              </div>
              <div className="col-md-3">
                <label htmlFor="curatorEmail">Электронная почта</label>
                <input value={curator.email ? curator.email : ''} className="form-control" id="curatorEmail" type="text" disabled />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3">
                <label htmlFor="curatorLastName">Фамилия</label>
                <input value={curator.lastName ? curator.lastName : ''} className="form-control" id="curatorLastName" type="text" disabled />
              </div>
              <div className="col-md-3">
                <label htmlFor="curatorFirstName">Имя</label>
                <input value={curator.firstName ? curator.firstName : ''} className="form-control" id="curatorFirstName" type="text" disabled />
              </div>
              <div className="col-md-3">
                <label htmlFor="curatorMiddleName">Отчество</label>
                <input value={curator.middleName ? curator.middleName : ''} className="form-control" id="curatorMiddleName" type="text" disabled />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

UserInfo.contextType = ModalMessagesContext;

export default UserInfo;
