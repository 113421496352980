import React from 'react';
import styled from 'styled-components';

export const ContainerWrapperIcon = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: ${(props) => (props.topIcon ? '4px' : '0')};
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const IconArrow = styled.div`
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: max-content;
  //min-width: 52px;
  background: ${(props) => (props.iconBackground || '#e4faed')};
  color: ${(props) => (props.iconColor || '#039a09')};
  padding-left: ${(props) => (props.topIcon ? '4px' : '3px')};
  padding-right: ${(props) => (props.topIcon ? '8px' : '5px')};
  @media (max-width: 767px) {
    padding-left: ${(props) => (props.topIcon ? '4px' : '2px')};
    padding-right: ${(props) => (props.topIcon ? '8px' : '3px')};
  }
  p {
    margin: 0;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 22px!important;
    @media (max-width: 767px) {
      font-size: ${(props) => (props.topIcon ? '12px !important' : '10px !important')};
    }
  }
  
  svg {
    transform: ${(props) => (props.rotate || 'rotate(180deg)')};
  }
`;

export default function DashboardProgressPercent(props) {
  const {
    children, disabled, width, iconBackground, iconColor, rotate, topIcon,
  } = props;

  return (
    <ContainerWrapperIcon disabled={disabled} width={width} topIcon={topIcon}>
      <IconArrow iconBackground={iconBackground} iconColor={iconColor} rotate={rotate} topIcon={topIcon}>
        {Array.isArray(children) ? (
          children.map((child) => child)
        ) : (
          children
        )}
      </IconArrow>
    </ContainerWrapperIcon>
  );
}
