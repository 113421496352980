import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faStar } from '@fortawesome/fontawesome-free-solid';
import { format } from 'date-fns';
import ReactLoading from 'react-loading';
import Tooltip from 'rc-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faClone, faEnvelope } from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import ym from 'react-yandex-metrika';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

class OsagoCheckInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: null,
      crossPrice: null,
      crossCommission: null,
      crossProductName: null,
      commission: null,
      paymentLink: null,
      emailForLink: this.props.formData.email.value,
      errorMessage: false,
      loadingPaymentLink: true,
      visibleTooltipCopyToClipboard: false,
      openModalVerifyPhone: false,
      verifyingPhoneCode: false,
      verifyPhoneCode: '',
      openModalSendLinkToEmail: false,
      linkForMail: '',
      costForMail: '',
      inPoolChanged: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { choosenOffer, policy } = this.props;
    if (choosenOffer && !policy.is_demo) {
      this.calculate();
    }
  }

  calculate = () => {
    const { choosenOffer, formIdHash } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: lsToken,
      },
      body: JSON.stringify({
        draftHash: formIdHash,
        insurance: choosenOffer.companyCode,
        crossProduct: choosenOffer.crossProduct,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          this.setState({
            price: data.price ? parseFloat(data.price) : null,
            crossPrice: data.chosenCrossProduct ? parseFloat(data.chosenCrossProduct.price) : null,
            crossProductName: data.chosenCrossProduct ? data.chosenCrossProduct.name : null,
            crossCommission: data.chosenCrossProduct ? parseFloat(data.chosenCrossProduct.commission) : null,
            commission: data.commission,
          });
          this.getPaymentLink();
        } else {
          this.setState({
            loadingPaymentLink: false,
            errorMessage: data.error,
          });
        }
      })
      .catch(() => {
        this.setState({
          loadingPaymentLink: false,
          errorMessage: 'Ошибка при формировании ссылки',
        });
      });
  }

  getPaymentLink = () => {
    this.setState({
      loadingPaymentLink: true,
    });
    const { formIdHash } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/getPaymentLink?draftHash=${formIdHash}`, {
      method: 'GET',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ loadingPaymentLink: false });
        if (response.error) {
          this.setState({ errorMessage: response.error });
        } else if (response.needSmsCode) {
          this.setState({ openModalVerifyPhone: true });
        } else {
          this.setState({ paymentLink: response.paymentLink });
        }
      })
      .catch(() => {
        this.setState({
          errorMessage: 'Не пройден скоринг. Выберите другое предложение.',
          loadingPaymentLink: false,
        });
      });
  };

  onCloseModalVerifyPhone = () => {
    this.setState({ openModalVerifyPhone: false });
  };

  onCloseModalSendLinkToEmail = () => {
    this.setState({ openModalSendLinkToEmail: false });
  };

  sendVerifyPhone = (e) => {
    const { showModalInfo } = this.context;
    e.preventDefault();
    const { verifyPhoneCode } = this.state;
    const { formIdHash, choosenOffer } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ verifyingPhoneCode: true });

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/verifyPhone`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        verifyPhoneCode: verifyPhoneCode.toString(),
        draftHash: formIdHash,
        service: 'true',
        chosenInsurance: choosenOffer.companyCode,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          if (response.paymentLink) {
            this.setState({ paymentLink: response.paymentLink });
          } else {
            this.getPaymentLink();
          }
          this.setState({ openModalVerifyPhone: false });
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
      })
      .finally(() => {
        this.setState({ verifyingPhoneCode: false });
      });
  };

  copyToClipBoard = () => {
    this.setState(
      { visibleTooltipCopyToClipboard: true },
      () => {
        setTimeout(() => {
          this.setState({ visibleTooltipCopyToClipboard: false });
        }, 3000);
      },
    );
    this.submitPayment();
  };

  sendLinkToEmail = (e) => {
    e.preventDefault();
    const {
      emailForLink,
      linkForMail,
      costForMail,
    } = this.state;
    const {
      formId,
      companies,
      choosenOffer,
    } = this.props;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/sendLinkToEmail`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailForLink,
        link: linkForMail,
        cost: costForMail,
        policyId: formId,
        company: companies[choosenOffer.companyCode].name,
        product: 'osago',
      }),
    })
      .then((response) => response.json())
      .then(() => {
        this.setState({ openModalSendLinkToEmail: false });
      });
  };

  handleChangeEmail = (e) => {
    this.setState({
      emailForLink: e.target.value,
    });
  };

  openModalSendLinkToEmail = (e, costForMail, linkForMail) => {
    this.setState({
      openModalSendLinkToEmail: true,
      costForMail,
      linkForMail,
    });
    this.submitPayment();
  };

  submitPayment = () => {
    ym('reachGoal', 'buyPolicy');
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { formIdHash, disableField } = this.props;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/payment?draftHash=${formIdHash}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(() => {
        disableField();
      });
  }

  render() {
    const {
      openModalSendLinkToEmail,
      openModalVerifyPhone,
      loadingPaymentLink,
      errorMessage,
      visibleTooltipCopyToClipboard,
      verifyingPhoneCode,
      paymentLink,
      verifyPhoneCode,
      emailForLink,
      commission,
      price,
      crossPrice,
      crossProductName,
      crossCommission,
      inPoolChanged,
    } = this.state;
    const {
      formData,
      backToPickCompany,
      backToEditForm,
      choosenOffer,
      companies,
      hideCommission,
      policy,
      prolongationChangeDateFlag,
      disableField,
    } = this.props;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
          closeIconSize={16}
          open={openModalSendLinkToEmail}
          onClose={this.onCloseModalSendLinkToEmail}
          center
        >
          <form onSubmit={this.sendLinkToEmail}>
            <label htmlFor="inputEmail">Укажите адрес электронной почты</label>
            <input value={emailForLink} id="inputEmail" name="email" className="form-control mb-2" placeholder="Email" required="" type="email" onChange={this.handleChangeEmail} />
            <SimpleNotification>На указанный адрес отправлено письмо со ссылкой на оплату</SimpleNotification>
            <button className="btn btn-success mt-2" type="submit">Отправить</button>
          </form>
        </Modal>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
          closeIconSize={16}
          open={openModalVerifyPhone}
          onClose={this.onCloseModalVerifyPhone}
          center
        >
          <div>
            {paymentLink
              ? (<a type="button" rel="noopener noreferrer" target="_blank" href={paymentLink} onClick={this.submitPayment} className="btn custom-btn">Оплатить</a>)
              : (
                <form onSubmit={this.sendVerifyPhone}>
                  <h4>Получение ссылки на оплату</h4>
                  <SimpleNotification>На ваш номер телефона {formData.phone.value} был отправлен код подтверждения. Пожалуйста, введите его в строке ниже.</SimpleNotification>
                  <label htmlFor="verifyPhoneCode">Введите код</label>
                  <input disabled={verifyingPhoneCode} id="verifyPhoneCode" name="verifyPhoneCode" value={verifyPhoneCode} onChange={this.handleChangeInput} className="form-control mb-2" placeholder="Код" required="" type="text" />
                  <button disabled={verifyingPhoneCode} className="btn btn-success" type="submit">Отправить</button>
                  {verifyingPhoneCode && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
                </form>
              )}
          </div>
        </Modal>
        <div className="search_results__main page--calck">
          <SimpleNotification offsetBottom="1rem">Отлично! Вы выбрали продукт от компании {companies[choosenOffer.companyCode].name}. {choosenOffer.companyCode === 'mafin' && (<span>Страховые услуги оказывает ООО «Абсолют Страхование»</span>)}</SimpleNotification>
          <SimpleNotification offsetBottom="1rem"><b>Ссылка на оплату действует в течение 20 минут</b></SimpleNotification>
          {hideCommission === 0 && (
            <>
              {crossCommission ? (
                <SimpleNotification offsetBottom="1rem">
                  Ваше вознаграждение по полису: {loadingPaymentLink ? (<ReactLoading type="spin" className="loading-circle d-inline-block" height={20} width={20} />) : <><b>{((commission * price) / 100).toFixed(2)} рублей</b> = {commission}%</>}.
                  <br />
                  Ваше вознаграждение по кросс-продукту: {loadingPaymentLink ? (<ReactLoading type="spin" className="loading-circle d-inline-block" height={20} width={20} />) : <><b>{((crossCommission * crossPrice) / 100).toFixed(2)} рублей</b> = {crossCommission}%</>}.
                </SimpleNotification>
              ) : (
                <SimpleNotification offsetBottom="1rem">Ваше вознаграждение: {loadingPaymentLink ? (<ReactLoading type="spin" className="loading-circle d-inline-block" height={20} width={20} />) : <><b>{((commission * price) / 100).toFixed(2)} рублей</b> = {commission}%</>}.</SimpleNotification>
              )}
            </>
          )}
          <SimpleNotification offsetBottom="1rem">Проверьте правильность введенных данных.</SimpleNotification>
          {prolongationChangeDateFlag ? (
            <SimpleNotification offsetBottom="1rem">
              Дата начала действия была автоматически изменена на {format(formData.policyDate.value, 'dd.MM.yyyy')}
            </SimpleNotification>
          ) : null}
          {crossProductName ? (
              <SimpleNotification offsetBottom="1rem">
                Полис ОСАГО + кросс-продукт &laquo;{crossProductName}&raquo;
              </SimpleNotification>
          ) : null}
          <div>
            <a rel="noopener noreferrer" href="#" onClick={backToPickCompany} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
          </div>
          <div className="mb-2 d-flex">
            <span className="mr-auto align-self-center">Введенные данные</span>
            {!disableField
              ? <button type="button" onClick={backToEditForm} className="btn custom-btn">Редактировать</button>
              : null}
          </div>
          <table className="data_table">
            <thead>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Данные полиса</span></td>
                <td className="data_table__row_content">
                  <p>{companies[choosenOffer.companyCode].name} ОСАГО,</p>
                  <p><b>Дата начала:</b> {format(formData.policyDate.value, 'dd.MM.yyyy')}, <b>Срок действия:</b> {formData.policyPeriod.value.label}</p>
                </td>
              </tr>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Персональные данные страхователя</span>
                </td>
                <td className="data_table__row_content">
                  <p>
                    <b>Паспорт:</b> {formData.insuredPassportNumberSerial.value.substring(0, 4)} {formData.insuredPassportNumberSerial.value.substring(4)}, {formData.insuredPassportDate.value ? format(formData.insuredPassportDate.value, 'dd.MM.yyyy') : '**.**.****'}
                    <br />
                    {`${formData.lastNameInsured.value} ${formData.firstNameInsured.value} ${formData.middleNameInsured.value}`}, {formData.insuredBirthday.value ? format(formData.insuredBirthday.value, 'dd.MM.yyyy') : '**.**.****'}
                  </p>
                  <p><b>Адрес:</b> {formData.insuredAddress.value.value}</p>
                  <p><b>Контакты:</b> {formData.phone.value}, {formData.email.value}</p>
                </td>
              </tr>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Персональные данные собственника</span>
                </td>
                <td className="data_table__row_content">
                  <p>
                    <b>Паспорт:</b> {formData.ownerPassportNumberSerial.value.substring(0, 4)} {formData.ownerPassportNumberSerial.value.substring(4)}, {formData.ownerPassportDate.value ? format(formData.ownerPassportDate.value, 'dd.MM.yyyy') : '**.**.****'}
                    <br />
                    {`${formData.lastNameOwner.value} ${formData.firstNameOwner.value} ${formData.middleNameOwner.value}`}, {formData.ownerBirthday.value ? format(formData.ownerBirthday.value, 'dd.MM.yyyy') : '**.**.****'}
                  </p>
                  <p><b>Адрес:</b> {formData.ownerAddress.value.value}</p>
                </td>
              </tr>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Данные водителей</span></td>
                <td className="data_table__row_content">
                  {formData.unlimitedDrivers.value.value === false ? formData.lastNameDriver.map((driver, number) => (
                    <p key={number}>
                      <b>ВУ:</b> {formData.driverLicenceSerial[number].value} {formData.driverLicenceNumber[number].value}, {formData.driverExperienceDate[number].value ? format(formData.driverExperienceDate[number].value, 'dd.MM.yyyy') : '**.**.****'}
                      <br />
                      {`${formData.lastNameDriver[number].value} ${formData.firstNameDriver[number].value} ${formData.middleNameDriver[number].value}`}, {formData.driverBirthday[number].value ? format(formData.driverBirthday[number].value, 'dd.MM.yyyy') : '**.**.****'}
                    </p>
                  ))
                    : <p>Неограниченное количество</p>}
                </td>
              </tr>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Данные автомобиля</span></td>
                <td className="data_table__row_content">
                  <p>{formData.markId.value.label} {formData.modelId.value.label}, {formData.carYear.value.label}{!formData.registrationNumberBlank ? <><b>, Гос. номер:</b> {formData.carNumber.value}</> : ''},
                    <br />
                    <b>{formData.vehicleIdentificationType.value.label}:</b>
                    {formData.vehicleIdentificationType.value.value === 'vin' ? formData.vehicleIdentificationNumberVin.value : ''}
                    {formData.vehicleIdentificationType.value.value === 'body' ? formData.vehicleIdentificationNumberBody.value : ''}
                    {formData.vehicleIdentificationType.value.value === 'chassis' ? formData.vehicleIdentificationNumberChassis.value : ''}
                    <br />
                    <b>{formData.vehicleTypeDocument.value.label}</b>: {formData.vehicleTypeDocument.value.value !== 'epassport' ? `${formData.serialDocument.value} ${formData.numberDocument.value}` : formData.numberEDocument.value}, <b>Мощность:</b> {formData.horsePower.value}
                  </p>
                </td>
              </tr>
            </thead>
          </table>
          {choosenOffer.coefficients && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>КБМ</th>
                    <th>КМ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{choosenOffer.coefficients.kbm}</td>
                    <td>{choosenOffer.coefficients.km}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <SimpleNotification>Для данного вида страхование возможно использование электронной версии полиса. После оплаты страховой премии вы получите электронную версию документа в формате pdf на указанный адрес e-mail: <b>{formData.email.value}</b>.</SimpleNotification>
        </div>
        <div className="sticky-top search_results__sidebar page--search js-search_results__sidebar">
          <div className="search_results__info" style={{ margintop: '0px' }}>
            <div className="product_block">
              <div className="product_block__title">
                <span className="product_block__title-name">Е-ОСАГО</span>
              </div>
              <div className="product_block__body">
                <div className="product_block__image">
                  <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[choosenOffer.companyCode].logo}`} width="142" height="62" alt="" />
                  <div className="company_item__star mt-2">
                    {[...Array(companies[choosenOffer.companyCode].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                  </div>
                </div>
                <div className="product_block__info">
                  <div className="product_block__info_title">Стоимость полиса</div>
                  <dl className="description_list">
                    <dt className="color-light">Полис</dt>
                    <dd>{loadingPaymentLink ? (<ReactLoading type="spin" className="loading-circle d-inline-block" height={18} width={18} />) : <b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(price)}</b>}</dd>
                    {crossPrice ? (
                      <>
                        <dt className="color-light">Кросс-продукт</dt>
                        <dd>{loadingPaymentLink ? (<ReactLoading type="spin" className="loading-circle d-inline-block" height={18} width={18} />) : <b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(crossPrice)}</b>}</dd>
                      </>
                    ) : null}
                  </dl>
                  <div className="product_block__info_footer">
                    <dl className="description_list">
                      <dt className="color-light">Итого:</dt>
                      <dd>{loadingPaymentLink ? (<ReactLoading type="spin" className="loading-circle d-inline-block" height={18} width={18} />) : <b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(price + crossPrice)}</b>}</dd>
                    </dl>
                  </div>
                </div>
                <div className="product_block__footer">
                  {policy && policy.is_demo ? (
                    <a type="button" rel="noopener noreferrer" onClick={(e) => e.preventDefault()} className="btn custom-btn mr-1">Оплатить</a>
                  ) : (
                    <div>
                      {loadingPaymentLink && (<><ReactLoading type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Получение ссылки на оплату</>)}
                    </div>
                  )}
                  {errorMessage ? (<div className="error_block">{errorMessage}</div>) : null}
                  {inPoolChanged ? (<div className="error_block mb-1">Котировка попала в пул. Будьте внимательны - страховая премия и размер КВ могли измениться.</div>) : null}
                  {paymentLink && !loadingPaymentLink && !errorMessage
                    ? (
                      <>
                        <a type="button" rel="noopener noreferrer" target="_blank" href={paymentLink} onClick={this.submitPayment} className="btn custom-btn mr-1">Оплатить</a>
                        <Tooltip
                          animation="zoom"
                          placement="topRight"
                          visible={visibleTooltipCopyToClipboard}
                          overlay={<span>Ссылка скопирована в буфер обмена</span>}
                          trigger=""
                          overlayClassName="custom-tooltip"
                        >
                          <CopyToClipboard text={paymentLink}>
                            <button type="button" title="Скопировать ссылку на оплату" onClick={(e) => this.copyToClipBoard(e)} className="btn btn-outline btn-square mr-1">
                              <FontAwesomeIcon icon={faClone} className="fa-fw" />
                            </button>
                          </CopyToClipboard>
                        </Tooltip>
                        <button type="button" title="Отправить ссылку на оплату" onClick={(e) => this.openModalSendLinkToEmail(e, choosenOffer.policyCost, paymentLink)} className="btn btn-outline btn-square">
                          <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
                        </button>
                        {choosenOffer.commission !== commission ? (<div className="error_block">Комиссионное вознаграждение изменилось.</div>) : null}
                      </>
                    ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

OsagoCheckInfo.contextType = ModalMessagesContext;

export default OsagoCheckInfo;
