import React, { Component } from 'react';
import queryString from 'query-string';
import RegistrationInviteForm from './RegistrationInviteForm';
import RegistrationCompleteForm from './RegistrationCompleteForm';
import RegistrationSimpleForm from './RegistrationSimpleForm';
import AuthService from '../Utils/AuthService';
import Footer from '../Layout/Footer';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
    this.state = {
      formType: 'complete',
      isFramed: 0,
    };
  }

  componentWillMount() {
    const { history } = this.props;
    if (this.Auth.loggedIn()) { history.replace('/products'); }
  }

  componentDidMount() {
    let isFramed;
    try {
      isFramed = window !== window.top || document !== top.document || self.location !== top.location;
    } catch (e) {
      isFramed = 1;
    }
    if (process.env.FORCE_WIDGET) isFramed = 1;
    this.setState({ isFramed });
    document.title = 'Регистрация';
    document.title = `Регистрация | ${window.location.host}`;
    const { location } = this.props;
    const values = queryString.parse(location.search);
    if (values.email && values.invite_code) {
      this.setState({ formType: 'invite' });
    } else if (values.simple) {
      this.setState({ formType: 'simple' });
    }
  }

  render() {
    const { formType, isFramed } = this.state;
    const { location, history, theme } = this.props;
    return (
      <div style={{ height: '100%' }} className="d-flex flex-column">
        {!isFramed ? (
          <nav className="navbar navbar-expand-lg navbar-dark">
            <a className="navbar-brand mr-auto" href={theme.domain === 'agents' ? 'https://b2b.polis.online' : '/'}>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logoNav}`} alt="" />
            </a>
          </nav>
        ) : null }
        <div className="container-fluid d-flex flex-fill flex-column">
          {formType === 'invite' && (<RegistrationInviteForm theme={theme} history={history} location={location} />)}
          {formType === 'complete' && (<RegistrationCompleteForm theme={theme} history={history} location={location} />)}
          {formType === 'simple' && (<RegistrationSimpleForm theme={theme} history={history} location={location} />)}
          {!isFramed ? (<Footer history={history} theme={theme} />) : null}
        </div>
      </div>
    );
  }
}

export default Registration;
