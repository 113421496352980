import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import DkpForm from './DkpForm';
import DkpTable from './DkpTable';
import TabNavList from '../../Layout/Tabs/TabNavList';
import TabNavItem from '../../Layout/Tabs/TabNavItem';

export default class Dkp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    document.title = 'Договор купли-продажи';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    window.history.replaceState(null, null, `?activeTab=${tab}`);
  }

  render() {
    const { activeTab } = this.state;
    const {
      match, history, location, userInfo,
    } = this.props;
    return (
      <>
        <h1 className="page_title">Договор купли-продажи автомобиля</h1>
        <div>
          <TabNavList>
            <TabNavItem
              onClick={() => { this.toggle('1'); }}
              active={activeTab === '1'}
              text="Новый договор"
            />
            <TabNavItem
              onClick={() => { this.toggle('2'); }}
              active={activeTab === '2'}
              text="Черновики"
            />
            {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
              <TabNavItem
                onClick={() => { this.toggle('3'); }}
                active={activeTab === '3'}
                text="Все черновики (админ)"
              />
            )}
          </TabNavList>
          <TabContent animation="false" activeTab={activeTab}>
            <TabPane tabId="1">
              { activeTab === '1' ? <DkpForm userInfo={userInfo} location={location} history={history} match={match} tab="1" /> : null }
            </TabPane>
            <TabPane tabId="2">
              { activeTab === '2' ? <DkpTable history={history} tab="2" type="draft" /> : null }
            </TabPane>
            {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
              <TabPane tabId="3">
                { activeTab === '3' ? <DkpTable history={history} tab="3" type="all" /> : null }
              </TabPane>
            )}
          </TabContent>
        </div>
      </>
    );
  }
}
