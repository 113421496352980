import React from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import { customSelectStyle, loadSuggestions } from '../../Utils/Helpers';

export default function AccountSystem({
  userRole,
  disableField,
  setLinkToDeal,
  linkToDeal,
  leadSourcesOptions,
  leadSource,
  setLeadSource,
  remoteEmployee,
  managerAttractedOptions,
  managerAttracted,
  setManagerAttracted,
  customStyles,
  blockNumber,
  userFullTimeEmployee,
  customFieldOptions,
  customField,
  customFieldName,
  setCustomField,
  digitalBroker,
  newAgent,
  setNewAgent,
  domain,
}) {
  return (
    <div className="card mb-4">
      <div className="card-header fill_section__title">
        <span className="title_num">
          {blockNumber}
        </span>
        <span>Данные для системы учета</span>
      </div>
      <div className="card-body">
        {userFullTimeEmployee ? (
          <div className="form-group row">
            <div className="col-lg-4 mb-3 mb-lg-0">
              <label htmlFor="linkToDeal">Ссылка на сделку</label>
              <InputMask
                required={userRole === 'user'}
                maskChar=""
                disabled={disableField}
                onChange={(e) => setLinkToDeal(e.target.value)}
                id="linkToDeal"
                className="form-control"
                placeholder="https://crm.infullbroker.ru/crm/deal/details/XXX/"
                name="linkToDeal"
                value={linkToDeal || ''}
              />
            </div>
            <div className="col-lg-4 mb-3 mb-lg-0">
              <label htmlFor="leadSource">Источник привлечения</label>
              <Select
                menuPlacement="auto"
                styles={customStyles}
                name="leadSource"
                isDisabled={disableField}
                placeholder="Реклама"
                classNamePrefix="react-select"
                inputId="leadSource"
                value={leadSourcesOptions.filter(({ value }) => value === leadSource)}
                onChange={(e) => setLeadSource(e.value)}
                className="form-control-custom"
                options={leadSourcesOptions}
                noOptionsMessage={() => 'Не найдено'}
              />
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={leadSource}
                required={userRole === 'user' && !remoteEmployee}
              />
            </div>
            <div className="col-lg-4 mb-3 mb-lg-0">
              <label htmlFor="managerAttracted">Менеджер привлек</label>
              <Select
                menuPlacement="auto"
                styles={customStyles}
                name="managerAttracted"
                isDisabled={disableField}
                placeholder="Иванов Иван Иванович"
                classNamePrefix="react-select"
                inputId="managerAttracted"
                value={managerAttractedOptions.filter(({ value }) => value === managerAttracted)}
                onChange={(e) => setManagerAttracted(e.value)}
                className="form-control-custom"
                options={managerAttractedOptions}
                noOptionsMessage={() => 'Не найдено'}
              />
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={managerAttracted}
                required={userRole === 'user' && !remoteEmployee}
              />
            </div>
          </div>
        ) : null}
        {customFieldOptions.length > 0 ? (
          <div className="form-group row">
            <div className="col-lg-4">
              <label htmlFor="customField">{customFieldName}</label>
              <Select
                menuPlacement="auto"
                styles={customStyles}
                name="customField"
                isDisabled={disableField}
                placeholder="Произвольное поле"
                classNamePrefix="react-select"
                inputId="customField"
                value={customField}
                onChange={(e) => setCustomField(e)}
                className="form-control-custom"
                options={customFieldOptions}
                noOptionsMessage={() => 'Не найдено'}
              />
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={customField.value}
                required
              />
            </div>
          </div>
        ) : null}
        {digitalBroker === 1 ? (
          <div className="form-group row">
            <div className="col-lg-4">
              <label htmlFor="customField">Партнер за которым закрепить продажу</label>
              <AsyncSelect
                value={newAgent.value ? newAgent : null}
                classNamePrefix="react-select"
                placeholder="Выберите партнера"
                name="newAgent"
                id="newAgent"
                loadingMessage={() => 'Загрузка'}
                noOptionsMessage={() => 'Не найдено'}
                onChange={setNewAgent}
                styles={customSelectStyle()}
                loadOptions={debounce(loadSuggestions('', domain), 500)}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
