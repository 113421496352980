import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/fontawesome-free-solid';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { customFetch, handleChange } from '../../Utils/Helpers';
import getMask from '../../Utils/Masks';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

export default function CargoChosenOffer(props) {
  const {
    chosenOffer,
    companies,
    backToForm,
    formData,
    disableField,
    policyStatus,
    setFormData,
    showModalInfo,
    policy,
    setChosenOffer,
    agentId,
    userRole,
  } = props;

  const url = `url(${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].logo})`;
  const [disabledBtn, setDisabledBtn] = useState(false);

  const checkAgreement = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    showModalInfo('Идёт обработка согласованной суммы');
    setDisabledBtn(true);
    if (formData.costAgreement.value !== 0 && formData.costAgreement.value) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/check_agreement`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cost: formData.costAgreement.value,
          id: policy.id,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error, 'error');
          } else {
            const { cost } = response;
            setChosenOffer((prev) => ({
              ...prev,
              price: cost,
            }));
            showModalInfo('Сумма полиса изменена');
          }
          setDisabledBtn(false);
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
          setDisabledBtn(false);
        });
    } else {
      setDisabledBtn(false);
      showModalInfo('Введите корректную сумму!');
    }
  };

  return (
    <div className="form-group row">
      <div className="col-lg-12">
        <FormNotificationColorLine>
          <div className="chosen-offer">
            <div className="chosen-offer__logo logo-box">
              <div className="logo-box__logo" style={{ backgroundImage: url }} />
            </div>
            <div className="chosen-offer__price">
              <span>
                {policyStatus === 'agreement' && !parseInt(chosenOffer.price, 10) && (userRole === 'superadmin' || agentId === 401) ? (
                  <div className="d-flex flex-row align-items-center">
                    <InputMask
                      maskChar=""
                      style={{ width: 120 }}
                      onChange={(e) => handleChange(e, 'costAgreement', formData, setFormData)}
                      id="costAgreement"
                      name="costAgreement"
                      className={classnames('form-control', { error: formData.costAgreement.errorMessage })}
                      value={formData.costAgreement.value}
                      mask={getMask('decimal').mask}
                      formatChars={getMask('decimal').formatChars}
                    />{formData.valute.value.label}
                    {formData.costAgreement.errorMessage && (
                      <div className="validation-error">
                        {formData.costAgreement.errorMessage}
                      </div>
                    )}
                    <button type="button" disabled={disabledBtn} onClick={checkAgreement} className="btn-success btn ml-2">
                      <FontAwesomeIcon icon={faCheck} className="fa-fw" style={{ width: 16, height: 16 }} />
                    </button>
                  </div>
                ) : (
                  <>
                    {chosenOffer.price ? chosenOffer.price : '-'} {formData.valute.value.label}
                  </>
                )}
              </span>
            </div>
            {(chosenOffer.agreement && policyStatus === 'agreement') || !chosenOffer.price ? (
              <div className="error_block ml-2">На согласовании!</div>) : null}
            {!disableField ? (
              <button type="button" className="btn btn-success" onClick={(e) => backToForm(e, 'short', true)}>Изменить
                данные
              </button>
            ) : null}
          </div>
          <hr className="mb-0" />
          <ul className="chosen-offer-info">
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Тип транспорта: {formData.transport.value.label}</span>
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Франшиза: {formData.franchise.value.label}</span>
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Тип груза: {formData.cargo.value.label}</span>
            </li>
            <li className="chosen-offer-info__item">
              <span
                className="chosen-offer-info__item-header"
              >Страховая сумма: {formData.costFull.value} {formData.valute.value.label}
              </span>
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Дальность перевозки: {formData.range.value} км</span>
            </li>
            <li className="chosen-offer-info__item">
              <span
                className="chosen-offer-info__item-header"
              >Погрузка/разгрузка: {formData.loadunload.value ? 'Есть' : 'Нет'}
              </span>
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">БУ груз: {formData.usedcargo.value ? 'Да' : 'Нет'}</span>
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Рефрежираторные риски: {formData.refrisks.value ? 'Есть' : 'Нет'}</span>
            </li>
          </ul>
        </FormNotificationColorLine>
      </div>
    </div>
  );
}
