import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import Button from '../Layout/Button';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../Utils/Helpers';

const Container = styled.div`
  padding: 20px 16px 10px;
  width: 100%;
  align-self: flex-end;
  background-color: #f6f6f6;
  border-radius: 4px;

  & Button:first-of-type {
    margin-bottom: 8px;
  }
`;

const PercentContainer = styled.div`
  margin-bottom: 21px;

  & span{
    margin: 0 0 8px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #737373;
  }
`;

const PercentBar = styled.div`
  position: relative;
  width: 100%;
  height: 9px;
  border-radius: 18px;
  background-color: #e9e9e9;
  border-radius: 18px;
`;

const PercentBarFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #01e250;
  width: ${(props) => (`${props.percent}%` || 0)};
  border-radius: 18px;
`;

function InterfaceTourSidebar(props) {
  const {
    userInfo, updateUser, getTourList, setTour, theme,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const disableInterfaceTours = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/interface-tours/disable`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const newUserInfo = { ...userInfo };
          newUserInfo.show_interface_tours = false;
          updateUser(newUserInfo);
          localStorage.removeItem('interfaceTourData');
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка запроса деактивации обучения', 'error');
      });
  };

  const goToNextTour = (e) => {
    e.preventDefault();

    const tourList = getTourList();
    const nextTour = tourList.find((tour) => tour.id === userInfo.next_tour_id);

    setTour(nextTour.code);
  };

  const colorSchema = useMemo(() => ({
    main: theme.mainColor,
    background: theme.mainColor,
    hover: theme.mainColorDarker,
    text: theme.buttonFontColor,
    textHover: theme.buttonFontColor,
  }), [theme]);

  return (
    <Container>
      <PercentContainer>
        <span>обучение пройдено на {userInfo.interface_tours_percent}%</span>
        <PercentBar>
          <PercentBarFill percent={userInfo.interface_tours_percent} />
        </PercentBar>
      </PercentContainer>
      <Button width="100%" colorSchema={colorSchema} onClick={goToNextTour}>Продолжить</Button>
      <Button
        width="100%"
        type="border"
        onClick={disableInterfaceTours}
        colorSchema={{
          main: '#fff',
          hover: '#f7f7f7',
          text: '#3d3d3b',
          textHover: '#3d3d3b',
          borderColor: '#cfcfcf',
          borderColorHover: '#a3a3a3',
        }}
      >
        Я знаком с сервисом
      </Button>
    </Container>
  );
}

export default InterfaceTourSidebar;
