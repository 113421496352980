import React, { Component } from 'react';
import validate from 'validate.js';
import Tooltip from 'rc-tooltip';
import Countdown from 'react-countdown-now';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid';
import ReactLoading from 'react-loading';
import { ReactDadata } from 'react-dadata';
import queryString from 'query-string';
import InputMask from 'react-input-mask';
import AuthService from '../Utils/AuthService';
import 'rc-tooltip/assets/bootstrap.css';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

const constraints = {
  password: {
    presence: {
      message: 'Заполните поле',
    },
    format: {
      pattern: '(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$',
      message: 'pattern!!!',
    },
  },
  passwordSubmit: {
    equality: 'password',
  },
};

const validator = (field, value) => {
  const object = {};
  object[field] = value;
  const constraint = constraints[field];
  const result = validate(object, { [field]: constraint });
  if (result) {
    return result[field][0];
  }
  return null;
};

class RegistrationInviteForm extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
    this.state = {
      verificationPhone: false,
      showCountdown: false,
      validateForm: false,
      errorSmsCode: false,
      password: '',
      phone: '',
      smsCode: '',
      region: '',
      passwordError: '',
      passwordSubmitError: '',
      visiblePhone: false,
      visiblePassword: false,
      visiblePasswordSubmit: false,
      type: 'password',
    };
  }

  componentDidMount() {
    document.title = 'Регистрация';
    document.title = `Регистрация | ${window.location.host}`;
  }

  onVisibleChange = (visible) => {
    this.setState({
      [visible]: [visible],
    });
  };

  handleChangePassword = (e) => {
    const passwordError = validator('password', e.target.value);
    this.setState({
      password: e.target.value,
      passwordError,
    });
  };

  handleChangePasswordSubmit = (e) => {
    const { password } = this.state;
    const passwordSubmitError = validate({
      passwordSubmit: e.target.value, password,
    }, constraints);
    this.setState({
      passwordSubmitError,
    });
  };

  handleChangePhone = (e) => {
    this.setState({ phone: e.target.value });
  };

  handleChangeSmsCode = (e) => {
    this.setState({ smsCode: e.target.value }, function () {
      if (this.state.smsCode.length === 4) {
        this.handleFormValidationSubmit();
      }
    });
  };

  handleFormValidationSubmit = () => {
    const {
      password, smsCode, region, phone,
    } = this.state;
    const { showModalInfo } = this.context;
    const { location, history } = this.props;
    this.setState({ verificationPhone: true });
    fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/verifyPhone`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone,
        code: smsCode,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'verified') {
          const values = queryString.parse(location.search);
          const invite = values.invite_code;
          const { email } = values;
          fetch(`${process.env.REACT_APP_API_DOMAIN}/signup`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              invite,
              email,
              password,
              region,
              phone,
            }),
          })
            .then((responseSignUp) => responseSignUp.json())
            .then((responseSignUp) => {
              if (responseSignUp === 'success') {
                this.Auth.login(email, password)
                  .then(() => {
                    const { from } = { from: { pathname: '/products' } };
                    history.replace(from);
                  })
                  .catch(() => {
                    showModalInfo('Неверный логин/пароль');
                  });
              } else if (responseSignUp.error) {
                showModalInfo(responseSignUp.error, 'error');
              }
            })
            .catch(() => {
              showModalInfo('Произошла ошибка, повторите попытку.');
            })
            .finally(() => {
              this.setState({ verificationPhone: false });
            });
        } else {
          this.setState({
            errorSmsCode: true,
          });
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка, повторите попытку.');
      })
      .finally(() => {
        this.setState({ verificationPhone: false });
      });
  };

  handleFormRegistrationSubmit = (e) => {
    e.preventDefault();
    const {
      passwordError, passwordSubmitError,
    } = this.state;
    if (passwordError == null) {
      this.setState({ visiblePassword: false });
      if (passwordSubmitError == null) {
        this.setState({ visiblePasswordSubmit: false });
        this.sendSms();
      } else {
        this.setState({
          visiblePasswordSubmit: true,
        });
      }
    } else {
      this.setState({
        visiblePassword: true,
      });
    }
  };

  sendSms = () => {
    const { phone } = this.state;
    fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/sendSms`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'phone_already_exist') {
          this.setState({ visiblePhone: true });
        } else {
          this.setState({
            validateForm: true,
            showCountdown: true,
          });
        }
      });
  };

  handleChangeAddress = (e) => {
    this.setState({ region: e.value });
  };

  passwordShowHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { type } = this.state;
    this.setState({
      type: type === 'input' ? 'password' : 'input',
    });
  };

  hideCountdown = () => {
    this.setState({ showCountdown: false });
  };

  render() {
    const {
      validateForm, smsCode, visiblePassword, visiblePasswordSubmit, type, visiblePhone, errorSmsCode, showCountdown, verificationPhone,
    } = this.state;
    const toolTipStyle = {
      maxWidth: '320px',
    };
    const { theme } = this.props;
    const formRegistration = (
      <form className="form-signin text-center" onSubmit={this.handleFormRegistrationSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">Регистрация</h1>
        <ReactDadata className="form-control mb-2" required bounds="city" token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed" onChange={this.handleChangeAddress} placeholder="Регион регистрации" />
        <label htmlFor="inputPhone" className="sr-only">Номер телефона</label>
        <Tooltip
          placement="top"
          visible={visiblePhone}
          animation="zoom"
          onVisibleChange={() => this.onVisibleChange('visiblePhone')}
          overlay={<span>Данный номер телефона уже зарегистрирован</span>}
          trigger=""
          overlayStyle={toolTipStyle}
        >
          <InputMask autoComplete="off" name="phone" required="" className="form-control mb-2" placeholder="Номер телефона" id="inputPhone" mask="+7 (999) 999-99-99" onChange={this.handleChangePhone} />
        </Tooltip>
        <label htmlFor="inputPassword" className="sr-only">Пароль</label>
        <Tooltip
          placement="top"
          visible={visiblePassword}
          animation="zoom"
          onVisibleChange={() => this.onVisibleChange('visiblePassword')}
          overlay={<span>Пароль должен содержать минимум 8 символов, заглавную букву, строчную букву и цифру</span>}
          trigger=""
          overlayStyle={toolTipStyle}
        >
          <input data-for="inputPassword" data-tip="inputPassword" id="inputPassword" name="password" className="form-control mb-2" placeholder="Придумайте пароль" type={type} onChange={this.handleChangePassword} />
        </Tooltip>
        <FontAwesomeIcon className="password-show" onClick={this.passwordShowHide} icon={type === 'input' ? faEyeSlash : faEye} />
        <Tooltip
          placement="top"
          visible={visiblePasswordSubmit}
          animation="zoom"
          onVisibleChange={() => this.onVisibleChange('visiblePasswordSubmit')}
          overlay={<span>Пароль должен совпадать</span>}
          trigger=""
          overlayStyle={toolTipStyle}
        >
          <input data-for="inputPasswordSubmit" data-tip="inputPasswordSubmit" id="inputPasswordSubmit" name="passwordSubmit" className="form-control" placeholder="Потдвердите пароль" type={type} onChange={this.handleChangePasswordSubmit} />
        </Tooltip>
        <div className="custom-checkbox ">
          <input required id="personalData" type="checkbox" className="checkbox-styled" />
          <label htmlFor="personalData">
            Я соглашаюсь на хранение и обработку своих данных
          </label>
        </div>
        <button className="btn btn-lg btn-success btn-block" type="submit">Продолжить</button>
      </form>
    );
    const formValidation = (
      <form className="form-signin text-center" onSubmit={this.handleFormValidationSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">{`Регистрация на ${theme.domain}.polis.online`}</h1>
        <label htmlFor="inputSmsCode" className="sr-only">Код</label>
        <Tooltip
          placement="top"
          visible={errorSmsCode}
          animation="zoom"
          onVisibleChange={() => this.onVisibleChange('errorSmsCode')}
          overlay={<span>Вы ввели неправильный код</span>}
          trigger=""
          overlayStyle={toolTipStyle}
        >
          <InputMask
            maskChar={null}
            disabled={verificationPhone}
            value={smsCode}
            name="smsCode"
            className="form-control mb-1"
            id="inputSmsCode"
            mask="9999"
            required=""
            placeholder="SMS Код"
            onChange={this.handleChangeSmsCode}
          />
        </Tooltip>
        {verificationPhone && (<ReactLoading className="loading-circle d-inline-block" type="spin" height={38} width={38} />)}
        {showCountdown
          ? (
            <Countdown
              date={Date.now() + 45000}
              renderer={(props) => <div>Отправить код повторно через {props.seconds} сек.</div>}
              onComplete={this.hideCountdown}
            />
          )
          : (<a role="button" tabIndex="0" onClick={() => this.sendSms(true)} className="dotted_link">Отправить СМС повторно</a>)}
      </form>
    );
    return (
      <>
        {validateForm ? formValidation : formRegistration}
      </>
    );
  }
}

RegistrationInviteForm.contextType = ModalMessagesContext;

export default RegistrationInviteForm;
