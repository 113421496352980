import { create } from 'zustand';

const useUserInfoStore = create((set) => ({
  userInfo: {
    confirmed: 'no_need',
    kbm_cost: 0,
    remote_employee: 0,
    created_at: null,
    egarant_cost: 0,
    parser_api_limit_is_paid: false,
    id: null,
    role: null,
    curator_obj: {},
    allow_checkbox_payment_partner: false,
    allow_agent_network: false,
    bonus_sum: 0,
    ref_quota: 0,
    balance: 0,
    currentProfit: 0,
    full_time_employee: 0,
    show_balance: 0,
    policiesCount: 0,
    premium: 0,
    hide_commission: 0,
    show_egarant_block: 0,
    unread_news_count: 0,
    post_paid: 0,
  },
  setUserInfo: (userInfo) => set({ userInfo }),
  theme: {},
  setTheme: (theme) => set({ theme}),
}));
export default useUserInfoStore;
