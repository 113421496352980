import React from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import useUserInfoStore from '../Stores/UserInfoStore';

const Link = styled.span`
    padding-bottom: 2px;
    background: linear-gradient(to right, rgba(17, 212, 95, 0.5), rgba(17, 212, 95, 0.5)) 100% 100% / 100% 0.1em no-repeat,
    linear-gradient(to right, ${(props) => (props.theme && props.theme.mainColor ? props.theme.mainColor : '#72c367')}, ${(props) => (props.theme && props.theme.mainColor ? props.theme.mainColor : '#72c367')}) 0 100% / 0 0.1em no-repeat;
    user-select: none;
    transition: background-size 0.3s, opacity 0.3s;
    cursor: pointer !important;

    &:hover,
    &:focus {
        background-size: 0 0.1em, 100% 0.1em;
    }
    
    ${(props) => (props.noSpaceAround ? '' : `
        &:before, &:after {
            content: "\\00a0";
        }`)};
`;

function LinkUnderLine(props) {
  const { children, onClick, noSpaceAround } = props;
  const { theme } = useUserInfoStore(
    (state) => ({
      theme: state.setUserInfo,
    }),
    shallow,
  );

  return (
    <Link theme={theme} onClick={onClick} noSpaceAround={noSpaceAround}>
      {Array.isArray(children) ? (
        children.map((child) => child)
      ) : (
        children
      )}
    </Link>
  );
}

export default LinkUnderLine;
