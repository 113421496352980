import React, { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import { format, parseISO } from 'date-fns';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { customFetch, toLocalDate } from '../Utils/Helpers';
import {
  ModalMessagesContext,
} from '../Utils/ContextsServices/ModalMessagesService';
import Acrobat from '../../img/acrobat.svg';

export default function FaqAnswer(props) {
  const { history, match, userRole } = props;
  const [data, setData] = useState(null);
  const { showModalInfo } = useContext(ModalMessagesContext);
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

  useEffect(() => {
    if (!data) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq/${match.params.id}`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((faqAnswer) => {
          if (!faqAnswer.error) {
            setData(faqAnswer);
          } else {
            showModalInfo(faqAnswer.error, 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        });
    }
  }, [match.params.id]);
  return (
    <>
      <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item">
          <a className="link" href="#" onClick={(e) => { e.preventDefault(); history.push('/faq_knowledge'); }}>Вопросы и ответы</a>
        </li>
        {data ? (
          <li className="breadcrumbs__item">
            <a className="link" href="#" onClick={(e) => { e.preventDefault(); history.push(`/faq_knowledge/${data.categories.id}`); }}>{data.categories.name}</a>
          </li>
        ) : null}
      </ul>
      {(userRole === 'admin' || userRole === 'superadmin') ? (
        <button type="button" className="btn btn-success mb-3" onClick={() => history.push(`/faq_answer/${match.params.id}/edit`)}>Редактировать контент</button>
      ) : null}
      {data ? (
        <div className="news-open-container mt-2">
          <h2 className="news-open-head">{data.question}</h2>
          <div className="news-open-container__info">
            <div className="news-open-container__info-autor dot-border">Автор: {`${data.creater.last_name} ${data.creater.first_name} ${data.creater.middle_name}`}</div>
            <div className="news-open-container__info-date dot-border">Дата создания: {data.created_at && format(toLocalDate(parseISO(data.created_at)), 'dd.MM.yyyy')}</div>
            <div className="news-open-container__tags">
              <a className="news-open-container__info-tags__tag" onClick={(e) => { e.preventDefault(); history.push(`/faq_knowledge/${data.categories.id}`); }}>{data.categories.name}</a>
            </div>
          </div>
          <div className="news-open-container__text">{parse(draftToHtml(JSON.parse(data.answer)))}</div>
          <div className={`news-open-container__file ${data.files?.length ? '' : 'd-none'}`}>
            {data.files && data.files.map((file) => (
              <div className="news-open-container__file-block">
                <img src={Acrobat} alt="" />
                <div className="news-open-container__file-block__info">
                  <span className="title-file">{file.path.split('/').slice(-1)[0].split('.')[0]}</span>
                  <span className="info-file">{file.path.split('/').slice(-1)[0].split('.').slice(-1)[0]}. {(file.size / 1000).toFixed(2).toString().replace('.', ',')}Kb</span>
                </div>
                <a href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${file.path}`} className="ml-auto" rel="noopener noreferrer" target="_blank">
                  <i className="icon border-0 bg-transparent mr-2 pb-1"><ArrowDownTrayIcon color="black" width={20} height={20} /></i>
                </a>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}
