import React, { Component } from 'react';
import { addDays, format, formatISO, parseISO, subYears } from 'date-fns';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-regular';
import ReactLoading from 'react-loading';
import { ReactDadata } from 'react-dadata';
import { animateScroll as scroll, Element, scroller } from 'react-scroll';
import InsuredPersons from './InsuredPersons';
import CheckInfo from './CheckInfo';
import AzureBlock from './AzureBlock';
import getMask from '../../Utils/Masks';
import { customFetch, switchLanguage } from '../../Utils/Helpers';
import getValidation from '../../Utils/Validation';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import ProgressBar from '../../Utils/UiComponents/ProgressBar';
import PolicyInfo from '../../Utils/UiComponents/PolicyInfo';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

class CovidForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policy: null,
      loadingInfoFlags: {
        all: true,
      },
      choosenOffer: {},
      showCheckForm: false,
      loadingAzure: false,
      showResults: false,
      blurClass: '',
      downloadingPolicyTemplate: false,
      showCommission: true,
      linkPdf: '',
      company: '',
      insuranceId: '',
      policyOrderDate: '',
      dropDown: '',
      policyStatus: null,
      uploading: false,
      policyId: this.props.match.params.id,
      formData: {
        insuredPersons: [
          {
            firstNameInsured: {
              value: '',
              errorMessage: '',
              errorVisible: false,
              validationType: 'name',
            },
            middleNameInsured: {
              value: '',
              errorMessage: '',
              errorVisible: false,
              validationType: 'name',
            },
            lastNameInsured: {
              value: '',
              errorMessage: '',
              errorVisible: false,
              validationType: 'name',
            },
            insuredBirthday: {
              value: null,
              errorMessage: '',
              errorVisible: false,
              validationType: 'date',
            },
          },
        ],
        firstNameInsurer: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        middleNameInsurer: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        lastNameInsurer: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        insurerBirthday: {
          value: null,
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        insurerPassportNumberSerie: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passportNumberSerie',
        },
        insurerPassportDate: {
          value: null,
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
        insurerAddress: {
          value: {
            value: '',
            data: {},
          },
          errorMessage: '',
          errorVisible: false,
          validationType: 'address',
        },
        phone: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'phone',
        },
        email: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'email',
        },
        policyDate: {
          value: formatISO(addDays(new Date(), 3)),
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
      },
      disableFields: false,
      errorNotAllowed: false,
      companies: null,
      info: {},
      policyInfo: {
        status: null,
        linkPdf: null,
        insuranceId: null,
        orderDate: null,
        company: null,
      },
    };
  }

  componentDidMount() {
    document.title = 'Оформить полис Коронавирус.НЕТ';
    const { policyId } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/info`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((info) => {
        this.setState({ info });
      });
    getCompanies(token).then((companies) => {
      this.setState({ companies });
    });
    if (policyId) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policyId}`, {
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((policy) => {
          if (policy.error) {
            this.setState({ errorNotAllowed: true });
          } else {
            const { formData } = this.state;
            Object.entries(JSON.parse(policy.form_data)).forEach(([key, value]) => {
              if (formData[key]) {
                if (Array.isArray(formData[key])) {
                  value.forEach((item, index) => {
                    if (index > 0) {
                      formData[key].push(JSON.parse(JSON.stringify(formData[key][0])));
                    }
                    Object.entries(item).forEach(([keyArray, valueArray]) => {
                      formData[key][index][keyArray].value = valueArray;
                    });
                  });
                } else if (formData[key].validationType) {
                  formData[key].value = value;
                } else {
                  formData[key] = value;
                }
              }
            });
            this.setState({
              formData,
              policyOrderDate: policy.order_date ? format(parseISO(policy.order_date), 'dd.MM.yyyy') : null,
              company: policy.company,
              linkPdf: policy.link,
              insuranceId: policy.insurance_id,
              policyStatus: policy.status,
              disableFields: policy.status !== 'pending',
            }, () => {
              this.setState((prevState) => ({
                ...prevState,
                loadingInfoFlags: {
                  ...prevState.loadingInfoFlags,
                  all: false,
                },
              }));
            });
          }
        })
        .catch(() => {
          const { showModalInfo } = this.context;
          showModalInfo('Ошибка загрузки');
          this.setState((prevState) => ({
            ...prevState,
            uploading: false,
            loadingInfoFlags: {
              ...prevState.loadingInfoFlags,
              all: false,
            },
          }));
        });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        loadingInfoFlags: {
          ...prevState.loadingInfoFlags,
          all: false,
        },
      }));
    }
  }

  handleValidate = (e) => {
    const { formData } = this.state;
    const { value } = formData[e.target.name];
    formData[e.target.name].errorMessage = getValidation(value, formData[e.target.name].validationType);
    if (formData[e.target.name].errorMessage) {
      formData[e.target.name].errorVisible = true;
    }
    this.setState({ formData });
  };

  handleValidateInsuredPerson = (e, index) => {
    const { formData } = this.state;
    const { value } = formData.insuredPersons[index][e.target.name];
    formData.insuredPersons[index][e.target.name].errorMessage = getValidation(value, formData.insuredPersons[index][e.target.name].validationType);
    if (formData.insuredPersons[index][e.target.name].errorMessage) {
      formData.insuredPersons[index][e.target.name].errorVisible = true;
    }
    this.setState({ formData });
  };

  handleChangeInputInsuredPerson = (e, index, translate = false, upperCase = false, pureValue = false) => {
    let { value } = e.target;
    if (pureValue) {
      value = value.replace(/[^a-zа-я0-9]+/ig, '');
    }
    if (translate) {
      value = switchLanguage(value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formData } = this.state;
    formData.insuredPersons[index][e.target.name].value = value;
    if (formData.insuredPersons[index][e.target.name].errorVisible) {
      formData.insuredPersons[index][e.target.name].errorVisible = false;
    }
    this.setState({
      formData,
      blurClass: 'blur',
    });
  };

  validateAllFields = () => {
    const { formData } = this.state;
    let validationSuccess = true;
    let errorBlockName = false;
    Object.keys(formData).forEach((field) => {
      if (Array.isArray(formData[field])) {
        formData[field].forEach((item, i) => {
          Object.keys(formData[field][i]).forEach((subField) => {
            if (formData[field][i][subField].validationType && document.getElementsByName(subField)[i]) {
              const { value } = formData[field][i][subField];
              formData[field][i][subField].errorMessage = getValidation(value, formData[field][i][subField].validationType);
              if (formData[field][i][subField].errorMessage) {
                validationSuccess = false;
                formData[field][i][subField].errorVisible = true;
                if (!errorBlockName) {
                  errorBlockName = subField;
                }
              }
            }
          });
        });
      } else if (formData[field].validationType && document.getElementsByName(field)[0]) {
        let value;
        if (formData[field].validationType === 'select') {
          value = formData[field].value ? formData[field].value.value : null;
        } else {
          value = formData[field].value;
        }
        formData[field].errorMessage = getValidation(value, formData[field].validationType);
        if (formData[field].errorMessage) {
          validationSuccess = false;
          formData[field].errorVisible = true;
          if (!errorBlockName) {
            errorBlockName = field;
          }
        }
      }
    });
    this.setState({
      formData,
    }, () => {
      if (errorBlockName) {
        scroller.scrollTo(errorBlockName, {
          duration: 1200,
          delay: 100,
          smooth: true,
          offset: -100,
        });
      }
    });
    return validationSuccess;
  };

  handleChangeInput = (e, translate = false, upperCase = false, pureValue = false) => {
    let { value } = e.target;
    if (pureValue) {
      value = value.replace(/[^a-zа-я0-9]+/ig, '');
    }
    if (translate) {
      value = switchLanguage(value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formData } = this.state;
    formData[e.target.name].value = value;
    if (formData[e.target.name].errorVisible) {
      formData[e.target.name].errorVisible = false;
    }
    this.setState({
      formData,
      blurClass: 'blur',
    });
  };

  handleChangeDateInsuredPerson = (date, field, index) => {
    const { formData } = this.state;
    formData.insuredPersons[index][field].value = formatISO(date);
    if (date) {
      formData.insuredPersons[index][field].errorVisible = false;
    }
    this.setState({
      formData,
      blurClass: 'blur',
    });
  };

  handleChangeDate = (date, field) => {
    const { formData } = this.state;
    formData[field].value = formatISO(date);
    if (date) {
      formData[field].errorVisible = false;
    }
    this.setState({
      formData,
      blurClass: 'blur',
    });
  };

  saveForm = (resolve) => {
    const { policyId, formData } = this.state;
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ uploading: true });
    const values = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (Array.isArray(value)) {
          const newArray = [];
          value.forEach((item) => {
            const newValue = Object.fromEntries(
              Object.entries(item).map(([key, value]) => {
                if (value.validationType) {
                  return [key, value.value];
                }
                return [key, value];
              }),
            );
            newArray.push(newValue);
          });
          return [key, newArray];
        }
        if (value.validationType) {
          return [key, value.value];
        }
        return [key, value];
      }),
    );
    if (policyId) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policyId}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ formData: values }),
      })
        .then((response) => response.json())
        .then(() => {
          showModalInfo('Черновик обновлен');
          resolve('updated');
        })
        .catch(() => {
          showModalInfo('Ошибка');
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    } else {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          formData: values,
          type: 'covid',
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          showModalInfo('Черновик сохранен');
          this.setState({
            policyId: response.id,
          }, () => {
            resolve('saved');
          });
          window.history.pushState('', '', `/covid/${response.id}`);
        })
        .catch(() => {
          showModalInfo('Ошибка');
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    }
  };

  handleSaveForm = () => {
    const promise = new Promise(this.saveForm);
    promise.then(() => {});
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    const promise = new Promise(this.saveForm);
    promise.then(() => {
      const validationSuccess = this.validateAllFields();
      if (validationSuccess) {
        this.setState({
          loadingAzure: true,
          showResults: false,
          blurClass: '',
        });
        const token = `Bearer ${localStorage.getItem('id_token')}`;
        const { formData, info } = this.state;
        const { userRole, employee } = this.props;
        let infoId = info.id;
        if (userRole === 'admin' || userRole === 'superadmin' || employee === 1) {
          infoId = 1;
        }
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/${infoId}`, {
          method: 'post',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address: formData.insurerAddress.value.data,
            product: 'covid',
          }),
        })
          .then((response) => response.json())
          .then((commissions) => {
            this.setState({
              commissions,
            });
          });
        setTimeout(() => this.setState({
          loadingAzure: false,
          showResults: true,
        }), 3000);
      }
    });
  }

  handleChooseOffer = (premium, commission) => {
    this.setState({
      showCheckForm: true,
      choosenOffer: {
        premium,
        polisOnlineCode: 'alpha',
        commission,
      },
    });
  };

  copyPolicy = (e) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    this.setState({ uploading: true, dropDown: '' });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { formData } = this.state;
    const values = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (Array.isArray(value)) {
          const newArray = [];
          value.forEach((item) => {
            const newValue = Object.fromEntries(
              Object.entries(item).map(([key, value]) => {
                if (value.validationType) {
                  return [key, value.value];
                }
                return [key, value];
              }),
            );
            newArray.push(newValue);
          });
          return [key, newArray];
        }
        if (value.validationType) {
          return [key, value.value];
        }
        return [key, value];
      }),
    );
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        formData: values,
        type: 'covid',
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        showModalInfo('Черновик скопирован');
        this.setState({
          policy: response,
          policyId: response.id,
          policyOrderDate: response.order_date ? format(parseISO(response.order_date), 'dd.MM.yyyy') : null,
          company: response.company,
          linkPdf: response.link,
          insuranceId: response.insurance_id,
          policyStatus: response.status,
          disableFields: response.status !== 'pending',
          policyInfo: {
            status: response.status,
            linkPdf: response.link,
            insuranceId: response.id,
            orderDate: response.order_date ? format(parseISO(response.order_date), 'dd.MM.yyyy') : null,
            company: response.company,
          },
        });
        window.history.pushState('', '', `/covid/${response.id}`);
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ uploading: false });
      });
  };

  handleChangeAddress = (query, name) => {
    const { formData } = this.state;
    formData[name].value = query;
    if (formData[name].errorMessage) {
      formData[name].errorVisible = false;
    }
    this.setState({
      formData,
      blurClass: 'blur',
    });
  };

  addInsured = () => {
    const { formData } = this.state;
    if (formData.insuredPersons.length < 4) {
      formData.insuredPersons.push({
        firstNameInsured: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        middleNameInsured: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        lastNameInsured: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        insuredBirthday: {
          value: null,
          errorMessage: '',
          errorVisible: false,
          validationType: 'date',
        },
      });
      this.setState({
        formData,
        blurClass: 'blur',
      });
    }
  };

  removeInsured = () => {
    const { formData } = this.state;
    if (formData.insuredPersons.length > 1) {
      formData.insuredPersons.pop();
      this.setState({
        formData,
        blurClass: 'blur',
      });
    }
  };

  copyInsurer = (index) => {
    const {
      formData,
    } = this.state;
    formData.insuredPersons[index].lastNameInsured.value = formData.lastNameInsurer.value;
    formData.insuredPersons[index].firstNameInsured.value = formData.firstNameInsurer.value;
    formData.insuredPersons[index].middleNameInsured.value = formData.middleNameInsurer.value;
    formData.insuredPersons[index].insuredBirthday.value = formData.insurerBirthday.value;
    Object.keys(formData.insuredPersons[index]).forEach((field) => {
      const { value } = formData.insuredPersons[index][field];
      formData.insuredPersons[index][field].errorVisible = false;
      formData.insuredPersons[index][field].errorMessage = getValidation(value, formData.insuredPersons[index][field].validationType);
      if (formData.insuredPersons[index][field].errorMessage) {
        formData.insuredPersons[index][field].errorVisible = true;
      }
    });
    this.setState({
      formData,
      blurClass: 'blur',
    });
  };

  handleChangeShowCommission = () => {
    this.setState((prevState) => ({
      showCommission: !prevState.showCommission,
    }));
  };

  setCursorToEnd = (e) => {
    e.target.onfocus = this.setAddressEnd;
  };

  setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  hideCheckForm = (e) => {
    e.preventDefault();
    this.setState({
      showCheckForm: false,
    }, () => {
      scroll.scrollToTop({
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    });
  };

  backToPickCompany = (e) => {
    e.preventDefault();
    this.setState({
      showCheckForm: false,
    }, () => {
      scroller.scrollTo('searchResults', {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -70,
      });
    });
  };

  goToPolicy = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/policies');
  }

  setDisableField = () => {
    this.setState({ disableFields: true });
  };

  render() {
    const {
      showCommission,
      formData,
      disableFields,
      uploading,
      errorNotAllowed,
      policyStatus,
      policyId,
      companies,
      blurClass,
      showResults,
      loadingAzure,
      showCheckForm,
      choosenOffer,
      commissions,
      policy,
      policyInfo,
    } = this.state;
    const { history, employee, userRole } = this.props;
    return (
      <>
        {showCheckForm && (<CheckInfo disableField={this.setDisableField} history={history} formData={formData} choosenOffer={choosenOffer} companies={companies} policyId={policyId} backToPickCompany={this.backToPickCompany} backToEditForm={this.hideCheckForm} />)}
        {(policyStatus === 'processing') && (
          <FormNotificationColorLine offsetBottom="1rem">Данный черновик был проведен до стадии формированния ссылки на оплату и заблокирован для дальнейших изменений. Если вы хотите произвести повторный расчет - скопируйте черновик.</FormNotificationColorLine>
        )}
        <div style={{ display: showCheckForm ? 'none' : 'block' }} className="position-relative">
          <PolicyInfo
            product="covid"
            policy={policy}
            policyInfo={policyInfo}
            copyForm={this.copyPolicy}
            goToPolicy={this.goToPolicy}
            employee={employee}
            history={history}
          />
          {errorNotAllowed && (
            <div className="row mb-3">
              <div className="col-lg-3">
                <div className="error_block">Недостаточно прав</div>
              </div>
            </div>
          )}
          <form onSubmit={this.handleSubmitForm}>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">1</span>
                <span>Данные страхователя</span>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="lastNameInsurer">Фамилия</label>
                    <InputMask
                      maskChar=""
                      onBlur={this.handleValidate}
                      disabled={disableFields}
                      onChange={(e) => this.handleChangeInput(e, true)}
                      name="lastNameInsurer"
                      placeholder="Иванов"
                      className={classnames('form-control', { error: formData.lastNameInsurer.errorVisible })}
                      mask={getMask('name').mask}
                      formatChars={getMask('name').formatChars}
                      value={formData.lastNameInsurer.value}
                    />
                    {formData.lastNameInsurer.errorVisible && (
                      <div className="validation-error">
                        {formData.lastNameInsurer.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="firstNameInsurer">Имя</label>
                    <InputMask
                      maskChar=""
                      onBlur={this.handleValidate}
                      disabled={disableFields}
                      onChange={(e) => this.handleChangeInput(e, true)}
                      name="firstNameInsurer"
                      placeholder="Иван"
                      className={classnames('form-control', { error: formData.firstNameInsurer.errorVisible })}
                      mask={getMask('name').mask}
                      formatChars={getMask('name').formatChars}
                      value={formData.firstNameInsurer.value}
                    />
                    {formData.firstNameInsurer.errorVisible && (
                      <div className="validation-error">
                        {formData.firstNameInsurer.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="middleNameInsurer">Отчество</label>
                    <InputMask
                      maskChar=""
                      onBlur={this.handleValidate}
                      disabled={disableFields}
                      onChange={(e) => this.handleChangeInput(e, true)}
                      name="middleNameInsurer"
                      placeholder="Иванович"
                      className={classnames('form-control', { error: formData.middleNameInsurer.errorVisible })}
                      mask={getMask('name').mask}
                      formatChars={getMask('name').formatChars}
                      value={formData.middleNameInsurer.value}
                    />
                    {formData.middleNameInsurer.errorVisible && (
                      <div className="validation-error">
                        {formData.middleNameInsurer.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="insurerBirthday">Дата рождения</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={disableFields}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formData.insurerBirthday.errorVisible })}
                      selected={formData.insurerBirthday.value ? parseISO(formData.insurerBirthday.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'insurerBirthday')}
                      name="insurerBirthday"
                      locale={ru}
                      maxDate={subYears(new Date(), 18)}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formData.insurerBirthday.errorVisible && (
                      <div className="validation-error">
                        {formData.insurerBirthday.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="insurerPassportNumberSerie">Серия и номер паспорта</label>
                    <InputMask
                      maskChar=""
                      onBlur={this.handleValidate}
                      disabled={disableFields}
                      onChange={(e) => this.handleChangeInput(e, false, false, true)}
                      name="insurerPassportNumberSerie"
                      placeholder="1111 111111"
                      className={classnames('form-control', { error: formData.insurerPassportNumberSerie.errorVisible })}
                      mask={getMask('passportNumberSerie').mask}
                      value={formData.insurerPassportNumberSerie.value}
                    />
                    {formData.insurerPassportNumberSerie.errorVisible && (
                      <div className="validation-error">
                        {formData.insurerPassportNumberSerie.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="insurerPassportDate">Дата выдачи паспорта</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={disableFields}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formData.insurerPassportDate.errorVisible })}
                      selected={formData.insurerPassportDate.value ? parseISO(formData.insurerPassportDate.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'insurerPassportDate')}
                      name="insurerPassportDate"
                      locale={ru}
                      maxDate={new Date()}
                      minDate={subYears(new Date(), 100)}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formData.insurerPassportDate.errorVisible && (
                      <div className="validation-error">
                        {formData.insurerPassportDate.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-lg-6">
                    <label htmlFor="insurerAddress">Адрес регистрации</label>
                    <ReactDadata
                      name="insurerAddress"
                      className={classnames('form-control', { error: formData.insurerAddress.errorVisible })}
                      query={formData.insurerAddress.value.value}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangeAddress(e, 'insurerAddress')}
                      placeholder="Можете начать с ввода улицы"
                      disabled={disableFields}
                      onFocus={this.setCursorToEnd}
                    />
                    {formData.insurerAddress.errorVisible && (
                      <div className="validation-error">
                        {formData.insurerAddress.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">2</span>
                <span>Данные застрахованного</span>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <SimpleNotification>Количество доступных предложений может быть ограничено при увеличении количества застрахованных.</SimpleNotification>
                  </div>
                </div>
                <InsuredPersons
                  insuredPersons={formData.insuredPersons}
                  handleValidate={this.handleValidateInsuredPerson}
                  handleChangeInput={this.handleChangeInputInsuredPerson}
                  addInsured={this.addInsured}
                  copyInsurer={this.copyInsurer}
                  removeInsured={this.removeInsured}
                  handleChangeDate={this.handleChangeDateInsuredPerson}
                  disableFields={disableFields}
                />
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">3</span>
                <span>Данные о полисе</span>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="col-lg-3">
                    <label htmlFor="policyDate">Дата начала действия</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={disableFields}
                      onBlur={this.handleValidate}
                      className={classnames('form-control', { error: formData.policyDate.errorVisible })}
                      selected={formData.policyDate.value ? parseISO(formData.policyDate.value) : null}
                      onChange={(date) => this.handleChangeDate(date, 'policyDate')}
                      name="policyDate"
                      locale={ru}
                      minDate={addDays(new Date(), 3)}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formData.policyDate.errorVisible && (
                      <div className="validation-error">
                        {formData.policyDate.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-9">
                    <SimpleNotification>Ближайшая дата начала действия полиса на 3 день после заключения договора.</SimpleNotification>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">4</span>
                <span>Контактные данные</span>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="col-lg-3">
                    <label htmlFor="phone">Телефон</label>
                    <InputMask
                      maskChar=""
                      onBlur={this.handleValidate}
                      disabled={disableFields}
                      onChange={this.handleChangeInput}
                      name="phone"
                      placeholder="+7 (999) 999-99-99"
                      className={classnames('form-control', { error: formData.phone.errorVisible })}
                      mask={getMask('phone').mask}
                      formatChars={getMask('phone').formatChars}
                      value={formData.phone.value}
                    />
                    {formData.phone.errorVisible && (
                      <div className="validation-error">
                        {formData.phone.errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="email">Email</label>
                    <input
                      onBlur={this.handleValidate}
                      disabled={disableFields}
                      onChange={this.handleChangeInput}
                      name="email"
                      placeholder="your@email.ru"
                      className={classnames('form-control', { error: formData.email.errorVisible })}
                      value={formData.email.value}
                    />
                    {formData.email.errorVisible && (
                      <div className="validation-error">
                        {formData.email.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-lg-12">
                <button disabled={disableFields} type="button" className="btn btn-success mr-3" onClick={this.handleSaveForm}>Сохранить</button>
                <button disabled={disableFields} type="submit" className="btn btn-success">Рассчитать</button>
                {loadingAzure && (
                  <div className="col-lg-6">
                    <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={38} width={38} />
                    <span>Идет подбор предложений..</span>
                  </div>
                )}
                {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </div>
            </div>
          </form>
          {loadingAzure && (
            <ProgressBar />
          )}
          {showResults && companies && (
            <div className="position-relative mt-2">
              <div className={`search_results__cont ${blurClass}`}>
                <Element name="searchResults"><div className="search_results__title h1">Результаты поиска</div></Element>
                <div className="form-inline mt-2">
                  <span className="switch-text">Режим работы с клиентом</span>
                  <label className="switch">
                    <input type="checkbox" />
                    <span onClick={this.handleChangeShowCommission} className="slider round" />
                  </label>
                </div>
                {(formData.insuredPersons.length > 1 && blurClass === '')
                  ? (
                    <div className="row mt-3">
                      <div className="col-lg-6">
                        <div className="error_block">Варианты не найдены. Попробуйте изменить заданные параметры или повторите расчет позднее.</div>
                      </div>
                    </div>
                  )
                  : (
                    <AzureBlock
                      disabledFields={disableFields}
                      showCommission={showCommission}
                      companies={companies}
                      handleChooseOffer={this.handleChooseOffer}
                      blurClass={blurClass}
                      commissions={commissions}
                      userRol={userRole}
                      employee={employee}
                    />
                  )}
              </div>
              {blurClass && (
                <div className="blur_message">
                  Внесены изменения в данные для расчета - необходимо обновить расчёт. Нажмите кнопку «рассчитать»
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

CovidForm.contextType = ModalMessagesContext;

export default CovidForm;
