import React, {
  useContext, useEffect, useMemo,
  useState,
} from 'react';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  customSelectStyle,
  getProducts,
  handleChange,
} from '../../Utils/Helpers';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import getBanks from '../../Utils/ClassifiersLoaders';
import CommissionInput from '../../Admin/CommissionInput';

export default function CustomContractsForm(props) {
  const {
    agent, setShowForm, selectedContract, setSelectedContract,
  } = props;
  const channelOptions = [
    { label: 'Оба', value: 'both' },
    { label: 'B2B', value: 'b2b' },
    { label: 'B2C', value: 'b2c' },
  ];
  const regions = [
    { value: 'moscow', label: 'Москва' },
    { value: 'spb', label: 'Санкт-Петербург' },
    { value: 'moscowObl', label: 'Московская обл.' },
    { value: 'spbObl', label: 'Ленинградская обл.' },
    { value: 'other', label: 'Россия' },
  ];
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [initFlag, setInitFlag] = useState(false);
  const customStyles = useMemo(() => customSelectStyle(), []);
  const [companies, setCompanies] = useState([]);
  const [products, setProducts] = useState([]);
  const [risksOptions, setRisksOptions] = useState([]);
  const [banks, setBanks] = useState([]);
  const [formData, setFormData] = useState({
    product: {
      value: { label: false, value: false },
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    risk: {
      value: { label: 'Общий', value: null },
      errorMessage: '',
      validationType: 'select',
      validationRequired: false,
    },
    channel: {
      value: { label: 'Оба', value: 'both' },
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    insurance: {
      value: false,
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    login: {
      value: '',
      errorMessage: '',
      validationType: 'plain',
      validationRequired: true,
    },
    password: {
      value: '',
      errorMessage: '',
      validationType: 'plain',
      validationRequired: true,
    },
    commission: {},
    active: {
      value: false,
      errorMessage: '',
      validationType: 'checkbox',
      validationRequired: true,
    },
    is_polis_online_contract: {
      value: false,
      errorMessage: '',
      validationType: 'checkbox',
      validationRequired: true,
    },
    authData: {
      'sberbank-strah': {
        pointOfSale: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        dealerContract: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      soglasie: {
        subUser: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        subUserPassword: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      ugoria: {
        appId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      tinkoff: {
        producerCode: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      renessans: {
        soapLogin: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        soapPassword: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      pari: {
        envoyId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        agentIdCalculate: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        agentIdAgreement: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      alpha: {
        agentContractId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        managerId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      'alpha-bank': {
        partnerProgramm: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      'alpha-retail': {
        agentContractId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        channelSaleId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        departmentId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        managerId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        signerId: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
      rshbstrah: {
        tokenAuthLogin: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
        tokenAuthPassword: {
          value: '',
          errorMessage: '',
          validationType: 'plain',
          validationRequired: true,
        },
      },
    },
  });

  const prepObjectData = (field) => {
    const data = {};
    if (field === 'commission') {
      Object.keys(formData[field]).forEach((insurance) => {
        data[insurance] = parseFloat(formData[field][insurance].value);
      });
    }
    if (field === 'authData') {
      Object.keys(formData[field]).forEach((insurance) => {
        if (insurance === formData.insurance.value.value) {
          data[insurance] = {};
          Object.keys(formData[field][insurance]).forEach((key) => {
            data[insurance][key] = formData[field][insurance][key].value;
          });
        }
      });
    }
    return data;
  };

  const saveContract = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/customContracts`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        agent_id: agent.id,
        insurance: formData.insurance.value.value,
        product: formData.product.value.value,
        risk: formData.risk.value.value,
        channel: formData.channel.value.value,
        commission: prepObjectData('commission'),
        login: formData.login.value,
        password: formData.password.value,
        active: formData.active.value,
        is_polis_online_contract: formData.is_polis_online_contract.value,
        auth_data: prepObjectData('authData'),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.id) {
          setShowForm(false);
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const updateContract = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/customContracts/${selectedContract}`, {
      method: 'put',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        agent_id: agent.id,
        insurance: formData.insurance.value.value,
        risk: formData.risk.value.value,
        product: formData.product.value.value,
        channel: formData.channel.value.value,
        commission: prepObjectData('commission'),
        login: formData.login.value,
        password: formData.password.value,
        active: formData.active.value,
        is_polis_online_contract: formData.is_polis_online_contract.value,
        auth_data: prepObjectData('authData'),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          setSelectedContract(false);
          setShowForm(false);
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const handleChangeQuota = (insurance, bank, e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      commission: {
        ...prev.commission,
        [bank]: {
          ...prev.commission[bank],
          value,
        },
      },
    }));
  };

  const downloadContract = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/customContracts/${selectedContract}`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.id) {
          const commission = {};
          Object.entries(response.commission).forEach(([key, value]) => {
            commission[key] = {
              value,
              errorMessage: '',
              validationType: 'percent',
              validationRequired: true,
            };
          });
          const updatedFormData = {
            ...formData,
            active: {
              ...formData.active,
              value: response.active,
            },
            is_polis_online_contract: {
              ...formData.is_polis_online_contract,
              value: response.is_polis_online_contract,
            },
            commission,
            login: {
              ...formData.login,
              value: response.login,
            },
            password: {
              ...formData.password,
              value: response.password,
            },
            insurance: {
              ...formData.insurance,
              value: { value: response.insurance.code, label: response.insurance.name },
            },
            product: {
              ...formData.product,
              value: { value: response.product.code, label: response.product.name },
            },
            risk: {
              ...formData.risk,
              value: response.risk ? { value: response.risk.code, label: response.risk.name } : { value: null, label: 'Общий' },
            },
            channel: {
              ...formData.channel,
              value: channelOptions.find(({ value }) => value === response.channel),
            },
          };
          if (Object.prototype.hasOwnProperty.call(updatedFormData.authData, response.insurance.code)) {
            const companyFields = response.auth_data[response.insurance.code];
            if (response.auth_data[response.insurance.code]) {
              Object.entries(companyFields).forEach(([field, value]) => {
                if (Object.prototype.hasOwnProperty.call(updatedFormData.authData[response.insurance.code], field)) {
                  updatedFormData.authData[response.insurance.code][field].value = value;
                }
              });
            }
          }
          setFormData(updatedFormData);
        } else {
          showModalInfo('Ошибка', 'error');
        }
      })
      .catch((err) => {
        showModalInfo(err, 'error');
      })
      .finally(() => {
        setInitFlag(true);
      });
  };

  useEffect(() => {
    if (formData.insurance.value.value && formData.product.value.value === 'mortgage') {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      getBanks(token, formData.insurance.value.value).then((response) => {
        const commission = {};
        const banksOptions = response.map((value) => {
          commission[value.bank.code] = {
            value: false,
            errorMessage: '',
            validationType: 'percent',
            validationRequired: true,
          };
          return ({ value: value.bank.code, label: value.bank.name });
        });
        setBanks(banksOptions);
        if (initFlag) {
          setFormData((prev) => ({
            ...prev,
            commission,
          }));
        }
      });
    }

    const insuranceValue = formData.insurance.value.value;
    const authDataCopy = { ...formData.authData };
    Object.keys(authDataCopy).forEach((authType) => {
      const fieldsToUpdate = {};
      const authData = authDataCopy[authType];
      const validationReq = insuranceValue === authType;

      Object.keys(authData).forEach((field) => {
        fieldsToUpdate[field] = {
          value: validationReq ? authData[field].value : '',
          errorMessage: '',
          validationRequired: validationReq,
        };
      });

      authDataCopy[authType] = fieldsToUpdate;
    });
    setFormData((prev) => ({
      ...prev,
      authData: authDataCopy,
    }));
  }, [formData.insurance.value.value]);

  useEffect(() => {
    if (initFlag) {
      setFormData((prev) => ({
        ...prev,
        commission: {},
        insurance: {
          ...prev.insurance,
          value: false,
          errorMessage: '',
        },
      }));
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      getCompanies(token, formData.product.value.value, 'direct').then((response) => {
        const companiesOptions = Object.entries(response).map((company) => ({ value: company[0], label: company[1].name }));
        setCompanies(companiesOptions);
      });
    }
  }, [formData.product.value.value]);

  useEffect(() => {
    if (products.length === 0) {
      getProducts(true).then((response) => {
        setFormData((prev) => ({
          ...prev,
          product: {
            ...prev.product,
            value: { label: response[0].name, value: response[0].code },
          },
        }));
        const risks = {};
        response.forEach((item) => {
          risks[item.code] = item.object_insurance.map((insurance) => ({
            value: insurance.code,
            label: insurance.name,
          }));
          risks[item.code].push({
            value: null,
            label: 'Общий',
          });
        });
        setRisksOptions(risks);
        setProducts(response.map((product) => ({ value: product.code, label: product.name })));
      });
    }
    if (selectedContract) {
      downloadContract();
    } else {
      setInitFlag(true);
    }
  }, []);

  const renderField = (label, fieldName, authType, additionalField = '') => {
    const fieldData = formData.authData[authType][fieldName];
    return (
      <div className="col-lg-3 col-sm-12" key={fieldName}>
        <label htmlFor={fieldName}>{label}</label>
        <InputMask
          maskChar=""
          onChange={(e) => handleChange(e, fieldName, formData, setFormData, `authData.${authType}${additionalField}`)}
          placeholder="123456"
          id={fieldName}
          name={fieldName}
          className={classnames('form-control', { error: fieldData.errorMessage })}
          value={fieldData.value}
        />
        {fieldData.errorMessage && (
          <div className="validation-error">
            {fieldData.errorMessage}
          </div>
        )}
      </div>
    );
  };

  const renderFieldsByInsurance = () => {
    const insuranceType = formData.insurance.value.value;

    if (!insuranceType || !formData.authData[insuranceType]) return null;
    const authData = formData.authData[insuranceType];
    const fieldNames = Object.keys(authData);

    const fieldGroups = [];
    for (let i = 0; i < fieldNames.length; i += 4) {
      const fields = fieldNames.slice(i, i + 4).map((fieldName) => {
        const field = authData[fieldName];
        return renderField(fieldName, fieldName, insuranceType, '', field.validationRequired);
      });
      fieldGroups.push(
        <div className="form-group row" key={i}>
          {fields}
        </div>,
      );
    }

    return fieldGroups;
  };

  return (
    <>
      {selectedContract ? <>Интеграция № {selectedContract}</> : null}
      <form onSubmit={saveContract}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-12">
                <div className="form-check custom-checkbox">
                  <input
                    onChange={(e) => handleChange(e, 'active', formData, setFormData)}
                    checked={formData.active.value ? formData.active.value : false}
                    className="form-check-input checkbox-styled"
                    type="checkbox"
                    name="active"
                    id="active"
                  />
                  <label className="form-check-label" htmlFor="active">
                    Активировать
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <div className="form-check custom-checkbox">
                  <input
                    onChange={(e) => handleChange(e, 'is_polis_online_contract', formData, setFormData)}
                    checked={formData.is_polis_online_contract.value ? formData.is_polis_online_contract.value : false}
                    className="form-check-input checkbox-styled"
                    type="checkbox"
                    name="is_polis_online_contract"
                    id="is_polis_online_contract"
                  />
                  <label className="form-check-label" htmlFor="is_polis_online_contract">
                    Взаиморасчет через ПО
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="product">Продукт</label>
                <Select
                  isOptionDisabled={(option) => option.disabled}
                  classNamePrefix="react-select"
                  inputId="product"
                  className="form-control-custom"
                  name="product"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдено'}
                  value={formData.product.value}
                  onChange={(e) => handleChange(e, 'product', formData, setFormData)}
                  styles={customStyles}
                  options={products}
                />
                {formData.product.errorMessage && (
                  <div className="validation-error">
                    {formData.product.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="risk">Риск</label>
                <Select
                  isOptionDisabled={(option) => option.disabled}
                  classNamePrefix="react-select"
                  inputId="risk"
                  className="form-control-custom"
                  name="risk"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдено'}
                  value={formData.risk.value}
                  onChange={(e) => handleChange(e, 'risk', formData, setFormData)}
                  styles={customStyles}
                  options={risksOptions && formData.product.value ? risksOptions[formData.product.value.value] : []}
                />
                {formData.risk.errorMessage && (
                  <div className="validation-error">
                    {formData.risk.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="insurance">Страховая компания</label>
                <Select
                  classNamePrefix="react-select"
                  inputId="insurance"
                  name="insurance"
                  placeholder="СК"
                  noOptionsMessage={() => 'Не найдено'}
                  value={formData.insurance.value}
                  styles={customStyles}
                  className={classnames('form-control-custom', { error: formData.insurance.errorMessage })}
                  onChange={(e) => handleChange(e, 'insurance', formData, setFormData)}
                  options={companies}
                />
                {formData.insurance.errorMessage && (
                  <div className="validation-error">
                    {formData.insurance.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="channel">Канал продаж</label>
                <Select
                  isOptionDisabled={(option) => option.disabled}
                  classNamePrefix="react-select"
                  inputId="channel"
                  className="form-control-custom"
                  name="channel"
                  placeholder="Выберите канал продаж"
                  noOptionsMessage={() => 'Не найдено'}
                  value={formData.channel.value}
                  onChange={(e) => handleChange(e, 'channel', formData, setFormData)}
                  styles={customStyles}
                  options={channelOptions}
                />
                {formData.product.errorMessage && (
                  <div className="validation-error">
                    {formData.product.errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="login">Логин</label>
                <InputMask
                  maskChar=""
                  onChange={(e) => handleChange(e, 'login', formData, setFormData)}
                  id="login"
                  placeholder="xxxx"
                  name="login"
                  className={classnames('form-control', { error: formData.login.errorMessage })}
                  value={formData.login.value}
                />
                {formData.login.errorMessage && (
                  <div className="validation-error">
                    {formData.login.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="password">Пароль</label>
                <InputMask
                  maskChar=""
                  onChange={(e) => handleChange(e, 'password', formData, setFormData)}
                  id="password"
                  placeholder="xxxx"
                  name="password"
                  className={classnames('form-control', { error: formData.password.errorMessage })}
                  value={formData.password.value}
                />
                {formData.password.errorMessage && (
                  <div className="validation-error">
                    {formData.password.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {renderFieldsByInsurance()}
        {(formData.product.value.value === 'mortgage' && banks.length > 0) ? (
          <div className="form-group row">
            {
              banks.map((bank) => (
                <React.Fragment key={bank.value}>
                  <label className="col-lg-2 col-form-label mb-1">{bank.label}</label>
                  <div className="col-lg-1 mb-1">
                    <CommissionInput
                      handleInputCommission={handleChangeQuota}
                      value={formData.commission[bank.value] ? formData.commission[bank.value].value : ''}
                      region={bank.value}
                      company={formData.insurance.value}
                    />
                  </div>
                </React.Fragment>
              ))
            }
          </div>
        ) : null}
        {(formData.product.value.value !== 'osago' && formData.product.value.value !== 'mortgage') ? (
          <div className="form-group row">
            <label className="col-lg-2 col-form-label mb-1">Россия</label>
            <div className="col-lg-1 mb-1">
              <CommissionInput
                handleInputCommission={handleChangeQuota}
                value={formData.commission.other ? formData.commission.other.value : ''}
                region="other"
                company={formData.insurance.value}
              />
            </div>
          </div>
        ) : null}
        {formData.product.value.value === 'osago' ? (
          <div className="form-group row">
            {
              regions.map((region) => (
                <React.Fragment key={region.value}>
                  <label className="col-lg-2 col-form-label mb-1">{region.label}</label>
                  <div className="col-lg-1 mb-1">
                    <CommissionInput
                      handleInputCommission={handleChangeQuota}
                      value={formData.commission[region.value] ? formData.commission[region.value].value : ''}
                      region={region.value}
                      company={formData.insurance.value}
                    />
                  </div>
                </React.Fragment>
              ))
            }
          </div>
        ) : null}
        {selectedContract
          ? <button type="button" className="btn btn-success mr-2" onClick={updateContract}>Сохранить</button>
          : <button type="submit" className="btn btn-success mr-2">Добавить</button>}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setSelectedContract(false);
            setShowForm(false);
          }}
        >
          Отмена
        </button>
      </form>
    </>
  );
}
