import React, { useContext, useEffect, useState } from 'react';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import { customFetch } from '../../Utils/Helpers';
import {
  ModalMessagesContext,
} from '../../Utils/ContextsServices/ModalMessagesService';

export default function Finances(props) {
  const { userId } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [curatorId, setCuratorId] = useState(false);

  useEffect(() => {
    if (!curatorId) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/curator`, {
        method: 'get',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error);
          } else {
            setCuratorId(response.id);
          }
        })
        .catch((error) => {
          error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
          showModalInfo(error.customMessage, 'error');
        })
        .finally(() => {
        });
    }
  }, []);

  return (
    <LoadingBanner loadingFlag={!curatorId || !userId}>
      <div style={{ height: '100%' }}>
        <iframe width="100%" height="100%" title="fins" src={`https://fins.money/gateway/api/salescab-agent/v5/partner/agent/webview/auth?code=${userId}&curatorId=${curatorId}&partnerCode=POLISONLINE`} frameBorder="0" />
      </div>
    </LoadingBanner>
  );
}
