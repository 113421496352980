import React, {
  forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, } from '@fortawesome/fontawesome-free-solid';
import {
  addDays, compareAsc, format, isLeapYear, parseISO,
} from 'date-fns';
import { animateScroll as scroll, scroller, } from 'react-scroll';
import queryString from 'query-string';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import {
  customFetch, getCustomFieldOptions, getDeepObjectValue, setDeepObjectValidationFlag, setDeepObjectValue, setNewPartnerForDigitalBroker, validateFields,
} from '../../Utils/Helpers';
import getValidation from '../../Utils/Validation';
import ZprCheckInfo from './ZprCheckInfo';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import ZprShortForm from './ZprShortForm';
import ZprForm from './ZprForm';
import ZprChosenOffer from './ZprChosenOffer';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

function ZprFormContainer(props, ref) {
  const {
    userFullTimeEmployee,
    userRole,
    policyStatus,
    policy,
    history,
    copyDraft,
    hideCommission,
    match,
    location,
    remoteEmployee,
    domain,
    userDigitalBroker,
  } = props;

  const genderOptions = [
    { value: 'M', label: 'Мужской' },
    { value: 'F', label: 'Женский' },
  ];

  const urlParams = useMemo(() => queryString.parse(location.search), []);

  const insuredAddressRegistrationRef = useRef();
  const creditAgreementAddressRef = useRef();

  const { showModalInfo } = useContext(ModalMessagesContext);

  const commercialOfferRef = useRef(null);

  const [customFieldName, setCustomFieldName] = useState('');
  const [customField, setCustomField] = useState(null);
  const [customFieldOptions, setCustomFieldOptions] = useState([]);
  const [linkToDeal, setLinkToDeal] = useState('');
  const [leadSource, setLeadSource] = useState('');
  const [managerAttracted, setManagerAttracted] = useState(null);
  const [leadSourcesOptions, setLeadSourcesOptions] = useState([]);
  const [managerAttractedOptions, setManagerAttractedOptions] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [errorBlockName, setErrorBlockName] = useState('');
  const [loadInfoFlags, setLoadingInfoFlags] = useState({
    companies: true,
    integrationsClassifiers: false,
    banks: true,
  });
  const [loadingZprInfo, setLoadingZprInfo] = useState(true);
  const [draftInfo, setDraftInfo] = useState({
    hash: 0,
    id: 0,
    type: 'zpr',
  });
  const [showZprCheckInfo, setShowZprCheckInfo] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [blurClass, setBlurClass] = useState('');
  const [newAgent, setNewAgent] = useState({ value: false, label: false });

  const [formData, setFormData] = useState({
    lastName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    firstName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    middleName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    lastNameInsured: {
      value: 'Иванов',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    firstNameInsured: {
      value: 'Иван',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    middleNameInsured: {
      value: 'Иванович',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    passport: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportNumberSerie',
    },
    birthday: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    passportDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    insuredAddress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    insuredAddressRegistration: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    gender: {
      value: genderOptions[0],
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    insuranceLimit: {
      value: '0',
    },
    insuranceLimitOptions: {
      value: {
        label: '210 000',
        value: '210000',
      },
      errorMessage: '',
      validationRequired: false,
      validationType: 'select',
    },
    period: {
      value: {
        value: 1,
        label: 'На год',
      },
      errorMessage: '',
      errorVisible: false,
      hintVisible: false,
      validationType: 'select',
    },
    addressRegistrationFlag: {
      value: false,
    },
    contactPhone: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'phone',
    },
    contactEmail: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'email',
    },
    startDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    endDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    passportDivision: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    passportUnitNumber: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportUnitNumber',
    },
    personalDataFlag: {
      value: false,
      errorMessage: '',
      validationRequired: false,
      validationType: 'checkbox',
    },
    contractInfoFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    insuranceRulesFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    birthPlace: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
  });
  const [companies, setCompanies] = useState({});
  const [chosenOffer, setChosenOffer] = useState({});
  const [scrollTo, setScrollTo] = useState('');
  const [goCalcFlag, setGoCalcFlag] = useState(false);
  const [phoneCheckRequestFlag, setPhoneCheckRequestFlag] = useState(false);
  const [showForm, setShowForm] = useState('short');
  const [loadingInit, setLoadingInit] = useState(false);
  const [selectedSort, setSelectedSort] = useState('commissionCurrency');
  const [policySortOrderUp, setPolicySortOrderUp] = useState(false);
  const [policyIntegrationsData, setPolicyIntegrationsData] = useState({
    zpr: [],
  });
  const [allPolices, setAllPolices] = useState({
    zpr: [],
  });
  const [showCommission, setShowCommission] = useState(true);
  const [commercialOfferCompanies, setCommercialOfferCompanies] = useState([]);
  const [showCommercialOfferModalForm, setShowCommercialOfferModalForm] = useState('');
  const [loadingCommercialOffer, setLoadingCommercialOffer] = useState(false);

  useEffect(() => {
    if (showForm !== 'short') {
      setShowForm(draftInfo.type);
    }
  }, [draftInfo.type]);

  const prepValueToValidate = (value, fieldName) => {
    if (value && typeof value === 'string') {
      value = value.trim();
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    if (typeof value === 'string'
      && fieldName === 'passport'
    ) {
      value = value.replace(/ +/g, '');
    }
    return value;
  };

  const postProcessingDate = (value, fieldName, errorMessage) => {
    let resetErrorMessage = errorMessage;
    if (fieldName === 'startDate' && formData.startDate.value && formData.endDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть больше даты окончания';
    } else if (fieldName === 'endDate' && formData.endDate.value && formData.startDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть меньше даты начала';
    }
    return resetErrorMessage;
  };

  const postProcessingAddress = (name, value) => {
    let registrationAddressFlag = formData.addressRegistrationFlag.value;
    if (name === 'insuredAddress') {
      registrationAddressFlag = false;
    }
    if (name === 'insuredAddressRegistration') {
      registrationAddressFlag = value === formData.insuredAddress.value.value;
    }

    setFormData((prev) => ({
      ...prev,
      addressRegistrationFlag: {
        ...prev.addressRegistrationFlag,
        value: registrationAddressFlag,
      },
    }));
  };

  const handleValidate = (e, args = {}) => {
    let fieldName = '';
    if (e) {
      const { target } = e;
      fieldName = target.id;
    } else {
      fieldName = args.fieldName;
    }
    const path = args.path ? `${args.path}.${fieldName}` : fieldName;
    let value = getDeepObjectValue(formData, path);
    let errorMessage = value.error;
    value = value.data;
    if (errorMessage) {
      errorMessage = value.error;
    } else {
      value = prepValueToValidate(value, fieldName);

      errorMessage = getValidation(value, formData[fieldName].validationType);

      if (fieldName === 'multiSelect' && (formData[fieldName].value === null || !formData[fieldName].value.length)) {
        errorMessage = 'Выберите хотя бы один вариант';
      }

      if (fieldName === 'startDate'
        || fieldName === 'endDate') {
        errorMessage = postProcessingDate(value, fieldName, errorMessage);
      }

      if (fieldName === 'insuredAddress'
        || fieldName === 'insuredAddressRegistration') {
        postProcessingAddress();
      }
    }
    setDeepObjectValue(formData, setFormData, path, value, errorMessage);
  };

  const setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  const setCursorToEnd = (e) => {
    e.target.onfocus = setAddressEnd;
  };

  const toggleFlag = (e) => {
    const { target } = e;
    setFormData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value: !prev[target.id].value,
        errorMessage: '',
      },
    }));
  };

  const getCompaniesList = (type) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getCompanies(lsToken, type).then((response) => {
      setCompanies(response);
      setLoadingInfoFlags((prev) => ({
        ...prev,
        companies: false,
      }));
    });
  };

  const getPrivacyPolicy = () => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/privacy_policy`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((response) => {
        setPrivacyPolicy(response.path);
      });
  };

  const makeFormDataFlatJson = () => {
    const flatJson = {};
    Object.keys(formData).forEach((key) => {
      flatJson[key] = formData[key].value;
    });

    return flatJson;
  };

  const validateForm = () => {
    let formFields;
    if (showForm === 'short') {
      formFields = {
        startDate: formData.startDate,
        endDate: formData.endDate,
        birthday: formData.birthday,
      };
      formFields.startDate.validationRequired = true;
      formFields.endDate.validationRequired = true;
      formFields.birthday.validationRequired = true;
    } else {
      formFields = { ...formData };
    }
    const validationResult = validateFields(formFields);
    let newErrorBlockName = '';
    newErrorBlockName = Object.keys(validationResult.result).find(((key) => validationResult.result[key].errorMessage));
    const newFormData = { ...formData };
    Object.keys(validationResult.result).forEach((field) => {
      newFormData[field] = validationResult.result[field];
    });
    setFormData(newFormData);
    if (newErrorBlockName) {
      setErrorBlockName(newErrorBlockName);
      setScrollTo('errorBlock');
    }
    return validationResult.validationSuccess;
  };

  const saveDraft = (e, goCalc = false, skipValidation = false) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    const controller = new AbortController();
    const formIsValid = skipValidation || validateForm();
    if (formIsValid) {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

      const data = makeFormDataFlatJson();
      data.passportDate = data.passportDate ? `${format(data.passportDate, 'yyyy-MM-dd')} 00:00:00` : '';
      data.birthday = data.birthday ? `${format(data.birthday, 'yyyy-MM-dd')} 00:00:00` : '';
      data.startDate = data.startDate ? `${format(data.startDate, 'yyyy-MM-dd')} 00:00:00` : '';
      data.endDate = data.endDate ? `${format(data.endDate, 'yyyy-MM-dd')} 00:00:00` : '';

      if (draftInfo.id) {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${draftInfo.id}`, {
          method: 'put',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: 'zpr',
            formData: data,
            service: true,
            product: draftInfo.type,
            leadSource,
            linkToDeal,
            managerAttracted,
            customField: customField ? customField.value : null,
            newAgent: newAgent.value,
          }),
        }, controller).then((response) => response.json())
          .then(() => {
            if (goCalc) {
              if (showForm === 'short') {
                setGoCalcFlag(true);
              } else {
                setShowZprCheckInfo(true);
                setScrollTo('check_info');
              }
            } else {
              if (showCommercialOfferModalForm) {
                if (showCommercialOfferModalForm === 'send') {
                  commercialOfferRef.current.open();
                  setShowCommercialOfferModalForm('');
                } else {
                  setLoadingCommercialOffer(true);
                  commercialOfferRef.current.getPdf(e);
                }
              }
              showModalInfo('Черновик сохранен');
            }
          })
          .catch(() => {
            showModalInfo('Ошибка');
          });
      } else {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
          method: 'post',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: 'zpr',
            formData: data,
            service: true,
            product: draftInfo.type,
            leadSource,
            linkToDeal,
            managerAttracted,
            customField: customField ? customField.value : null,
            newAgent: newAgent.value,
          }),
        }).then((response) => response.json())
          .then((newDraft) => {
            setDraftInfo((prev) => ({
              ...prev,
              id: newDraft.id,
              hash: newDraft.hash,
            }));
            if (goCalc) {
              setGoCalcFlag(true);
            } else {
              showModalInfo('Черновик сохранен');
            }
          })
          .catch(() => {
            showModalInfo('Ошибка');
          });
      }
    }
  };

  const loadPolicyData = () => {
    const draftData = policy.form_data;
    let newStartDate = null;
    let newEndDate = null;
    let diffDays = 0;
    if (policy.status !== 'complete' && policy.status !== 'processing') {
      if (draftData.startDate && compareAsc(parseISO(draftData.startDate), new Date()) === 1) {
        newStartDate = parseISO(draftData.startDate.substring(0, 10));
        diffDays = 0;
      } else {
        newStartDate = new Date(new Date().setHours(0, 0, 0, 0));
        newStartDate.setDate(newStartDate.getDate() + 1);
        newStartDate = new Date(newStartDate);
        if (draftData.startDate) {
          const oldStartDate = parseISO(draftData.startDate.substring(0, 10));
          const diffTime = Math.abs(newStartDate - oldStartDate);
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
      }
    } else if (draftData.startDate) {
      newStartDate = parseISO(draftData.startDate.substring(0, 10));
    }
    if ((draftData.endDate && compareAsc(parseISO(draftData.startDate), new Date()) === 1) || policy.status === 'complete' || policy.status === 'processing') {
      newEndDate = parseISO(draftData.endDate.substring(0, 10));
    } else if (newStartDate) {
      if (draftData.endDate) {
        newEndDate = new Date(parseISO(draftData.endDate));
        newEndDate = newEndDate.setDate(newEndDate.getDate() + diffDays);
        if (isLeapYear(new Date(newEndDate)) && !isLeapYear(new Date(draftData.endDate))) {
          newEndDate = addDays(newEndDate, 1);
        }
        newEndDate = new Date(newEndDate);
      } else {
        newEndDate = new Date();
        newEndDate = newEndDate.setFullYear(newStartDate.getFullYear() + 1);
        newEndDate = new Date(newEndDate);
      }
    }

    const birthday = draftData.birthday ? parseISO(draftData.birthday.substring(0, 10)) : '';
    const passportDate = draftData.passportDate ? parseISO(draftData.passportDate.substring(0, 10)) : '';
    const genderValue = draftData.gender && genderOptions.filter((option) => option.value === draftData.gender.value).length ? draftData.gender : genderOptions[0];
    const insLimOptions = draftData.insuranceLimitOptions ? draftData.insuranceLimitOptions : { label: '210 000', value: '210000' };
    const insLimValue = draftData.insuranceLimit ? draftData.insuranceLimit : '0';
    const errorMessageStartDate = newStartDate && newEndDate && newEndDate < newStartDate ? 'Не может быть больше даты окончания' : '';
    const errorMessageEndDate = newEndDate && newStartDate && newEndDate < newStartDate ? 'Не может быть меньше даты начала' : '';
    setDraftInfo({
      type: policy.type_insurance,
      id: policy.id,
      hash: policy.hash,
    });
    setLeadSource(policy.lead_source ? policy.lead_source : null);
    setManagerAttracted(policy.manager_attracted ? policy.manager_attracted : null);
    setCustomField(policy.custom_field ? { label: policy.custom_field.name, value: policy.custom_field.id } : null);
    setLinkToDeal(policy.link_to_deal ? policy.link_to_deal : null);
    const data = {
      lastName: {
        ...formData.lastName,
        value: draftData.lastName,
      },
      firstName: {
        ...formData.firstName,
        value: draftData.firstName,
      },
      middleName: {
        ...formData.middleName,
        value: draftData.middleName,
      },
      passport: {
        ...formData.passport,
        value: draftData.passport ? draftData.passport : '',
      },
      birthday: {
        ...formData.birthday,
        value: birthday,
      },
      passportDate: {
        ...formData.passportDate,
        value: passportDate,
      },
      insuredAddress: {
        ...formData.insuredAddress,
        value: draftData.insuredAddress,
      },
      insuredAddressRegistration: {
        ...formData.insuredAddressRegistration,
        value: draftData.insuredAddressRegistration,
      },
      gender: {
        ...formData.gender,
        value: genderValue,
      },
      insuranceLimit: {
        ...formData.insuranceLimit,
        value: insLimValue,
      },
      insuranceLimitOptions: {
        ...formData.insuranceLimitOptions,
        value: insLimOptions,
      },
      period: {
        ...formData.period,
        value: draftData.period,
      },
      addressRegistrationFlag: {
        ...formData.addressRegistrationFlag,
        value: draftData.addressRegistrationFlag,
      },
      contactPhone: {
        ...formData.contactPhone,
        value: draftData.contactPhone,
      },
      contactEmail: {
        ...formData.contactEmail,
        value: draftData.contactEmail,
      },
      startDate: {
        ...formData.startDate,
        value: newStartDate,
        errorMessage: formData.startDate.errorMessage ? formData.startDate.errorMessage : errorMessageStartDate,
      },
      endDate: {
        ...formData.endDate,
        value: newEndDate,
        errorMessage: formData.endDate.errorMessage ? formData.endDate.errorMessage : errorMessageEndDate,
      },
      passportDivision: {
        ...formData.passportDivision,
        value: draftData.passportDivision ? draftData.passportDivision : '',
      },
      passportUnitNumber: {
        ...formData.passportUnitNumber,
        value: draftData.passportUnitNumber ? draftData.passportUnitNumber : '',
      },
      personalDataFlag: {
        ...formData.personalDataFlag,
        value: draftData.personalDataFlag || false,
      },
      contractInfoFlag: {
        ...formData.contractInfoFlag,
        value: draftData.contractInfoFlag || false,
      },
      insuranceRulesFlag: {
        ...formData.insuranceRulesFlag,
        value: draftData.insuranceRulesFlag || false,
      },
      birthPlace: {
        ...formData.birthPlace,
        value: draftData.birthPlace || '',
      },
    };
    setFormData(data);
    if (policy.type_integration && policy.company) {
      setChosenOffer({
        companyCode: policy.company_code,
        companyName: policy.company,
        integration: policy.type_integration,
        price: policy.cost,
      });
      setShowForm(policy.type_insurance);
    }

    setNewPartnerForDigitalBroker(policy, setNewAgent);

    setDisableField(policy.status !== 'pending');
    setLoadingZprInfo(false);
  };

  useEffect(() => {
    if (formData.period.value.value === 1) {
      if (formData.startDate.value) {
        let newEndDate = null;
        newEndDate = new Date(formData.startDate.value.getTime());
        newEndDate = newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        newEndDate = new Date(newEndDate);
        newEndDate = newEndDate.setDate(newEndDate.getDate() - 1);
        newEndDate = new Date(newEndDate);

        setFormData((prev) => ({
          ...prev,
          endDate: {
            ...prev.endDate,
            value: newEndDate,
            errorMessage: '',
          },
        }));
      }
    }
  }, [formData.startDate.value, formData.period.value.value]);

  const copyForm = () => {
    copyDraft();
    setLoadingInit(false);
    setShowForm('short');
    setShowZprCheckInfo(false);
    setChosenOffer({});
    setPolicyIntegrationsData({
      zpr: [],
    });
    setAllPolices({
      zpr: [],
    });
    setDisableField(false);
  };

  const goToBuying = (e) => {
    e.preventDefault();
    if (!policy.is_demo) {
      saveDraft(e, true);
    } else {
      setShowZprCheckInfo(true);
    }
  };

  const goToFullForm = (e, companyCode, price, companyName, integration) => {
    setChosenOffer({
      companyCode,
      companyName,
      integration,
      price,
    });
    if (companyCode === 'renessans') {
      const newFormData = { ...formData };
      newFormData.insuranceLimit.value = formData.insuranceLimitOptions.value.value;
      setFormData(newFormData);
    }
    setShowForm(draftInfo.type);
    setScrollTo('chosen-offer');
  };

  const backToForm = (e, scrollTarget = '', onlyShowData = false) => {
    e.preventDefault();
    setShowZprCheckInfo(false);
    if (scrollTarget === 'short') {
      setShowForm('short');
      if (!allPolices[draftInfo.type].length && !onlyShowData) {
        setGoCalcFlag(true);
        scrollTarget = 'searchResults';
      } else {
        scrollTarget = 'zpr_container';
      }
    }
    setScrollTo(scrollTarget);
  };

  useEffect(() => {
    if (scrollTo !== '') {
      switch (scrollTo) {
        case 'searchResults':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -70,
          });
          break;
        case 'chosen-offer':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        case 'top':
          scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: true,
          });
          break;
        case 'errorBlock':
          scroller.scrollTo(errorBlockName, {
            duration: 1200,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        default:
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
      }
    }
    setScrollTo('');
  }, [scrollTo]);

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
  }));

  useEffect(() => {
    if (policy.id !== 0) {
      if (policy.status !== 'pending') {
        getCompaniesList('all');
      } else {
        getCompaniesList('zpr');
      }
    } else if (!match.params.id) {
      getCompaniesList('zpr');
    }
  }, [policy.id]);

  useEffect(() => {
    if (!loadInfoFlags.companies) {
      if (match.params.id) {
        if (policy.id !== 0) {
          loadPolicyData(policy.id);
        }
      } else {
        setLoadingZprInfo(false);
      }
    }
  }, [loadInfoFlags.companies, policy.id]);

  useEffect(() => {
    if (draftInfo.id) {
      let link = `/zpr/${draftInfo.id}`;
      if (urlParams.adminEdit) {
        link = `/zpr/${draftInfo.id}?adminEdit=1`;
      }
      window.history.pushState(null, null, link);
    }
  }, [draftInfo.id]);

  useEffect(() => {
    if (draftInfo.id) {
      setBlurClass('blur');
    }
  }, [draftInfo.type]);

  useEffect(() => {
    if (formData.startDate.value) {
      let newEndDate = null;
      newEndDate = new Date(formData.startDate.value.getTime());
      newEndDate = newEndDate.setFullYear(newEndDate.getFullYear() + 1);
      newEndDate = new Date(newEndDate);
      newEndDate = newEndDate.setDate(newEndDate.getDate() - 1);
      newEndDate = new Date(newEndDate);

      setFormData((prev) => ({
        ...prev,
        endDate: {
          ...prev.endDate,
          value: newEndDate,
          errorMessage: '',
        },
      }));
    }
    if (!formData.startDate.errorMessage && formData.endDate.value && formData.endDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        endDate: {
          ...prev.endDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.startDate.value]);

  useEffect(() => {
    if (formData.addressRegistrationFlag.value) {
      setFormData((prev) => ({
        ...prev,
        insuredAddressRegistration: {
          ...prev.insuredAddressRegistration,
          value: prev.insuredAddress.value,
          errorMessage: '',
          validationRequired: true,
        },
      }));
      if (insuredAddressRegistrationRef.current) {
        insuredAddressRegistrationRef.current.state.query = formData.insuredAddress.value.value;
        insuredAddressRegistrationRef.current.state.inputQuery = formData.insuredAddress.value.value;
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        insuredAddressRegistration: {
          ...prev.insuredAddressRegistration,
          value: {
            value: '',
            data: {},
          },
          errorMessage: '',
          validationRequired: true,
        },
      }));
      if (insuredAddressRegistrationRef.current) {
        insuredAddressRegistrationRef.current.state.query = '';
        insuredAddressRegistrationRef.current.state.inputQuery = '';
      }
    }
  }, [formData.addressRegistrationFlag.value]);

  useEffect(() => {
    if (!formData.endDate.errorMessage && formData.startDate.value && formData.startDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        startDate: {
          ...prev.startDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.endDate.value]);

  useEffect(() => {
    if (userFullTimeEmployee === 1 && process.env.REACT_APP_API_DOMAIN === 'https://api.polis.online/api') {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/lead_sources`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setLeadSourcesOptions(response.map((source) => ({ value: source.id, label: source['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/managers`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setManagerAttractedOptions(response.map((manager) => ({ value: manager.id, label: manager['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
    }
  }, [userFullTimeEmployee]);

  useEffect(() => {
    const flagPaths = {
      lastName: showCommercialOfferModalForm || showForm !== 'short',
      firstName: showCommercialOfferModalForm || showForm !== 'short',
      middleName: showCommercialOfferModalForm || showForm !== 'short',
      startDate: showCommercialOfferModalForm || showForm !== 'short',
      endDate: showCommercialOfferModalForm || showForm !== 'short',
      contactPhone: showCommercialOfferModalForm || showForm !== 'short',
      contactEmail: showCommercialOfferModalForm || showForm !== 'short',
      birthday: showForm === 'short' && !showCommercialOfferModalForm,
      gender: showForm === 'short' && !showCommercialOfferModalForm,
      passport: showForm !== 'short' && !showCommercialOfferModalForm,
      passportDate: showForm !== 'short' && !showCommercialOfferModalForm,
      insuredAddress: showForm !== 'short' && !showCommercialOfferModalForm,
      insuredAddressRegistration: showForm !== 'short' && !showCommercialOfferModalForm,
      passportDivision: showForm !== 'short' && !showCommercialOfferModalForm,
      passportUnitNumber: showForm !== 'short' && !showCommercialOfferModalForm,
      personalDataFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      contractInfoFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      insuranceRulesFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      birthPlace: showForm !== 'short' && !showCommercialOfferModalForm,
    };

    setDeepObjectValidationFlag(formData, setFormData, flagPaths);
  }, [showForm, showCommercialOfferModalForm, chosenOffer.companyCode]);

  useEffect(() => {
    getPrivacyPolicy();
    getCustomFieldOptions().then((response) => {
      if (response.options) {
        setCustomFieldOptions(response.options.map((option) => ({ value: option.id, label: option.name })));
        setCustomFieldName(response.name);
      }
    });
  }, []);

  return (
    <>
      {(policyStatus === 'processing' || policyStatus === 'calculated') && (
        <FormNotificationColorLine offsetBottom="1rem">Данный черновик был проведен до стадии формирования ссылки на оплату и заблокирован для дальнейших изменений. Если вы хотите произвести повторный расчет - скопируйте черновик.</FormNotificationColorLine>
      )}
      {showZprCheckInfo && (
        <ZprCheckInfo
          history={history}
          formData={formData}
          companies={companies}
          backToForm={backToForm}
          chosenOffer={chosenOffer}
          setChosenOffer={setChosenOffer}
          draftInfo={draftInfo}
          setDisableField={setDisableField}
          blurClass={blurClass}
          policy={policy}
        />
      )}
      <div name="zpr_container" style={{ display: showZprCheckInfo ? 'none' : 'block' }}>
        <LoadingBanner loadingFlag={loadingZprInfo}>
          {!chosenOffer.companyCode || showForm === 'short' ? (
            <ZprShortForm
              disableField={disableField}
              formData={formData}
              setFormData={setFormData}
              saveDraft={saveDraft}
              handleValidate={handleValidate}
              genderOptions={genderOptions}
              companies={companies}
              hideCommission={hideCommission}
              goToFullForm={goToFullForm}
              draftInfo={draftInfo}
              goCalcFlag={goCalcFlag}
              setGoCalcFlag={setGoCalcFlag}
              blurClass={blurClass}
              setBlurClass={setBlurClass}
              showForm={showForm}
              toggleFlag={toggleFlag}
              backToForm={backToForm}
              loadingInit={loadingInit}
              setLoadingInit={setLoadingInit}
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              policySortOrderUp={policySortOrderUp}
              setPolicySortOrderUp={setPolicySortOrderUp}
              policyIntegrationsData={policyIntegrationsData}
              setPolicyIntegrationsData={setPolicyIntegrationsData}
              allPolices={allPolices}
              setAllPolices={setAllPolices}
              showCommission={showCommission}
              setShowCommission={setShowCommission}
              commercialOfferCompanies={commercialOfferCompanies}
              setCommercialOfferCompanies={setCommercialOfferCompanies}
              setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
              showCommercialOfferModalForm={showCommercialOfferModalForm}
              commercialOfferRef={commercialOfferRef}
              setLoadingCommercialOffer={setLoadingCommercialOffer}
              setScrollTo={setScrollTo}
              policy={policy}
              setCursorToEnd={setCursorToEnd}
            />
          ) : null}
          {showForm === 'zpr' ? (
            <>
              {chosenOffer.companyCode ? (
                <ZprChosenOffer
                  disableField={disableField}
                  chosenOffer={chosenOffer}
                  companies={companies}
                  backToForm={backToForm}
                  formData={formData}
                  draftInfo={draftInfo}
                />
              ) : null}
              <div className="form-group row">
                <div className="col-lg-12">
                  <h3>Оформление страхового полиса</h3>
                </div>

                {!policy.is_demo && !disableField ? (
                  <div className="pl-3">
                    <a rel="noopener noreferrer" href="#" onClick={(e) => backToForm(e, 'short')} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {showForm !== 'short' ? (
            <ZprForm
              customFieldOptions={customFieldOptions}
              setCustomField={setCustomField}
              customFieldName={customFieldName}
              customField={customField}
              domain={domain}
              linkToDeal={linkToDeal}
              leadSource={leadSource}
              setLeadSource={setLeadSource}
              managerAttracted={managerAttracted}
              setManagerAttracted={setManagerAttracted}
              setLinkToDeal={setLinkToDeal}
              remoteEmployee={remoteEmployee}
              leadSourcesOptions={leadSourcesOptions}
              managerAttractedOptions={managerAttractedOptions}
              userRole={userRole}
              userFullTimeEmployee={userFullTimeEmployee}
              setFormData={setFormData}
              companies={companies}
              privacyPolicy={privacyPolicy}
              formData={formData}
              disableField={disableField}
              genderOptions={genderOptions}
              handleValidate={handleValidate}
              toggleFlag={toggleFlag}
              setCursorToEnd={setCursorToEnd}
              insuredAddressRegistrationRef={insuredAddressRegistrationRef}
              creditAgreementAddressRef={creditAgreementAddressRef}
              phoneCheckRequestFlag={phoneCheckRequestFlag}
              policy={policy}
              urlParams={urlParams}
              saveDraft={saveDraft}
              goToBuying={goToBuying}
              draftInfo={draftInfo}
              chosenOffer={chosenOffer}
              newAgent={newAgent}
              userDigitalBroker={userDigitalBroker}
              setNewAgent={setNewAgent}
            />
          ) : null}
        </LoadingBanner>
      </div>
    </>
  );
}

export default forwardRef(ZprFormContainer);
