import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import ThemeSwitcher from 'react-css-vars';
import TopBarProgress from 'react-topbar-progress-indicator';
import './css/font-awesome-4.7.0/css/font-awesome.min.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import 'react-table-6/react-table.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'rc-slider/assets/index.css';
import './css/index.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/translucent.css';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Favicon from 'react-favicon';
import Login from './components/Auth/Login';
import Registration from './components/Auth/Registration';
import App from './components/App';
import TechWork from './components/Layout/TechWork';
import ResetPassword from './components/Auth/ResetPassword';
import ResetPhone from './components/Auth/ResetPhone';
import ResetEmail from './components/Auth/ResetEmail';
import RefWidgetPage from './components/RefWidgetPage/RefWidgetPage';
import { unregister } from './serviceWorker';
import ThankYou from './components/Layout/ThankYou';
import PaymentFail from './components/Layout/PaymentFail';
import SetYandexOauthToken from './components/Widgets/SetYandexOauthToken';
import ModalMessagesService from './components/Utils/ContextsServices/ModalMessagesService';
import ModalInfoList from './components/Utils/ModalNotification/ModalInfoList';
import { lightenDarkenColor } from './components/Utils/Helpers';
import useUserInfoStore from './components/Stores/UserInfoStore';

unregister();

TopBarProgress.config({
  barColors: {
    0: '#ffffff',
    0.5: '#808080',
    1.0: '#000000',
  },
  barThickness: 3,
  shadowBlur: 0,
});

function Init() {
  const [theme, setData] = useState({ theme: {} });
  const token = `Bearer ${localStorage.getItem('id_token')}`;
  async function fetchData() {
    const res = await fetch(`${process.env.REACT_APP_API_DOMAIN}/theme?domain=${process.env.REACT_APP_API_DOMAIN === 'http://212.193.56.155/api' ? 'localhost:3000' : window.location.host.split('.')[0]}`, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await res.json();
    data.subColorLighter = lightenDarkenColor(data.subColor, 130);
    data.mainColorDarker = lightenDarkenColor(data.mainColor, -25);
    data.mainColorLighter = lightenDarkenColor(data.mainColor, 100);
    setData(data);
    useUserInfoStore.setState({ theme: data });
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    theme.id ? (
      <ThemeSwitcher theme={theme}>
        <ModalMessagesService>
          <ModalInfoList />
          <Favicon url={[`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.favicon}`]} />
          <Router>
            <Switch>
              <Route exact path="/set-yandex-oauth-token" render={(props) => <SetYandexOauthToken {...props} />} />
              <Route exact path="/tech_work" render={(props) => <TechWork theme={theme} {...props} />} />
              <Route exact path="/login" render={(props) => <Login theme={theme} {...props} />} />
              <Route exact path="/registration" render={(props) => <Registration {...props} theme={theme} />} />
              <Route path="/reset" render={(props) => <ResetPassword {...props} theme={theme} />} />
              <Route path="/reset_phone" render={(props) => <ResetPhone {...props} theme={theme} />} />
              <Route path="/reset_email" render={(props) => <ResetEmail {...props} theme={theme} />} />
              <Route path="/ref/osago" render={(props) => { const decoratedProps = { ...props, product: 'osago' }; return (<RefWidgetPage { ...decoratedProps } theme={theme} />); }} />
              <Route path="/ref/mortgage" render={(props) => { const decoratedProps = { ...props, product: 'mortgage' }; return (<RefWidgetPage { ...decoratedProps } theme={theme} />); }} />
              <Route path="/ref/zpr" render={(props) => { const decoratedProps = { ...props, product: 'zpr' }; return (<RefWidgetPage { ...decoratedProps } theme={theme} />); }} />
              <Route path="/ref/mortgageLife" render={(props) => { const decoratedProps = { ...props, product: 'mortgageLife' }; return (<RefWidgetPage { ...decoratedProps } theme={theme} />); }} />
              <Route path="/thank_you" render={(props) => <ThankYou {...props} theme={theme} />} />
              <Route path="/payment_fail" render={(props) => <PaymentFail {...props} theme={theme} />} />
              <Route path="/" render={(props) => <App {...props} theme={theme} />} />
            </Switch>
          </Router>
        </ModalMessagesService>
      </ThemeSwitcher>
    )
      : (<TopBarProgress />)
  );
}

render(<Init />, document.getElementById('react-app'));
