import React, { useMemo } from 'react';
import Select from 'react-select';
import { ReactDadata } from 'react-dadata';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  subYears, addDays, addYears,
} from 'date-fns';
import { faCalendarAlt, faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import Tippy from '@tippyjs/react';
import getMask from '../../Utils/Masks';
import { customSelectStyle, handleChange } from '../../Utils/Helpers';
import AccountSystem from '../Blocks/AccountSystem';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

export default function ZprForm(props) {
  const {
    customFieldName,
    companies,
    privacyPolicy,
    formData,
    disableField,
    goToBuying,
    handleValidate,
    toggleFlag,
    setCursorToEnd,
    genderOptions,
    insuredAddressRegistrationRef,
    phoneCheckRequestFlag,
    policy,
    urlParams,
    saveDraft,
    draftInfo,
    chosenOffer,
    setFormData,
    userFullTimeEmployee,
    userRole,
    linkToDeal,
    leadSource,
    managerAttracted,
    setLeadSource,
    setManagerAttracted,
    setLinkToDeal,
    remoteEmployee,
    leadSourcesOptions,
    managerAttractedOptions,
    customFieldOptions,
    setCustomField,
    customField,
    newAgent,
    userDigitalBroker,
    setNewAgent,
    domain,
  } = props;

  const customStyles = useMemo(() => customSelectStyle(), []);

  const periodOptions = [
    { value: 1, label: 'На год' },
    // { value: 2, label: 'На период' },
  ];

  const docsBlock = (
    <>
      <div className="form-group row">
        <div className="col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <label htmlFor="passport">Серия и номер паспорта</label>
          <InputMask
            maskChar=""
            onBlur={handleValidate}
            disabled={disableField}
            onChange={(e) => handleChange(e, 'passport', formData, setFormData)}
            id="passport"
            placeholder="1111 111111"
            name="passport"
            className={classnames('form-control', { error: formData.passport.errorMessage })}
            mask={getMask('passportNumberSerie').mask}
            value={formData.passport.value}
            inputMode="tel"
          />
          {formData.passport.errorMessage && (
            <div className="validation-error">
              {formData.passport.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-4 col-xl-3">
          <label htmlFor="passportDate">Дата выдачи паспорта</label>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            disabled={disableField}
            onBlur={() => handleValidate(null, { fieldName: 'passportDate' })}
            onCalendarClose={() => handleValidate(null, { fieldName: 'passportDate' })}
            onClickOutside={() => handleValidate(null, { fieldName: 'passportDate' })}
            className={classnames('form-control', { error: formData.passportDate.errorMessage })}
            selected={formData.passportDate.value ? formData.passportDate.value : null}
            onChange={(e) => handleChange(e, 'passportDate', formData, setFormData)}
            name="passportDate"
            id="passportDate"
            locale={ru}
            maxDate={new Date()}
            minDate={formData.birthday.value ? addYears(formData.birthday.value, 14) : subYears(new Date(), 100)}
            showYearDropdown
            showMonthDropdown
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
          <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
          {formData.passportDate.errorMessage && (
            <div className="validation-error">
              {formData.passportDate.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-4 col-xl-3">
          <label htmlFor="birthday">Дата рождения</label>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            disabled={disableField || formData.birthday.value}
            onBlur={() => handleValidate(null, { fieldName: 'birthday' })}
            onCalendarClose={() => handleValidate(null, { fieldName: 'birthday' })}
            onClickOutside={() => handleValidate(null, { fieldName: 'birthday' })}
            className={classnames('form-control', { error: formData.birthday.errorMessage })}
            selected={formData.birthday.value ? formData.birthday.value : null}
            onChange={(e) => handleChange(e, 'birthday', formData, setFormData)}
            name="birthday"
            id="birthday"
            locale={ru}
            maxDate={new Date()}
            minDate={subYears(new Date(), 100)}
            showYearDropdown
            showMonthDropdown
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
          <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
          {formData.birthday.errorMessage && (
            <div className="validation-error">
              {formData.birthday.errorMessage}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-12 col-xl-9">
          <label htmlFor="passportDivision">Кем выдан</label>
          <Tippy
            className="tippy-custom"
            arrow={false}
            offset={[0, 5]}
            content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
            hideOnClick={false}
          >
            <div className="d-inline-block">
              <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
            </div>
          </Tippy>
          <InputMask
            maskChar=""
            onBlur={handleValidate}
            disabled={disableField}
            onChange={(e) => handleChange(e, 'passportDivision', formData, setFormData)}
            id="passportDivision"
            name="passportDivision"
            placeholder=""
            className={classnames('form-control', { error: formData.passportDivision.errorMessage })}
            value={formData.passportDivision.value}
          />
          {formData.passportDivision.errorMessage && (
            <div className="validation-error">
              {formData.passportDivision.errorMessage}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-4 col-xl-3">
          <label htmlFor="passportUnitNumber">Код подразделения</label>
          <InputMask
            maskChar=""
            onBlur={handleValidate}
            disabled={disableField}
            onChange={(e) => handleChange(e, 'passportUnitNumber', formData, setFormData, null, { pureValue: true })}
            id="passportUnitNumber"
            name="passportUnitNumber"
            mask={getMask('passportUnitNumber').mask}
            placeholder="123-456"
            formatChars={getMask('passportUnitNumber').formatChars}
            className={classnames('form-control', { error: formData.passportUnitNumber.errorMessage })}
            value={formData.passportUnitNumber.value}
          />
          {formData.passportUnitNumber.errorMessage && (
            <div className="validation-error">
              {formData.passportUnitNumber.errorMessage}
            </div>
          )}
        </div>
        <div className="col-lg-3 mb-3 mb-lg-0">
          <label htmlFor="gender">Пол</label>
          <Select
            classNamePrefix="react-select"
            styles={customStyles}
            onBlur={handleValidate}
            isDisabled={disableField || formData.gender.value.value}
            name="gender"
            noOptionsMessage={() => 'Не найдено'}
            inputId="gender"
            isSearchable={false}
            value={formData.gender.value.value ? formData.gender.value : null}
            onChange={(e) => handleChange(e, 'gender', formData, setFormData)}
            options={genderOptions}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-12 col-xl-6">
          <label htmlFor="birthPlace">Место рождения</label>
          <Tippy
            className="tippy-custom"
            arrow={false}
            offset={[0, 5]}
            content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
            hideOnClick={false}
          >
            <div className="d-inline-block">
              <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
            </div>
          </Tippy>
          <InputMask
            maskChar=""
            onBlur={handleValidate}
            disabled={disableField}
            onChange={(e) => handleChange(e, 'birthPlace', formData, setFormData)}
            id="birthPlace"
            name="birthPlace"
            className={classnames('form-control', { error: formData.birthPlace.errorMessage })}
            value={formData.birthPlace.value}
          />
          {formData.birthPlace.errorMessage && (
            <div className="validation-error">
              {formData.birthPlace.errorMessage}
            </div>
          )}
        </div>
      </div>
    </>
  );

  return (
    <form name="zprForm" id="zpr_form">
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">1</span>
          <span>Страхователь</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_1">
              <label htmlFor="lastName">Фамилия</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="lastName"
                name="lastName"
                placeholder="Иванов"
                query={formData.lastName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'lastName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['SURNAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.lastName.errorMessage })}
              />
              {formData.lastName.errorMessage && (
                <div className="validation-error">
                  {formData.lastName.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_2">
              <label htmlFor="firstName">Имя</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="firstName"
                name="firstName"
                placeholder="Иван"
                query={formData.firstName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'firstName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['NAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.firstName.errorMessage })}
              />
              {formData.firstName.errorMessage && (
                <div className="validation-error">
                  {formData.firstName.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label htmlFor="middleName">Отчество</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="middleName"
                name="middleName"
                placeholder="Иванович"
                query={formData.middleName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'middleName', formData, setFormData, null, { dadataType: 'fio', multiWords: true, firstCapital: true })}
                parts={['PATRONYMIC']}
                disabled={disableField}
                translate
                className={classnames('form-control', { error: formData.middleName.errorMessage })}
              />
              {formData.middleName.errorMessage && (
                <div className="validation-error">
                  {formData.middleName.errorMessage}
                </div>
              )}
            </div>
          </div>
          {docsBlock}
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insuredAddress">Фактический адрес</label>
              <ReactDadata
                id="insuredAddress"
                name="insuredAddress"
                className={classnames('form-control', { error: formData.insuredAddress.errorMessage })}
                query={formData.insuredAddress.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insuredAddress', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField}
                onFocus={setCursorToEnd}
              />
              {formData.insuredAddress.errorMessage && (
                <div className="validation-error">
                  {formData.insuredAddress.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className="form-check custom-checkbox">
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.addressRegistrationFlag.value}
                  name="addressRegistrationFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="addressRegistrationFlag"
                />
                <label className="form-check-label" htmlFor="addressRegistrationFlag">Фактический адрес и адрес регистрации совпадают</label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-6">
              <label htmlFor="insuredAddressRegistration">Адрес регистрации</label>
              <ReactDadata
                id="insuredAddressRegistration"
                name="insuredAddressRegistration"
                className={classnames('form-control', { error: formData.insuredAddressRegistration.errorMessage })}
                query={formData.insuredAddressRegistration.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onBlur={handleValidate}
                onChange={(e) => handleChange(e, 'insuredAddressRegistration', formData, setFormData)}
                placeholder="Можете начать с ввода улицы"
                disabled={disableField || formData.addressRegistrationFlag.value}
                onFocus={setCursorToEnd}
                ref={insuredAddressRegistrationRef}
              />
              {formData.insuredAddressRegistration.errorMessage && (
                <div className="validation-error">
                  {formData.insuredAddressRegistration.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">2</span>
          <span>Данные о полисе</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="period">Тип полиса</label>
              <Select
                classNamePrefix="react-select"
                styles={customStyles}
                isDisabled={disableField || formData.period.value.value === 1}
                onBlur={handleValidate}
                name="period"
                inputId="period"
                isSearchable={false}
                value={formData.period.value.value ? formData.period.value : null}
                onChange={(e) => handleChange(e, 'period', formData, setFormData)}
                options={periodOptions}
              />
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="startDate">Дата начала</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField}
                onBlur={() => handleValidate(null, { fieldName: 'startDate' })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'startDate' })}
                onClickOutside={() => handleValidate(null, { fieldName: 'startDate' })}
                className={classnames('form-control', { error: formData.startDate.errorMessage })}
                selected={formData.startDate.value ? formData.startDate.value : null}
                onChange={(e) => handleChange(e, 'startDate', formData, setFormData)}
                name="startDate"
                id="startDate"
                locale={ru}
                minDate={new Date()}
                maxDate={addDays(new Date(), 60)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.startDate.errorMessage && (
                <div className="validation-error">
                  {formData.startDate.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="endDate">Дата окончания</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField || formData.period.value.value === 1}
                onBlur={() => handleValidate(null, { fieldName: 'endDate' })}
                onCalendarClose={() => handleValidate(null, { fieldName: 'endDate' })}
                onClickOutside={() => handleValidate(null, { fieldName: 'endDate' })}
                className={classnames('form-control', { error: formData.endDate.errorMessage })}
                selected={formData.endDate.value ? formData.endDate.value : null}
                onChange={(e) => handleChange(e, 'endDate', formData, setFormData)}
                name="endDate"
                id="endDate"
                locale={ru}
                minDate={addDays(new Date(), 1)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.endDate.errorMessage && (
                <div className="validation-error">
                  {formData.endDate.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">3</span>
          <span>Контактная информация</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="contactPhone">Телефон</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'contactPhone', formData, setFormData)}
                id="contactPhone"
                placeholder="+7 (999) 999-99-99"
                name="contactPhone"
                className={classnames('form-control', { error: formData.contactPhone.errorMessage })}
                mask={getMask('phone').mask}
                formatChars={getMask('phone').formatChars}
                value={formData.contactPhone.value}
              />
              {formData.contactPhone.errorMessage && (
                <div className="validation-error">
                  {formData.contactPhone.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="contactEmail">Email</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'contactEmail', formData, setFormData)}
                id="contactEmail"
                placeholder="your@email.ru"
                name="contactEmail"
                className={classnames('form-control', { error: formData.contactEmail.errorMessage })}
                value={formData.contactEmail.value}
                mask={getMask('email').mask}
                formatChars={getMask('email').formatChars}
              />
              {formData.contactEmail.errorMessage && (
                <div className="validation-error">
                  {formData.contactEmail.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <SimpleNotification>Вы получите электронную версию документа на указанный адрес электронной почты</SimpleNotification>
            </div>
          </div>
          {phoneCheckRequestFlag ? (
            <div className="form-group row">
              <div className="col-12">
                <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={20} width={20} />
                Проверяем телефон...
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">4</span>
          <span>Финальные условия</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-12">
              <div className={classnames('custom-checkbox form-check', { error: formData.personalDataFlag.errorMessage })}>
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.personalDataFlag.value}
                  name="personalDataFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="personalDataFlag"
                />
                <label className="form-check-label" htmlFor="personalDataFlag">
                  Согласен с передачей <a rel="noreferrer" className="dotted_link" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${privacyPolicy}`}>персональных данных</a>
                </label>
                {formData.personalDataFlag.errorMessage && (
                  <div className="validation-error">
                    {formData.personalDataFlag.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className={classnames('custom-checkbox form-check', { error: formData.contractInfoFlag.errorMessage })}>
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.contractInfoFlag.value}
                  name="contractInfoFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="contractInfoFlag"
                />
                <label className="form-check-label" htmlFor="contractInfoFlag">Подтверждаю полноту и достоверность предоставленных данных в договоре страхования</label>
                {formData.contractInfoFlag.errorMessage && (
                  <div className="validation-error">
                    {formData.contractInfoFlag.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className={classnames('custom-checkbox form-check', { error: formData.insuranceRulesFlag.errorMessage })}>
                <input
                  disabled={disableField}
                  onChange={toggleFlag}
                  checked={formData.insuranceRulesFlag.value}
                  name="insuranceRulesFlag"
                  className="form-check-input checkbox-styled"
                  type="checkbox"
                  id="insuranceRulesFlag"
                />
                <label className="form-check-label" htmlFor="insuranceRulesFlag">
                  {companies[chosenOffer.companyCode].rules && companies[chosenOffer.companyCode].rules[draftInfo.type] && companies[chosenOffer.companyCode].rules[draftInfo.type] ? (
                    <>Согласен с <a rel="noreferrer" className="dotted_link" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].rules[draftInfo.type]}`}>условиями страхования</a></>
                  ) : (
                    <>Согласен с правилами страхования</>
                  )}
                </label>
                {formData.insuranceRulesFlag.errorMessage && (
                  <div className="validation-error">
                    {formData.insuranceRulesFlag.errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <SimpleNotification>Осуществляя покупку полиса и заполняя настоящие формы, я подтверждаю, что даю согласие выбранной страховой компании на обработку персональных данных и информации, в том числе на сбор, систематизацию, блокирование, уничтожение персональных данных и иные действия, предусмотренные Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» в целях определения условий и заключения со мной договора страхования, проведения маркетинговых исследований. При этом такое согласие я даю на неограниченный срок и могу отозвать согласие в любое время путем передачи страховой компании соответствующего уведомления. Безопасность передаваемой информации обеспечивается с помощью современных протоколов обеспечения безопасности в интернете (SSL/TLS).</SimpleNotification>
            </div>
          </div>
        </div>
      </div>
      {userFullTimeEmployee === 1 || customFieldOptions.length > 0 ? (
        <AccountSystem
          customFieldName={customFieldName}
          customFieldOptions={customFieldOptions}
          customField={customField}
          setCustomField={setCustomField}
          userFullTimeEmployee={userFullTimeEmployee}
          blockNumber="8"
          userRole={userRole}
          disableField={disableField}
          setLinkToDeal={setLinkToDeal}
          linkToDeal={linkToDeal}
          leadSourcesOptions={leadSourcesOptions}
          leadSource={leadSource}
          setLeadSource={setLeadSource}
          remoteEmployee={remoteEmployee}
          managerAttractedOptions={managerAttractedOptions}
          managerAttracted={managerAttracted}
          setManagerAttracted={setManagerAttracted}
          customStyles={customStyles}
          newAgent={newAgent}
          setNewAgent={setNewAgent}
          domain={domain}
          digitalBroker={userDigitalBroker}
        />
      ) : null}
      <div className="form-row row-grid">
        <div className="col-lg-12">
          {!policy.is_demo || urlParams.adminEdit ? (
            <button disabled={disableField} type="button" className="btn btn-success mr-3 mb-3" onClick={saveDraft}>Сохранить</button>
          ) : null}
          <button disabled={disableField} type="button" className="btn btn-success mr-3 mb-3" onClick={(e) => goToBuying(e)}>Оформить</button>
        </div>
      </div>
    </form>
  );
}
