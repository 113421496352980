import React, { useEffect, useMemo, useState } from 'react';
import ReactLoading from 'react-loading';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import getMask from '../../Utils/Masks';
import getValidation from '../../Utils/Validation';
import { customSelectStyle, switchLanguage, validateFields } from '../../Utils/Helpers';

const typeOptions = [
  { value: 'carNumber', label: 'Гос. номер' },
  { value: 'vin', label: 'ВИН' },
];

export default function CheckOsagoForm(props) {
  const {
    requestWaitingFlag,
    checkPolicy,
  } = props;

  const customStyles = useMemo(() => customSelectStyle());
  const [type, setType] = useState(typeOptions[0]);
  const [formData, setFormData] = useState({
    vin: {
      value: '',
      errorMessage: '',
      validationType: 'vin',
      validationRequired: true,
    },
    carNumber: {
      value: '',
      errorMessage: '',
      validationType: 'carNumber',
      validationRequired: true,
    },
  });

  useEffect(() => {
    if (type.value === 'vin') {
      setFormData((prev) => ({
        ...prev,
        vin: {
          ...prev.vin,
          validationRequired: true,
        },
        carNumber: {
          ...prev.carNumber,
          validationRequired: false,
        },
      }));
    } else if (type.value === 'carNumber') {
      setFormData((prev) => ({
        ...prev,
        vin: {
          ...prev.vin,
          validationRequired: false,
        },
        carNumber: {
          ...prev.carNumber,
          validationRequired: true,
        },
      }));
    }
  }, [type.value]);

  const getInfo = (e) => {
    e.preventDefault();
    const parameters = [];
    const validatedFields = validateFields(formData);
    setFormData({ ...validatedFields.result });
    if (validatedFields.validationSuccess) {
      if (type.value === 'vin') {
        parameters.push({ checkType: 'vin', value: formData.vin.value });
      }
      if (type.value === 'carNumber') {
        parameters.push({ checkType: 'regNumber', value: formData.carNumber.value });
      }
      checkPolicy(parameters);
    }
  };

  const validateInput = (e) => {
    const fieldName = e.target.name;
    const { value } = e.target;
    const errorMessage = getValidation(value, formData[fieldName].validationType);
    setFormData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        errorMessage,
      },
    }));
  };

  const changeInput = (e, translate = false, upperCase = false) => {
    e.preventDefault();
    const { target } = e;
    const fieldName = target.id;

    let { value } = target;
    if (translate) {
      value = switchLanguage(value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }

    setFormData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        value,
        errorMessage: '',
      },
    }));
  };

  return (
    <form onSubmit={getInfo}>
      <div className="card mb-3">
        <div className="form-group row">
          <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
            <Select
              classNamePrefix="react-select"
              inputId="insuranceType"
              className="form-control-custom"
              name="insuranceType"
              placeholder="Тип"
              noOptionsMessage={() => 'Не найдено'}
              value={type}
              styles={customStyles}
              onChange={(e) => setType(e)}
              options={typeOptions}
            />
          </div>
        </div>
        {type.value === 'vin' ? (
          <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
              <label htmlFor="vin">VIN</label>
              <InputMask
                maskChar=""
                onBlur={validateInput}
                onChange={changeInput}
                id="vin"
                placeholder="ААА12345ААААААААА"
                name="vin"
                className={classnames('form-control upperCase', { error: formData.vin.errorMessage })}
                mask={getMask('vin').mask}
                formatChars={getMask('vin').formatChars}
                value={formData.vin.value}
              />
              {formData.vin.errorMessage && (
                <div className="validation-error">
                  {formData.vin.errorMessage}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="form-group row">
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
              <label htmlFor="carNumber">Гос. номер</label>
              <InputMask
                maskChar=""
                onBlur={validateInput}
                onChange={(e) => changeInput(e, true, true)}
                id="carNumber"
                placeholder="А111АА111"
                name="carNumber"
                className={classnames('form-control column-margin upperCase', { error: formData.carNumber.errorMessage })}
                mask={getMask('carNumber').mask}
                formatChars={getMask('carNumber').formatChars}
                value={formData.carNumber.value}
              />
              {formData.carNumber.errorMessage && (
                <div className="validation-error">
                  {formData.carNumber.errorMessage}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="form-group row">
        <div className="col-12" style={{ display: 'flex' }}>
          <button className="btn btn-success" type="submit" disabled={requestWaitingFlag}>Получить данные</button>
          {requestWaitingFlag && <ReactLoading className="loading-circle ml-2" type="spin" height={37} width={37} />}
        </div>
      </div>
    </form>
  );
}
