import React, {
  useContext, useEffect, useState,
} from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { faCircle } from '@fortawesome/fontawesome-free-solid';
import { format, parseISO } from 'date-fns';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  getDefaultSorting7,
  onFilteredChangeCustom,
  setTableSettings7,
  setTableSorting,
} from '../../Utils/Helpers';
import Table from '../../Layout/Table';

export default function AgentEnforcementProceedingsTable(props) {
  const {
    agent, tab, setSelectedEnforcementProceeding, setShowForm,
  } = props;
  const defaultSorting = getDefaultSorting7('enforcementProceedingsTableSettings');
  const [filtered, setFiltered] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingEnforcementProceedingsFlag, setLoadingEnforcementProceedingsFlag] = useState(false);
  const [enforcementProceedings, setEnforcementProceedings] = useState([]);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const getEnforcementProceedings = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingEnforcementProceedingsFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/enforcementProceeding?agentId=${agent.id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setEnforcementProceedings(response);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error, 'error');
      })
      .finally(() => {
        setLoadingEnforcementProceedingsFlag(false);
      });
  };

  const deleteEnforcementProceeding = (id) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/enforcementProceeding/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          getEnforcementProceedings();
          showModalInfo('ИП удалено');
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error, 'error');
      });
  };

  const updateTableColumns = () => {
    const updatedColumns = [{
      Header: 'Номер ИП',
      accessor: 'number',
      width: reactLocalStorage.getObject('enforcementProceedingsTableSettings').number,
      show: reactLocalStorage.getObject('enforcementProceedingsTableSettings').show_number !== false,
    }, {
      Header: 'Сумма ИП',
      accessor: 'amount',
      width: reactLocalStorage.getObject('enforcementProceedingsTableSettings').amount,
      show: reactLocalStorage.getObject('enforcementProceedingsTableSettings').show_amount !== false,
    }, {
      Header: 'Процент удержания',
      accessor: 'percentage',
      width: reactLocalStorage.getObject('enforcementProceedingsTableSettings').percentage,
      show: reactLocalStorage.getObject('enforcementProceedingsTableSettings').show_percentage !== false,
    }, {
      Header: 'Дата ИП',
      accessor: 'date',
      Cell: ({ row }) => format(parseISO(row.original.date), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('enforcementProceedingsTableSettings').date,
      show: reactLocalStorage.getObject('enforcementProceedingsTableSettings').show_date !== false,
    }, {
      Header: 'Дата оплаты',
      accessor: 'paymentDate',
      Cell: ({ row }) => (row.original.paymentDate ? format(parseISO(row.original.paymentDate), 'dd.MM.yyyy') : ''),
      width: reactLocalStorage.getObject('enforcementProceedingsTableSettings').paymentDate,
      show: reactLocalStorage.getObject('enforcementProceedingsTableSettings').show_paymentDate !== false,
    }, {
      Header: 'Активность',
      accessor: 'active',
      Cell: ({ row }) => {
        if (row.original.paymentDate) {
          return (<><FontAwesomeIcon color="#00E646" icon={faCircle} className="fa-fw" />Оплачен</>);
        }
        if (row.original.active) {
          return (<><FontAwesomeIcon color="#ff5f00" icon={faCircle} className="fa-fw" />Активен</>);
        }
        return (<><FontAwesomeIcon color="#f0f000" icon={faCircle} className="fa-fw" />Приостановлен</>);
      },
      width: reactLocalStorage.getObject('enforcementProceedingsTableSettings').active,
      show: reactLocalStorage.getObject('enforcementProceedingsTableSettings').show_active !== false,
    }, {
      Header: () => null, // No header
      id: 'delete',
      maxWidth: 10,
      disableFilters: true,
      show: !!setSelectedEnforcementProceeding,
      clickable: true,
      Cell: ({ row }) => (
        <div title="Удалить ИП"><FontAwesomeIcon onClick={() => deleteEnforcementProceeding(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    },
    ];
    setColumns(updatedColumns);
  };

  const onTableCellClick = (e, row, cell) => {
    e.preventDefault();
    if (!cell.column.clickable && setSelectedEnforcementProceeding) {
      setSelectedEnforcementProceeding(row.original.id);
      setShowForm(true);
    }
  };

  useEffect(() => {
    if (agent.id) {
      getEnforcementProceedings();
      updateTableColumns();
    }
  }, []);
  return (
    <Table
      onTableCellClick={onTableCellClick}
      defaultSorted={defaultSorting}
      defaultFiltered={filtered}
      data={enforcementProceedings}
      columns={columns}
      loading={loadingEnforcementProceedingsFlag}
      noDataText="Интеграции не найдены"
      onSortedChange={(newSort) => {
        setTableSorting(newSort, 'enforcementProceedingsTableSettings');
      }}
      onResizedChange={(newResized) => {
        setTableSettings7(newResized, 'enforcementProceedingsTableSettings');
      }}
      onFilteredChange={(newFiltered) => {
        setFiltered(onFilteredChangeCustom(newFiltered, null, tab));
      }}
    />
  );
}
