import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import styled from 'styled-components';
import CustomIcon from './CustomIcon';
import NavbarUserProfile from './NavbarUserProfile/NavbarUserProfile';
import NavbarUserBalance from './NavbarUserBalance/NavbarUserBalance';
import UserNotificationsIcon from '../Utils/UiComponents/UserNotificationsIcon/UserNotificationsIcon';
import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

const MenuIcon = styled.div`
  & > div{
    cursor: pointer;
    margin-right: 16px;
    color: ${(props) => (props.theme.mainColor)}
  }
`;

const Nav = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  padding: 10px 32px 10px 18px;
  background-color: #fff;
  flex-shrink: 0;

  @media (max-width: 767px) {
    padding: 10px 16px;
  }

  &:after{
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    z-index: 1;
    content: "";
    width: 100%;
    height: 1px;
    opacity: 1;
    background-color: #e9e9e9;
    transition: left .15s ease;
    }
`;

const NavContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img { 
    max-height: 35px;
    max-width: 180px;
  }

  @media (max-width: 767px) {
    img { 
      max-width: 155px;
    }
  }
`;

const UserNav = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 767px) {
      margin-left: auto;
  }
`;

export default function Navbar(props) {
  const {
    toggleSidebar, setSidebarFull, sidebarIsFull, sidebarActive, isFramed, theme, userInfo, history, handleLogout, faq,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const notificationsTippy = useRef(null);
  const [notificationsTippyActiveFlag, setNotificationsTippyActiveFlag] = useState(false);

  const [notificationsList, setNotificationsList] = useState([]);

  const loadNotifications = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/user-notifications/${userInfo.id}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          setNotificationsList(response.data);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при загрузке уведомлений');
      });
  };

  useEffect(() => {
    if (userInfo.id) {
      loadNotifications();
    }
  }, [userInfo.id]);

  useEffect(() => () => {
    if (!sidebarActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [sidebarActive]);

  const openSideBar = () => {
    if (window.innerWidth < 769) {
      if (notificationsTippyActiveFlag) {
        notificationsTippy.current.click();
      }
      toggleSidebar(!sidebarActive);
    } else {
      setSidebarFull(!sidebarIsFull);
    }
  };

  return (
    <Nav>
      <NavContainer>
        <MenuIcon>
          <CustomIcon icon="menu" width="24px" height="24px" onClick={openSideBar} />
        </MenuIcon>
        {!isFramed ? (
          <a aria-label="Оформить осаго" className="navbar-brand" href="/products">
            <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${theme.logoNav}`} alt="" />
          </a>
        ) : null }
        <UserNav>
          <NavbarUserProfile
            userInfo={userInfo}
            theme={theme}
            handleLogout={handleLogout}
            notificationsList={notificationsList}
            notificationsTippy={notificationsTippy}
            notificationsTippyActiveFlag={notificationsTippyActiveFlag}
            faq={faq}
          />
          <NavbarUserBalance theme={theme} userInfo={userInfo} />
          <UserNotificationsIcon
            history={history}
            theme={theme}
            notificationsList={notificationsList}
            setNotificationsList={setNotificationsList}
            notificationsTippy={notificationsTippy}
            notificationsTippyActiveFlag={notificationsTippyActiveFlag}
            setNotificationsTippyActiveFlag={setNotificationsTippyActiveFlag}
          />
        </UserNav>
      </NavContainer>
    </Nav>
  );
}
